import React, { Component } from "react";
import { Container } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

// importaciones de modulos
import Loading from '../../../../components/loading/Loading';
import Footer from "../../../../components/footer/Footer";
import axiosInstance from "../../../../utils/axios";
import HeaderAdmin from "../../../../components/header-admin/HeaderAdmin";
import ListAdmins from "../../components/list-admins/ListAdmins";
import ListProviders from "../../components/list-providers/ListProviders";

class DashboardMaster extends Component {
    constructor() {
        super();

        this.state = {
            user: JSON.parse(localStorage.getItem('user')),
            loading: false,
            listAdmins: [],
            listProviders: [],
            listProvidersExport: [],
            anchorEl: null,
            user_id: '',
            paginatorAdmins: {
                page: 1,
                size: 10,
                totalPages: 0,
                totalItems: 0
            },
            paginatorProviders: {
                page: 1,
                size: 5,
                totalPages: 0,
                totalItems: 0
            },
            tabValue: 0
        }
    }

    loadInfoDataAdmins = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`users/info/getAdmins?filter=&page=${(this.state.paginatorAdmins.page - 1)}&size=${this.state.paginatorAdmins.size}`,)
                .then((get) => {
                    if (get.data) {
                        get.data.admins.map((element) => {
                            element.openDepartments = false;
                        });

                        this.setState({
                            ...this.state,
                            listAdmins: get.data.admins,
                            loading: false,
                            paginatorAdmins: {
                                ...this.state.paginatorAdmins,
                                page: get.data.currentPage + 1,
                                totalItems: get.data.totalItems,
                                totalPages: get.data.totalPages
                            }
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    loadInfoDataProviders = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`providers/forManager?filter=&page=${(this.state.paginatorProviders.page - 1)}&size=${this.state.paginatorProviders.size}`,)
                .then((get) => {
                    if (get.data) {
                        var temp = [];
                        get.data.providers.map((element) => {
                            element.openDepartments = false;
                            var departments = '';

                            element.departments.map((ele, index) => {
                                if (index !== (element.departments.length - 1)) {
                                    departments += ele.department.name + ' | ';
                                } else {
                                    departments += ele.department.name;
                                }
                            });
                        });
                        this.setState({
                            listProviders: get.data.providers,
                            listProvidersExport: [],
                            loading: false,
                            paginatorProviders: {
                                ...this.state.paginatorProviders,
                                page: get.data.currentPage + 1,
                                totalItems: get.data.totalItems,
                                totalPages: get.data.totalPages
                            }
                        });

                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    handleChangeTab = (event, value) => {
        console.log(value);
        this.setState({
            ...this.state,
            tabValue: value
        });
    }

    handleChangePageAdmins = (value) => {
        this.setState({
            paginatorAdmins: {
                ...this.state.paginatorAdmins,
                page: value
            }
        });
        this.loadInfoDataAdmins();
    };

    handleOpenDepartmentsAdmins = (admin) => {
        const data = [];
        this.state.listAdmins.forEach((element) => {
            if (admin.id === element.id) {
                element.openDepartments = !element.openDepartments;
            } else {
                element.openDepartments = false;
            }
            data.push(element);
        });

        this.setState({
            ...this.state,
            listAdmins: data
        });
    }

    handleClick = (anchorEl, user_id) => {
        this.setState({
            ...this.state,
            anchorEl,
            user_id
        });
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            anchorEl: null
        });
    };

    handleSelect = (action) => {
        this.setState({
            ...this.state,
            anchorEl: null
        });

        const data = {
            status: action
        }

        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.put(`users/${this.state.user_id}`, data);
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.success("Información del proveedor actializada con éxito!");
                    this.loadInfoData();
                } catch (error) {
                    // Error 😨
                    if (error.response) {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }
    }

    handleChangePageProviders = (value) => {
        this.setState({
            paginatorProviders: {
                ...this.state.paginatorProviders,
                page: value
            }
        });
        this.loadInfoDataProviders();
    };

    handleOpenDepartmentsProviders = (admin) => {
        const data = [];
        this.state.listProviders.forEach((element) => {
            if (admin.id === element.id) {
                element.openDepartments = !element.openDepartments;
            } else {
                element.openDepartments = false;
            }
            data.push(element);
        });

        this.setState({
            ...this.state,
            listProviders: data
        });
    }

    handleGetInfoToExportExcel = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`providers/forManager?filter=&page=0&size=${this.state.paginatorProviders.totalItems}`,)
                .then((get) => {
                    if (get.data) {
                        var temp = [];
                        get.data.providers.map((element) => {
                            element.openDepartments = false;
                            var departments = '';

                            element.departments.map((ele, index) => {
                                if (index !== (element.departments.length - 1)) {
                                    departments += ele.department.name + ' | ';
                                } else {
                                    departments += ele.department.name;
                                }
                            });
                            var warehouses = '';

                            element.warehouses.map((ele, index) => {
                                if (index !== (element.warehouses.length - 1)) {
                                    warehouses += ele.warehouse.initials + ' | ';
                                } else {
                                    warehouses += ele.warehouse.initials;
                                }
                            });
                            temp.push({
                                id: element.provider_id,
                                provider_id: element.provider_id,
                                business_name: element.business_name,
                                rfc: element.rfc,
                                status: element.status === 'active' ? 'Activo' : element.status === 'inactive' ? 'Inactivo' : 'Pendiente',
                                ubicacion: element.address,
                                departments,
                                warehouses,
                                cant_products: element.cant_products,
                                schedules: element.schedule,
                                created_at: moment(element.created_at).format("DD/MM/YYYY"),
                            });
                        });
                        this.setState({
                            ...this.state,
                            listProvidersExport: temp,
                            loading: false
                        });

                        this.clickExportFile();


                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    clickExportFile = () => {
        document.getElementById('file-export').click();
    }

    render() {
        return (
            <>
                <HeaderAdmin
                    title={this.state.user.name}
                    user={this.state.user}
                    type='master' />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Container maxWidth="lg">
                            <Tabs
                                variant="fullWidth"
                                value={this.state.tabValue}
                                onChange={this.handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                centered>
                                <Tab label="Asesores" />
                                <Tab label="Proveedores" />
                            </Tabs>

                            {this.state.tabValue === 0 ? (
                                <>
                                    <ListAdmins
                                        listAdmins={this.state.listAdmins}
                                        handleChangePage={this.handleChangePageAdmins}
                                        handleOpenDepartments ={this.handleOpenDepartmentsAdmins}
                                        paginator={this.state.paginatorAdmins} />
                                </>
                            ) : (
                                <>
                                    <ListProviders 
                                        listProviders={this.state.listProviders} 
                                        listProvidersExport={this.state.listProvidersExport}
                                        anchorEl={this.state.anchorEl}
                                        paginator={this.state.paginatorProviders}
                                        handleClick={this.handleClick}
                                        handleClose={this.handleClose}
                                        handleSelect={this.handleSelect}
                                        handleChangePage={this.handleChangePageProviders}
                                        handleOpenDepartments={this.handleOpenDepartmentsProviders}
                                        handleGetInfoToExportExcel={this.handleGetInfoToExportExcel}/>
                                </>
                            )}
                        </Container>
                    </>
                )}
                <Footer />
            </>
        );
    }

    componentDidMount() {
        this.loadInfoDataAdmins();
        this.loadInfoDataProviders();
    }
}

export default DashboardMaster;