import React, { useState } from "react";
import { useHistory } from 'react-router';
import { getToken } from '../../utils/auth-herpers';
import jwt_decode from "jwt-decode";

// importar estilos
import './sidebar.css';

// importar lista de menues
import sidebar_items from '../../assets/jsonData/sidebar_routes.json';
import sidebar_items_permissions from '../../assets/jsonData/sidebar_routes_permissions.json';

// import images
const images = require.context('../../assets/images/', true);

const SidebarItem = props => {

    const active = props.active ? 'active' : '';
    const image = images(`./${props.active ? props.icon_active : props.icon}`);

    return (
        <li className={`${active}`} onClick={(e) => props.handleClick(props.route)}>
            <img src={image.default} alt="" />
            <span className={`${active}`}>{props.title}</span>
        </li>
    )
}

function SideBarProvider(props) {
    const history = useHistory();

    const handleClick = (route) => {
        console.log(route);
        history.push(route);
    }

    let list = [];

    if (jwt_decode(getToken()).role.group.length) {
        console.log(jwt_decode(getToken()).role.group)
        const groups = jwt_decode(getToken()).role.group.map((group) => group.key.split('_all')[0]);
        list = sidebar_items_permissions.filter((item) => {
            return item.key.find((key) => groups.indexOf(key) != -1);
        });
    } else {
        list = sidebar_items.filter((item) => {
            return item.roles.find((rol) => rol === jwt_decode(getToken()).role.key);
        });
    }

    const url = props.location.pathname.split('/');
    const activeItem = list.findIndex(item => item.route.indexOf(`/${url[1]}/${url[2]}`) !== -1);

    return (
        <>
            <div className="container-sidebar">
                <ul className="list-sidebar">
                    {
                        list.map((item, index) => (
                            <SidebarItem
                                key={index}
                                handleClick={handleClick}
                                title={item.display_name}
                                icon={item.icon}
                                icon_active={item.icon_active}
                                active={index === activeItem}
                                route={item.route}
                            />
                        ))
                    }
                </ul>
            </div>
        </>
    );
}

export default SideBarProvider;