import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// importar estilos
import './register-provider.css';

// importaciones de modulos
import Header from '../../../components/header/Header';
import Loading from '../../../components/loading/Loading';
import Footer from "../../../components/footer/Footer";
import axiosInstance from "../../../utils/axios";
import FormRegister from "../../../components/form-register";

// images
import img_icon_information from '../../../assets/images/icon-information.png';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Inicio',
        url: '/'
    },
    {
        title: 'Registro',
        url: '/register'
    },
    {
        title: '1. Información general'
    }
];

export default function RegisterProvider2() {

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [estadosState, setEstadosState] = useState([]);
    const [idTemp, setIdTemp] = useState(Math.floor(1 + Math.random() * (99999 - 1)));

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            const temp = await axiosInstance.get('/utils/estados');
            setEstadosState(temp.data);
            setLoading(false);
        }
        fetch();
    }, []);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setError,
        getValues,
        setValue
    } = useForm();

    const onSubmit = async (values) => {
        const data = {
            ...values,
            address_state: getValues('address_state').value,
            address_delegation: getValues('address_delegation').value,
            address_suburb: getValues('address_suburb').value,
            number_employees: getValues('number_employees') !== null && getValues('number_employees') !== undefined ? getValues('number_employees').value : '',
            id_temp: idTemp
        }
        try {
            const data2 = await axiosInstance.post('providers', data);

            setLoading(true);
            // this.handleCleanForm();
            localStorage.setItem('provider', JSON.stringify(data2.data.data));
            localStorage.setItem('isRegisterProvider', JSON.stringify(true));
            setLoading(false);
            history.push("/register");
        } catch (error) {
            // Error 😨
            if (error && error.response && error.response?.data) {
                if (error.response?.data?.error) {
                    toast.error(error.response?.data?.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
                else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Header listBreadcrumbs={listBreadcrumbs} title='1. Información general' icon={img_icon_information} />
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <FormRegister
                        estados={estadosState}
                        register={register}
                        errors={errors}
                        control={control}
                        onSubmit={onSubmit}
                        handleSubmit={handleSubmit}
                        getValues={getValues}
                        setValue={setValue}
                        setError={setError}
                        idTemp={idTemp} />
                    <Footer />
                </>
            )}
        </>
    );
}