import React, { useState, useEffect } from 'react';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import HeaderOC from "../../header";
import axiosInstance from "../../../../../utils/axios";
import ListProductsPacklist from '../../../../../components/list-products-packlist';
import ListProductsDeliveries from "./listProducts";
import ModalCancelSheduleDelivery from "../cancel";
import { toast, ToastContainer } from "react-toastify";
import { generateString } from "../../../../../utils";
import ConfirmUnitsModal from "./confirmUnitsModal";
import RescheduleDeliveryModal from "../RescheduleDelivery";

export default function DetailScheduledDeliveries(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [scheduledDelivery, setScheduledDelivery] = useState({});
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [confirmUnitsModal, setConfirmUnitsModal] = useState(false);
    const [randomStr, setRandomStr] = useState();

    const [listBreadcrumbs, setListBreadcrumbs] = useState([
        {
            title: 'Entregas programadas',
            url: '/receipt/deliveries/list'
        },
        {
            title: ''
        }
    ]);

    const onReschedule = async (warehouse, date, hour) => {
        const data = {
            warehouse_id: warehouse,
            date,
            hour
        }
        setLoading(true);
        try {
            let response = await axiosInstance.put(`scheduled_deliveries/reschedule/${scheduledDelivery?.id}`, data);
            toast.info("Reagendamiento realizado con éxito");
            setRandomStr(generateString());
        } catch (e) {
            console.log(e);
            toast.error("No se pudo reagendar la entrega");
        }
        finally {
            setLoading(false);
            setRescheduleModal(false);
        }
    }
    const handleCloseModalReschedule = () => {
        setRescheduleModal(false);
    }

    const handleCloseModalConfirmUnits = () => {
        setConfirmUnitsModal(false);
        setRandomStr(generateString());
    }

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/${props.match.params.id}`);
            setScheduledDelivery(response.data);
            console.log(response.data)
            let temp = [...listBreadcrumbs];
            temp[1].title = scheduledDelivery?.scheduled_delivery_id;
            setListBreadcrumbs(temp);
            setLoading(false);
        }

        fetch();
    }, [randomStr]);

    useEffect(() => {
        let temp = [...listBreadcrumbs];
        temp[1].title = scheduledDelivery?.scheduled_delivery_id;
    }, [scheduledDelivery]);

    const handleConfirmUnits = async (products) => {
        setLoading(true);
        try {
            let response = await axiosInstance.put(`scheduled_deliveries/confirm/${scheduledDelivery?.id}`, { products });
            toast.info("Entrega programada actualizada con éxito");
            console.log("HOLA MUNDOOOOOOO")
            setRandomStr(generateString());
            setConfirmUnitsModal(true);
        } catch (e) {
            console.log(e);
            toast.error("No se pudo actualizar la entrega programada");
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            <HeaderOC
                title={scheduledDelivery?.scheduled_delivery_id}
                user={user}
                view='detail-packlist'
                type='detail'
                listBreadcrumbs={listBreadcrumbs}
                scheduledDelivery={scheduledDelivery}
            />
            <ToastContainer />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <ListProductsDeliveries
                        handleConfirm={handleConfirmUnits}
                        confirmUnitsModal={setConfirmUnitsModal}
                        setReschedule={setRescheduleModal}
                        status={scheduledDelivery?.status}
                        scheduled_delivery_id={scheduledDelivery.id}
                        listProductsView={scheduledDelivery?.products} />
                    {
                        rescheduleModal && (
                            <RescheduleDeliveryModal
                                open={rescheduleModal}
                                handleSaveData={onReschedule}
                                listDataToModal={scheduledDelivery?.products}
                                onClose={handleCloseModalReschedule}
                                scheduled_delivery_id={scheduledDelivery.scheduled_delivery_id}
                            />
                        )
                    }

                    {
                        confirmUnitsModal && (
                            <ConfirmUnitsModal
                                open={confirmUnitsModal}
                                onClose={handleCloseModalConfirmUnits}
                            />
                        )
                    }
                    <Footer />
                </>
            )}
        </>
    )
}