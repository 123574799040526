import { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

//import
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from '../../header/Header';
import Footer from "../../../../../components/footer/Footer";

// images
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import Loading from "../../../../../components/loading/Loading";
import FormUserProvider from "../../../../../components/form-user-provider";

export default function NewUserProviderSuperAdmin(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [listModules, setListModules] = useState([]);
    const [listRoles, setListRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [providerId, setProviderId] = useState(props.match.params.providerId);
    const [provider, setProvider] = useState();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control
    } = useForm();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const listMod = await axiosInstance.get('/modules?filter=&page=0&size=1000&order=name ASC');
                const listRol = await axiosInstance.get(`roles/provider`,);
                const prov = await axiosInstance.get(`/providers/${providerId}`,);

                setListModules(listMod.data.modules);
                setListRoles(listRol.data);
                setProvider(prov.data);
            } catch (error) {
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                } else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            } finally {
                setLoading(false);
            }

        }
        fetch();
    }, []);

    // lista para breadcrounb
    const listBreadcrumbs = [
        {
            title: 'Proveedores',
            url: '/super_admin/providers/list'
        },
        {
            title: provider ? provider.business_name : '',
            url: '/super_admin/providers/edit/'+providerId
        },
        {
            title: 'Nuevo usuario'
        }
    ];


    const onSubmit = async (values) => {
        try {
            setLoading(true);
            await axiosInstance.post(`providers/info/users`, {...values, providerId});
            toast.success('Usuario agreagado con éxito!');
        } catch (error) {
            console.log(error.response);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <HeaderProvider
                title="Nuevo usuario"
                icon={icon_list_products}
                listBreadcrumbs={listBreadcrumbs}
                user={user}
                view='empresa'
                type='detail' />

            {loading ? (
                <Loading />
            ) : (
                <>
                    <FormUserProvider
                        register={register}
                        errors={errors}
                        control={control}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit} 
                        listModules={listModules}
                        listRoles={listRoles}/>
                    < Footer />
                </>
            )}
        </>
    )
}