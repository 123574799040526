import React, { Component } from "react";
import { Container, Grid } from '@material-ui/core';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ExportReact from 'react-export-excel';

// importar estilos
import './list-providers.css';

// imagenes
import img_icon_arrow_logout from '../../../../assets/images/icon-arrow-logout.png';
import img_icon_export_excel from '../../../../assets/images/image-export-excel.png';

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;

class ListProviders extends Component {

    constructor(props) {
        super(props);
    }

    handleClick = (event, user_id) => {
        this.props.handleClick(event.currentTarget, user_id);
    };

    handleClose = () => {
        this.props.handleClose();
    };

    handleSelect = (event, action) => {
        this.props.handleSelect(action);
    }

    handleChangePage = (event, value) => {
        this.props.handleChangePage(value);
    };

    handleOpenDepartments = (provider) => {
        this.props.handleOpenDepartments(provider);
    }

    handleGetInfoToExportExcel = () => {
        this.props.handleGetInfoToExportExcel();
    }

    render() {

        const rowsProviders = this.props.listProviders.map((provider) => {
            return (
                <div className="row-table row-body" key={provider.id}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}>
                            <div className="text-item-table">
                                {provider.provider_id}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="text-item-table">
                                {provider.business_name}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            {provider.departments.length > 2 ? (
                                <>
                                    {provider.openDepartments ? (
                                        <>
                                            {provider.departments.map((department, index) => {
                                                return (
                                                    <div className="text-item-table product department" key={index}>
                                                        {department.department.name}
                                                        <br />
                                                    </div>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {provider.departments.map((department, index) => {
                                                if (index < 2) {
                                                    return (
                                                        <div className="text-item-table product department" key={index}>
                                                            {department.department.name}
                                                            <br />
                                                        </div>
                                                    );
                                                }

                                            })}
                                        </>
                                    )}
                                    <div className="icon-more" onClick={(e) => this.handleOpenDepartments(provider)}>
                                        {provider.openDepartments ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {provider.departments.map((department, index) => {
                                        return (
                                            <div className="text-item-table product department" key={index}>
                                                {department.department.name}
                                                <br />
                                            </div>
                                        );
                                    })}
                                </>
                            )}

                        </Grid>
                        <Grid item xs={2}>
                            <div className="text-item-table not-first product">
                                {provider.cant_products}
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="text-item-table not-first date">
                                {moment(provider.created_at).format("DD/MM/YYYY")}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className={`status ${provider.status === 'pending' ? 'pending' : provider.status === 'inactive' ? 'inactive' : 'active'}`}>
                                <Button className="menu-status" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClick(e, provider.user_id)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8} className="estado">
                                            <span className="desk">
                                                {provider.status === 'pending' ? 'Pendiente' : provider.status === 'inactive' ? 'Inactivo' : 'Activo'}
                                            </span>
                                            <span className="mobile">
                                                {provider.status === 'pending' ? 'P' : provider.status === 'inactive' ? 'I' : 'A'}
                                            </span>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <img src={img_icon_arrow_logout} alt="" />
                                        </Grid>
                                    </Grid>

                                </Button>
                                <Menu
                                    id="long-menu"
                                    anchorEl={this.props.anchorEl}
                                    keepMounted
                                    open={Boolean(this.props.anchorEl)}
                                    onClose={(e) => this.handleClose(e)}>
                                    <MenuItem onClick={(event) => this.handleSelect(event, 'active')}> Activo </MenuItem>
                                    <MenuItem onClick={(event) => this.handleSelect(event, 'inactive')}> Inactivo </MenuItem>
                                </Menu>
                            </div>
                        </Grid>
                    </Grid>
                </div >

            )
        });

        return (
            <>
                <Container maxWidth="lg">
                    <div className="icons-header-table">
                        <img src={img_icon_export_excel} alt="Exportar a excel" className="icon-export" onClick={(e) => this.handleGetInfoToExportExcel()} />
                        <ExcelFile element={<button id="file-export"></button>} filename="Lista proveedores">
                            <ExcelSheet data={this.props.listProvidersExport} name="Hoja 1">
                                    <ExcelColumn label="ID" value="provider_id" />
                                    <ExcelColumn label="Nombre" value="business_name" />
                                    <ExcelColumn label="RFC" value="rfc" />
                                    <ExcelColumn label="Estado" value="status" />
                                    <ExcelColumn label="Ubicación" value="ubicacion" />
                                    <ExcelColumn label="Departamentos" value="departments" />
                                    <ExcelColumn label="Bodegas" value="warehouses" />
                                    <ExcelColumn label="Horarios" value="schedules" />
                                    <ExcelColumn label="Cantidad de productos" value="cant_products" />
                                    <ExcelColumn label="Fecha de registro" value="created_at" />
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                    <div className="row-table row-title">
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <div className="title-table razon">
                                    ID
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="title-table razon">
                                    Razón social
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="title-table not-first product">
                                    Departamentos
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="title-table not-first product">
                                    Productos
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="title-table not-first date">
                                    Fecha registro
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="title-table not-first state">
                                    Estado
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="providers">
                        {rowsProviders}
                        <div className="paginator">
                            <Pagination
                                count={this.props.paginator.totalPages}
                                page={this.props.paginator.page}
                                onChange={this.handleChangePage} />
                        </div>
                    </div>
                </Container>
            </>
        );
    }
}

export default ListProviders;