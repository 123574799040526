import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'


const navigateCONST = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

const translateViews = {
    month: 'Mes',
    day: 'Día',
    week: 'Semana',
}

export default function Toolbar(props) {
    const {
        localizer: { messages },
        label,
    } = props

    const [year, setYear] = useState();
    const [month, setMonth] = useState();

    const navigate = action => {
        props.onNavigate(action)
    }

   const view = view => {
        props.onView(view)
    }

    useEffect(()  => {
        setYear(label?.split(' ')[1])
        setMonth(label?.split(' ')[0])
    },[props.label])
    console.log(props.views)

    const viewNamesGroup = (messages) => {
        let viewNames = props.views
        const currentView = props.view
        console.log(messages)

        if (viewNames.length > 1) {
            return viewNames.map(name => (
                <button
                    type="button"
                    key={name}
                    style={{border: "none",color: currentView === name ? "#2D4838":"rgba(45, 72, 56, .5)", fontSize: 18}}
                    onClick={() => view(name)}
                >
                    {translateViews[name]}
                </button>
            ))
        }
    }

    return (
        <div className="rbc-toolbar">
        <span className="rbc-btn-group">
        </span>
            <span className="rbc-toolbar-label">{month} <span style={{fontWeight: 300, fontSize: 28}}>{year}</span></span>
            <span className="rbc-btn-group">{viewNamesGroup(messages)}</span>
            <button
                type="button"
                className="btn-calendar-prev"
                onClick={() => navigate(navigateCONST.PREVIOUS)}
            >
                {messages.previous}
            </button>
            <button
                className="btn-calendar-next"
                type="button"
                onClick={() => navigate(navigateCONST.NEXT)}
            >
                {messages.next}
            </button>
        </div>
    )
}


