import axiosInstance from "../utils/axios";

export const login = async credentials => {
    const { data } = await axiosInstance.post(`login`, credentials)
    return data
}

export const updatePassword = async info => {
    const { data } = await axiosInstance.put(`users/updatePassword/${info.id}`, info)
    return data
}

export const passwordReset = async info => {
    const { data } = await axiosInstance.post(`users/passwordReset`, info)
    return data
}

export const updatePasswordReset = async info => {
    const { data } = await axiosInstance.post(`users/${info.userId}/${info.token}`, {password: info.password})
    return data
}


export default { login, updatePassword, passwordReset, updatePasswordReset }