import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Container, Grid } from '@material-ui/core';
import Select from 'react-select';

// images
import icon_delete from '../../assets/images/delete.png'

// importaciones
import { currencyFormat } from '../../utils/pipes';
import ModalDeleteProduct from './DeleteProduct';

export default function FormSuggestOrder({ listProductSelected, listProductsApi, onSubmit, register, errors, handleSubmit, control, getValues, setValue, titleButton}) {

    const [listProductSelect, setListProductSelect] = useState(listProductSelected);
    const [indexDelete, setIndexDelete] = useState();
    const [openModaleDelete, setOpenModaleDelete] = useState(false);

    const onChangeNumber = (event, index) => {
        let temp = [...listProductSelect];
        temp[index] = { ...temp[index], cant: event.target.value, total: (temp[index].net_unit_cost * event.target.value) };
        setListProductSelect(temp);

        handleSumTotal();
    }

    function handleSumTotal() {
        let sum = 0;
        for (const item of listProductSelect) {
            if (!isNaN(item.total))
                sum += item.total;
        }

        let ret = !isNaN(sum) ? parseFloat(sum) : 0;

        return ret;
    }

    const handleChangeFormSelect = (event, index) => {
        let temp = [...listProductSelect];
        temp[index] = { ...listProductsApi.find((element) => element.id === event.value), cant: '', total: '' };
        setListProductSelect(temp);
    }

    const handleCloseModal = () => {
        setIndexDelete('');
        setOpenModaleDelete(false);
    }

    const handleDelete = () => {
        listProductSelect.splice(indexDelete, 1);

        for (let index = indexDelete; index < listProductSelect.length; index++) {
            setValue(`cant[${index}]`, getValues(`cant[${index + 1}]`));
            setValue(`product[${index}]`, getValues(`product[${index + 1}]`));
        }

        setValue(`cant[${listProductSelect.length}]`, '');
        setValue(`product[${listProductSelect.length}]`, '');
        handleCloseModal();
    }

    const isEven = (score) => !isNaN(score);

    return (
        <>
            <div className="body-provider">
                <Container>
                    <div className="row-table row-title">
                        <Grid container spacing={1}>
                            <Grid item xs={5} md={3}>
                                <div className="title-table-orders">
                                    Nombre del producto
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    UPC
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    Departamento
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    precio
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    IMPUESTOS*
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    CANTIDAD
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    TOTAL
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="title-table-orders">
                                    ACCIÓN
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="providers">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {listProductSelect.map((product, index) => (
                                <>
                                    <div className="row-table row-body" key={index}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8} md={3}>
                                                <div className="item-provider">
                                                    <Controller
                                                        name={`product[${index}]`}
                                                        control={control}
                                                        {...register(`product[${index}]`, {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido',
                                                            },
                                                        })}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                onChange={(e) => {
                                                                    handleChangeFormSelect(e, index);
                                                                    field.onChange(e);
                                                                }}
                                                                placeholder={'Seleccione un producto'}
                                                                options={listProductsApi.map((v) => ({
                                                                    value: v.id,
                                                                    label: `${v.description}`,
                                                                }))}
                                                                isSearchable
                                                            />
                                                        )}
                                                    />
                                                    {errors.product && (
                                                        errors.product[index] && (
                                                            <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                                {errors.product[index].message}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.upc}
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.product_subcategory?.product_category?.department?.name}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.net_unit_cost ? currencyFormat(product?.net_unit_cost) : ''}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.net_unit_cost ? 'IVA-IEPS' : ''}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} className="mobilNone">
                                                <div className="item-provider">
                                                    <Controller
                                                        name={`cant[${index}]`}
                                                        control={control}
                                                        {...register(`cant[${index}]`,
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido',
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    message: 'Valor mínimo permitido 0',
                                                                },
                                                                max: {
                                                                    value: (product.uOrder - product.cant_delivered),
                                                                    message: `Valor máximo permitido ${(product.uOrder - product.cant_delivered)}`,
                                                                },
                                                                validate: isEven,
                                                            })
                                                        }
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                onChange={(e) => {
                                                                    onChangeNumber(e, index);
                                                                    field.onChange(e);
                                                                }}
                                                                type="text"
                                                                className="input-register"
                                                            />
                                                        )}
                                                    />
                                                    {errors.cant && (
                                                        errors.cant[index]?.type === "validate" && (
                                                            <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                                El campo solo admite números
                                                            </p>
                                                        )
                                                    )}

                                                    {errors.cant && (
                                                        errors.cant[index] && (
                                                            <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                                {errors.cant[index].message}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.total ? currencyFormat(product?.total) : ''}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <div className="item-provider sort" style={{ paddingTop: '15px' }}>
                                                    <img src={icon_delete} alt="" className="icon-remove" onClick={(e) => {
                                                        setOpenModaleDelete(true);
                                                        setIndexDelete(index)
                                                    }} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>)
                            )}

                            {listProductSelect.length > 0 ? (
                                <>
                                    <div className="total-orders">
                                        Total orden de compra: <span>{currencyFormat(handleSumTotal())}</span>
                                    </div>
                                </>
                            ) : (<></>)}

                            <button type="submit" className="btnDetails rigth">
                                <div className="text">
                                    {titleButton}
                                </div>
                            </button>
                            <button className="btnAddProduct rigth" onClick={() => setListProductSelect([...listProductSelect, {}])}>
                                <div className="text">
                                    Agregar producto
                                </div>
                            </button>
                        </form>
                    </div>

                </Container>
            </div>

            <ModalDeleteProduct
                open={openModaleDelete}
                onClose={handleCloseModal}
                onDelete={handleDelete} />
        </>
    );
}