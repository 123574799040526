import { useState, useEffect, useRef } from 'react';
import { Container, Grid, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';

//importaciones
import HeaderAdmin from '../../../admin/header/Header';
import axiosInstance from '../../../../../utils/axios';
import Loading from '../../../../../components/loading/Loading';
import Footer from '../../../../../components/footer/Footer';

const CssTextField = withStyles({
  root: {
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2D4838',
      },
    },
  },
})(TextField);

export default function EditAdviserMaster(props) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState();
  const [listDepartments, setListDepartments] = useState([]);
  const [listWarehouses, setListWarehouses] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [openDepartment, setOpenDepartments] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      let response = await axiosInstance.get(`users/${props.match.params.id}`);
      let responseDepartments = await axiosInstance.get(`departments`);
      let responseWarehouses = await axiosInstance.get(`warehouses`);
      let responseRoles = await axiosInstance.get(`roles`);

      setAdmin(response.data);

      setListDepartments(responseDepartments.data);
      setListWarehouses(responseWarehouses.data);
      setListRoles(responseRoles.data);

      setLoading(false);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (admin?.id) {
      reset(admin);
      setValue(
        'departments',
        admin?.departments?.map(v => ({ value: v.department.id, label: v.department.name }))
      );
      setValue(
        'warehouses',
        admin?.warehouses?.map(v => ({ value: v.warehouse.id, label: v.warehouse.initials }))
      );
      setValue('role', { value: admin?.role?.id, label: admin?.role?.description });
      setValue('password', '');

      admin?.departments.length > 10 ? setOpenDepartments(true) : setOpenDepartments(false);

      setLoading(false);
    }
  }, [admin?.id]);

  const password = useRef({});
  password.current = watch('password', '');

  const handleClickShowNewPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownConfirmPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = event => {
    event.preventDefault();
  };

  const onChangeDepart = e => {
    e.length > 10 ? setOpenDepartments(true) : setOpenDepartments(false);
  };

  const onSubmit = async values => {
    const { name, email, password, departments, warehouses, role } = values;

    const data = {
      name,
      email,
      password,
      departments,
      warehouses,
      role_id: role.value,
    };

    try {
      setLoading(true);
      await axiosInstance.put('users/updateAdmin/' + admin.id, data);
      toast.info('Asesor modificado con éxito!');
    } catch (error) {
      // Error 😨
      if (error.response)
        if (error.response.data.error) toast.error(error.response.data.msg);
        else toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
      else toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
    } finally {
      setLoading(false);
    }
  };

  //listas para breadcrumbs
  const listBreadcrumbs = [
    {
      title: 'Asesores',
      url: '/master/advisers/list',
    },
    {
      title: `${admin?.name}`,
    },
  ];

  return (
    <>
      <HeaderAdmin
        title={`${admin?.name}`}
        user={user}
        type='detail'
        view='edit-adviser'
        listBreadcrumbs={listBreadcrumbs}
      />
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Container maxWidth='md'>
            <div className='section-register'>
              <div className='title-section'>Información cuenta</div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className='label-register'>Nombre y apellido *</div>
                    <Controller
                      name='name'
                      control={control}
                      {...register('name', {
                        required: {
                          value: true,
                          message: 'Este campo es requerido',
                        },
                      })}
                      render={({ field }) => <input {...field} type='text' className='input-register' />}
                    />
                    {errors.name && (
                      <p className='text-red-700  text-xs error-text' style={{ marginTop: '0px' }}>
                        {errors.name.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className='label-register'>Correo electrónico *</div>
                    <Controller
                      name='email'
                      control={control}
                      {...register('email', {
                        required: {
                          value: true,
                          message: 'Este campo es requerido',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'La dirección de correo electrónico es inválida',
                        },
                      })}
                      render={({ field }) => <input {...field} type='text' className='input-register' />}
                    />
                    {errors.email && (
                      <p className='text-red-700  text-xs error-text' style={{ marginTop: '0px' }}>
                        {errors.email.message}
                      </p>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className='label-register'>Departamentos *</div>
                    <Controller
                      name='departments'
                      control={control}
                      {...register('departments')}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={'Seleccione un departamento'}
                          options={listDepartments.map(v => ({
                            value: v.id,
                            label: `${v.name}`,
                          }))}
                          onChange={e => {
                            field.onChange(e);
                            onChangeDepart(e);
                          }}
                          isSearchable
                          isMulti
                          className={openDepartment ? 'input-select' : ''}
                        />
                      )}
                    />
                    {errors.departments && (
                      <p className='text-red-700  text-xs error-text' style={{ marginTop: '0px' }}>
                        {errors.departments.message}
                      </p>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={6} md={6} lg={6}>
                    <div className='label-register'>Rol *</div>
                    <Controller
                      name='role'
                      control={control}
                      {...register('role', {
                        required: {
                          value: true,
                          message: 'Este campo es requerido',
                        },
                      })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={'Seleccione un rol'}
                          options={listRoles.map(v => ({
                            value: v.id,
                            label: `${v.description}`,
                          }))}
                          isSearchable
                        />
                      )}
                    />
                    {errors.role && (
                      <p className='text-red-700  text-xs error-text' style={{ marginTop: '0px' }}>
                        {errors.role.message}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6} lg={6}>
                    <div className='label-register'>Bodegas</div>
                    <Controller
                      name='warehouses'
                      control={control}
                      {...register('warehouses')}
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder={'Seleccione una bodega'}
                          options={listWarehouses.map(v => ({
                            value: v.id,
                            label: `${v.initials}`,
                          }))}
                          isSearchable
                          isMulti
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={5}>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className='label-login label-register-password'>
                      Nueva contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
                    </div>
                    <CssTextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={e => handleClickShowNewPassword()}
                              onMouseDown={e => handleMouseDownNewPassword(e)}
                              edge='end'
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className='input-register'
                      name='password'
                      {...register('password', {
                        pattern: {
                          value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                          message: 'La contraseña no es segura',
                        },
                      })}
                      type={showPassword ? 'text' : 'password'}
                      variant='outlined'
                    />

                    {errors.password && <p className='text-red-700  text-xs error-text'>{errors.password.message}</p>}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className='label-login label-register-password'>Confirmar Contraseña *</div>
                    <CssTextField
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={e => handleClickShowConfirmPassword()}
                              onMouseDown={e => handleMouseDownConfirmPassword(e)}
                              edge='end'
                            >
                              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className='input-register'
                      name='confirm_password'
                      {...register('confirm_password', {
                        validate: value => value === password.current || 'No coinciden las contraseñas.',
                      })}
                      type={showConfirmPassword ? 'text' : 'password'}
                      variant='outlined'
                    />
                    {errors.confirm_password && (
                      <p className='text-red-700  text-xs error-text'>{errors.confirm_password.message}</p>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={5}>
                    <button type='submit' className='btnDetails rigth'>
                      <div className='text'>Editar información</div>
                    </button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </>
      )}
      <div style={{ marginTop: '150px' }}></div>
      <Footer />
      <ToastContainer />
    </>
  );
}
