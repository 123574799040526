import { Component } from "react";
import { Container, Grid } from '@material-ui/core';
import NativeSelect from '@material-ui/core/NativeSelect';
import Select from 'react-select';

// importar estilos
//import './form-product.css';

class FormProductOCS extends Component {
    constructor(props) {
        super(props);

        this.state = this.props.state;
    }

    handleValidForm = () => {
        if (this.props.isAdmin)
            return true;
        else {
            if ((this.state.formProduct.description !== '' && this.state.formProduct.description !== null) &&
                (this.state.formProduct.product_explanation !== '' && this.state.formProduct.product_explanation !== null) &&
                (this.state.formProduct.product_subcategory_id !== '0' && this.state.formProduct.product_subcategory_id !== null) &&
                (this.state.formProduct.product_brand_id !== '0' && this.state.formProduct.product_brand_id !== null) &&
                (this.state.formProduct.variety !== '' && this.state.formProduct.variety !== null) &&
                (this.state.formProduct.packing !== '' && this.state.formProduct.packing !== null) &&
                (this.state.formProduct.barcode_piece !== '' && this.state.formProduct.barcode_piece !== null) &&
                (this.state.formProduct.image_download_link !== '' && this.state.formProduct.image_download_link !== null) &&
                (this.state.formProduct.grammage !== '' && this.state.formProduct.grammage !== null) &&
                (this.state.formProduct.unit !== '' && this.state.formProduct.unit !== null) &&
                (this.state.formProduct.barcode_box !== '' && this.state.formProduct.barcode_box !== null) &&
                (this.state.formProduct.sat_key !== '' && this.state.formProduct.sat_key !== null) &&
                (this.state.formProduct.refill_type !== '' && this.state.formProduct.refill_type !== null) &&
                (this.state.formProduct.gross_cost_piece !== '' && this.state.formProduct.gross_cost_piece !== null) &&
                (this.state.formProduct.ieps !== '' && this.state.formProduct.ieps !== null) &&
                (this.state.formProduct.tax !== '' && this.state.formProduct.tax !== null) &&
                (this.state.formProduct.net_unit_cost !== '' && this.state.formProduct.net_unit_cost !== null) &&
                (this.state.formProduct.public_sale_price !== '' && this.state.formProduct.public_sale_price !== null) &&
                (this.state.formProduct.number_pieces_box !== '' && this.state.formProduct.number_pieces_box !== null) &&
                (this.state.formProduct.gross_cost_box !== '' && this.state.formProduct.gross_cost_box !== null)) {
                return true
            }
            return false;
        }
    }

    handleChangeForm = (event) => {
        this.setState({
            formProduct: {
                ...this.state.formProduct,
                [event.target.name]: event.target.value
            }
        });
    }

    handleChangeFormSelect = (option) => {
        this.setState({
            formProduct: {
                ...this.state.formProduct,
                product_subcategory_id: option.value
            }
        });

    }

    handleSaveInfo = (event) => {

        event.preventDefault();

        this.props.handleSaveInfo(this.state);

    }

    render() {
        return (
            <>
                <Container maxWidth="md">
                    <div className="section-register">
                        <div className="title-section">
                            Información del producto {this.state.formProduct.status}
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <div className="label-register">
                                    Nombre completo del producto
                                </div>
                                <input disabled className="input-register" name="description" type="text" value={this.state.formProduct.description}  />
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <div className="label-register">
                                    Descripción
                                </div>
                                <textarea disabled className="input-register textarea" name="product_explanation" value={this.state.formProduct.product_explanation}  />
                            </Grid>
                        </Grid>


                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <div className="label-register">
                                    Subcategoría
                                </div>
                                <Select
                                    id="product_subcategory_id"
                                    defaultValue={
                                        this.state.listSubcategories.filter(option =>
                                            option.value.toString() === this.state.formProduct.product_subcategory_id)
                                    }
                                    name="product_subcategory_id"
                                    isDisabled
                                    />
                            </Grid>
                            <Grid item xs={6}>
                                <div className="label-register">
                                    Marca
                                </div>
                                <input disabled className="input-register" name="product_brand" type="text" value={this.state.formProduct.product_brand}  />
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Variedad del producto - Sabor, ejemplo adobadas, natural
                                </div>
                                <input disabled className="input-register" name="variety" type="text" value={this.state.formProduct.variety}  />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Empaque (botella, envase, pouch, cartón, lata)
                                </div>
                                <input disabled className="input-register" name="packing" type="text" value={this.state.formProduct.packing}  />
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Codigo de barra - Pieza (EAN 13)
                                </div>
                                <input disabled className="input-register" name="barcode_piece" type="text" value={this.state.formProduct.barcode_piece}  />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Link de descarga de imagen, UPC como nombre de imagen y formato JPG
                                </div>
                                <input disabled className="input-register" name="image_download_link" type="text" value={this.state.formProduct.image_download_link}  />
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Gramaje
                                </div>
                                <input disabled className="input-register" name="grammage" type="text" value={this.state.formProduct.grammage}  />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Unidad
                                </div>
                                <input disabled className="input-register" name="unit" type="text" value={this.state.formProduct.unit}  />
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Codigo de barra - Caja (DUN 14)
                                </div>
                                <input disabled className="input-register" name="barcode_box" type="text" value={this.state.formProduct.barcode_box}  />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Clave SAT
                                </div>
                                <input disabled className="input-register" name="sat_key" type="text" value={this.state.formProduct.sat_key}  />
                            </Grid>
                        </Grid>


                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Tipo de resurtido (I&O/Línea)
                                </div>
                                <input disabled className="input-register" name="refill_type" type="text" value={this.state.formProduct.refill_type}  />
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Información de costos
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Costo bruto pieza
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.gross_cost_piece}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="gross_cost_piece" type="text" value={this.state.formProduct.gross_cost_piece}  />
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    IEPS
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.ieps}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="ieps" type="text" value={this.state.formProduct.ieps}  />
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    IVA
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.tax}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="tax" type="text" value={this.state.formProduct.tax}  />
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Costo neto unitario
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.net_unit_cost}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="net_unit_cost" type="text" value={this.state.formProduct.net_unit_cost}  />
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Precio de venta público
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.public_sale_price}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="public_sale_price" type="text" value={this.state.formProduct.public_sale_price}  />
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Piezas por Caja
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.number_pieces_box}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="number_pieces_box" type="text" value={this.state.formProduct.number_pieces_box}  />
                                    </>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Costo Bruto Caja
                                </div>
                                {this.props.editCost === 'false' ? (
                                    <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {this.state.formProduct.gross_cost_box}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <input disabled className="input-register" name="gross_cost_box" type="text" value={this.state.formProduct.gross_cost_box}  />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </>
        );
    }

}

export default FormProductOCS;