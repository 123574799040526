import { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Redirect, useLocation } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { getToken, initAxiosInterceptor, isUserAuthenticated } from './utils/auth-herpers';
import { AppContext } from "./libs/contextLib";
import jwt_decode from "jwt-decode";

// Estilos
import './assets/css/estilos.css';
import 'react-toastify/dist/ReactToastify.css';

// importaciones
import Home from './pages/register/home/Home';
import LoadProducts from './pages/register/load-products/LoadProducts';
import RegisterProvider from './pages/register/register-provider/RegisterProvider';
import ListOrders from './pages/admin/provider/orders/list-orders/ListOrders';
import ListProducts from './pages/admin/provider/products/list-products/ListProducts';
import LoadMissingInformation from './pages/admin/provider/load-missing-information/LoadMissingInformation';
import NotFound from './components/not-found/NotFound';
import Finish from './pages/register/finish/Finish';
import DashboardMaster from './pages/admin/master/dashboard/Dashboard';
import DashboardManager from './pages/admin/manager/dashboard/Dashboard';

import Login from './pages/login/Login';
import ListIncidences from './pages/admin/provider/inicidences/list-incidences/ListIncedences';
import DetailIncidence from './pages/admin/provider/inicidences/detail-incidences/DetailIncidences';
import NewIncidence from './pages/admin/provider/inicidences/new-incidence/NewIncidence';
import NewProduct from './pages/admin/provider/products/new-product/NewProduct';
import EditProduct from './pages/admin/provider/products/edit-product/EditProduct';
import Quicksight from './pages/admin/provider/reports/quicksight/Quicksight';
import Profile from './pages/admin/provider/provider-profile/Profile';
import EditProviderProfile from './pages/admin/provider/provider-profile/edit-provider-profile/EditProviderProfile';

import ListProviders from './pages/admin/admin/providers/list-providers/ListProviders';
import SuggestOrders from './pages/admin/provider/orders/suggest';
import EditSuggestOrders from './pages/admin/provider/orders/edit-suggest';
import ListProductsAdmin from './pages/admin/admin/products/list-products/ListProductsAdmin';
import EditProviderAdmin from './pages/admin/admin/providers/edit-provider/EditProvider';
import DetailIncidenceAdmin from './pages/admin/admin/incidences/detail-incidences/DetailIncidencesAdmin';
import EditProductAdmin from './pages/admin/admin/products/edit-produc/EditProductAdmin';
import ListIncidencesAdmin from './pages/admin/admin/incidences/list-incidences/ListIncidencesAdmin';

import ListProvidersManager from './pages/admin/manager/providers/list-providers/ListProviders';
import ListProductsManager from './pages/admin/manager/products/list-products/ListProducts';
import EditProviderManager from './pages/admin/manager/providers/edit-provider/EditProvider';
import EditProductManager from './pages/admin/manager/products/edit-produc/EditProduct';
import ListIncidencesManager from './pages/admin/manager/incidences/list-incidences/ListIncidences';
import DetailIncidenceManager from './pages/admin/manager/incidences/detail-incidences/DetailIncidences';
import ListAdvisersManager from './pages/admin/manager/advisers/list-advisers/ListAdvisers';

import ListProvidersSuperAdmin from './pages/admin/super_admin/providers/list-providers/ListProviders';
import ListProductsSuperAdmin from './pages/admin/super_admin/products/list-products/ListProducts';
import EditProviderSuperAdmin from './pages/admin/super_admin/providers/edit-provider/EditProvider';
import EditProductSuperAdmin from './pages/admin/super_admin/products/edit-product/EditProduct';
import ListIncidencesSuperAdmin from './pages/admin/super_admin/incidences/list-incidences/ListIncidences';
import DetailIncidenceSuperAdmin from './pages/admin/super_admin/incidences/detail-incidences/DetailIncidences';
import ListAdvisersSuperAdmin from './pages/admin/super_admin/advisers/list-advisers/ListAdvisers';
import ListOrdersSuperAdmin from './pages/admin/super_admin/orders/list';
import ListScheduledDeliveriesSuperAdmin from './pages/admin/super_admin/scheduled_deliveries/list';
import DetailScheduledDeliveriesSuperAdmin from './pages/admin/super_admin/scheduled_deliveries/detail';
import PackListSuperAdmin from './pages/admin/super_admin/orders/packlist';
import SuggestOrdersSuperAdmin from './pages/admin/super_admin/orders/suggest';
import EditSuggestOrdersSuperAdmin from './pages/admin/super_admin/orders/detail-suggest';
import EditAdviserSuperAdmin from './pages/admin/super_admin/advisers/edit';
import NewAdviserSuperAdmin from './pages/admin/super_admin/advisers/new';
import QuicksightSuperAdmin from './pages/admin/super_admin/reports/quicksight/Quicksight';


import ListProvidersMaster from './pages/admin/master/providers/list-providers/ListProviders';
import ListProductsMaster from './pages/admin/master/products/list-products/ListProducts';
import EditProviderMaster from './pages/admin/master/providers/edit-provider/EditProvider';
import EditProductMaster from './pages/admin/master/products/edit-produc/EditProduct';
import ListIncidencesMaster from './pages/admin/master/incidences/list-incidences/ListIncidences';
import DetailIncidenceMaster from './pages/admin/master/incidences/detail-incidences/DetailIncidences';
import ListAdvisersMaster from './pages/admin/master/advisers/list-advisers/ListAdvisers';
import ListOrdersMaster from './pages/admin/master/orders/list';
import ListScheduledDeliveriesMaster from './pages/admin/master/scheduled_deliveries/list';
import DetailScheduledDeliveriesMaster from './pages/admin/master/scheduled_deliveries/detail';
import PackListMaster from './pages/admin/master/orders/packlist';
import SuggestOrdersMaster from './pages/admin/master/orders/suggest';
import EditSuggestOrdersMaster from './pages/admin/master/orders/detail-suggest';

import ListOrdersOC from './pages/admin/ocs/orders/list';
import ListScheduledDeliveriesOC from './pages/admin/ocs/scheduled_deliveries/list';
import DetailScheduledDeliveriesOC from './pages/admin/ocs/scheduled_deliveries/detail';
import PackListOC from './pages/admin/ocs/orders/packlist';
import SuggestOrdersOC from './pages/admin/ocs/orders/suggest';
import EditSuggestOrdersOC from './pages/admin/ocs/orders/detail-suggest';
import ListProvidersOC from './pages/admin/ocs/providers/list-providers/ListProviders';
import EditProviderOC from './pages/admin/ocs/providers/edit-provider/EditProvider';
import ListIncidencesOC from './pages/admin/ocs/incidences/list-incidences/ListIncidences';
import DetailIncidenceOC from './pages/admin/ocs/incidences/detail-incidences/DetailIncidences';
import NewIncidenceOC from './pages/admin/ocs/incidences/new-incidence/NewIncidence';
import ListProductsOC from './pages/admin/ocs/products/list-products/ListProducts';
import EditProductOC from './pages/admin/ocs/products/edit-product/EditProduct';

import ListOrdersRECEIPT from './pages/admin/receipt/orders/list';
import ListScheduledDeliveriesRECEIPT from './pages/admin/receipt/scheduled_deliveries/list';
import DetailScheduledDeliveriesRECEIPT from './pages/admin/receipt/scheduled_deliveries/detail';
import PackListRECEIPT from './pages/admin/receipt/orders/packlist';
import SuggestOrdersRECEIPT from './pages/admin/receipt/orders/suggest';
import EditSuggestOrdersRECEIPT from './pages/admin/receipt/orders/detail-suggest';
import ListProvidersRECEIPT from './pages/admin/receipt/providers/list-providers/ListProviders';
import EditProviderRECEIPT from './pages/admin/receipt/providers/edit-provider/EditProvider';
import ListIncidencesRECEIPT from './pages/admin/receipt/incidences/list-incidences/ListIncidences';
import DetailIncidenceRECEIPT from './pages/admin/receipt/incidences/detail-incidences/DetailIncidences';
import NewIncidenceRECEIPT from './pages/admin/receipt/incidences/new-incidence/NewIncidence';

import ListOrdersFINANCE from './pages/admin/finance/orders/list';
import ListScheduledDeliveriesFINANCE from './pages/admin/finance/scheduled_deliveries/list';
import DetailScheduledDeliveriesFINANCE from './pages/admin/finance/scheduled_deliveries/detail';
import PackListFINANCE from './pages/admin/finance/orders/packlist';
import SuggestOrdersFINANCE from './pages/admin/finance/orders/suggest';
import EditSuggestOrdersFINANCE from './pages/admin/finance/orders/detail-suggest';
import ListProvidersFINANCE from './pages/admin/finance/providers/list-providers/ListProviders';
import EditProviderFINANCE from './pages/admin/finance/providers/edit-provider/EditProvider';
import ListIncidencesFINANCE from './pages/admin/finance/incidences/list-incidences/ListIncidences';
import DetailIncidenceFINANCE from './pages/admin/finance/incidences/detail-incidences/DetailIncidences';
import NewIncidenceFINANCE from './pages/admin/finance/incidences/new-incidence/NewIncidence';


import ListProductsCatalogue from './pages/admin/catalogue/products/list-products';
import EditProductCatalogue from './pages/admin/catalogue/products/edit-produc/EditProductCatalogue';
import ListIncidencesCatalogue from './pages/admin/catalogue/incidences/list-incidences/ListIncidencesCatalogue';
import DetailIncidenceCatalogue from './pages/admin/catalogue/incidences/detail-incidences/DetailIncidencesCatalogue';
import PasswordReset from './pages/password-reset';
import PackList from './pages/admin/provider/orders/packlist';
import ListScheduledDeliveries from './pages/admin/provider/scheduled_deliveries/list';
import DetailScheduledDeliveries from './pages/admin/provider/scheduled_deliveries/detail';
import EditAdviserManager from './pages/admin/manager/advisers/edit';
import EditAdviserMaster from './pages/admin/master/advisers/edit';
import EditScheduledDelivery from './pages/admin/provider/scheduled_deliveries/edit';
import ListProductsAdmin2 from './pages/admin/admin/products/list-products';
import ListProvidersAdmin2 from './pages/admin/admin/providers/list-providers';
import ListProvidersCatalogue from './pages/admin/catalogue/providers/list-providers';
import RegisterProvider2 from './pages/register/register-provider';
import LoadMissingInformation2 from './pages/admin/provider/load-missing-information';
import NewAdviserMaster from './pages/admin/master/advisers/new';
import EditProviderCatalogue from './pages/admin/catalogue/providers/edit-provider/EditProvider';
import ListProvidersGrowth from './pages/admin/growth/providers/list-providers/ListProviders';
import EditProviderGrowth from './pages/admin/growth/providers/edit-provider/EditProvider';
import NewUserProvider from './pages/admin/provider/provider-profile/new-user';
import EditUserProvider from './pages/admin/provider/provider-profile/edit-user';
import EditUserProviderSuperAdmin from './pages/admin/super_admin/providers/edit-user';
import NewUserProviderSuperAdmin from './pages/admin/super_admin/providers/new-user';

// Imagenes

initAxiosInterceptor();

function App() {
    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [role, setRole] = useState('');
    const [routeProvider, setRouteProvider] = useState('');

    useEffect(() => {
        onLoad();
    }, []);

    function HeaderView() {
        let location = useLocation();
        return location.pathname;
    }

    function RouteProvider() {
        var decoded = jwt_decode(getToken());
        if (decoded.role.group.length) {
            const groups = decoded.role.group.map((group) => group.key.split('_all')[0]);
            if (groups[0] === 'insights')
                return '/provider/insights';
            else if (groups[0] === 'profile')
                return `/provider/profile`;
            else if (groups[0] === 'purchase_orders')
                return `/provider/orders/list`;
            else if (groups[0] === 'delivery_schedule')
                return `/provider/deliveries/list`;
            else
                return `/provider/${groups[0]}/list`;
        } else {
            return "/provider/profile";
        }
    }

    async function onLoad() {
        try {
            if (isUserAuthenticated()) {
                var decoded = jwt_decode(getToken());
                setRole(decoded.role.key.indexOf('provider') !== -1 ? 'provider' : decoded.role.key);
                userHasAuthenticated(true);
            } else {
                userHasAuthenticated(false);
            }
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        setIsAuthenticating(false);
    }

    const requireLogin = (to, from, next) => {
        if (to.meta.auth) {
            if (isUserAuthenticated()) {
                var decoded = jwt_decode(getToken());
                setRole(decoded.role.key.indexOf('provider') !== -1 ? 'provider' : decoded.role.key);
                if (to.meta.role === decoded.role.key.indexOf('provider') !== -1 ? 'provider' : decoded.role.key) {
                    if (decoded.role.group.length && from.location.pathname.split('/')[2]) {
                        const groups = decoded.role.group.map((group) => group.key.split('_all')[0]);
                        console.log(from.location.pathname.split('/')[2]);
                        console.log(groups);
                        if (groups.indexOf(from.location.pathname.split('/')[2] === 'products' ? 'catalogue' :
                            from.location.pathname.split('/')[2] === 'orders' ? 'purchase_orders' :
                                from.location.pathname.split('/')[2] === 'deliveries' ? 'delivery_schedules' :
                                    from.location.pathname.split('/')[2]) != -1)
                            next();
                        else
                            next.redirect('/');
                    } else
                        next();
                }
                else
                    next.redirect('/');
            }
            next.redirect('/');
        } else {
            next();
        }
    };

    return (
        <>
            <BrowserRouter>
                <div className={isAuthenticated && HeaderView() === '/admin' ? 'admin' : !isAuthenticated && HeaderView() === '/login' ? 'login' : ''}>
                    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
                        <GuardProvider guards={[requireLogin]} error={NotFound}>
                            <Switch>
                                <GuardedRoute path="/" exact>
                                    {isAuthenticated ?
                                        <Redirect to={role === 'admin' ? "/admin/providers/list" : role === 'provider' ? RouteProvider() : role === 'master' ? "/master/providers/list" : role === 'catalogue' ? "/catalogue/products/list" : role === 'ocs' ? '/ocs/orders/list' : role === 'receipt' ? '/receipt/providers/list' : role === 'super_admin' ? '/super_admin/providers/list' : role === 'finance' ? '/finance/providers/list' : role === 'growth' ? '/growth/providers/list' : "/manager/providers/list"} />
                                        : <Login />}
                                </GuardedRoute>

                                <GuardedRoute path="/password-reset/:userId/:token" exact component={PasswordReset} />

                                <GuardedRoute path="/register" exact component={Home} />
                                <GuardedRoute path="/register/load-products" exact component={LoadProducts} />
                                <GuardedRoute path="/register/provider" exact component={RegisterProvider2} />
                                <GuardedRoute path="/register/finish" exact component={Finish} />
                                {/* SUPERADMIN */}
                                <GuardedRoute path="/super_admin/advisers/list" exact component={ListAdvisersSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/advisers/edit/:id" exact component={EditAdviserSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/advisers/new" exact component={NewAdviserSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/providers/list" exact component={ListProvidersSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/providers/edit/:id" exact component={EditProviderSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/providers/:providerId/user/new" exact component={NewUserProviderSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/providers/:providerId/user/edit/:id" exact component={EditUserProviderSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/products/list" exact component={ListProductsSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/products/edit/:id" exact component={EditProductSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/incidences/list" exact component={ListIncidencesSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/incidences/detail/:id" exact component={DetailIncidenceSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/insights" exact component={QuicksightSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                {/* SUPERADMIN - ordenes de compra */}
                                <GuardedRoute path="/super_admin/orders/list" exact component={ListOrdersSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/orders/packlist/:id" exact component={PackListSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/orders/suggest/new" exact component={SuggestOrdersSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/orders/suggest/edit/:id" exact component={EditSuggestOrdersSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                {/* SUPERADMIN - entregas programadas */}
                                <GuardedRoute path="/super_admin/deliveries/list" exact component={ListScheduledDeliveriesSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />
                                <GuardedRoute path="/super_admin/deliveries/detail/:id" exact component={DetailScheduledDeliveriesSuperAdmin} meta={{ auth: true, role: 'super_admin' }} />

                                {/* asesores */}
                                <GuardedRoute path="/admin/providers/list" exact component={ListProvidersAdmin2} meta={{ auth: true, role: 'admin' }} />
                                <GuardedRoute path="/admin/providers/edit/:id" exact component={EditProviderAdmin} meta={{ auth: true, role: 'admin' }} />
                                <GuardedRoute path="/admin/products/list" exact component={ListProductsAdmin2} meta={{ auth: true, role: 'admin' }} />
                                <GuardedRoute path="/admin/products/edit/:id" exact component={EditProductAdmin} meta={{ auth: true, role: 'admin' }} />
                                <GuardedRoute path="/admin/incidences/list" exact component={ListIncidencesAdmin} meta={{ auth: true, role: 'admin' }} />
                                <GuardedRoute path="/admin/incidences/detail/:id" exact component={DetailIncidenceAdmin} meta={{ auth: true, role: 'admin' }} />

                                {/* jefes directos */}
                                <GuardedRoute path="/manager/advisers/list" exact component={ListAdvisersManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/advisers/edit/:id" exact component={EditAdviserManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/providers/list" exact component={ListProvidersManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/providers/edit/:id" exact component={EditProviderManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/products/list" exact component={ListProductsManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/products/edit/:id" exact component={EditProductManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/incidences/list" exact component={ListIncidencesManager} meta={{ auth: true, role: 'manager' }} />
                                <GuardedRoute path="/manager/incidences/detail/:id" exact component={DetailIncidenceManager} meta={{ auth: true, role: 'manager' }} />

                                {/* Director general */}
                                <GuardedRoute path="/master/advisers/list" exact component={ListAdvisersMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/advisers/new" exact component={NewAdviserMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/advisers/edit/:id" exact component={EditAdviserMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/providers/list" exact component={ListProvidersMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/providers/edit/:id" exact component={EditProviderMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/products/list" exact component={ListProductsMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/products/edit/:id" exact component={EditProductMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/incidences/list" exact component={ListIncidencesMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/incidences/detail/:id" exact component={DetailIncidenceMaster} meta={{ auth: true, role: 'master' }} />
                                {/* Director general  - ordenes de compra */}
                                <GuardedRoute path="/master/orders/list" exact component={ListOrdersMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/orders/packlist/:id" exact component={PackListMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/orders/suggest/new" exact component={SuggestOrdersMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/orders/suggest/edit/:id" exact component={EditSuggestOrdersMaster} meta={{ auth: true, role: 'master' }} />
                                {/* Director general  - entregas programadas */}
                                <GuardedRoute path="/master/deliveries/list" exact component={ListScheduledDeliveriesMaster} meta={{ auth: true, role: 'master' }} />
                                <GuardedRoute path="/master/deliveries/detail/:id" exact component={DetailScheduledDeliveriesMaster} meta={{ auth: true, role: 'master' }} />

                                {/* Director equipo de catalogo */}
                                <GuardedRoute path="/catalogue/products/list" exact component={ListProductsCatalogue} meta={{ auth: true, role: 'catalogue' }} />
                                <GuardedRoute path="/catalogue/products/edit/:id" exact component={EditProductCatalogue} meta={{ auth: true, role: 'catalogue' }} />
                                <GuardedRoute path="/catalogue/providers/list" exact component={ListProvidersCatalogue} meta={{ auth: true, role: 'catalogue' }} />
                                <GuardedRoute path="/catalogue/providers/edit/:id" exact component={EditProviderCatalogue} meta={{ auth: true, role: 'catalogue' }} />
                                <GuardedRoute path="/catalogue/incidences/list" exact component={ListIncidencesCatalogue} meta={{ auth: true, role: 'catalogue' }} />
                                <GuardedRoute path="/catalogue/incidences/detail/:id" exact component={DetailIncidenceCatalogue} meta={{ auth: true, role: 'catalogue' }} />

                                <GuardedRoute path="/provider/form-providers" exact component={LoadMissingInformation2} meta={{ auth: true, role: 'provider' }} />
                                {/* perfil */}
                                <GuardedRoute path="/provider/profile" exact component={Profile} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/profile/edit" exact component={EditProviderProfile} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/profile/user/new" exact component={NewUserProvider} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/profile/user/edit/:id" exact component={EditUserProvider} meta={{ auth: true, role: 'provider' }} />

                                {/* productos */}
                                <GuardedRoute path="/provider/products/list" exact component={ListProducts} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/products/new" exact component={NewProduct} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/products/edit/:id" exact component={EditProduct} meta={{ auth: true, role: 'provider' }} />
                                {/* ordenes de compra */}
                                <GuardedRoute path="/provider/orders/list" exact component={ListOrders} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/orders/packlist/:id" exact component={PackList} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/orders/suggest/new" exact component={SuggestOrders} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/orders/suggest/edit/:id" exact component={EditSuggestOrders} meta={{ auth: true, role: 'provider' }} />

                                {/* entregas programadas */}
                                <GuardedRoute path="/provider/deliveries/list" exact component={ListScheduledDeliveries} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/deliveries/detail/:id" exact component={DetailScheduledDeliveries} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/deliveries/edit/:id" exact component={EditScheduledDelivery} meta={{ auth: true, role: 'provider' }} />

                                {/* incidencias */}
                                <GuardedRoute path="/provider/incidences/list" exact component={ListIncidences} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/incidences/new" exact component={NewIncidence} meta={{ auth: true, role: 'provider' }} />
                                <GuardedRoute path="/provider/incidences/detail/:id" exact component={DetailIncidence} meta={{ auth: true, role: 'provider' }} />
                                {/* insights */}
                                <GuardedRoute path="/provider/insights" exact component={Quicksight} meta={{ auth: true, role: 'provider' }} />

                                {/* OCS - ordenes de compra */}
                                <GuardedRoute path="/ocs/orders/list" exact component={ListOrdersOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/orders/packlist/:id" exact component={PackListOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/orders/suggest/new" exact component={SuggestOrdersOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/orders/suggest/edit/:id" exact component={EditSuggestOrdersOC} meta={{ auth: true, role: 'ocs' }} />
                                {/* OCS - entregas programadas */}
                                <GuardedRoute path="/ocs/deliveries/list" exact component={ListScheduledDeliveriesOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/deliveries/detail/:id" exact component={DetailScheduledDeliveriesOC} meta={{ auth: true, role: 'ocs' }} />
                                {/* OCS - incidencias */}
                                <GuardedRoute path="/ocs/incidences/list" exact component={ListIncidencesOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/incidences/new" exact component={NewIncidenceOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/incidences/detail/:id" exact component={DetailIncidenceOC} meta={{ auth: true, role: 'ocs' }} />
                                {/* OCS - PROVIDERS */}
                                <GuardedRoute path="/ocs/providers/list" exact component={ListProvidersOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/providers/edit/:id" exact component={EditProviderOC} meta={{ auth: true, role: 'ocs' }} />
                                {/* OCS - products */}
                                <GuardedRoute path="/ocs/products/list" exact component={ListProductsOC} meta={{ auth: true, role: 'ocs' }} />
                                <GuardedRoute path="/ocs/products/edit/:id" exact component={EditProductOC} meta={{ auth: true, role: 'ocs' }} />

                                {/* RECEIPT - ordenes de compra */}
                                <GuardedRoute path="/receipt/orders/list" exact component={ListOrdersRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/orders/packlist/:id" exact component={PackListRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/orders/suggest/new" exact component={SuggestOrdersRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/orders/suggest/edit/:id" exact component={EditSuggestOrdersRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                {/* RECEIPT - entregas programadas */}
                                <GuardedRoute path="/receipt/deliveries/list" exact component={ListScheduledDeliveriesRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/deliveries/detail/:id" exact component={DetailScheduledDeliveriesRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                {/* RECEIPT - incidencias */}
                                <GuardedRoute path="/receipt/incidences/list" exact component={ListIncidencesRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/incidences/new" exact component={NewIncidenceRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/incidences/detail/:id" exact component={DetailIncidenceRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                {/* RECEIPT - providers */}
                                <GuardedRoute path="/receipt/providers/list" exact component={ListProvidersRECEIPT} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/receipt/providers/edit/:id" exact component={EditProviderRECEIPT} meta={{ auth: true, role: 'receipt' }} />

                                {/* FINANCE - ordenes de compra */}
                                <GuardedRoute path="/finance/orders/list" exact component={ListOrdersFINANCE} meta={{ auth: true, role: 'finance' }} />
                                <GuardedRoute path="/finance/orders/packlist/:id" exact component={PackListFINANCE} meta={{ auth: true, role: 'finance' }} />
                                <GuardedRoute path="/finance/orders/suggest/new" exact component={SuggestOrdersFINANCE} meta={{ auth: true, role: 'receipt' }} />
                                <GuardedRoute path="/finance/orders/suggest/edit/:id" exact component={EditSuggestOrdersFINANCE} meta={{ auth: true, role: 'finance' }} />
                                {/* FINANCE - entregas programadas */}
                                <GuardedRoute path="/finance/deliveries/list" exact component={ListScheduledDeliveriesFINANCE} meta={{ auth: true, role: 'finance' }} />
                                <GuardedRoute path="/finance/deliveries/detail/:id" exact component={DetailScheduledDeliveriesFINANCE} meta={{ auth: true, role: 'finance' }} />
                                {/* FINANCE - incidencias */}
                                <GuardedRoute path="/finance/incidences/list" exact component={ListIncidencesFINANCE} meta={{ auth: true, role: 'finance' }} />
                                <GuardedRoute path="/finance/incidences/new" exact component={NewIncidenceFINANCE} meta={{ auth: true, role: 'finance' }} />
                                <GuardedRoute path="/finance/incidences/detail/:id" exact component={DetailIncidenceFINANCE} meta={{ auth: true, role: 'finance' }} />
                                {/* FINANCE - providers */}
                                <GuardedRoute path="/finance/providers/list" exact component={ListProvidersFINANCE} meta={{ auth: true, role: 'finance' }} />
                                <GuardedRoute path="/finance/providers/edit/:id" exact component={EditProviderFINANCE} meta={{ auth: true, role: 'finance' }} />

                                {/* GROWTH - providers */}
                                <GuardedRoute path="/growth/providers/list" exact component={ListProvidersGrowth} meta={{ auth: true, role: 'growth' }} />
                                <GuardedRoute path="/growth/providers/edit/:id" exact component={EditProviderGrowth} meta={{ auth: true, role: 'growth' }} />

                                <GuardedRoute path="*" component={NotFound} />
                            </Switch>
                        </GuardProvider>
                    </AppContext.Provider>
                </div>
            </BrowserRouter>
        </>
    );
}

export default App;