import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

// importar estilos
import '../../../../register/register-provider/register-provider.css';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderAdmin from "../../header/Header";
import FormProduct from "../../../provider/products/form-product/FormProduct";

import img_icon_arrow_logout from '../../../../../assets/images/icon-arrow-logout.png';

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Catálogo',
        url: '/master/products/list'
    },
    {
        title: '',
        url: ''
    },
    {
        title: '',
    }
];

class EditProductMaster extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: this.props.match.params,
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            listSubcategories: [],
            anchorEl: null,
            listBreadcrumbs,
            formProduct: {
                description: '',
                product_explanation: '',
                product_subcategory_id: '',
                product_brand: '',
                variety: '',
                packing: '',
                barcode_piece: '',
                image_download_link: '',
                grammage: '',
                unit: '',
                barcode_box: '',
                sat_key: '',
                refill_type: '',
                gross_cost_piece: '',
                ieps: '',
                status: '',
                provider: {}
            }
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const product_subcategories = await axiosInstance.get(`product_subcategories`,);
                const product = await axiosInstance.get(`products/${this.state.params.id}?include=provider`,);

                const listSubcategories = product_subcategories.data.map((subcategory) => { return { value: subcategory.id, label: subcategory.name } });

                let temp = this.state.listBreadcrumbs;

                temp[1].title = product.data.provider.business_name;
                temp[1].url = `/master/providers/edit/${product.data.provider.id}`;
                temp[2].title = product.data.description;

                this.setState({
                    ...this.state,
                    listSubcategories,
                    formProduct: {
                        ...product.data,
                        product_brand: product.data.product_brand.name
                    },
                    loading: false,
                    listBreadcrumbs: temp
                })
            } catch (error) {
                console.log(error);
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            }
        });
    }

    handleSaveInfo = (state) => {
        this.setState({
            ...this.state,
            formProduct: {
                ...state.formProduct,
                status: this.state.formProduct.status
            }
        })
        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.put(`products/${state.formProduct.id}`, this.state.formProduct);

                    this.setState({ loading: false })

                    toast.success('¡Producto modificado con éxito!');
                } catch (error) {
                    console.log(error);
                    console.log(error.response);
                    // Error 😨
                    if (error.response) {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }
    }

    handleClick = (anchorEl) => {
        this.setState({
            ...this.state,
            anchorEl: anchorEl.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            anchorEl: null
        });
    };

    handleSelect = (event, status) => {
        this.setState({
            ...this.state,
            anchorEl: null,
            formProduct: {
                ...this.state.formProduct,
                status
            }
        });
    };

    render() {
        return (
            <>
                <HeaderAdmin
                    title={this.state.formProduct.description}
                    user={this.state.user}
                    view='edit-product'
                    type='detail'
                    listBreadcrumbs={this.state.listBreadcrumbs} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Container maxWidth="md">
                            <div className="section-register">
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            ID
                                        </div>
                                        {this.state.formProduct.provider.provider_id}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            Estado
                                        </div>
                                        <div>
                                            <div className={`status ${this.state.formProduct.status === 'pending' ? 'pending' : this.state.formProduct.status === 'inactive' ? 'inactive' : 'active'}`}>
                                                <Button className="menu-status" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClick(e)}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={8} className="estado">
                                                            <span className="desk">
                                                                {this.state.formProduct.status === 'pending' ? 'Pendiente' : this.state.formProduct.status === 'inactive' ? 'Inactivo' : 'Activo'}
                                                            </span>
                                                            <span className="mobile">
                                                                {this.state.formProduct.status === 'pending' ? 'P' : this.state.formProduct.status === 'inactive' ? 'I' : 'A'}
                                                            </span>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <img src={img_icon_arrow_logout} alt="" />
                                                        </Grid>
                                                    </Grid>

                                                </Button>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.anchorEl)}
                                                    onClose={(e) => this.handleClose(e)}>
                                                    <MenuItem onClick={(event) => this.handleSelect(event, 'active')}> Activo </MenuItem>
                                                    <MenuItem onClick={(event) => this.handleSelect(event, 'inactive')}> Inactivo </MenuItem>
                                                    <MenuItem onClick={(event) => this.handleSelect(event, 'pending')}> Pendiente </MenuItem>
                                                </Menu>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                        <FormProduct
                            state={this.state}
                            handleSaveInfo={this.handleSaveInfo}
                            textButton='Guardar cambios'
                            editCost='true'
                            isAdmin='true' />
                        <Footer />
                    </>
                )}
            </>
        );
    }

    componentDidMount() {
        this.loadInfoData();
    }

}

export default EditProductMaster;