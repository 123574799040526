import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

// importaciones de modulos
import Loading from '../../../../../components/loading/Loading';
import Footer from '../../../../../components/footer/Footer';
import axiosInstance from '../../../../../utils/axios';
import FormProvider from '../../../../../components/form-provider/FormProvider';

// images
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import HeaderProvider from '../../header/Header';

class EditProviderProfile extends Component {
  constructor() {
    super();

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      loading: false,
      formProvider: {
        id_temp: Math.floor(1 + Math.random() * (99999 - 1)),
        business_name: '',
        rfc: '',
        account_clabe: '',
        errorAccountClabe: false,
        textErrorAccountClabe: 'El número de cuenta clabe debe tener mínimo 11 dígitos',
        account_bank: '',
        account_number: '',
        errorAccountNumber: false,
        textErrorAccountNumber: 'El número de cuenta debe tener mínimo 6 dígitos',
        username: '',
        email: '',
        errorEmail: false,
        textErrorEmail: 'El correo no es válido',
        password: '',
        passwordErrorMessage: '',
        passwordValid: false,
        showPassword: false,
        confirm_password: '',
        confirmPasswordErrorMessage: '',
        showConfirmPassword: false,
        confirmPasswordValid: false,
        contact_business_email: '',
        errorContactBusinessEmail: false,
        textErrorContactBusinessEmail: 'El correo no es válido',
        textErrorContactBusinessPhone: 'El telefono debe contener almenos 7 digitos',
        contact_business_phone: '',
        contact_business_name: '',
        contact_business_workstation: '',
        contact_logistic_email: '',
        contact_logistic_phone: '',
        contact_logistic_name: '',
        contact_logistic_workstation: '',
        contact_financial_email: '',
        contact_financial_phone: '',
        contact_financial_name: '',
        contact_financial_workstation: '',
        contact_other_email: '',
        contact_other_phone: '',
        contact_other_name: '',
        contact_other_workstation: '',
        address_street: '',
        address_number_exterior: '',
        address_number_interior: '',
        address_suburb: '',
        address_delegation: '',
        address_postcode: '',
        address_city: '',
        address_state: '',
        number_employees: '',
        company_classification: '',
        credit_days: '',
        name_legal_representative: '',
        curp_legal_representative: '',
      },
      form: {
        bank_face: '', //file
        bank_face_percent: '',
        opinion_fulfillment_sat: '', //file
        opinion_fulfillment_sat_percent: '',
        power_attorney: '', //file
        power_attorney_percent: '', //file
        opinion_fulfillment_tax_obligations: '', //file
        opinion_fulfillment_tax_obligations_percent: '', //file
        identification_legal_representative_front: '', //file
        identification_legal_representative_front_percent: '', //file
        identification_legal_representative_reverse: '', //file
        identification_legal_representative_reverse_percent: '', //file
        proof_address: '', //file
        proof_address_percent: '', //file
        proof_tax_situation: '', //file
        proof_tax_situation_percent: '', //file
        constitutive_act: '', //file
        constitutive_act_percent: '', //file
      },
    };
  }

  loadInfoData = () => {
    this.setState({ loading: true }, () => {
      axiosInstance
        .get(`providers/info/iam`)
        .then(get => {
          if (get.data) {
            this.setState({
              ...this.state,
              formProvider: {
                ...this.state.formProvider,
                ...get.data,
                password: '',
              },
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };
  saveInfoProvider = data => {
    let stateLocal = this.state;

    let combinedData = {
      ...data,
      formProvider: {
        ...data.formProvider,
        departments: stateLocal.formProvider.departments,
        warehouses: stateLocal.formProvider.warehouses,
        credit_days: stateLocal.formProvider.credit_days,
        schedule: stateLocal.formProvider.schedule,
        status: stateLocal.formProvider.status,
      },
    };

    this.saveInfo(combinedData);
  };

  saveInfoHeader = data => {
    this.saveInfo(this.state);
  };

  saveInfo = data => {
    try {
      this.setState({ loading: true }, async () => {
        try {
          await axiosInstance.put(`providers/updateForAdmin/${data.formProvider.id}`, data.formProvider);

          this.setState({ loading: false });

          toast.success('¡Proveedor modificado con éxito!');
        } catch (error) {
          console.log(error.response);
          // Error 😨
          if (error.response) {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          } else {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          }
        }
      });
    } catch (e) {
      alert(e.message);
    }
  };

  // handleSaveInfo = (state) => {
  //     try {
  //         this.setState({ loading: true }, async () => {
  //             try {
  //                 await axiosInstance.post('providers/info/iam', state.formProvider);
  //                 this.setState({ loading: false });
  //                 var user = JSON.parse(localStorage.getItem('user'));
  //                 user.notLoad = false;
  //                 localStorage.setItem('user', JSON.stringify(user));
  //                 this.props.history.push("/provider/products/list");
  //             } catch (error) {
  //                 console.log(error);
  //                 console.log(error.response);
  //                 // Error 😨
  //                 if (error.response) {
  //                     if (error.response.data.error) {
  //                         toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
  //                     }
  //                     else {
  //                         toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
  //                     }
  //                     this.setState({ ...state, loading: false });
  //                 } else {
  //                     toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
  //                     this.setState({ ...state, loading: false });
  //                 }
  //             }
  //         });
  //     } catch (e) { alert(e.message); }

  // }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <HeaderProvider
              title='Empresa'
              icon={icon_list_products}
              user={this.state.user}
              view='empresa'
              type='detail'
            />
            <FormProvider state={this.state} />
            <Footer />
          </>
        )}
      </>
    );
  }
  componentDidMount() {
    this.loadInfoData();
  }
}

export default EditProviderProfile;
