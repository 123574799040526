import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

// images
import img_close_modal from '../../assets/images/close-modal.png';

export default function ModalDeleteProduct(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        ><Fade in={props.open}>
                <div className="modal-delete">
                    <div className="icon-close" onClick={props.onClose}>
                        <div className="title-modal-provider">
                            Eliminar producto
                            <img src={img_close_modal} alt="Cerrar" />
                        </div>
                    </div>
                    <div className="documents-modal-provider">
                        <div className="container-load-products container-modal-detele">
                            <div className="label text-center">
                                ¿Seguro desea eliminar el producto de la order de compra?
                            </div>

                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <div className="container-btn-save" onClick={props.onClose}>
                                        <button className="button-cancel">
                                            Cancelar
                                        </button>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="container-btn-save">
                                        <button className="button-delete" onClick={props.onDelete}>
                                            Eliminar
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}