import React, { useState, useEffect } from 'react';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import HeaderOC from "../../header";
import axiosInstance from "../../../../../utils/axios";
import ListProductsPacklist from '../../../../../components/list-products-packlist';
import ListProductsDeliveries from "./listProducts";
import ModalCancelSheduleDelivery from "../cancel";
import {toast, ToastContainer} from "react-toastify";
import {generateString} from "../../../../../utils";

export default function DetailScheduledDeliveries(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [scheduledDelivery, setScheduledDelivery] = useState({});
    const [openModaleCancel, setOpenModaleCancel] = useState(false);
    const [randomStr, setRandomStr] = useState();

    const [listBreadcrumbs, setListBreadcrumbs] = useState([
        {
            title: 'Entregas programadas',
            url: '/ocs/deliveries/list'
        },
        {
            title: ''
        }
    ]);

    const onCancel = async () => {
        setOpenModaleCancel(false);
        setLoading(true);
        try {
            await axiosInstance.put("/scheduled_deliveries/cancel/" + scheduledDelivery?.id);
            toast.info('Entrega programada modificada con éxito!');
            setRandomStr(generateString());
        } catch (error) {
            setLoading(false);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }finally {
            setLoading(false);
        }
    }
    const handleCloseModalCancel = () => {
        setOpenModaleCancel(false);
    }


    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/${props.match.params.id}`);
            setScheduledDelivery(response.data);
            let temp = [...listBreadcrumbs];
            temp[1].title = scheduledDelivery?.scheduled_delivery_id;
            console.log(temp);
            setListBreadcrumbs(temp);
            setLoading(false);
        }

        fetch();
    }, [randomStr]);

    useEffect(() => {
        let temp = [...listBreadcrumbs];
        temp[1].title = scheduledDelivery?.scheduled_delivery_id;
    }, [scheduledDelivery]);

    return (
        <>
            <HeaderOC
                title={scheduledDelivery?.scheduled_delivery_id}
                user={user}
                view='detail-packlist'
                type='detail'
                listBreadcrumbs={listBreadcrumbs}
                scheduledDelivery={scheduledDelivery}
            />
            <ToastContainer />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <ListProductsDeliveries setModal={setOpenModaleCancel} status={scheduledDelivery?.status} listProductsView={scheduledDelivery?.products} />
                    <ModalCancelSheduleDelivery
                        open={openModaleCancel}
                        onClose={handleCloseModalCancel}
                        onCancel={onCancel} />
                    />
                    <Footer />
                </>
            )}
        </>
    )
}