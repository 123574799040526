import React, { Component } from "react";
import { Container, Grid } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import ExportReact from 'react-export-excel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// importar estilos
import './styles.css';

// imagenes
import icon_list_orders from '../../../../../assets/images/icon-list-orders.png';
import img_icon_export from '../../../../../assets/images/icon-export.png';
import icon_detail from '../../../../../assets/images/icon-detail.png';
import icon_order from '../../../../../assets/images/icon-order.png';
import icon_order_desc from '../../../../../assets/images/icon-order-desc.png';
import icon_order_asc from '../../../../../assets/images/icon-order-asc.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderOrder from "../../header/Header";
import { currencyFormat } from '../../../../../utils/pipes';
import ModalCancelOrderOCS from "./Cancel";

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;
class ListOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listOrders: [],
            listOrdersExport: [],
            listProviders: [],
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            provider_id: '',
            search: '',
            order: 'suggest_order_id DESC',
            paginator: {
                page: 1,
                size: 5,
                totalPages: 0,
                totalItems: 0
            },
            tabValue: 0,
        }
    }

    loadProviders = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`providers/list`,)
                .then((get) => {
                    if (get.data) {
                        let listProviders = get.data.map((provider) => { return { value: provider.id, label: provider.business_name } });
                        listProviders.unshift({ value: 'all', label: 'TODOS' });
                        this.setState({
                            ...this.state,
                            listProviders,
                            loading: false,
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ loading: false })
                });
        });
    }

    handleGetInfoToExportExcel = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`purchase_orders?type=${this.state.tabValue}&filter=${this.state.search}&order=${this.state.order}&page=0&size=${this.state.paginator.totalItems}`,)
                .then((get) => {
                    if (get.data) {
                        var temp = [];

                        get.data.orders.map((element, i) => {
                            var departments = '';
                            element.departments.map((ele, index) => {
                                if (index !== (element.departments.length - 1)) {
                                    departments += ele + ' | ';
                                } else {
                                    departments += ele;
                                }
                            });

                            temp.push({
                                suggest_order_id: element.suggest_order_id,
                                purchase_order_id: element.purchase_order_id,
                                created_at: moment(element.created_at).format("DD/MM/YYYY"),
                                departments,
                                cant: element.cant,
                                total: currencyFormat(element.total),
                                status_pay: element.status_pays[0].key === 'pay' ? 'Pagado' : element.status_pays[0].key === 'pending' ? 'Pendiente' : 'NA',
                                status_oc: element.status_ocs[0].key === 'pay' ? 'Pagado' : element.status_ocs[0].key === 'pending' ? 'Pendiente' : 'NA'
                            });
                        });
                        this.setState({
                            ...this.state,
                            listOrdersExport: temp,
                            loading: false
                        });

                        this.clickExportFile();


                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ loading: false });
                    if (error.response) {
                        if (error.response.data.error) {
                            toast.error(error.response.data.msg);
                        } else {
                            toast.info(error.response.data.msg);
                        }
                    }
                });
        });
    }

    handleSort = async (field, direction) => {
        let order = '';
        if (this.state.order.split(' ')[0] === field) {
            order = `${field} ${this.state.order.split(' ')[1] === 'ASC' ? 'DESC' : 'ASC'}`
        } else {
            order = `${field} ASC`;
        }
        this.setState({
            ...this.state,
            order,
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        });

        this.handleChangeProviders(this.state.provider_id);
    }

    clickExportFile = () => {
        document.getElementById('file-export').click();
    }

    handleSearch = (term) => {
        this.setState({
            ...this.state,
            search: term
        });
    }

    handleSearchButton = () => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        });
        if (this.state.provider_id != '')
            this.handleChangeProviders(this.state.provider_id);
    }

    handleChangePage = (event, value) => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: value
            }
        });
        if (this.state.provider_id != '')
            this.handleChangeProviders(this.state.provider_id);
    };

    handleChangeTab = (event, value) => {
        this.setState({
            ...this.state,
            tabValue: value,
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        });

        if (this.state.provider_id != '')
            this.handleChangeProviders(this.state.provider_id);
    }

    handleOpenDepartments = (provider) => {
        const data = [];
        this.state.listOrders.forEach((element) => {
            if (provider.id === element.id) {
                element.openDepartments = !element.openDepartments;
            } else {
                element.openDepartments = false;
            }
            data.push(element);
        });

        this.setState({
            listOrders: data
        })
    }

    handleChangeProviders = (provider_id) => {
        if (provider_id === 'all') {
            this.setState({ loading: true }, () => {
                axiosInstance.get(`purchase_orders?type=${this.state.tabValue}&filter=${this.state.search}&order=${this.state.order}&page=${(this.state.paginator.page - 1)}&size=${this.state.paginator.size}`,)
                    .then((get) => {
                        if (get.data) {
                            this.setState({
                                listOrders: get.data.orders,
                                loading: false,
                                paginator: {
                                    ...this.state.paginator,
                                    page: get.data.currentPage + 1,
                                    totalItems: get.data.totalItems,
                                    totalPages: get.data.totalPages
                                },
                                provider_id
                            })
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                    .finally(() => this.setState({ loading: false }))

            });
        } else {
            this.setState({ loading: true }, () => {
                axiosInstance.get(`purchase_orders/byProvider/${provider_id}?type=${this.state.tabValue}&filter=${this.state.search}&order=${this.state.order}&page=${(this.state.paginator.page - 1)}&size=${this.state.paginator.size}`,)
                    .then((get) => {
                        if (get.data) {
                            this.setState({
                                listOrders: get.data.orders,
                                loading: false,
                                paginator: {
                                    ...this.state.paginator,
                                    page: get.data.currentPage + 1,
                                    totalItems: get.data.totalItems,
                                    totalPages: get.data.totalPages
                                },
                                provider_id
                            })
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                    .finally(() => this.setState({ loading: false }))

            });
        }
    }

    render() {
        const rowsOrders = this.state.listOrders.map((order) => {
            return (
                <div className="row-table row-body" key={order.id}>
                    <Grid container>
                        <Grid item md={1}>
                            <div className="item-provider">
                                {order.suggest_order_id}
                            </div>
                        </Grid>
                        <Grid item md={1}>
                            <div className="item-provider">
                                {order.purchase_order_id}
                            </div>
                        </Grid>
                        <Grid item md={1}>
                            <div className="item-provider mobilNone">
                                {moment(order.created_at).format("DD/MM/YYYY")}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="item-provider created_at mobilNone">
                                {order.departments.length > 2 ? (
                                    <>
                                        {order.openDepartments ? (
                                            <>
                                                {order.departments.map((department, index) => {
                                                    return (
                                                        <>
                                                            <div className="text-item-table-privider product department" key={index}>
                                                                {department}
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {order.departments.map((department, index) => {
                                                    if (index < 2) {
                                                        return (
                                                            <>
                                                                <div className="text-item-table-privider product department" key={index}>
                                                                    {department}
                                                                </div>
                                                            </>
                                                        );
                                                    }

                                                })}
                                            </>
                                        )}
                                        <div className="icon-more" onClick={(e) => this.handleOpenDepartments(order)}>
                                            {order.openDepartments ? <Visibility /> : <VisibilityOff />}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {order.departments.map((department, index) => {
                                            return (
                                                <>
                                                    <div className="text-item-table-privider product department" key={index}>
                                                        {department}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider mobilNone">
                                {order.cant}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider mobilNone">
                                {currencyFormat(order.total)}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider mobilNone">
                                {order.file_billing_pdf ? (
                                    <>
                                        <Tooltip title="Descargar factura en PDF" className="tooltip">
                                            <div className="icon-export-provider">
                                                <Link className="text-load" target="_blank" href={order.file_billing_pdf}>
                                                    <img src={img_icon_export} alt="" />
                                                    <br />
                                                    PDF
                                                </Link>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                                {order.file_billing_xml ? (
                                    <>
                                        <Tooltip title="Descargar factura en XML" className="tooltip">
                                            <div className="icon-export-provider ">
                                                <Link className="text-load" target="_blank" href={order.file_billing_xml}>
                                                    <img src={img_icon_export} alt="" />
                                                    <br />
                                                    XML
                                                </Link>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : null}
                            </div>
                        </Grid>
                        <Grid item xs={2} className="mobilNone">
                            <div style={{ width: '120px' }} className={`status-provider ${order.status_ocs[0].key === 'aproved_oc' || order.status_ocs[0].key === 'delivered' ? 'active' : order.status_ocs[0].key === 'pending' ? 'pending' : 'inactive'}`}>
                                <div className="item-provider item-status ">
                                    <span className="desk">
                                        {order.status_ocs[0].key === 'aproved_oc' ? 'Por entregar' : order.status_ocs[0].key === 'pending' ? 'Sugerida' : order.status_ocs[0].key === 'cancel' ? 'Cancelada' : order.status_ocs[0].key === 'delivered' ? 'Entregada' : 'NA'}
                                    </span>
                                    <span className="mobile">
                                        {order.status_ocs[0].key === 'aproved_oc' ? 'PE' : order.status_ocs[0].key === 'pending' ? 'PE' : order.status_ocs[0].key === 'cancel' ? 'CA' : order.status_ocs[0].key === 'delivered' ? 'EN' : 'NA'}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div style={{ width: '120px' }} className={`status-provider ${order.status_pays[0].key === 'pay' ? 'active' : order.status_pays[0].key === 'pending' ? 'pending' : 'inactive'}`}>
                                <div className="item-provider item-status ">
                                    <span className="desk">
                                        {order.status_pays[0].key === 'pay' ? 'Pagado' : order.status_pays[0].key === 'pending' ? 'Pendiente' : 'NA'}
                                    </span>
                                    <span className="mobile">
                                        {order.status_pays[0].key === 'pay' ? 'PA' : order.status_pays[0].key === 'pending' ? 'PE' : 'NA'}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider item-actions">
                                <div className="icon-action-provider">
                                    {order.file_oc_pdf && (
                                        <Tooltip title="Descargar OC en PDF" className="tooltip" style={{ paddingRight: '10px' }}>
                                            <div className="icon-export-provider">
                                                <Link className="text-load" target="_blank" href={order.file_oc_pdf}>
                                                    <img src={img_icon_export} alt="" />
                                                    <br />
                                                    PDF
                                                </Link>
                                            </div>
                                        </Tooltip>
                                    )}
                                    <Tooltip title="Detalle" className="tooltip" onClick={(e) => this.props.history.push(`/master/orders/suggest/edit/${order.id}`)}>
                                        <img src={icon_detail} alt="" />
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div >
            )
        });
        return (
            <>
                <HeaderOrder
                    title="Órdenes de compra"
                    icon={icon_list_orders}
                    user={this.state.user}
                    search={this.handleSearch}
                    textSearch='Buscar orden de compra'
                    view='orders'
                    onSearchButton={(e) => this.handleSearchButton()}
                    handleChangeProviders={this.handleChangeProviders}
                    listProviders={this.state.listProviders}
                    handleGetInfoToExportExcel={this.handleGetInfoToExportExcel} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <ExcelFile element={<button id="file-export"></button>} filename="Lista de órdenes de compra">
                            <ExcelSheet data={this.state.listOrdersExport} name="Hoja 1">
                                <ExcelColumn label="# Orden de compra sugerida" value="suggest_order_id" />
                                <ExcelColumn label="# Orden de compra" value="purchase_order_id" />
                                <ExcelColumn label="Fecha de creación" value="created_at" />
                                <ExcelColumn label="Departamentos" value="departments" />
                                <ExcelColumn label="Unidades" value="cant" />
                                <ExcelColumn label="Monto total" value="total" />
                                <ExcelColumn label="Estado de pago" value="status_pay" />
                                <ExcelColumn label="Estado de OC" value="status_oc" />
                            </ExcelSheet>
                        </ExcelFile>
                        <div className="body-provider">
                            <Tabs
                                variant="standard"
                                value={this.state.tabValue}
                                onChange={this.handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                className="bgtabs">
                                <Tab label="OC en proceso" />
                                <Tab label="OC entregadas" />
                            </Tabs>
                            <Container>
                                <div className="row-table row-title">
                                    <Grid container>
                                        <Grid item xs={9} md={1}>
                                            <div className="title-table-orders sort" onClick={(e) => this.handleSort('suggest_order_id')}>
                                                # Orden de compra sugerida<img src={this.state.order.split(' ')[0] === 'suggest_order_id' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} md={1}>
                                            <div className="title-table-orders sort" onClick={(e) => this.handleSort('purchase_order_id')}>
                                                # Orden de compra<img src={this.state.order.split(' ')[0] === 'purchase_order_id' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('created_at')}>
                                                Fecha de creación<img src={this.state.order.split(' ')[0] === 'created_at' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={2}>
                                            <div className="title-table-orders created_at mobilNone">
                                                Departamento
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('cant')}>
                                                Unidades<img src={this.state.order.split(' ')[0] === 'cant' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('total')}>
                                                Monto total<img src={this.state.order.split(' ')[0] === 'total' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                Factura
                                            </div>
                                        </Grid>
                                        <Grid item md={2}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('suggest_order_id')}>
                                                Estado de órden
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('suggest_order_id')}>
                                                Estado de pago
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <div className="title-table-orders text-center">
                                                Acciones
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="providers">
                                    {rowsOrders}
                                    <div className="paginator">
                                        <Pagination
                                            hidePrevButton
                                            hideNextButton
                                            count={this.state.paginator.totalPages}
                                            page={this.state.paginator.page}
                                            onChange={this.handleChangePage} />
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <Footer />
                    </>)}

                <ToastContainer />

                <ModalCancelOrderOCS
                    open={this.state.openModalCancel}
                    onClose={() => this.setState({ openModalCancel: false })}
                    onReject={this.onReject}
                    orderId={this.state.idCancel}
                />
            </>
        )
    }



    componentDidMount() {
        // this.loadInfoData();
        this.loadProviders();
    }
}

export default ListOrders;