import { Calendar, momentLocalizer } from 'react-big-calendar'

import "moment/locale/es";
import moment from 'moment'

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";

import {useHistory} from "react-router-dom";
import {EventText} from "./eventText";
import Toolbar from "./Toolbar";
import {useState} from "react";
import ke from "react-datepicker";

moment.locale('es');
const localizer = momentLocalizer(moment)

const DeliveriesCalendar = ({ deliveries }) => {
    const history = useHistory();

    const statusColors = {
        delivered: "rgba(210,11,11,1)",
        pending: "rgba(45, 72, 56,1)",
        hasIncidence: "rgb(13,71,227)",
        rescheduled: "rgba(4,159,67,1)",
    }

    const [view, setView] = useState('month');

    const onSelectSlot = (e) => {

    };

    const onSelectEvent = (e) => {
        history.push(`/receipt/deliveries/detail/${e.id}`)
    };

    const onDoubleClick = (params) => {

    };

    const onViewChange = (params) => {
        setView(params);
    }
    const eventStyleGetter = (event, start, end, isSelected) => {
        /*const { status: { key } } = event;
        let color = statusColors.delivered

        if(key === "delivered") color = statusColors.delivered
        if(key === "pending") color = statusColors.pending */

        const style = {
            backgroundColor: statusColors.pending,
            borderRadius: "3px",
            color: "white",
            height: 80,
            marginTop: 10
        };

        return {
            style,
        };
    };

    return (
    <div>
        <Calendar
            views={['month', 'week', 'day']}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            eventPropGetter={eventStyleGetter}
            events={ deliveries ? deliveries?.map((delivery)=>({
                id:delivery.id,
                title: delivery.id,
                start: new Date(delivery.date + " "+ delivery?.hour?.split('-')[0]),
                end: new Date(delivery.date + " "+delivery?.hour?.split('-')[1]),
                purchase_order: delivery?.purchase_order,
                warehouse: delivery?.warehouse,
                departments: delivery?.departments,
                status: delivery?.status,
                hour: delivery?.hour,
            })): []}
            style={{height: "77vh", width: "85vw", marginTop: 20}}
            onSelectEvent={onSelectEvent}
            onSelectSlot={onSelectSlot}
            onView={onViewChange}
            selectable={ true }
            components = {{
                event: EventText,
                toolbar: Toolbar
            }}
        />
    </div>
    )
}

export default DeliveriesCalendar;