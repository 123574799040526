import Select from 'react-select';
import { Controller } from 'react-hook-form';

export default function InputSelect({ errors, register, name, placeholder, required = true, options, control, onChange = () => { } }) {
    return (
        <>
            <Controller
                name={name}
                control={control}
                {...register(name, {
                    required: {
                        value: required,
                        message: 'Este campo es requerido',
                    },
                })}
                render={({ field }) => (
                    <Select
                        {...field}
                        placeholder={placeholder}
                        options={options}
                        onChange={(e) => {
                            field.onChange(e);
                            onChange();
                        }}
                        isClearable
                        isSearchable
                    />
                )}
            />
            {errors[`${name}`] && (
                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                    {errors[`${name}`].message}
                </p>
            )}
        </>
    )
}