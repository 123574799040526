import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Container, Grid, TextField } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';

// estilos
import './form-provider.css';

// importaciones de modulos
import axiosInstance from '../../utils/axios';

// imagenes
import img_file_load from '../../assets/images/file_load.png';
import img_remove_file from '../../assets/images/icon-remove-file.png';

const CssTextField = withStyles({
  root: {
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2D4838',
      },
    },
  },
})(TextField);

const listNumberEmployees = [
  { value: '0-50', label: '0-50' },
  { value: '50-100', label: '50-100' },
  { value: '200-500', label: '200-500' },
  { value: '500-1000', label: '500-1000' },
  { value: '>1000', label: '>1000' },
];

class FormProvider extends Component {
  selectRefDelegation = null;
  selectRefSuburb = null;

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.state,
      submitDisabled: false,
    };

    this.handleChangeFormSelect = this.handleChangeFormSelect.bind(this);
  }

  // selectFileBankFace = e => {
  //   document.getElementById('selectFileBankFace').click();
  // };

  // selectFileOpinionFulfillmentSat = e => {
  //   document.getElementById('selectFileOpinionFulfillmentSat').click();
  // };

  selectFileProofAddress = e => {
    document.getElementById('selectFileProofAddress').click();
  };

  selectFileProofTaxSituation = e => {
    document.getElementById('selectFileProofTaxSituation').click();
  };

  selectFileConstitutiveAct = e => {
    document.getElementById('selectFileConstitutiveAct').click();
  };

  selectFileOpinionFulfillmentTaxObligations = e => {
    document.getElementById('selectFileOpinionFulfillmentTaxObligations').click();
  };

  selectFilePowerAttorney = e => {
    document.getElementById('selectFilePowerAttorney').click();
  };

  selectFileIdentificationLegalRepresentativeFront = e => {
    document.getElementById('selectFileIdentificationLegalRepresentativeFront').click();
  };

  selectFileIdentificationLegalRepresentativeReverse = e => {
    document.getElementById('selectFileIdentificationLegalRepresentativeReverse').click();
  };

  handleChangeFormImage = async event => {
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: event.target.files[0],
      },
    });
    event.preventDefault();
    const formData = new FormData();
    // agregar imagenes
    formData.append('file', event.target.files[0]);
    formData.append('field', [event.target.name]);
    formData.append('id_temp', this.state.formProvider.id_temp);

    const response = await axiosInstance.post('/resources', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: data => {
        //Set the progress value to show the progress bar
        this.setState({
          form: {
            ...this.state.form,
            [event.target.name + '_percent']: Math.round((100 * data.loaded) / data.total),
          },
        });
      },
    });
    this.setState({
      form: {
        ...this.state.form,
        [event.target.name]: response.data.url,
      },
    });
  };

  validateEmail(email) {
    const pattern =
      /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/g;
    const result = pattern.test(email);
    return result;
  }

  handleChangeForm = event => {
    this.setState({
      formProvider: {
        ...this.state.formProvider,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleChangeFormVerify = event => {
    var valid = {
      errorEmail: this.state.formProvider.errorEmail,
      errorAccountNumber: this.state.formProvider.errorAccountNumber,
      errorAccountClabe: this.state.formProvider.errorAccountClabe,
      errorContactBusinessEmail: this.state.formProvider.errorContactBusinessEmail,
      errorContactBusinessPhone: this.state.formProvider.errorContactBusinessPhone,
      errorContactlogisticPhone: this.state.formProvider.errorContactlogisticPhone,
    };
    if (event.target.name === 'email') {
      valid.errorEmail = this.validateEmail(event.target.value) ? false : true;
    }
    if (event.target.name === 'account_number') {
      valid.errorAccountNumber = event.target.value.length < 6 ? true : false;
    }
    if (event.target.name === 'account_clabe') {
      valid.errorAccountClabe = event.target.value.length < 11 ? true : false;
    }
    if (event.target.name === 'contact_business_phone') {
      valid.errorContactBusinessPhone = event.target.value.length < 7 ? true : false;
    }
    if (event.target.name === 'contact_logistic_phone') {
      valid.errorContactlogisticPhone = event.target.value.length < 7 ? true : false;
    }
    if (event.target.name === 'contact_business_email') {
      valid.errorContactBusinessEmail = this.validateEmail(event.target.value) ? false : true;
    }
    if (event.target.name === 'contact_other_email') {
      valid.errorContactOtherEmail = this.validateEmail(event.target.value) ? false : true;
    }

    this.setState({
      formProvider: {
        ...this.state.formProvider,
        errorAccountNumber: valid.errorAccountNumber,
        errorAccountClabe: valid.errorAccountClabe,
        errorEmail: valid.errorEmail,
        errorContactBusinessEmail: valid.errorContactBusinessEmail,
        errorContactBusinessPhone: valid.errorContactBusinessPhone,
        errorContactlogisticPhone: valid.errorContactlogisticPhone,
        errorContactOtherPhone: valid.errorContactOtherPhone,
      },
    });
  };

  handleChangeFormPassword = event => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (strongRegex.test(event.target.value)) {
      this.setState({
        formProvider: {
          ...this.state.formProvider,
          [event.target.name]: event.target.value,
          passwordErrorMessage: '',
          passwordValid: true,
        },
      });
    } else {
      this.setState({
        formProvider: {
          ...this.state.formProvider,
          [event.target.name]: event.target.value,
          passwordErrorMessage: 'Ingrese contraseña nueva',
          passwordValid: false,
        },
      });
    }
  };

  handleChangeFormConfirmPassword = event => {
    if (this.state.formProvider.password !== event.target.value) {
      this.setState({
        formProvider: {
          ...this.state.formProvider,
          [event.target.name]: event.target.value,
          confirmPasswordErrorMessage: 'No coinciden las contraseñas.',
          confirmPasswordValid: false,
        },
      });
    } else {
      this.setState({
        formProvider: {
          ...this.state.formProvider,
          [event.target.name]: event.target.value,
          confirmPasswordErrorMessage: '',
          confirmPasswordValid: true,
        },
      });
    }
  };

  handleValidForm = () => {
    if (
      // this.state.form.bank_face_percent === 100 &&
      // this.state.form.opinion_fulfillment_sat_percent === 100 &&
      this.state.formProvider.username !== '' &&
      this.state.formProvider.username !== null &&
      this.state.formProvider.email !== '' &&
      this.state.formProvider.email !== null &&
      this.state.formProvider.password !== '' &&
      this.state.formProvider.password !== null &&
      // this.state.formProvider.account_clabe !== '' &&
      // this.state.formProvider.account_clabe !== null &&
      // this.state.formProvider.account_bank !== '' &&
      // this.state.formProvider.account_bank !== null &&
      // this.state.formProvider.account_number !== '' &&
      // this.state.formProvider.account_number !== null &&
      this.state.formProvider.business_name !== '' &&
      this.state.formProvider.business_name !== null &&
      this.state.formProvider.rfc !== '' &&
      this.state.formProvider.rfc !== null &&
      this.state.formProvider.contact_business_name !== '' &&
      this.state.formProvider.contact_business_name !== null &&
      this.state.formProvider.contact_business_workstation !== '' &&
      this.state.formProvider.contact_business_workstation !== null &&
      this.state.formProvider.contact_business_email !== '' &&
      this.state.formProvider.contact_business_email !== null &&
      this.state.formProvider.contact_business_phone !== '' &&
      this.state.formProvider.contact_business_phone !== null &&
      this.state.formProvider.address_street !== '' &&
      this.state.formProvider.address_street !== null &&
      this.state.formProvider.address_number_exterior !== '' &&
      this.state.formProvider.address_number_exterior !== null &&
      this.state.formProvider.address_postcode !== '' &&
      this.state.formProvider.address_postcode !== null &&
      this.state.formProvider.address_delegation !== '' &&
      this.state.formProvider.address_delegation !== null &&
      this.state.formProvider.address_city !== '' &&
      this.state.formProvider.address_city !== null &&
      this.state.formProvider.address_state !== '' &&
      this.state.formProvider.address_state !== null &&
      this.state.formProvider.passwordValid &&
      this.state.formProvider.confirmPasswordValid
    ) {
      return true;
    }
    return false;
  };

  handleSaveInfo = event => {
    event.preventDefault();

    this.props.handleSaveInfo(this.state);
  };

  handleClickShowPassword = () => {
    this.setState({
      ...this.state,
      formProvider: {
        ...this.state.formProvider,
        showPassword: !this.state.formProvider.showPassword,
      },
    });
  };
  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowConfirmPassword = () => {
    this.setState({
      ...this.state,
      formProvider: {
        ...this.state.formProvider,
        showConfirmPassword: !this.state.formProvider.showConfirmPassword,
      },
    });
  };

  handleMouseDownConfirmPassword = event => {
    event.preventDefault();
  };

  handleChangeFormSelect = (value, attr) => {
    if (attr.action !== 'clear') {
      this.setState({
        ...this.state,
        formProvider: {
          ...this.state.formProvider,
          [attr.name]: value.value,
        },
      });

      if (attr.name === 'address_state') {
        this.loadDelegations(value.value);
      } else if (attr.name === 'address_delegation') {
        this.loadSuburbs(value.value);
      }
    }
  };

  loadDelegations = async state => {
    this.clearValueDelegation();
    this.clearValueSuburb();
    try {
      const delegations = await axiosInstance.get(`utils/municipios/${state}`);

      const listDelegations = delegations.data.map(delegation => {
        return { value: delegation.id, label: delegation.nombre };
      });

      this.setState({
        ...this.state,
        formProvider: {
          ...this.state.formProvider,
          address_delegation: '',
          address_suburb: '',
        },
        listDelegations,
        listSuburbs: [],
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadSuburbs = async state => {
    this.clearValueSuburb();
    try {
      const delegations = await axiosInstance.get(`utils/colonias/${state}`);

      const listSuburbs = delegations.data.map(delegation => {
        return { value: delegation.id, label: delegation.nombre };
      });

      this.setState({
        ...this.state,
        formProvider: {
          ...this.state.formProvider,
          address_suburb: '',
        },
        listSuburbs,
        loading: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  clearValueDelegation = () => {
    this.state.selectRefDelegation.clearValue();
  };

  clearValueSuburb = () => {
    this.state.selectRefSuburb.clearValue();
  };

  handleRemoveFile = (name, id) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [name]: '',
      },
    });

    document.getElementById(id).value = null;
  };

  onChangeNumber(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({
        ...this.state,
        formProvider: {
          ...this.state.formProvider,
          [event.target.name]: event.target.value,
        },
      });
    }
  }

  render() {
    return (
      <>
        <Container maxWidth='md'>
          <div className='section-register'>
            <div className='title-section'>Información cuenta</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Nombre de contacto *</div>
                <input
                  autoComplete='off'
                  className='input-register'
                  name='username'
                  type='text'
                  value={this.state.formProvider.username}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Correo electrónico *</div>
                <input
                  autoComplete='off'
                  className='input-register'
                  name='email'
                  type='email'
                  value={this.state.formProvider.email}
                  onChange={e => this.handleChangeForm(e)}
                  onBlur={e => this.handleChangeFormVerify(e)}
                />
                {this.state.formProvider.errorEmail ? (
                  <>
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {this.state.formProvider.textErrorEmail}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>
                  Contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
                </div>
                <CssTextField
                  autoComplete='off'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={e => this.handleClickShowPassword()}
                          onMouseDown={e => this.handleMouseDownPassword(e)}
                          edge='end'
                        >
                          {this.state.formProvider.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className='input-register'
                  name='password'
                  value={this.state.formProvider.password}
                  type={this.state.formProvider.showPassword ? 'text' : 'password'}
                  variant='outlined'
                  onChange={e => this.handleChangeForm(e)}
                  onBlur={this.handleChangeFormPassword}
                />
                <span
                  style={{
                    color: 'red',
                  }}
                >
                  {this.state.formProvider.passwordErrorMessage}
                </span>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Confirmar contraseña *</div>
                <CssTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={e => this.handleClickShowConfirmPassword()}
                          onMouseDown={e => this.handleMouseDownConfirmPassword(e)}
                          edge='end'
                        >
                          {this.state.formProvider.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className='input-register'
                  name='confirm_password'
                  value={this.state.formProvider.confirm_password}
                  type={this.state.formProvider.showConfirmPassword ? 'text' : 'password'}
                  variant='outlined'
                  onChange={e => this.handleChangeForm(e)}
                  onBlur={this.handleChangeFormConfirmPassword}
                />
                <span
                  style={{
                    color: 'red',
                  }}
                >
                  {this.state.formProvider.confirmPasswordErrorMessage}
                </span>
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Información comercial</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Razón social *</div>
                <input
                  className='input-register'
                  name='business_name'
                  type='text'
                  value={this.state.formProvider.business_name}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>RFC *</div>
                <input
                  className='input-register'
                  name='rfc'
                  type='text'
                  value={this.state.formProvider.rfc}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Opinión de cumplimiento de SAT *</div>
                <input
                  id='selectFileOpinionFulfillmentSat'
                  type='file'
                  accept='application/pdf'
                  name='opinion_fulfillment_sat'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.opinion_fulfillment_sat !== '' && this.state.form.opinion_fulfillment_sat !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.opinion_fulfillment_sat?.name ? (
                              this.state.form.opinion_fulfillment_sat.name
                            ) : (
                              <Link
                                className='text-load'
                                target='_blank'
                                href={this.state.form.opinion_fulfillment_sat}
                              >
                                opinión_de_cumplimiento_de_SAT.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.opinion_fulfillment_sat_percent === 100 ||
                        this.state.form.opinion_fulfillment_sat !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        opinion_fulfillment_sat_percent: 0,
                                        opinion_fulfillment_sat: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.opinion_fulfillment_sat_percent !== '' &&
                      this.state.form.opinion_fulfillment_sat_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress
                              variant='determinate'
                              value={this.state.form.opinion_fulfillment_sat_percent}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button className='button-load-register' onClick={e => this.selectFileOpinionFulfillmentSat()}>
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Carátula bancaria *</div>
                <input
                  id='selectFileBankFace'
                  type='file'
                  accept='application/pdf'
                  name='bank_face'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.bank_face !== '' && this.state.form.bank_face !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.bank_face?.name ? (
                              this.state.form.bank_face.name
                            ) : (
                              <Link className='text-load' target='_blank' href={this.state.form.bank_face}>
                                caratula_bancaria.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.bank_face_percent === 100 || this.state.form.bank_face !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        bank_face_percent: 0,
                                        bank_face: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.bank_face_percent !== '' && this.state.form.bank_face_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress variant='determinate' value={this.state.form.bank_face_percent} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button className='button-load-register' onClick={e => this.selectFileBankFace()}>
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Banco *</div>
                <Select
                                    ref={ref => {
                                        this.state.selectRefDelegation = ref;
                                    }}
                                    id="address_delegation"
                                    name="address_delegation"
                                    placeholder={'Seleccione una opción'}
                                    options={this.state.listDelegations}
                                    defaultValue={
                                        this.state.listDelegations.filter(option =>
                                            option.value.toString() === this.state.formProvider.address_delegation)
                                    }
                                    onChange={this.handleChangeFormSelect} />
                <input
                  className='input-register'
                  name='account_bank'
                  type='text'
                  value={this.state.formProvider.account_bank}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Número de cuenta *</div>
                <input
                  className='input-register'
                  name='account_number'
                  type='text'
                  value={this.state.formProvider.account_number}
                  onChange={e => this.onChangeNumber(e)}
                  onBlur={e => this.handleChangeFormVerify(e)}
                />
                {this.state.formProvider.errorAccountNumber ? (
                  <>
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {this.state.formProvider.textErrorAccountNumber}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Cuenta CLABE *</div>
                <input
                  className='input-register'
                  name='account_clabe'
                  type='text'
                  value={this.state.formProvider.account_clabe}
                  onChange={e => this.onChangeNumber(e)}
                  onBlur={e => this.handleChangeFormVerify(e)}
                />
                {this.state.formProvider.errorAccountClabe ? (
                  <>
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {this.state.formProvider.textErrorAccountClabe}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Grid> */}
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de contacto comercial</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Nombre de contacto *</div>
                <input
                  className='input-register'
                  name='contact_business_name'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_business_name}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Puesto *</div>
                <input
                  className='input-register'
                  name='contact_business_workstation'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_business_workstation}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Correo electrónico *</div>
                <input
                  className='input-register'
                  name='contact_business_email'
                  type='email'
                  disabled
                  value={this.state.formProvider.contact_business_email}
                  onChange={e => this.handleChangeForm(e)}
                  onBlur={e => this.handleChangeFormVerify(e)}
                />
                {this.state.formProvider.errorContactBusinessEmail ? (
                  <>
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {this.state.formProvider.textErrorContactBusinessEmail}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Teléfono *</div>
                <input
                  className='input-register'
                  name='contact_business_phone'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_business_phone}
                  onChange={e => this.onChangeNumber(e)}
                  onBlur={e => this.handleChangeFormVerify(e)}
                />
                {this.state.formProvider.errorContactBusinessPhone ? (
                  <>
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {this.state.formProvider.textErrorContactBusinessPhone}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de contacto logístico</div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Nombre de contacto</div>
                <input
                  className='input-register'
                  name='contact_logistic_name'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_logistic_name}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Puesto</div>
                <input
                  className='input-register'
                  name='contact_logistic_workstation'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_logistic_workstation}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Correo electrónico</div>
                <input
                  className='input-register'
                  name='contact_logistic_email'
                  type='email'
                  disabled
                  value={this.state.formProvider.contact_logistic_email}
                  onChange={e => this.handleChangeForm(e)}
                  onBlur={e => this.handleChangeFormVerify(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Teléfono</div>
                <input
                  className='input-register'
                  name='contact_logistic_phone'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_logistic_phone}
                  onChange={e => this.onChangeNumber(e)}
                />
                {this.state.formProvider.errorContactlogisticPhone ? (
                  <>
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {this.state.formProvider.textErrorContactlogisticPhone}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de contacto financiero</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Nombre de contacto</div>
                <input
                  className='input-register'
                  name='contact_financial_name'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_financial_name}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Puesto</div>
                <input
                  className='input-register'
                  name='contact_financial_workstation'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_financial_workstation}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Correo electrónico</div>
                <input
                  className='input-register'
                  name='contact_financial_email'
                  type='email'
                  disabled
                  value={this.state.formProvider.contact_financial_email}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Teléfono</div>
                <input
                  className='input-register'
                  name='contact_financial_phone'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_financial_phone}
                  onChange={e => this.onChangeNumber(e)}
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de otro contacto</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Nombre de contacto</div>
                <input
                  className='input-register'
                  name='contact_other_name'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_other_name}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Puesto</div>
                <input
                  className='input-register'
                  name='contact_other_workstation'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_other_workstation}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Correo electrónico</div>
                <input
                  className='input-register'
                  name='contact_other_email'
                  type='email'
                  disabled
                  value={this.state.formProvider.contact_other_email}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Teléfono</div>
                <input
                  className='input-register'
                  name='contact_other_phone'
                  type='text'
                  disabled
                  value={this.state.formProvider.contact_other_phone}
                  onChange={e => this.onChangeNumber(e)}
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Domicilio fiscal</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Calle *</div>
                <input
                  className='input-register'
                  name='address_street'
                  type='text'
                  value={this.state.formProvider.address_street}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Número exterior *</div>
                <input
                  className='input-register'
                  name='address_number_exterior'
                  type='text'
                  value={this.state.formProvider.address_number_exterior}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Número interior</div>
                <input
                  className='input-register'
                  name='address_number_interior'
                  type='text'
                  value={this.state.formProvider.address_number_interior}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>CP *</div>
                <input
                  className='input-register'
                  name='address_postcode'
                  type='text'
                  value={this.state.formProvider.address_postcode}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Estado *</div>
                <Select
                  id='address_state'
                  name='address_state'
                  placeholder={'Seleccione una opción'}
                  options={this.state.listStates}
                  defaultValue={this.state.listStates.filter(
                    option => option.value.toString() === this.state.formProvider.address_state
                  )}
                  onChange={this.handleChangeFormSelect}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Municipio / Alcaldía *</div>
                <Select
                  ref={ref => {
                    this.state.selectRefDelegation = ref;
                  }}
                  id='address_delegation'
                  name='address_delegation'
                  placeholder={'Seleccione una opción'}
                  options={this.state.listDelegations}
                  defaultValue={this.state.listDelegations.filter(
                    option => option.value.toString() === this.state.formProvider.address_delegation
                  )}
                  onChange={this.handleChangeFormSelect}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Colonia *</div>
                <Select
                  ref={ref => {
                    this.state.selectRefSuburb = ref;
                  }}
                  id='address_suburb'
                  name='address_suburb'
                  placeholder={'Seleccione una opción'}
                  defaultValue={this.state.listSuburbs.filter(
                    option => option.value.toString() === this.state.formProvider.address_suburb
                  )}
                  options={this.state.listSuburbs}
                  onChange={this.handleChangeFormSelect}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Ciudad *</div>
                <input
                  className='input-register'
                  name='address_city'
                  type='text'
                  value={this.state.formProvider.address_city}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Información empresarial</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Número de empleados</div>
                <Select
                  id='number_employees'
                  name='number_employees'
                  placeholder={'Seleccione una opción'}
                  value={listNumberEmployees.filter(
                    option => option.value.toString() === this.state.formProvider.number_employees
                  )}
                  options={listNumberEmployees}
                  onChange={this.handleChangeFormSelect}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Empresa</div>
                <RadioGroup
                  row
                  aria-label='position'
                  name='company_classification'
                  onChange={e => this.handleChangeForm(e)}
                  value={this.state.company_classification}
                  defaultValue='top'
                >
                  <FormControlLabel value='national' control={<Radio color='primary' />} label='Nacional' />
                  <FormControlLabel value='international' control={<Radio color='primary' />} label='Internacional' />
                </RadioGroup>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register">
                                    Días de crédito acordados
                                </div>
                                <input className="input-register" name="credit_days" type="number" value={this.state.formProvider.credit_days} onChange={(e) => this.handleChangeForm(e)} />
                            </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Comprobante de domicilio no mayor a 3 meses de antigüedad</div>
                <input
                  id='selectFileProofAddress'
                  type='file'
                  accept='application/pdf'
                  name='proof_address'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.proof_address !== '' && this.state.form.proof_address !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.proof_address?.name ? (
                              this.state.form.proof_address.name
                            ) : (
                              <Link target='_blank' href={this.state.form.proof_address}>
                                comprobante_domicilio.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.proof_address_percent === 100 || this.state.form.proof_address !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        proof_address_percent: 0,
                                        proof_address: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.proof_address_percent !== '' && this.state.form.proof_address_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress variant='determinate' value={this.state.form.proof_address_percent} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button className='button-load-register' onClick={e => this.selectFileProofAddress()}>
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Constancia de situación fiscal</div>
                <input
                  id='selectFileProofTaxSituation'
                  type='file'
                  accept='application/pdf'
                  name='proof_tax_situation'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.proof_tax_situation !== '' && this.state.form.proof_tax_situation !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.proof_tax_situation?.name ? (
                              this.state.form.proof_tax_situation.name
                            ) : (
                              <Link target='_blank' href={this.state.form.proof_tax_situation}>
                                constancia_Situacion_fiscal.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.proof_tax_situation_percent === 100 ||
                        this.state.form.proof_tax_situation !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        proof_tax_situation_percent: 0,
                                        proof_tax_situation: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.proof_tax_situation_percent !== '' &&
                      this.state.form.proof_tax_situation_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress variant='determinate' value={this.state.form.proof_tax_situation_percent} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button className='button-load-register' onClick={e => this.selectFileProofTaxSituation()}>
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Acta constitutiva completa con sus anexos</div>
                <input
                  id='selectFileConstitutiveAct'
                  type='file'
                  accept='application/pdf'
                  name='constitutive_act'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.constitutive_act != null && this.state.form.constitutive_act !== '' ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.constitutive_act?.name ? (
                              this.state.form.constitutive_act.name
                            ) : (
                              <Link target='_blank' href={this.state.form.constitutive_act}>
                                acta_constitutiva_con_anexos.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.constitutive_act_percent === 100 ||
                        this.state.form.constitutive_act !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        constitutive_act_percent: 0,
                                        constitutive_act: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.constitutive_act_percent !== '' &&
                      this.state.form.constitutive_act_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress variant='determinate' value={this.state.form.constitutive_act_percent} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button className='button-load-register' onClick={e => this.selectFileConstitutiveAct()}>
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Opinión del cumplimiento de obligaciones fiscales</div>
                <input
                  id='selectFileOpinionFulfillmentTaxObligations'
                  type='file'
                  accept='application/pdf'
                  name='opinion_fulfillment_tax_obligations'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.opinion_fulfillment_tax_obligations !== '' &&
                this.state.form.opinion_fulfillment_tax_obligations !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.opinion_fulfillment_tax_obligations?.name ? (
                              this.state.form.opinion_fulfillment_tax_obligations.name
                            ) : (
                              <Link target='_blank' href={this.state.form.opinion_fulfillment_tax_obligations}>
                                opinion_cumplimiento_de_obligaciones.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.opinion_fulfillment_tax_obligations_percent === 100 ||
                        this.state.form.opinion_fulfillment_tax_obligations !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        opinion_fulfillment_tax_obligations_percent: 0,
                                        opinion_fulfillment_tax_obligations: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.opinion_fulfillment_tax_obligations_percent !== '' &&
                      this.state.form.opinion_fulfillment_tax_obligations_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress
                              variant='determinate'
                              value={this.state.form.opinion_fulfillment_tax_obligations_percent}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className='button-load-register'
                      onClick={e => this.selectFileOpinionFulfillmentTaxObligations()}
                    >
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Poder notarial del representante legal</div>
                <input
                  id='selectFilePowerAttorney'
                  type='file'
                  accept='application/pdf'
                  name='power_attorney'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.power_attorney !== '' && this.state.form.power_attorney !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.power_attorney?.name ? (
                              this.state.form.power_attorney.name
                            ) : (
                              <Link target='_blank' href={this.state.form.power_attorney}>
                                poder_notarial_representante_legal.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.power_attorney_percent === 100 || this.state.form.power_attorney !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        power_attorney_percent: 0,
                                        power_attorney: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.power_attorney_percent !== '' &&
                      this.state.form.power_attorney_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress variant='determinate' value={this.state.form.power_attorney_percent} />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button className='button-load-register' onClick={e => this.selectFilePowerAttorney()}>
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
            </Grid>
          </div>

          <div className='section-register borF'>
            <div className='title-section'>Información de representante legal</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Nombre completo del representante legal</div>
                <input
                  className='input-register'
                  name='name_legal_representative'
                  type='text'
                  value={this.state.formProvider.name_legal_representative}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>CURP del representante legal</div>
                <input
                  className='input-register'
                  name='curp_legal_representative'
                  type='text'
                  value={this.state.formProvider.curp_legal_representative}
                  onChange={e => this.handleChangeForm(e)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Identificación oficial INE - Frente</div>
                <input
                  id='selectFileIdentificationLegalRepresentativeFront'
                  type='file'
                  accept='application/pdf'
                  name='identification_legal_representative_front'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.identification_legal_representative_front !== '' &&
                this.state.form.identification_legal_representative_front !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.identification_legal_representative_front?.name ? (
                              this.state.form.identification_legal_representative_front.name
                            ) : (
                              <Link target='_blank' href={this.state.form.identification_legal_representative_front}>
                                identificacion_oficial_INE_frente.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.identification_legal_representative_front_percent === 100 ||
                        this.state.form.identification_legal_representative_front !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        identification_legal_representative_front_percent: 0,
                                        identification_legal_representative_front: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.identification_legal_representative_front_percent !== '' &&
                      this.state.form.identification_legal_representative_front_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress
                              variant='determinate'
                              value={this.state.form.identification_legal_representative_front_percent}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className='button-load-register'
                      onClick={e => this.selectFileIdentificationLegalRepresentativeFront()}
                    >
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Identificación oficial INE - Reverso</div>
                <input
                  id='selectFileIdentificationLegalRepresentativeReverse'
                  type='file'
                  accept='application/pdf'
                  name='identification_legal_representative_reverse'
                  onChange={e => this.handleChangeFormImage(e)}
                />
                {this.state.form.identification_legal_representative_reverse !== '' &&
                this.state.form.identification_legal_representative_reverse !== null ? (
                  <>
                    <div className='button-load-register'>
                      <Grid container>
                        <Grid item xs={11}>
                          <div className='text-load'>
                            {this.state.form.identification_legal_representative_reverse?.name ? (
                              this.state.form.identification_legal_representative_reverse.name
                            ) : (
                              <Link target='_blank' href={this.state.form.identification_legal_representative_reverse}>
                                identificacion_oficial_INE_reverso.pdf
                              </Link>
                            )}
                          </div>
                        </Grid>
                        {this.state.form.identification_legal_representative_reverse_percent === 100 ||
                        this.state.form.identification_legal_representative_reverse !== null ? (
                          <>
                            <Grid item xs={1}>
                              <span className='img-button'>
                                <img
                                  src={img_remove_file}
                                  alt=''
                                  onClick={() => {
                                    this.setState({
                                      form: {
                                        ...this.state.form,
                                        identification_legal_representative_reverse_percent: 0,
                                        identification_legal_representative_reverse: null,
                                      },
                                    });
                                  }}
                                />
                              </span>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      {this.state.form.identification_legal_representative_reverse_percent !== '' &&
                      this.state.form.identification_legal_representative_reverse_percent !== 100 ? (
                        <>
                          <div className='linear-progress'>
                            <LinearProgress
                              variant='determinate'
                              value={this.state.form.identification_legal_representative_reverse_percent}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className='button-load-register'
                      onClick={e => this.selectFileIdentificationLegalRepresentativeReverse()}
                    >
                      <Grid container>
                        <Grid item xs={1}>
                          <div className='img-button-not-loaded'>
                            <img src={img_file_load} alt='Cargar' />
                          </div>
                        </Grid>
                        <Grid item xs={11}>
                          <div className='text-button text-button-not-loaded'>
                            <span className='text-underline-load'>Selecciónalo desde tu dispositivo</span>
                          </div>
                        </Grid>
                      </Grid>
                    </button>
                  </>
                )}
              </Grid>
            </Grid>
          </div>
          <div className='button-register'>
            <button
              className={
                !this.props?.disabledSubmitButton || this.handleValidForm() ? 'button-save-active' : 'button-save'
              }
              onClick={e => this.handleSaveInfo(e)}
            >
              <div className='text'>{this.props.titleButton ? this.props.titleButton : 'Finalizar Registro'}</div>
            </button>
          </div>
        </Container>
      </>
    );
  }
}

export default FormProvider;
