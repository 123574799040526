import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExportReact from 'react-export-excel';
import { ToastContainer, toast } from 'react-toastify';

// importaciones de modulos
import Loading from '../../../../components/loading/Loading';
import Footer from "../../../../components/footer/Footer";
import axiosInstance from "../../../../utils/axios";
import HeaderAdmin from "../../../../components/header-admin/HeaderAdmin";

// importar estilos
import '../../components/list-providers/list-providers.css';
import './list-admins.css';

// imagenes
import img_icon_arrow_logout from '../../../../assets/images/icon-arrow-logout.png';
import img_icon_export_excel from '../../../../assets/images/image-export-excel.png';

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;

class ListAdmins extends Component {
    constructor(props) {
        super(props);
    }

    handleOpenDepartments = (admin) => {
        this.props.handleOpenDepartments(admin);
    }

    handleChangePage = (event, value) => {
        this.props.handleChangePage(value);
    };

    render() {
        const rowsAdmins = this.props.listAdmins.map((admin) => {
            return (
                <div className="row-table row-body" key={admin.id}>
                    <Grid container spacing={4}>
                        <Grid item xs={5}>
                            <div className="text-item-table">
                                {admin.user.name}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="text-item-table not-first product">
                                {admin.user.email}
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="text-item-table not-first product">
                                {admin.departments.length > 2 ? (
                                    <>
                                        {admin.openDepartments ? (
                                            <>
                                                {admin.departments.map((department, index) => {
                                                    return (
                                                        <>
                                                            <div className="text-item-table product department" key={index}>
                                                                {department.department.name}
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {admin.departments.map((department, index) => {
                                                    if (index < 2) {
                                                        return (
                                                            <>
                                                                <div className="text-item-table product department" key={index}>
                                                                    {department.department.name}
                                                                </div>
                                                            </>
                                                        );
                                                    }

                                                })}
                                            </>
                                        )}
                                        <div className="icon-more" onClick={(e) => this.handleOpenDepartments(admin)}>
                                            {admin.openDepartments ? <Visibility /> : <VisibilityOff />}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {admin.departments.map((department, index) => {
                                            return (
                                                <>
                                                    <div className="text-item-table product department" key={index}>
                                                        {department.department.name}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div >
            )
        });

        return (
            <>

                <div className="row-table row-title">
                    <Grid container spacing={4}>
                        <Grid item xs={5}>
                            <div className="title-table razon">
                                Nombre
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="title-table not-first product">
                                Correo
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className="title-table not-first date">
                                Departamentos
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="providers">
                    {rowsAdmins}
                    <div className="paginator">
                        <Pagination
                            count={this.props.paginator.totalPages}
                            page={this.props.paginator.page}
                            onChange={this.handleChangePage} />
                    </div>
                </div>
            </>
        );
    }
}

export default ListAdmins;
