import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';

// imports
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";
import ModalSheduleDelivery from './SheduleDelivery';
import FormPacklist from '../../../../../components/form-packlist';

export default function PackList(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

    const [listProductsApi, setListProductsApi] = useState([]);
    const [listProductSelect, setListProductSelect] = useState([]);
    const [order, setOrder] = useState([]);

    const [listDataToModal, setListDataToModal] = useState([]);
    const [listWarehouses, setListWarehouses] = useState([]);

    const [loading, setLoading] = useState(false);
    const [openModaleSheduleDelivery, setOpenModaleSheduleDelivery] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues
    } = useForm();

    const history = useHistory();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`products/provider/iam?filter=&order=description ASC&page=0&size=5000`,);
            let responseOrder = await axiosInstance.get(`purchase_orders/${props.match.params.id}`,);
            let responseWarehouses = await axiosInstance.get('warehouses/byProvider/getAll');
            setListWarehouses(responseWarehouses.data);
            setOrder(responseOrder.data);
            setListProductsApi(response.data.products);
        }

        fetch();
    }, []);

    useEffect(() => {
        if (order.id && listProductsApi.length) {
            let listPro = [];
            for (let index = 0; index < order.products.length; index++) {
                const element = order.products[index];
                const product = listProductsApi.find((item) => item.id === element.product_id);
                listPro.push({ ...product, uOrder: element.cant, cant_delivered: element.cant_delivered, cant_schedule_amount: element.cant_schedule_amount });
            }
            setListProductSelect(listPro);
            setLoading(false);
        }
    }, [order.i, listProductsApi.length]);

    //listas para breadcrumbs
    const listBreadcrumbs = [
        {
            title: 'Órdenes de compra',
            url: '/provider/orders/list'
        },
        {
            title: `Orden ${order.purchase_order_id ? order.purchase_order_id : ''}`
        }
    ];

    const onSubmit = (values) => {
        const { cant } = values;
        let data = [];
        for (let index = 0; index < cant.length; index++) {
            data.push({
                product_id: listProductSelect[index].id,
                department: listProductSelect[index].product_subcategory?.product_category?.department?.id,
                cant: cant[index]
            })
        }

        setListDataToModal(data);
        setOpenModaleSheduleDelivery(true);
    }

    const handleSaveData = async (info) => {

        setOpenModaleSheduleDelivery(false);
        const { cant } = getValues();


        let data = [];
        for (let index = 0; index < cant.length; index++) {
            data.push({
                product_id: listProductSelect[index].id,
                department: listProductSelect[index].product_subcategory?.product_category?.department?.id,
                cant: cant[index]
            })
        }

        const dataInfo = {
            ...info,
            purchase_order_id: order.id,
            products: data
        }

        try {
            setLoading(true);
            await axiosInstance.post('scheduled_deliveries', dataInfo);
            toast.info('Entrega programada creada con éxito!');
            setTimeout(() => {
                history.push("/provider/deliveries/list");
                setLoading(false);
            }, 2000);
        } catch (error) {
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            }
        }
    }

    const handleCloseModal = () => {
        setOpenModaleSheduleDelivery(false);
    }

    return (
        <>
            <HeaderProvider
                title={`Orden ${order.purchase_order_id ? order.purchase_order_id : ''}`}
                user={user}
                view='edit-order'
                type='detail'
                listBreadcrumbs={listBreadcrumbs} />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <FormPacklist
                        type='new'
                        listProductSelected={listProductSelect}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        control={control}
                        order={order}
                        titleButton="Continuar con fecha de entrega" />
                    <Footer />
                </>
            )}
            {openModaleSheduleDelivery && (
                <ModalSheduleDelivery
                    open={openModaleSheduleDelivery}
                    onClose={handleCloseModal}
                    handleSaveData={handleSaveData}
                    purchaseOrderId={`${order.purchase_order_id ? order.purchase_order_id : ''}`}
                    listDataToModal={listDataToModal}
                    listWarehouses={listWarehouses} />
            )}
        </>

    );
}