import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Container, Tab, Tabs } from '@material-ui/core';
import jwt_decode from 'jwt-decode';

// importaciones de modulos
import Loading from '../../../../components/loading/Loading';
import Footer from '../../../../components/footer/Footer';
import axiosInstance from '../../../../utils/axios';
import FormProvider from '../../../../components/form-provider/FormProvider';
import ListUserProvider from '../../../../components/list-users-providers';
import HeaderProvider from '../header/Header';
import { getToken } from '../../../../utils/auth-herpers';

// importar estilos
import '../../../register/register-provider/register-provider.css';

// images
import icon_list_products from '../../../../assets/images/icon-list-products.png';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      role: jwt_decode(getToken()).role.key,
      loading: false,
      listStates: [],
      activeUsers: [],
      listDelegations: [],
      listSuburbs: [],
      tabValue: 0,
      cantUsers: 0,
      formProvider: {
        id_temp: Math.floor(1 + Math.random() * (99999 - 1)),
        business_name: '',
        rfc: '',
        account_clabe: '',
        errorAccountClabe: false,
        textErrorAccountClabe: 'El número de cuenta clabe debe tener mínimo 11 dígitos',
        account_bank: '',
        account_number: '',
        errorAccountNumber: false,
        textErrorAccountNumber: 'El número de cuenta debe tener mínimo 6 dígitos',
        username: '',
        email: '',
        errorEmail: false,
        textErrorEmail: 'El correo no es válido',
        password: '',
        passwordErrorMessage: '',
        passwordValid: false,
        showPassword: false,
        confirm_password: '',
        confirmPasswordErrorMessage: '',
        showConfirmPassword: false,
        confirmPasswordValid: false,
        contact_business_email: '',
        errorContactBusinessEmail: false,
        textErrorContactBusinessEmail: 'El correo no es válido',
        contact_business_phone: '',
        errorContactBusinessPhone: false,
        textErrorContactBusinessPhone: 'El número de telefono debe tener mínimo 7 dígitos',
        contact_business_name: '',
        contact_business_workstation: '',
        contact_logistic_email: '',
        errorContactLogisticEmail: false,
        textErrorContactLogisticEmail: 'El correo no es válido',
        contact_logistic_phone: '',
        errorContactlogisticPhone: false,
        textErrorContactlogisticPhone: 'El número de telefono debe tener mínimo 7 dígitos',
        contact_logistic_name: '',
        contact_logistic_workstation: '',
        contact_financial_email: '',
        errorContactFinancialEmail: false,
        textErrorContactFinancialEmail: 'El correo no es válido',
        contact_financial_phone: '',
        contact_financial_name: '',
        contact_financial_workstation: '',
        address_street: '',
        address_number_exterior: '',
        address_number_interior: '',
        address_suburb: '',
        address_delegation: '',
        address_postcode: '',
        address_city: '',
        address_state: '',
        number_employees: '',
        company_classification: '',
        credit_days: '',
        name_legal_representative: '',
        curp_legal_representative: '',
      },
      form: {
        bank_face: '', //file
        bank_face_percent: '',
        opinion_fulfillment_sat: '', //file
        opinion_fulfillment_sat_percent: '',
        power_attorney: '', //file
        power_attorney_percent: '', //file
        opinion_fulfillment_tax_obligations: '', //file
        opinion_fulfillment_tax_obligations_percent: '', //file
        identification_legal_representative_front: '', //file
        identification_legal_representative_front_percent: '', //file
        identification_legal_representative_reverse: '', //file
        identification_legal_representative_reverse_percent: '', //file
        proof_address: '', //file
        proof_address_percent: '', //file
        proof_tax_situation: '', //file
        proof_tax_situation_percent: '', //file
        constitutive_act: '', //file
        constitutive_act_percent: '', //file
      },
    };
  }

  loadInfoData = () => {
    this.setState({ loading: true }, async () => {
      try {
        const get = await axiosInstance.get(`providers/info/iam`);
        const users = (await axiosInstance.get(`providers/info/users`))?.data?.users || [];
        const states = await axiosInstance.get(`utils/estados`);

        const activeUsers = users.filter(u => u.status_id === '0021a567-66a4-4147-8f4c-4a54287d9305');

        let listDelegations = [];
        let listSuburbs = [];

        if (get.data.address_state) {
          const delegations = await axiosInstance.get(`utils/municipios/${get.data.address_state}`);
          const suburbs = await axiosInstance.get(`utils/colonias/${get.data.address_delegation}`);

          listDelegations = delegations.data.map(delegation => {
            return { value: delegation.id, label: delegation.nombre };
          });
          listSuburbs = suburbs.data.map(suburb => {
            return { value: suburb.id, label: suburb.nombre };
          });
        }
        const listStates = states.data.map(state => {
          return { value: state.id, label: state.nombre };
        });

        this.setState({
          ...this.state,
          listStates,
          activeUsers,
          listDelegations,
          listSuburbs,
          formProvider: {
            ...this.state.formProvider,
            ...get.data,
            password: '',
          },
          form: {
            ...this.state.form,
            ...get.data,
          },
          loading: false,
        });

        var temp = listStates.filter(option => option.value.toString() === get.data.address_state);
      } catch (error) {
        console.log(error);
      }
    });
  };

  saveInfoProvider = data => {
    let stateLocal = this.state;

    let combinedData = {
      ...data,
      formProvider: {
        ...data.formProvider,
        ...data.form,
        departments: stateLocal.formProvider.departments,
        warehouses: stateLocal.formProvider.warehouses,
        credit_days: stateLocal.formProvider.credit_days,
        schedule: stateLocal.formProvider.schedule,
        status: stateLocal.formProvider.status,
      },
    };

    this.saveInfo(combinedData);
  };

  saveInfoHeader = data => {
    this.saveInfo(this.state);
  };

  saveInfo = data => {
    try {
      this.setState({ loading: true }, async () => {
        try {
          await axiosInstance.put(`providers/${data.formProvider.id}`, data.formProvider);

          this.setState({ loading: false });

          toast.success('¡Proveedor modificado con éxito!');
          this.loadInfoData();
        } catch (error) {
          console.log(error.response);
          // Error 😨
          if (error.response) {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          } else {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          }
        }
      });
    } catch (e) {
      alert(e.message);
    }
  };

  handleChangeTab = (event, value) => {
    this.setState({
      ...this.state,
      tabValue: value,
    });
  };

  handleCantUsers = cant => {
    this.setState({
      ...this.state,
      cantUsers: cant,
    });
  };

  render() {
    return (
      <>
        <HeaderProvider
          title='Empresa'
          icon={icon_list_products}
          user={this.state.user}
          view='empresa'
          type='detail'
          isUsers={this.state.tabValue === 0 ? false : this.state.cantUsers > 3 ? false : true}
        />
        <Container>
          <Tabs
            variant='standard'
            value={this.state.tabValue}
            onChange={this.handleChangeTab}
            indicatorColor='primary'
            textColor='primary'
            className='bgtabs'
          >
            <Tab label='Información' />
            {this.state.role === 'provider' && <Tab label='Usuarios' />}
          </Tabs>
          {this.state.loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              {this.state.tabValue === 0 ? (
                <FormProvider
                  handleSaveInfo={this.saveInfoProvider}
                  state={this.state}
                  hiddenPassword='true'
                  titleButton='Guardar cambios'
                />
              ) : (
                this.state.role === 'provider' && (
                  <>
                    <ListUserProvider onCantUsers={this.handleCantUsers} updateProfile={this.loadInfoData} />
                  </>
                )
              )}
            </>
          )}
        </Container>
        <Footer />
      </>
    );
  }

  componentDidMount() {
    var notLoad = JSON.parse(localStorage.getItem('user'));
    if (notLoad.notLoad) {
      this.props.history.push('/provider/form-providers');
    }
    this.loadInfoData();
  }
}

export default Profile;
