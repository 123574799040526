import React, {useState, useEffect, useLayoutEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

// imports
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header";
import FormDetailOrder from "../../../../../components/form-detail-order";
import moment from "moment";
import ModalCancelOrderOCSDetail from './Cancel';
import ModalApprovedOC from './Approved';
import {generateString} from "../../../../../utils";

export default function DetailSuggestOrders(props) {

    const [listProductSelect, setListProductSelect] = useState([]);
    const [order, setOrder] = useState({});

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [openModalApprovedOC, setOpenModalApprovedOC] = useState(false);
    const [randomStr, setRandomStr] = useState();

    const history = useHistory();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        setValue
    } = useForm();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let responseOrder = await axiosInstance.get(`purchase_orders/${props.match.params.id}`,);
            setOrder(responseOrder.data);
        }
        fetch();
        setLoading(false);
    }, [randomStr]);

    useEffect(() => {
        if (order.id) {
            let listPro = [];
            for (let index = 0; index < order.products.length; index++) {
                const element = order.products[index];
                listPro.push({ ...element, total: (element?.unit_price * element?.cant) });
            }
            setListProductSelect(listPro);
            setLoading(false);
        }
    }, [order.id])

    const handleSaveImport = async (file, idOC) => {
        setOpenModalApprovedOC(false);
        const formData = new FormData();

        formData.append("file", file);
        formData.append('purchase_order_id',idOC)
        formData.append("type", "PDF");
            setLoading(true);
            try {
                await axiosInstance.put("/purchase_orders/approved/" + order?.id, formData);
                setRandomStr(generateString())
            } catch (error) {
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                } else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            }
            finally {
                setLoading(false);
            }
    }

    const rejectOC = async () => {
        setLoading(true);
        try {
            await axiosInstance.put("/purchase_orders/cancel/" + order?.id);
            setRandomStr(generateString())
        } catch (error) {
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
        finally {
            setLoading(false);
        }
    }

    //listas para breadcrumbs
    const listBreadcrumbs = [
        {
            title: 'Órdenes de compra',
            url: '/finance/orders/list'
        },
        {
            title: `Orden de compra ${order.purchase_order_id ? order.purchase_order_id : ''}`
        }
    ];

    const onSubmit = async (values) => {
        // Aprobar orden
    }

    const onCancel = () => {
        console.log('cancelar');
    }

    const onApprovedOC = () => {
        console.log('cancelar');
    }

    return (
        <>
            <HeaderProvider
                title={`${order.purchase_order_id ? order.purchase_order_id : ''} - Proveedor`}
                user={user}
                view='edit-order'
                type='detail'
                createdAt={moment(order?.created_at).format("DD/MM/YYYY")}
                status={order.id ? order?.status_ocs[0]?.key : ''}
                listBreadcrumbs={listBreadcrumbs} />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <FormDetailOrder
                        listProductSelected={listProductSelect}
                        order={order}
                        role="receipt"
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        openModalCancel={() => setOpenModalCancel(true)}
                        openModalApprovedOC={() => setOpenModalApprovedOC(true)}
                    />
                    <Footer />
                </>
            )}
            <ModalCancelOrderOCSDetail
                open={openModalCancel}
                onClose={() => setOpenModalCancel(false)}
                onReject={rejectOC}
            />

            <ModalApprovedOC
                open={openModalApprovedOC}
                onClose={() => setOpenModalApprovedOC(false)}
                onCancel={onApprovedOC}
                onSave={handleSaveImport}
                init=""/>
            <ToastContainer />
        </>
    );

}