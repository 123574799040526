import { Container, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import InputPassword from '../common/Forms/InputPassword';
import InputTextField from '../common/Forms/InputTextField';
import InputEmail from '../common/Forms/InputEmail';
import InputNumber from '../common/Forms/InputNumber';
import InputSelect from '../common/Forms/InputSelect';
import InputFile from '../common/Forms/InputFile';
import axiosInstance from '../../utils/axios';
import { toast } from 'react-toastify';

const listNumberEmployees = [
  { value: '0-50', label: '0-50' },
  { value: '50-100', label: '50-100' },
  { value: '200-500', label: '200-500' },
  { value: '500-1000', label: '500-1000' },
  { value: '>1000', label: '>1000' },
];

export default function FormRegister({
  estados,
  municipios = [],
  colonias = [],
  idTemp,
  register,
  errors,
  control,
  onSubmit,
  handleSubmit,
  getValues,
  setValue,
  setError,
}) {
  const [estadosState, setEstadosState] = useState(estados);
  const [municipiosState, setMunicipiosState] = useState(municipios);
  const [coloniasState, setColoniasState] = useState(colonias);

  //variables para los select de la direccion
  const [estadoId, setEstadoId] = useState(null);
  const [municipioId, setMunicipioId] = useState(null);

  //variables para los input tipos file
  // const [bankFacePercent, setBankFacePercent] = useState(getValues('bank_fase') != '' ? 100 : null);
  // const [opinionFulfillmentSatPercent, setOpinionFulfillmentSatPercent] = useState(
  //   getValues('opinion_fulfillment_sat') != '' ? 100 : null
  // );

  useEffect(() => {
    async function fetch() {
      const temp = await axiosInstance.get(`/utils/municipios/${estadoId}`);
      setMunicipiosState(temp.data);
    }
    if (estadoId !== null) {
      fetch();
    }
  }, [estadoId]);

  useEffect(() => {
    async function fetch() {
      const temp = await axiosInstance.get(`/utils/colonias/${municipioId}`);
      setColoniasState(temp.data);
    }
    if (municipioId !== null) {
      fetch();
    }
  }, [municipioId]);

  const onChangeEstado = () => {
    setEstadoId(getValues('address_state')?.value || '');
    setMunicipiosState([]);
    setColoniasState([]);
    setValue('address_delegation', null);
    setValue('address_suburb', null);
  };

  const onChangeMunicipio = () => {
    setMunicipioId(getValues('address_delegation').value);
    setColoniasState([]);
    setValue('address_suburb', null);
  };

  const validPercents = values => {
    return onSubmit(values);

    // let band = true;

    // if (values.bank_face == undefined || values.bank_face === null) {
    //   setError(
    //     'bank_face',
    //     {
    //       type: 'manual',
    //       message: 'Este campo es obligatorio',
    //     },
    //     {
    //       shouldFocus: true,
    //     }
    //   );
    //   band = false;
    // }
    // if (values.opinion_fulfillment_sat === undefined || values.opinion_fulfillment_sat === null) {
    //   setError(
    //     'opinion_fulfillment_sat',
    //     {
    //       type: 'manual',
    //       message: 'Este campo es obligatorio',
    //     },
    //     {
    //       shouldFocus: true,
    //     }
    //   );
    //   band = false;
    // }

    // if (band) {
    //   console.log('Bank face ', bankFacePercent);
    //   // console.log('Opinion Fulfillment Sat ', opinionFulfillmentSatPercent);
    //   if (bankFacePercent === 100 && opinionFulfillmentSatPercent === 100) {
    //     onSubmit(values);
    //   } else {
    //     toast.warning('Verifique que todos los archivos se cargaron correctamente');
    //   }
    // }
  };

  return (
    <>
      <Container maxWidth='md'>
        <form onSubmit={handleSubmit(validPercents)} style={{ paddingTop: '50px' }}>
          <div className='section-register'>
            <div className='title-section'>Información cuenta</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Nombre de contacto *</div>
                <InputTextField register={register} errors={errors} name='username' placeholder='Nombre de contacto' />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Correo electrónico *</div>
                <InputEmail register={register} errors={errors} name='email' placeholder='Correo electrónico' />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>
                  Contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
                </div>
                <InputPassword register={register} errors={errors} name='password' placeholder='Contraseña' />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>
                  Confirmar contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
                </div>
                <InputPassword
                  register={register}
                  errors={errors}
                  name='confirm_password'
                  placeholder='Confirmar Contraseña'
                />
              </Grid>
            </Grid>
          </div>
          <div className='section-register'>
            <div className='title-section'>Información comercial</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Razón social *</div>
                <InputTextField register={register} errors={errors} name='business_name' placeholder='Razón social' />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>RFC *</div>
                <InputTextField register={register} errors={errors} name='rfc' placeholder='RFC' />
              </Grid>

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Opinión de cumplimiento de SAT *</div>
                <InputFile
                  setValue={setValue}
                  nameInput='opinion_fulfillment_sat'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  handleInputDataPercent={setOpinionFulfillmentSatPercent}
                  dataFileInput={getValues('opinion_fulfillment_sat') ?? ''}
                />
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Carátula bancaria *</div>
                <InputFile
                  setValue={setValue}
                  nameInput='bank_face'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  handleInputDataPercent={setBankFacePercent}
                  dataFileInput={getValues('bank_face') ?? ''}
                />
              </Grid> */}

              {/* aqui van los archivos */}

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Banco *</div>
                <InputTextField register={register} errors={errors} name='account_bank' placeholder='Banco' />
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Número de cuenta *</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  minLength='6'
                  name='account_number'
                  placeholder='Número de cuenta'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Cuenta CLABE *</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  minLength='11'
                  name='account_clabe'
                  placeholder='Cuenta CLABE'
                />
              </Grid> */}
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de contacto comercial</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Nombre de contacto *</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  name='contact_business_name'
                  placeholder='Nombre de contacto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Puesto *</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  name='contact_business_workstation'
                  placeholder='Puesto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Correo electrónico *</div>
                <InputEmail
                  register={register}
                  errors={errors}
                  name='contact_business_email'
                  placeholder='Correo electrónico'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Teléfono *</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  minLength='7'
                  name='contact_business_phone'
                  placeholder='Teléfono'
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de contacto logístico</div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Nombre de contacto</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_logistic_name'
                  placeholder='Nombre de contacto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Puesto</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_logistic_workstation'
                  placeholder='Puesto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Correo electrónico</div>
                <InputEmail
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_logistic_email'
                  placeholder='Correo electrónico'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Teléfono</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  required={false}
                  minLength='7'
                  name='contact_logistic_phone'
                  placeholder='Teléfono'
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de contacto financiero</div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Nombre de contacto</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_financial_name'
                  placeholder='Nombre de contacto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Puesto</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_financial_workstation'
                  placeholder='Puesto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Correo electrónico</div>
                <InputEmail
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_financial_email'
                  placeholder='Correo electrónico'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Teléfono</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  required={false}
                  minLength='7'
                  name='contact_financial_phone'
                  placeholder='Teléfono'
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Datos de otro contacto</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> Nombre de contacto</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_other_name'
                  placeholder='Nombre de contacto'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Puesto</div>
                <InputTextField
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_other_workstation'
                  placeholder='Puesto'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Correo electrónico</div>
                <InputEmail
                  register={register}
                  errors={errors}
                  required={false}
                  name='contact_other_email'
                  placeholder='Correo electrónico'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Teléfono</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  required={false}
                  minLength='7'
                  name='contact_other_phone'
                  placeholder='Teléfono'
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Domicilio fiscal</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Calle *</div>
                <InputTextField register={register} errors={errors} name='address_street' placeholder='Calle' />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Número exterior </div>
                <InputTextField
                  required={false}
                  register={register}
                  errors={errors}
                  name='address_number_exterior'
                  placeholder='Número exterior'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Número interior *</div>
                <InputTextField
                  required={true}
                  register={register}
                  errors={errors}
                  name='address_number_interior'
                  placeholder='Número interior'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> CP *</div>
                <InputNumber
                  register={register}
                  errors={errors}
                  minLength='4'
                  name='address_postcode'
                  placeholder='CP'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> Estado *</div>
                <InputSelect
                  register={register}
                  errors={errors}
                  name='address_state'
                  placeholder='Seleccione un estado'
                  control={control}
                  options={estadosState?.map(e => ({
                    value: e.id,
                    label: e.nombre,
                  }))}
                  onChange={onChangeEstado}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> Municipio / Alcaldía *</div>
                <InputSelect
                  register={register}
                  errors={errors}
                  name='address_delegation'
                  placeholder='Seleccione un municipio / alcaldía'
                  control={control}
                  options={municipiosState.map(e => ({
                    value: e.id,
                    label: e.nombre,
                  }))}
                  required={true}
                  onChange={onChangeMunicipio}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> Colonia *</div>
                <InputSelect
                  register={register}
                  errors={errors}
                  name='address_suburb'
                  placeholder='Seleccione una colonia'
                  control={control}
                  options={coloniasState.map(e => ({
                    value: e.id,
                    label: e.nombre,
                  }))}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'>Ciudad *</div>
                <InputTextField register={register} errors={errors} name='address_city' placeholder='Ciudad' />
              </Grid>
            </Grid>
          </div>

          <div className='section-register'>
            <div className='title-section'>Información empresarial</div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> Número de empleados</div>
                <InputSelect
                  register={register}
                  errors={errors}
                  name='number_employees'
                  placeholder='Seleccione una opción'
                  control={control}
                  options={listNumberEmployees.map(e => ({
                    value: e.value,
                    label: e.label,
                  }))}
                  required={false}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Empresa</div>
                <RadioGroup
                  row
                  aria-label='position'
                  name='company_classification'
                  defaultValue='top'
                  {...register('company_classification')}
                >
                  <FormControlLabel value='national' control={<Radio color='primary' />} label='Nacional' />
                  <FormControlLabel value='international' control={<Radio color='primary' />} label='Internacional' />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Comprobante de domicilio no mayor a 3 meses de antigüedad</div>
                <InputFile
                  setValue={setValue}
                  nameInput='proof_address'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('proof_address') ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Constancia de situación fiscal</div>
                <InputFile
                  setValue={setValue}
                  nameInput='proof_tax_situation'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('proof_tax_situation') ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Acta constitutiva completa con sus anexos</div>
                <InputFile
                  setValue={setValue}
                  nameInput='constitutive_act'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('constitutive_act') ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Opinión del cumplimiento de obligaciones fiscales</div>
                <InputFile
                  setValue={setValue}
                  nameInput='opinion_fulfillment_tax_obligations'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('opinion_fulfillment_tax_obligations') ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Poder notarial del representante legal</div>
                <InputFile
                  setValue={setValue}
                  nameInput='power_attorney'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('power_attorney') ?? ''}
                />
              </Grid>
            </Grid>
          </div>

          <div className='section-register borF'>
            <div className='title-section'>Información de representante legal</div>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> Nombre completo del representante legal</div>
                <InputTextField
                  register={register}
                  required={false}
                  errors={errors}
                  name='name_legal_representative'
                  placeholder='Nombre completo del representante legal'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register label-register-password'> CURP del representante legal</div>
                <InputTextField
                  register={register}
                  required={false}
                  errors={errors}
                  name='curp_legal_representative'
                  placeholder='CURP del representante legal'
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Identificación oficial INE - Frente</div>
                <InputFile
                  setValue={setValue}
                  nameInput='identification_legal_representative_front'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('identification_legal_representative_front') ?? ''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <div className='label-register'>Identificación oficial INE - Reverso</div>
                <InputFile
                  setValue={setValue}
                  nameInput='identification_legal_representative_reverse'
                  idTemp={idTemp}
                  register={register}
                  errors={errors}
                  control={control}
                  dataFileInput={getValues('identification_legal_representative_reverse') ?? ''}
                />
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={5}>
            <Grid item xs={2} md={4} lg={4}></Grid>
            <Grid item xs={8} md={4} lg={4}>
              <button className='button-save-active' type='submit'>
                <div className='text'>Finalizar registro</div>
              </button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <br />
      <br />
    </>
  );
}
