import React, { useState } from "react";
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router';

// Importaciones
import { useAppContext } from "../../libs/contextLib";

// importar estilos
import './header-admin.css';

// images
import img_logo from '../../assets/images/logo.png';
import img_icon_arrow_logout from '../../assets/images/icon-arrow-logout.png';

function HeaderAdmin(prop) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');

    const history = useHistory();
    const { userHasAuthenticated } = useAppContext();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.clear();
        history.replace('/');
        setAnchorEl(null);
        userHasAuthenticated(false);
    }

    const handleChangeForm = (event) => {
        setSearch(event.target.value);
        prop.search(event.target.value);
    }

    const handleClearSearch = () => {
        setSearch('');
        prop.search('');
        prop.onSearchButton();
    }

    const handleSubmitSearch = (event) => {
        event.preventDefault();
        prop.onSearchButton();
    }

    return (
        <>
            <Container maxWidth="xl" className="head">
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <div className="logo-admin">
                            <img src={img_logo} alt="logo" />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Button className="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            {prop.user.email} <span className="arrow-logout"><img src={img_icon_arrow_logout} alt="" /></span>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Container>
            {prop.type === 'admin' ? (
                <>
                    <div className='box-title-admin'>
                        <div className="title-min">
                            {prop.title}
                        </div>
                        <Container maxWidth="lg">
                            <div className="title-view-admin">
                                <img src={prop.icon} alt={prop.title} /> {prop.title}
                            </div>

                            <div className="con">
                                <form onSubmit={handleSubmitSearch}>
                                    <input type="text" className="input-header-search" placeholder="Buscar proveedores" name="search" value={search} onChange={(e) => handleChangeForm(e)} />
                                    {search.length > 0 ? (
                                        <>
                                            <Close onClick={handleClearSearch} />
                                        </>
                                    ) : (
                                        <>
                                            <Search />
                                        </>
                                    )}

                                </form>
                            </div>

                        </Container>
                    </div>
                </>) : prop.type === 'provider' ? (
                    <>
                        <div className='box-title-admin-provider'>
                            <div className="title-min">
                                Proveedores registrados {'>'} {prop.title}
                            </div>
                            <Container maxWidth="md">
                                <div className="title-view-admin">
                                    {prop.title}
                                </div>
                            </Container>
                        </div>
                    </>
                ) : (<>
                    <div className='box-title-admin-provider'>
                        <div className="title-min">
                            Asesores registrados {'>'} {prop.user.name}
                        </div>
                        <Container maxWidth="lg">
                            <div className="title-view-admin">
                                {prop.user.name}
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </>
    );
}


export default HeaderAdmin;