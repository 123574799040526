import React from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export default function Toast() {
    return (
        <ToastContainer
            position="top-right"
            autoClose={6000}
            hideProgressBar={true}
            closeOnClick
            draggable
            pauseOnHover={false}
        />
    );
}