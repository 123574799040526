import React, { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

// images
import img_close_modal from '../../../../../assets/images/close-modal.png';
import axiosInstance from '../../../../../utils/axios';
import moment from 'moment';

registerLocale('es', es);


export default function ModalSheduleDelivery({ open, handleSaveData, listDataToModal, listWarehouses, onClose, purchaseOrderId }) {

    const [listShedules, setListShedules] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [warehouse, setWarehouse] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        setValue
    } = useForm();

    const selectInputRef = useRef();

    const onSubmit = async (values) => {
        const data = {
            date: values.date,
            hour: values.hour.value,
            warehouse_id: values.warehouse_id.value
        }

        handleSaveData(data);
    }

    const loadSchedules = async () => {
        if (getValues('date') && getValues('warehouse_id')) {
            let data = [];
            for (let index = 0; index < listDataToModal.length; index++) {
                data.push({
                    product_id: listDataToModal[index].product_id,
                    department: listDataToModal[index].department,
                    cant: listDataToModal[index].cant
                })
            }

            const body = {
                date: getValues('date'),
                warehouse_id: getValues('warehouse_id').value,
                products: data
            }

            const list = await axiosInstance.post('/scheduled_deliveries/calculateScheduled', body);
            setValue('hour', null);
            setListShedules(list.data);
        }
    }

    const getDay = date => {
        console.log(date);
        return date.getDay();
    }

    const getWarehouse = () => {
        if (warehouse !== null) {
            if (warehouse.label.split(' - ')[1] === 'AB')
                return 0
            return -1;
        }
        return 0;
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        ><Fade in={open}>
                <div className="modal-delete" style={{ maxWidth: '800px' }}>
                    <div className="icon-close" onClick={onClose}>
                        <div className="title-modal-provider">
                            Agendar Entrega
                            <img src={img_close_modal} alt="Cerrar" />
                        </div>
                    </div>
                    <div className="documents-modal-provider">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="container-load-products container-modal-detele">
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="label">
                                            Orden de compra
                                        </div>
                                        <div className="item-privider">
                                            {purchaseOrderId}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="label">
                                            Bodega de entrega
                                        </div>
                                        <div className="item-privider">
                                            <Controller
                                                name='warehouse_id'
                                                control={control}
                                                {...register('warehouse_id', {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                })}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder={'Seleccione una bodega'}
                                                        options={listWarehouses.map((item) => ({
                                                            value: item.id,
                                                            label: `${item.name} - ${item.initials}`
                                                        }))}
                                                        onChange={e => {
                                                            field.onChange(e);
                                                            selectInputRef.current.value = null;
                                                            loadSchedules();
                                                            setWarehouse(e);
                                                        }}
                                                        isSearchable
                                                    />
                                                )}
                                            />
                                            {errors.warehouse_id && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.warehouse_id.message}
                                                </p>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="container-select-day">
                                            <div className="label-order">
                                                Seleccionar día de entrega
                                            </div>
                                            <Controller
                                                name='date'
                                                control={control}
                                                {...register('date',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        }
                                                    })
                                                }
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        locale="es"
                                                        onChange={e => {
                                                            field.onChange(e);
                                                            setStartDate(e);
                                                            loadSchedules();
                                                        }}
                                                        selected={startDate}
                                                        minDate={new Date()}
                                                        filterDate={date => getDay(date) !== getWarehouse()}
                                                        className="input-register" />
                                                    // <input
                                                    //     {...field}
                                                    //     onChange={e => {
                                                    //         field.onChange(e);
                                                    //         loadSchedules();
                                                    //     }}
                                                    //     min={new Date().toISOString().split("T")[0]}
                                                    //     type="date"
                                                    //     className="input-register"
                                                    //     isValidDate={disableWeekends}
                                                    // />
                                                )}
                                            />
                                            {errors.date && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.date.message}
                                                </p>
                                            )}

                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="container-select-day">
                                            <div className="label-order">
                                                Seleccionar horario de entrega
                                            </div>
                                            <Controller
                                                name='hour'
                                                control={control}
                                                {...register('hour', {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                })}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder={'Seleccione un horario'}
                                                        options={listShedules.map((item) => ({
                                                            value: `${item.start_date} - ${item.end_date}`,
                                                            label: `${item.start_date} - ${item.end_date}`
                                                        }))}
                                                        ref={selectInputRef}
                                                        isSearchable
                                                    />
                                                )}
                                            />
                                            {errors.hour && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.hour.message}
                                                </p>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={6}>
                                        <div className="container-btn-save">
                                            <button className="button-delete" type="submit">
                                                Agendar entrega
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}