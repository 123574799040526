import React, { Component } from "react";
import { Container, Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

// importaciones de modulos
import Header from '../../../components/header/Header';
import Loading from '../../../components/loading/Loading';
import Footer from "../../../components/footer/Footer";
import axiosInstance from "../../../utils/axios";

// importar estilos
import './load-products.css';

// images
import img_file_download from '../../../assets/images/file_download.png';
import img_file_load from '../../../assets/images/file_load.png';
import img_remove_file from '../../../assets/images/icon-remove-file.png';
import img_icon_products from '../../../assets/images/icon-products.png';

// archivo de descargar
import file from '../../../assets/file.xlsx';

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Inicio',
        url: '/'
    },
    {
        title: 'Registro',
        url: '/register'
    },
    {
        title: '2 - Alta de productos'
    }
];

class LoadProducts extends Component {

    constructor() {
        super();

        this.state = {
            loading: false,
            form: {
                name: '',
                file: '',
                loadImage: false
            },
            provider_id: JSON.parse(localStorage.getItem('provider')).id
        }
    }

    selectFile = (e) => {
        document.getElementById('selectedFile').click();
    }

    handleChangeForm = (event) => {
        this.setState({
            form: {
                ...this.state.form,
                loadImage: true,
                [event.target.name]: event.target.files[0],
                name: event.target.files[0].name
            }
        })
    }

    handleCleanForm = (id) => {
        this.setState({
            form: {
                name: '',
                file: '',
                loadImage: false
            }
        });
        document.getElementById(id).value = null;
    }

    handleValidForm = () => {
        if ((this.state.form.file !== '' && this.state.form.file !== null)) {
            return true
        }
        return false;
    }

    handleSaveProducts = async (event) => {

        event.preventDefault();

        try {

            const formData = new FormData();
            formData.append("file", this.state.form.file);
            formData.append("provider_id", this.state.provider_id);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            this.setState({ loading: true });

            try {
                await axiosInstance.post(`products/upload`, formData, config);
                this.setState({ ...this.state.form, loading: false });
                localStorage.setItem('isLoadProducts', JSON.stringify(true));
                toast.success("Catálogo cargado con éxito");
                this.props.history.push("/register");
            } catch (error) {
                this.setState({ loading: false });
                console.log(error);

                // Error 😨
                if (error && error.response && error.response.data) {
                    if (error.response.data.error) {
                        toast.error(error.response.data.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    }
                    else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    }
                } else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            }
        } catch (e) { console.log(e); }
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Header listBreadcrumbs={listBreadcrumbs} title='2 - Alta de productos' icon={img_icon_products} />
                        <Container maxWidth="md">
                            <div className="container-load-products">
                                <div className="title-section">
                                    Subir productos
                                </div>

                                <div className="label">
                                    1. Descarga el formato de Excel y <span className="text-underline">llena los campos de cada producto</span> *
                                </div>

                                <a href={file}>
                                    <div className="button-load-products">
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <div className="img-button-not-loaded">
                                                    <img src={img_file_download} alt="Download" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <div className="text-button">Descarga de formato de Excel</div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </a>

                                <div className="label">
                                    2. Sube el archivo Exel diligenciado con todos los productos y asi guardar y continuar el proceso de registro *
                                </div>

                                <input
                                    id="selectedFile"
                                    type="file"
                                    name="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={(e) => this.handleChangeForm(e)}
                                />

                                {this.state.form.loadImage ? (
                                    <>
                                        <div className="button-load-products-file">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load-product">
                                                        {this.state.form.name}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <button className="img-button-product" onClick={(e) => this.handleCleanForm('selectedFile')}>
                                                        <img src={img_remove_file} alt="Eliminar" />
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>

                                ) : (
                                    <>
                                        <button className="button-load-products-file" onClick={(e) => this.selectFile()}>
                                            <Grid container >
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded">
                                                        <img src={img_file_load} alt="Cargar" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <div className="text-button text-button-not-loaded">
                                                        Arrastra el archivo aquí o <span className="text-underline-load-products">selecciónalo desde tu dispositivo</span>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </button>
                                    </>
                                )}

                                <br />
                                <span className="nota"><strong>Nota:</strong> Todos los campos del archivo tienen que estar completos para que sea válido</span>
                                <br />
                                <br />

                                <div>
                                    <button className={this.handleValidForm() ? 'button-save-active' : 'button-save'} onClick={(e) => this.handleSaveProducts(e)} disabled={!this.handleValidForm()}>
                                        <div className="text">
                                            Guardar productos y continuar
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </Container>
                        <Footer />
                    </>
                )}
            </>
        );
    }
}

export default LoadProducts;