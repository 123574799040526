import React, { useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';

//importaciones
import { currencyFormat } from '../../utils/pipes';

export default function FormPacklist({ type, listProductSelected, onSubmit, register, errors, handleSubmit, control, order, titleButton }) {

    const [listProductSelect, setListProductSelect] = useState(listProductSelected);

    const isEven = (score) => !isNaN(score);

    const rowsProductsList = listProductSelect.map((product, index) => {
        return (
            <div className="row-table row-body" key={product.id}>
                <Grid container spacing={1}>
                    <Grid item md={type === 'new' ? 3 : 5}>
                        <div className="item-provider" style={{ paddingTop: '20px' }} >
                            {product.description}
                        </div>
                    </Grid>
                    <Grid item xs={2} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            #{product.upc}
                        </div>
                    </Grid>
                    <Grid item xs={2} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.product_subcategory?.product_category?.department?.name}
                        </div>
                    </Grid>
                    <Grid item xs={1} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.net_unit_cost ? currencyFormat(product?.net_unit_cost) : ''}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.net_unit_cost ? 'IVA-IEPS' : ''}
                        </div>
                    </Grid>
                    {type === 'new' ? (
                        <>
                            <Grid item xs={1} className="mobilNone">
                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                    {product.uOrder}
                                </div>
                            </Grid>
                            <Grid item xs={1} className="mobilNone">
                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                    {product.cant_delivered}
                                </div>
                            </Grid>
                        </>
                    ) : (<></>)}
                    <Grid item xs={1}>
                        <div className="item-provider">
                            <div className="item-provider">
                                {product.cant_schedule_amount <= product.uOrder && (
                                    <>
                                        <Controller
                                            name={`cant[${index}]`}
                                            control={control}
                                            {...register(`cant[${index}]`,
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                    min: {
                                                        value: 0,
                                                        message: 'Valor mínimo permitido 0',
                                                    },
                                                    max: {
                                                        value: (product.uOrder - (product.cant_delivered + product.cant_schedule_amount)),
                                                        message: `Valor máximo permitido ${(product.uOrder - (product.cant_delivered + product.cant_schedule_amount))}`,
                                                    },
                                                    validate: isEven,
                                                })
                                            }
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    className="input-register"
                                                    disabled={product.cant_schedule_amount >= product.uOrder}
                                                />
                                            )}
                                        />
                                        {errors.cant && (
                                            errors.cant[index]?.type === "validate" && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    El campo solo admite números
                                                </p>
                                            )
                                        )}

                                        {errors.cant && (
                                            errors.cant[index] && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.cant[index].message}
                                                </p>
                                            )
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    });

    return (
        <>
            <div className="body-provider">
                <Container>
                    <div className="row-table row-title">
                        <Grid container spacing={1}>
                            <Grid item md={type === 'new' ? 3 : 5}>
                                <div className="title-table-orders">
                                    Nombre del producto
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    UPC
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    Departamento
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    precio
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    IMPUESTOS*
                                </div>
                            </Grid>
                            {type === 'new' ? (
                                <>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            u. pedidas
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            u. entregadas
                                        </div>
                                    </Grid>
                                </>
                            ) : (<></>)}

                            <Grid item xs={1}>
                                <div className="title-table-orders">
                                    u. A entregar
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="providers">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {rowsProductsList}
                            {order.totalCantScheduleAmount >= order.cant ? (<></>) : (
                                <>
                                    <button className="btnDetails rigth" type='submit'>
                                        <div className="text">
                                            {titleButton}
                                        </div>
                                    </button>
                                </>
                            )}
                        </form>
                    </div>
                </Container>
            </div>
        </>
    )
}