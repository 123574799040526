import React, { useState } from "react";
import { Container, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import { useAppContext } from "../../libs/contextLib";

// importar estilos
import './header.css';

// images
import img_logo from '../../assets/images/logo.png';
import img_welcome from '../../assets/images/welcome.png';
import img_separator_home from '../../assets/images/separator-header-home.png';
import img_check_home from '../../assets/images/check-home.png';
import img_files_home from '../../assets/images/files-home.png';
import img_close_modal from '../../assets/images/close-modal.png';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        borderRadius: "8.02065px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 0,
        '& li': {
            padding: '8px 0',
            fontSize: '15px',
          }
    },
}));

function Header(prop) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { userHasAuthenticated } = useAppContext();

    const history = useHistory();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLink = (url) => {
        if(prop.clear){
            localStorage.clear();
            userHasAuthenticated(false);
        }
        history.push(url);
    }

    const rowsSpaces = prop.listBreadcrumbs.map((item, index) => {
        return (index !== prop.listBreadcrumbs.length - 1) ? (
            <span key={index} onClick={(e) => handleLink(item.url)} className="item-breadcrumb">
                <span className="link-inactive">{item.title}</span>
                <span className="link-inactive"> &gt; </span>
            </span>
        ) : (
            <span key={index}>
                <span className="link-active">{item.title}</span>
            </span>
        )
    });

    return (
        <>
            <div className="logo-head head-1">
                <img src={img_logo} alt="logo" />
            </div>
            {prop.type === 'finish' ? (
                <></>
            ) : (
                <>
                    <div className={prop.type === 'home' ? 'box-title-breadcrumbs-home' : 'box-title-breadcrumbs'}>
                        <div className="breadcrumbs">
                            {rowsSpaces}
                        </div>
                        {prop.type === 'home' ? (
                            <>
                                <Container maxWidth="md">
                                    <div className="image-home-hand">
                                        <img src={img_welcome} alt='Bienvenido' />
                                    </div>
                                    <div className="image-home">
                                        <img src={img_separator_home} alt='Separator' />
                                    </div>
                                    <br />
                                    <Grid container spacing={8}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <div className="text-home">
                                                <Grid container spacing={4}>
                                                    <Grid item xs={2}>
                                                        <img src={img_check_home} alt="Check" />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p>Completa los siguientes pasos para darte de alta como proveedor</p>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <div className="text-home">
                                                <Grid container spacing={4}>
                                                    <Grid item xs={2}>
                                                        <img src={img_files_home} alt="Files" />
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        Recuerda tener listos <span className="text-underline-home" onClick={handleOpen}>estos documentos</span> antes de registrarte
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </>
                        ) : (
                            <>
                                <Container maxWidth="md">
                                    <div className="title-view">
                                        <img src={prop.icon} alt={prop.title} /> {prop.title}
                                    </div>
                                </Container>
                            </>
                        )}
                    </div>
                </>
            )}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            ><Fade in={open}>
                    <div className={classes.paper}>
                        <div className="icon-close" onClick={handleClose}>
                            <img src={img_close_modal} alt="Cerrar" />
                        </div>
                        <div className="title-modal">
                            <img src={img_files_home} alt="Files" /> Documentos comerciales
                        </div>
                        <div className="documents">
                            <ul>
                                <li>Opinión de cumpliento de SAT </li>
                                <li>Carátula Bancaria </li>
                                <li>Comprobante de domicilio no mayor a 3 meses de antigüedad </li>
                                <li>Comprobante de domicilio no mayor a 3 meses de antigüedad </li>
                                <li>Constancia de situación fiscal </li>
                                <li>Acta Constitutiva completa con sus anexos</li>
                                <li>Opinión del cumplimineto de obligaciones fiscales *</li>
                                <li>Poder notarial del representante legal </li>
                                <li>Identificación Oficial representante legal (frente y reverso)</li>
                            </ul>
                        </div>

                        {/* <img src={img_list_modal} alt="Lista" /> */}
                    </div>
                </Fade>
            </Modal>
        </>
    );
}


export default Header;