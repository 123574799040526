import { Grid, LinearProgress, Link } from "@material-ui/core";
import { useRef, useState } from "react";
import { Controller } from 'react-hook-form';

//import
import axiosInstance from "../../../utils/axios";

// imagenes
import img_file_load from '../../../assets/images/file_load.png';
import img_remove_file from '../../../assets/images/icon-remove-file.png';

export default function InputFile({ register, control, errors, nameInput, idTemp, required = null, handleInputDataPercent, dataFileInput, setValue }) {

    const [fileInputData, setFileInputData] = useState(dataFileInput);
    const [fileInputDataPercent, setFileInputDataPercent] = useState(dataFileInput != '' ? 100 : '');

    const fileInput = useRef(null)

    const handleChangeFormImage = (event) => {
        setFileInputData(event.target.files[0]);
        event.preventDefault();
        const formData = new FormData();
        // agregar imagenes
        formData.append("file", event.target.files[0]);
        formData.append("field", nameInput);
        formData.append("id_temp", idTemp);

        axiosInstance.post("/resources", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                setFileInputDataPercent(Math.round((100 * data.loaded) / data.total));
                handleInputDataPercent(Math.round((100 * data.loaded) / data.total));
            },
        });
    }

    const selectFile = (event) => {
        event.preventDefault();
        fileInput.current && fileInput.current.click();
    }

    const handleClear = (e) => {
        setFileInputDataPercent(null);
        setFileInputData(null);
        setValue(nameInput, '');
    }

    return (
        <>
            {(fileInputData !== '' && fileInputData !== null) ? (
                <>
                    <div className="button-load-register">
                        <Grid container >
                            <Grid item xs={11}>
                                <div className="text-load">
                                    {fileInputData?.name ? fileInputData.name : (
                                        <Link className="text-load" target="_blank" href={fileInputData}>{nameInput}.pdf</Link>
                                    )}
                                </div>
                            </Grid>
                            {fileInputDataPercent === 100 || fileInputData !== null ? (
                                <>
                                    <Grid item xs={1}>
                                        <span className="img-button">
                                            <img
                                                src={img_remove_file} alt=""
                                                onClick={(e) => handleClear(e)} />
                                        </span>
                                    </Grid>
                                </>) : (<></>)}
                        </Grid>

                        {fileInputDataPercent && fileInputDataPercent !== 100 ? (
                            <>
                                <div className="linear-progress">
                                    <LinearProgress variant="determinate" value={fileInputDataPercent} />
                                </div>
                            </>) : (<></>)}
                    </div>
                </>

            ) : (
                <>
                    <Controller
                        name={nameInput}
                        control={control}
                        {...register(nameInput)}
                        render={({ field }) => (
                            <input
                                {...field}
                                ref={fileInput}
                                type="file"
                                hidden
                                accept="application/pdf"
                                onChange={e => {
                                    field.onChange(e);
                                    handleChangeFormImage(e);
                                }}
                            />
                        )}
                    />
                    <button className="button-load-register" onClick={selectFile}>
                        <Grid container >
                            <Grid item xs={1}>
                                <div className="img-button-not-loaded">
                                    <img src={img_file_load} alt="Cargar" />
                                </div>
                            </Grid>
                            <Grid item xs={11}>
                                <div className="text-button text-button-not-loaded">
                                    <span className="text-underline-load">Selecciónalo desde tu dispositivo</span>
                                </div>
                            </Grid>
                        </Grid>
                    </button>
                </>
            )}
            {errors[`${nameInput}`] && (
                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                    {errors[`${nameInput}`].message}
                </p>
            )}
        </>
    );
}