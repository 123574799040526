import { Container, Grid } from '@material-ui/core';

//imports
import InputPassword from '../../components/common/Forms/InputPassword';
import InputTextField from '../../components/common/Forms/InputTextField';
import InputEmail from '../../components/common/Forms/InputEmail';
import InputNumber from '../../components/common/Forms/InputNumber';
import InputSelectMultiple from '../../components/common/Forms/InputSelectMultiple';
import InputSelect from '../../components/common/Forms/InputSelect';

export default function FormUserProvider({
  register,
  errors,
  handleSubmit,
  control,
  onSubmit,
  listModules,
  listRoles,
  textButton = null,
  user = {},
}) {
  const isActive = user.status_id === '0021a567-66a4-4147-8f4c-4a54287d9305';

  return (
    <Container maxWidth='md'>
      <div className='section-register'>
        <div className='title-section'>
          <p>Información cuenta</p>
          <button
            style={{
              color: '#333',
              height: 'auto',
              width: 'max-content',
              backgroundColor: '#d6f5d5',
              borderRadius: '39px',
              padding: '10px 20px',
              display: 'inline-block',
            }}
          >
            {isActive ? 'Activo' : 'Inactivo'}
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>Nombre de contacto *</div>
              <InputTextField register={register} errors={errors} name='name' placeholder='Nombre de contacto' />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>Puesto *</div>
              <InputTextField register={register} errors={errors} name='workstation' placeholder='Puesto' />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>Correo electrónico *</div>
              <InputEmail register={register} errors={errors} name='email' placeholder='Correo electrónico' />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>Teléfono *</div>
              <InputNumber register={register} errors={errors} minLength='7' name='phone' placeholder='Teléfono' />
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>
                Contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
              </div>
              <InputPassword
                register={register}
                errors={errors}
                name='password'
                placeholder='Contraseña'
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>
                Confirmar contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
              </div>
              <InputPassword
                register={register}
                errors={errors}
                name='confirm_password'
                placeholder='Confirmar Contraseña'
                required={false}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>Rol *</div>
              <InputSelect
                register={register}
                options={listRoles?.map(e => ({
                  value: e.id,
                  label: e.description,
                }))}
                control={control}
                errors={errors}
                name='role'
                placeholder='Roles'
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className='label-register label-register-password'>Módulos *</div>
              <InputSelectMultiple
                register={register}
                options={listModules?.map(e => ({
                  value: e.key,
                  label: e.name,
                }))}
                control={control}
                errors={errors}
                name='modules'
                placeholder='Modulos'
                required={true}
              />
            </Grid>
          </Grid>

          <br />
          <br />

          <Grid container spacing={5}>
            <Grid item xs={2} md={4} lg={4}></Grid>
            <Grid item xs={8} md={4} lg={4}>
              <button className='button-save-active' type='submit'>
                <div className='text'>{textButton !== null ? textButton : 'Guardar'}</div>
              </button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
