import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

// images
import img_close_modal from '../../../../../assets/images/close-modal.png';
import axiosInstance from '../../../../../utils/axios';

const listNumberEmployees = [
    { value: '00:00 AM - 12:00 PM', label: '00:00 AM - 12:00 PM' },
    { value: '12:00 AM - 6:00 PM', label: '12:00 AM - 6:00 PM' },
    { value: '6:00 AM - 12:00 PM', label: '6:00 AM - 12:00 PM' },
    { value: '6:00 PM - 12:00 PM', label: '6:00 PM - 12:00 PM' },
    { value: '12:00 AM - 6:00 AM', label: '12:00 AM - 6:00 AM' },
];

export default function ModalSheduleDelivery({ open, handleSaveData, listDataToModal, onClose, purchaseOrderId }) {
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        watch
    } = useForm();

    const onSubmit = async (values) => {
        const data = {
            date: values.date,
            hour: values.hour.value
        }

        handleSaveData(data);
    }

    const loadSchedules = async () => {
        console.log(listDataToModal);
        let data = [];
        for (let index = 0; index < listDataToModal.length; index++) {
            data.push({
                product_id: listDataToModal[index].product_id,
                department: listDataToModal[index].department,
                cant: listDataToModal[index].cant
            })
        }

        console.log(data);

        const body = {
            date: getValues('date'),
            warehouse_id: '8643c895-de47-4adb-a22f-9fe295870d2d',
            products: data
        }

        console.log(body);

        const list = await axiosInstance.post('/scheduled_deliveries/calculateScheduled', body);

        console.log(list);
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        ><Fade in={open}>
                <div className="modal-delete">
                    <div className="icon-close" onClick={onClose}>
                        <div className="title-modal-provider">
                            Agendar Entrega
                            <img src={img_close_modal} alt="Cerrar" />
                        </div>
                    </div>
                    <div className="documents-modal-provider">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="container-load-products container-modal-detele">
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <div className="label">
                                            Orden de compra
                                        </div>
                                        <div className="item-privider">
                                            {purchaseOrderId}
                                        </div>
                                        <div className="container-select-day">
                                            <div className="label-order">
                                                Seleccionar día de entrega
                                            </div>
                                            <Controller
                                                name='date'
                                                control={control}
                                                {...register('date',
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido',
                                                        }
                                                    })
                                                }
                                                render={({ field }) => (
                                                    <input
                                                        {...field}
                                                        onChange={e => {
                                                            field.onChange(e);
                                                            loadSchedules();
                                                        }}
                                                        min={new Date().toISOString().split("T")[0]}
                                                        type="date"
                                                        className="input-register"
                                                    />
                                                )}
                                            />
                                            {errors.date && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.date.message}
                                                </p>
                                            )}

                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="label">
                                            Bodega de entrega
                                        </div>
                                        <div className="item-privider">
                                            Bodega San Rafael
                                        </div>
                                        <div className="container-select-day">

                                            <div className="label-order">
                                                Seleccionar horario de entrega
                                            </div>
                                            <Controller
                                                name='hour'
                                                control={control}
                                                {...register('hour', {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                })}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        placeholder={'Seleccione un horario'}
                                                        options={listNumberEmployees}
                                                        isSearchable
                                                    />
                                                )}
                                            />
                                            {errors.hour && (
                                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                    {errors.hour.message}
                                                </p>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={6}>
                                        <div className="container-btn-save">
                                            <button className="button-delete" type="submit">
                                                Agendar entrega
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}