import React, { useContext, useState } from 'react';
import { Grid, Paper, TextField, Container } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import loginService from '../../services/login';
import Loading from '../../components/loading/Loading';
import Footer from '../../components/footer/Footer';
import RegistroImg1 from '../../components/icons/Registro';
import RegisterImg2 from '../../components/icons/register2';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import jwt_decode from 'jwt-decode';
import { useForm } from 'react-hook-form';

import './login.css';

// Importaciones
import { useAppContext } from '../../libs/contextLib';

//IMAGENES
import img_close_modal from '../../assets/images/close-modal.png';

import { useHistory } from 'react-router';
import { setToken } from '../../utils/auth-herpers';
import Topnav from '../../components/topnav/Topnav';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '151px 141px',
    [theme.breakpoints.down(425 + theme.spacing(2))]: {
      padding: '20px 20px 30px',
    },
    textTransform: 'capitalize',
  },
  grid: {
    border: '1px solid rgb(45 72 56 / 10%)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginTop: 0,
    [theme.breakpoints.down(400 + theme.spacing(2))]: {
      width: '100%',
    },
  },
  paper: {
    height: '500px;',
    color: '#333333',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: theme.spacing(9),
    [theme.breakpoints.down(768 + theme.spacing(2))]: {
      padding: '30px 26px',
      width: '100%',
      height: '100%',
    },
  },
  paper2: {
    textAlign: 'center',
    height: '500px;',
    color: '#333333',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F5F5',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    padding: theme.spacing(9),
    [theme.breakpoints.down(768 + theme.spacing(2))]: {
      padding: '30px 21px',
      width: '100%',
      height: '100%',
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper3: {
    width: 584,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #fff',
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
    outline: 0,
  },
}));

const CssTextField = withStyles({
  root: {
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#2D4838',
      },
    },
  },
})(TextField);

const PaperRegister = props => <Paper {...props} />;

const Login = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userLogged, setUserLogged] = useState(null);
  const [loading, setLoading] = useState(Boolean);
  const [showPassword, setShowPassword] = useState(false);
  const [loginActivo, setLoginActivo] = useState(false);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);

  const [openModalPasswordReset, setOpenModalPasswordReset] = useState(false);
  const [emailPasswordReset, setEmailPasswordReset] = useState('');
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [formPassword, setFormPassword] = useState({
    password: '',
    confirm_password: '',
    showPassword: false,
    passwordErrorMessage: '',
    passwordValid: false,
    showConfirmPassword: false,
    confirmPasswordErrorMessage: '',
    confirmPasswordValid: false,
  });

  const { userHasAuthenticated } = useAppContext();

  const history = useHistory();

  const handleCloseModalPasswordReset = () => {
    setOpenModalPasswordReset(false);
    reset();
  };

  const onSubmitPasswordReset = async values => {
    try {
      setLoading(true);
      const { email_reset } = values;
      await loginService.passwordReset({
        email: email_reset,
      });
      setLoading(false);
      toast.info('¡Se ha enviado el correo!');
      setOpenModalPasswordReset(false);
      reset();
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response) {
        if (error.response.data.error) {
          toast.error(error.response.data.msg);
        } else {
          toast.info(error.response.data.msg);
        }
      }
      console.log('Error de identificacion');
    }
  };

  const handleChangeForm = event => {
    setFormPassword({
      ...formPassword,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeFormPassword = event => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
    if (strongRegex.test(event.target.value)) {
      setFormPassword({
        ...formPassword,
        [event.target.name]: event.target.value,
        passwordErrorMessage: '',
        passwordValid: true,
      });
    } else {
      setFormPassword({
        ...formPassword,
        [event.target.name]: event.target.value,
        passwordErrorMessage: 'Contraseña no segura',
        passwordValid: true,
      });
    }
  };

  const handleChangeFormConfirmPassword = event => {
    if (formPassword.password !== event.target.value) {
      setFormPassword({
        ...formPassword,
        [event.target.name]: event.target.value,
        confirmPasswordErrorMessage: 'No coinciden las contraseñas.',
        confirmPasswordValid: false,
      });
    } else {
      setFormPassword({
        ...formPassword,
        [event.target.name]: event.target.value,
        confirmPasswordErrorMessage: '',
        confirmPasswordValid: true,
      });
    }
  };

  const handleClickShowNewPassword = () => {
    setFormPassword({
      ...formPassword,
      showPassword: !formPassword.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setFormPassword({
      ...formPassword,
      showConfirmPassword: !formPassword.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownNewPassword = event => {
    event.preventDefault();
  };

  const handleLogin = async event => {
    event.preventDefault();

    try {
      setLoading(true);
      const user = await loginService.login({
        email,
        password,
      });

      setLoading(false);
      setEmail('');
      setPassword('');

      await setToken(user.user.token);
      let decoded = jwt_decode(user.user.token);

      if (decoded.role.key.indexOf('provider') !== -1) {
        userHasAuthenticated(true);
        const dataUser = {
          email: user.user.email,
          name: user.user.name,
          notLoad: user.user.notLoad,
        };

        localStorage.setItem('user', JSON.stringify(dataUser));

        if (user.user.notLoad) {
          history.push('/provider/form-providers');
        } else {
          if (decoded.role.group.length) {
            const groups = decoded.role.group.map(group => group.key.split('_all')[0]);
            if (groups[0] === 'insights') history.push(`/provider/insights`);
            else if (groups[0] === 'profile') history.push(`/provider/profile`);
            else if (groups[0] === 'catalogue') history.push(`/provider/products/list`);
            else if (groups[0] === 'purchase_orders') history.push(`/provider/orders/list`);
            else if (groups[0] === 'delivery_schedule') history.push(`/provider/deliveries/list`);
            else history.push(`/provider/${groups[0]}/list`);
          } else history.push(`/provider/profile`);
        }
      } else {
        if (user.user.isFirst === 1) {
          setUserLogged(decoded);
          setOpenModalChangePassword(true);
        } else {
          userHasAuthenticated(true);
          const dataUser = {
            email: user.user.email,
            name: user.user.name,
            notLoad: user.user.notLoad,
          };

          localStorage.setItem('user', JSON.stringify(dataUser));

          if (decoded.role.key === 'provider') {
            history.push(`/provider/profile`);
          } else if (decoded.role.key === 'admin') {
            history.push(`/${decoded.role.key}/providers/list`);
          } else if (decoded.role.key === 'manager') {
            history.push(`/${decoded.role.key}/advisers/list`);
          } else if (decoded.role.key === 'master') {
            history.push(`/${decoded.role.key}/advisers/list`);
          } else if (decoded.role.key === 'catalogue') {
            history.push(`/${decoded.role.key}/products/list`);
          } else if (decoded.role.key === 'ocs') {
            history.push(`/${decoded.role.key}/providers/list`);
          } else if (decoded.role.key === 'receipt') {
            history.push(`/${decoded.role.key}/providers/list`);
          } else if (decoded.role.key === 'super_admin') {
            history.push(`/${decoded.role.key}/providers/list`);
          } else if (decoded.role.key === 'finance') {
            history.push(`/${decoded.role.key}/providers/list`);
          } else if (decoded.role.key === 'growth') {
            history.push(`/${decoded.role.key}/providers/list`);
          } else {
            history.push(`/${decoded.role.key}/dashboard`);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response) {
        if (error.response.data.error) {
          toast.error(error.response.data.msg);
        } else {
          toast.info(error.response.data.msg);
        }
      }
    }
  };

  const handleUpdatePassword = async event => {
    setOpenModalChangePassword(false);
    event.preventDefault();
    try {
      setLoading(true);
      const user = await loginService.updatePassword({
        id: userLogged.id,
        password: formPassword.password,
        is_first: 0,
      });

      setLoading(false);
      toast.info('Contraseña actualizada con éxito');

      userHasAuthenticated(true);
      const dataUser = {
        email: userLogged.email,
        name: userLogged.name,
        notLoad: userLogged.notLoad,
      };

      localStorage.setItem('user', JSON.stringify(dataUser));

      if (userLogged.role.key === 'provider') {
        history.push(`/provider/profile`);
      } else if (userLogged.role.key === 'admin') {
        history.push(`/${userLogged.role.key}/providers/list`);
      } else if (userLogged.role.key === 'manager') {
        history.push(`/${userLogged.role.key}/advisers/list`);
      } else if (userLogged.role.key === 'master') {
        history.push(`/${userLogged.role.key}/advisers/list`);
      } else if (userLogged.role.key === 'catalogue') {
        history.push(`/${userLogged.role.key}/products/list`);
      } else if (userLogged.role.key === 'ocs') {
        history.push(`/${userLogged.role.key}/providers/list`);
      } else if (userLogged.role.key === 'receipt') {
        history.push(`/${userLogged.role.key}/providers/list`);
      } else if (userLogged.role.key === 'super_admin') {
        history.push(`/${userLogged.role.key}/providers/list`);
      } else if (userLogged.role.key === 'finance') {
        history.push(`/${userLogged.role.key}/providers/list`);
      } else if (userLogged.role.key === 'growth') {
        history.push(`/${userLogged.role.key}/providers/list`);
      } else {
        history.push(`/${userLogged.role.key}/dashboard`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.response) {
        if (error.response.data.error) {
          toast.error(error.response.data.msg);
        } else {
          toast.info(error.response.data.msg);
        }
      }
      console.log('Error de identificacion');
    }
  };

  const handleRegister = () => {
    history.push('/register');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleValidForm = () => {
    if (email !== '' && email !== null && password !== '' && password !== null) {
      return true;
    }
    return false;
  };

  const handleSendNewPassword = () => {
    console.log('aqui');
  };

  const handleValidFormNewPassword = () => {
    if (
      formPassword.password !== '' &&
      formPassword.password !== null &&
      formPassword.confirm_password !== '' &&
      formPassword.confirm_password !== null &&
      formPassword.passwordValid &&
      formPassword.confirmPasswordValid &&
      formPassword.confirm_password === formPassword.password
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <Topnav />
          <div className={classes.root}>
            <Grid container className={classes.grid}>
              <Grid item xs={12} sm={6}>
                <PaperRegister disableElevation className={classes.paper2}>
                  <div className='img-register'>
                    <RegistroImg1 />
                  </div>

                  <div className='label-title-register'>Quiero ser Proveedor de Jüsto</div>
                  <br />

                  <button fullWidth variant='outlined' className='buttonRegister' onClick={handleRegister}>
                    Registrarme
                  </button>
                  <div className='img2'>
                    <RegisterImg2 />
                  </div>
                </PaperRegister>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper disableElevation className={classes.paper}>
                  <div className='label-register'>INICIAR SESIÓN</div>
                  <form onSubmit={handleLogin}>
                    <div className='label-login'>Correo electrónico *</div>
                    <CssTextField
                      fullWidth
                      color='primary'
                      margin='normal'
                      variant='outlined'
                      value={email}
                      name='email'
                      placeholder='Correo electrónico'
                      className='TextField'
                      onChange={({ target }) => setEmail(target.value)}
                    />
                    <div className='label-login'>Contraseña *</div>
                    <CssTextField
                      fullWidth
                      value={password}
                      type={showPassword ? 'text' : 'password'}
                      placeholder='Contraseña'
                      color='primary'
                      margin='normal'
                      variant='outlined'
                      name='password'
                      className='TextField'
                      onChange={({ target }) => setPassword(target.value)}
                      // {handleChange('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={e => handleClickShowPassword()}
                              onMouseDown={e => handleMouseDownPassword(e)}
                              edge='end'
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      className={handleValidForm() ? 'buttonLogin-active' : 'buttonLogin'}
                      disableElevation
                      disabled={!handleValidForm()}
                    >
                      Iniciar sesión
                    </button>
                  </form>
                  <br />
                  <span className='span' onClick={e => setOpenModalPasswordReset(true)}>
                    ¿Olvidaste tu contraseña?
                  </span>
                </Paper>
              </Grid>
            </Grid>
          </div>
          <Footer />
        </>
      )}

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openModalChangePassword}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalChangePassword}>
          <div className={classes.paper3}>
            <div className='icon-close'>
              <div className='title-modal-provider'>Nueva contraseña</div>
            </div>

            <Container>
              <Grid container spacing={5}>
                <Grid item xs={12} md={6} lg={6}>
                  <div className='label-register label-register-password'>
                    Contraseña *<p>Al menos 8 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 número</p>
                  </div>
                  <CssTextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={e => handleClickShowNewPassword()}
                            onMouseDown={e => handleMouseDownNewPassword(e)}
                            edge='end'
                          >
                            {formPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className='input-register'
                    name='password'
                    value={formPassword.password}
                    type={formPassword.showPassword ? 'text' : 'password'}
                    variant='outlined'
                    onChange={e => handleChangeForm(e)}
                    onBlur={handleChangeFormPassword}
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    {formPassword.passwordErrorMessage}
                  </span>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <div className='label-register label-register-password'>Confirmar contraseña *</div>
                  <CssTextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={e => handleClickShowConfirmPassword()}
                            onMouseDown={e => handleMouseDownConfirmPassword(e)}
                            edge='end'
                          >
                            {formPassword.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className='input-register'
                    name='confirm_password'
                    value={formPassword.confirm_password}
                    type={formPassword.showConfirmPassword ? 'text' : 'password'}
                    variant='outlined'
                    onChange={e => handleChangeForm(e)}
                    onBlur={handleChangeFormConfirmPassword}
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                    }}
                  >
                    {formPassword.confirmPasswordErrorMessage}
                  </span>
                </Grid>
              </Grid>
              <br />
              <br />
              <div className='container-btn-save'>
                <button
                  className={handleValidFormNewPassword() ? 'buttonLogin-active' : 'buttonLogin'}
                  onClick={handleUpdatePassword}
                  disabled={!handleValidFormNewPassword()}
                >
                  <div className='text'>Guardar</div>
                </button>
              </div>
              <br />
            </Container>
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openModalPasswordReset}
        onClose={handleCloseModalPasswordReset}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPasswordReset}>
          <div className={classes.paper3}>
            <div className='icon-close'>
              <div className='title-modal-provider'>Recuperar contraseña</div>
            </div>

            <Container>
              <form onSubmit={handleSubmit(onSubmitPasswordReset)}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <div className='label-register label-register-password'>Correo electrónico *</div>
                    <input
                      placeholder='Correo electrónico'
                      type='email'
                      place
                      name='email_reset'
                      id='email_reset'
                      autoComplete='email'
                      className='input-register'
                      {...register('email_reset', {
                        required: {
                          value: true,
                          message: 'Este campo es requerido',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'La dirección de correo electrónico es inválida',
                        },
                      })}
                    />
                    {errors.email_reset && (
                      <p className='text-red-700  text-xs error-text'>{errors.email_reset.message}</p>
                    )}
                  </Grid>
                </Grid>
                <br />
                <br />
                <div className='container-btn-save'>
                  <button type='submit' className='buttonLogin-active'>
                    <div className='text'>Enviar</div>
                  </button>
                </div>
                <br />
              </form>
            </Container>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Login;
