import React, { useState, useEffect } from 'react';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import HeaderProvider from "../../header/Header";
import axiosInstance from "../../../../../utils/axios";
import ListProductsPacklist from '../../../../../components/list-products-packlist';
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import { toast } from "react-toastify";
import moment from 'moment';

export default function DetailScheduledDeliveries(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [scheduledDelivery, setScheduledDelivery] = useState({});

    const [packlistExport, setPacklistExport] = useState([]);

    const [listBreadcrumbs, setListBreadcrumbs] = useState([
        {
            title: 'Entregas programadas',
            url: '/provider/deliveries/list'
        },
        {
            title: ''
        }
    ]);

    const handleGetInfoToExportExcel = () => {
        setLoading(true);
        axiosInstance.get(`scheduled_deliveries/${props.match.params.id}`)
            .then((res) => {
                const { data: { products } } = res;
                const list = [
                    {
                        product_name: res.data.scheduled_delivery_id,
                        upc: moment(res.data?.date).format('DD/MM/YYYY'),
                        department: res.data?.warehouse?.name,
                        price: res.data?.hour,
                        charge: '',
                        cant: '',
                        total: ''
                    },
                    {
                        product_name: '',
                        upc: '',
                        department: '',
                        price: '',
                        charge: '',
                        cant: '',
                        total: ''
                    },
                    {
                        product_name: 'Nombre del producto',
                        upc: '#UPC',
                        department: 'Departamentos',
                        price: 'Precio',
                        charge: 'Impuestos',
                        cant: 'Cantidad',
                        total: 'Total'
                    },
                ];
                products.forEach((product) => {
                    list.push({
                        product_name: product?.product?.description,
                        upc: product?.product?.upc,
                        department: product?.product?.product_subcategory?.product_category?.department?.name,
                        price: product?.product?.net_unit_cost ? product?.product.net_unit_cost : '',
                        charge: product?.product?.net_unit_cost ? 'IVA-IEPS' : '',
                        cant: product?.cant,
                        total: product?.product?.net_unit_cost * product?.cant
                    })
                })
                setPacklistExport(list);
            })
            .catch((err) => {
                toast.error('   Error al exportar el packlist')
            })
            .finally(() => {
                setLoading(false);
                document.getElementById('file-export').click();
            });
        //
    }

    const printInfo = async () => {
        try {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/pdf/${scheduledDelivery.id}`);

            // Abre el PDF
            if (response.data.response) {
                window.open(response.data.response.url, '_blank');
            }
            else {
                toast.error('Ha ocurrido un error al generar el pdf');
            }

            setLoading(false);
        }

        catch (error) {
            setLoading(false);
            console.log(error);

            // Error 😨
            if (error && error.response && error.response.data) {
                if (error.response.data.error) {
                    toast.error(error.response.data.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
                else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
    }

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/${props.match.params.id}`);
            setScheduledDelivery(response.data);
            let temp = [...listBreadcrumbs];
            temp[1].title = scheduledDelivery?.scheduled_delivery_id;
            console.log(response.data)
            setListBreadcrumbs(temp);
            setLoading(false);
        }

        fetch();
    }, []);

    useEffect(() => {
        let temp = [...listBreadcrumbs];
        temp[1].title = scheduledDelivery?.scheduled_delivery_id;
    }, [scheduledDelivery]);

    return (
        <>
            <HeaderProvider
                title={scheduledDelivery?.scheduled_delivery_id}
                user={user}
                view='detail-packlist'
                type='detail'
                listBreadcrumbs={listBreadcrumbs}
                scheduledDelivery={scheduledDelivery}
                handleGetInfoToExportExcel={handleGetInfoToExportExcel}
                printPacklist={printInfo}
            />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <ExcelFile element={<button id="file-export" />} filename={scheduledDelivery?.scheduled_delivery_id || 'Packlist'}>
                        <ExcelSheet data={packlistExport} name="Hoja 1">
                            <ExcelColumn label="ID Packlist" value="product_name" />
                            <ExcelColumn label="Fecha de entrega" value="upc" />
                            <ExcelColumn label="Bodega" value="department" />
                            <ExcelColumn label="Horario" value="price" />
                            <ExcelColumn label="" value="charge" />
                            <ExcelColumn label="" value="cant" />
                            <ExcelColumn label="" value="total" />
                        </ExcelSheet>
                    </ExcelFile>
                    <ListProductsPacklist listProductsView={scheduledDelivery?.products} />
                    <Footer />
                </>
            )}
        </>
    )
}