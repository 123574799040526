import React from "react";

export const EventText = ({ event, ...props }) => {
    const { id, purchase_order, warehouse, departments, hour } = event;
    return (
        <div>
            {props?.continuesAfter === false ?
                <>
                    <p style={{fontWeight: 400, fontSize: 13, whiteSpace: "initial",}}>
                        {purchase_order.purchase_order_id} - {departments?.map((v) => v + ", ")}
                        - Bodega {warehouse.name} - {hour}
                    </p>
                </>
                :
                <p style={{fontWeight: 400, fontSize: 13, whiteSpace: "initial",}}>
                    {purchase_order.purchase_order_id}
                </p>
            }
        </div>
    );
};