import { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

//import
import axiosInstance from '../../../../../utils/axios';
import HeaderProvider from '../../header/Header';
import Footer from '../../../../../components/footer/Footer';

// images
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import Loading from '../../../../../components/loading/Loading';
import FormUserProvider from '../../../../../components/form-user-provider';

export default function NewUserProvider() {
  const [user] = useState(JSON.parse(localStorage.getItem('user')));
  const [listModules, setListModules] = useState([]);
  const [listRoles, setListRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const listMod = await axiosInstance.get('/modules?filter=&page=0&size=1000&order=name ASC');
        const listRol = await axiosInstance.get(`roles/provider`);
        const providerUsers = (await axiosInstance.get('/providers/info/users'))?.data?.users || [];

        const usedRoles = providerUsers
          .filter(u => u.status_id === '0021a567-66a4-4147-8f4c-4a54287d9305') // Only Filter Active Users
          .map(u => u.role_users[0].role.name); // Get Role Names

        const filteredRoles = listRol.data.filter(rol => !usedRoles.includes(rol.name));

        const modules = listMod.data.modules.filter(mod => ['profile', 'insights'].includes(mod.key));

        setListModules(modules);
        setListRoles(filteredRoles);
      } catch (error) {
        console.log(error.response);
        // Error 😨
        if (error.response) {
          toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
        } else {
          toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
        }
      } finally {
        setLoading(false);
      }
    }
    fetch();
  }, []);

  // lista para breadcrounb
  const listBreadcrumbs = [
    {
      title: 'Empresa',
      url: '/provider/profile',
    },
    {
      title: 'Nuevo usuario',
    },
  ];

  const onSubmit = async values => {
    try {
      setLoading(true);
      await axiosInstance.post(`providers/info/users`, values);
      toast.success('Usuario agreagado con éxito!');
      history.goBack();
    } catch (error) {
      console.log(error.response);
      // Error 😨
      if (error.response) {
        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
      } else {
        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeaderProvider
        title='Nuevo usuario'
        icon={icon_list_products}
        listBreadcrumbs={listBreadcrumbs}
        user={user}
        view='empresa'
        type='detail'
      />

      {loading ? (
        <Loading />
      ) : (
        <>
          <FormUserProvider
            register={register}
            errors={errors}
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            listModules={listModules}
            listRoles={listRoles}
          />
          <Footer />
        </>
      )}
    </>
  );
}
