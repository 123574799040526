import { useState } from 'react';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

//IMAGES
import img_close_modal from '../../assets/images/close-modal.png';
import img_file_load from '../../assets/images/file_load.png';
import img_remove_file from '../../assets/images/icon-remove-file.png';

// archivo de descargar
import fileImport from '../../assets/file.xlsx';

export default function ImportFileProducts(props) {

    const [file, setFile] = useState(props.init);
    const [error, setError] = useState(props.init);

    const handleCleanForm = () => {
        setFile('');
        document.getElementById('selectedFile').value = null;
    }

    const handleChangeFormFileImport = (event) => {
        setFile(event.target.files[0]);
        setError('');
    }
    const selectFile = (e) => {
        document.getElementById('selectedFile').click();
    }

    const onSave = (event) => {
        if (file !== '') {
            props.onSave(file);
            handleCleanForm();
        } else {
            setError('Este campo es obligatorio');
        }
    }

    return (
        <Modal
            {...props}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        ><Fade in={props.open}>
                <div className="modal-import">
                    <div className="icon-close" onClick={props.onClose}>
                        <div className="title-modal-provider">
                            Importar Archivo
                            <img src={img_close_modal} alt="Cerrar" />
                        </div>
                    </div>
                    <div className="documents-modal-provider">
                        <div className="container-load-products container-modal-detele">
                            <div className="label">
                                Sube <a href={fileImport}><span className="text-underline">el archivo de Excel</span></a> diligenciado con todos los productos *
                            </div>

                            <input
                                id="selectedFile"
                                type="file"
                                name="file"
                                hidden
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => handleChangeFormFileImport(e)}
                            />

                            {file.name ? (
                                <>
                                    <div className="button-load-file antes-nota">
                                        <Grid container >
                                            <Grid item xs={11}>
                                                <div className="text-load-modal">
                                                    {file.name}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <button className="img-button-modal img-button-load" onClick={handleCleanForm}>
                                                    <img src={img_remove_file} alt="Eliminar" />
                                                </button>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <button className="button-load-products-file antes-nota" onClick={selectFile}>
                                        <Grid container >
                                            <Grid item xs={1}>
                                                <div className="img-button-not-loaded">
                                                    <img src={img_file_load} alt="Cargar" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <div className="text-button text-button-not-loaded">
                                                    <span className="text-underline-load-products">Selecciónalo desde tu dispositivo</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </button>
                                </>
                            )}
                            {error && (
                                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                    {error}
                                </p>
                            )}
                            <br />
                            <span className="nota"><strong>Nota:</strong> Todos los campos del archivo tienen que estar completos para que sea válido</span>
                            <br />
                            <br />
                            <div className="container-btn-save">
                                <button className="button-modal-save button-modal-save-active" style={{ cursor: "pointer" }} onClick={(e) => onSave(e)}>
                                    Importar archivo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}