import Loader from 'react-loader-spinner';

const Loading= () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="ThreeDots" color="#2D4838" height="100" width="100" />
        </div>
    )
}

export default Loading;