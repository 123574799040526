import { useState, useEffect, useRef } from 'react';
import { Container } from '@material-ui/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// estilos
import './quicksight.css';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header";

// imagenes
import icon_list_products from '../../../../../assets/images/icon-list-products.png';

function Quicksight() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [providers, setProviders] = useState([]);
    const [providerID, setProviderID] = useState(-1);
    const [tabValue, setTabValue] = useState('hermes');
    const [loading, setLoading] = useState(false);
    const [tabs, setTabs] = useState([]);
    const [view, setView] = useState(true);

    const dashboardRef = useRef(null);

    const embed = (url) => {
        const options = {
            url: url,
            container: dashboardRef.current,
            scrolling: "no",
            height: "700px",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: true
        };
        QuickSightEmbedding.embedDashboard(options)
    };

    const loadProviders = () => {
        setLoading(true);
        axiosInstance.get(`providers/list`,)
            .then((get) => {
                if (get.data) {
                    let listProviders = get.data.map((provider) => { return { value: provider.id, label: provider.business_name } });
                    setProviders(listProviders);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    const handleChangeProviders = async (provider_id) => {
        setProviderID(provider_id);
        setLoading(true);
        try {
            const data = await axiosInstance.get(`providers/info/insights?providerId=${provider_id}`);
            const tabs = data.data.map((item) => item.insight_plan);
            setTabs(tabs);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(async () => {
        if (providerID !== -1)
            try {
                setLoading(true);
                setView(false);
                let url = await axiosInstance.get(`utils/quicksight/${tabValue}?providerId=${providerID}`,);
                setView(true);
                setLoading(false);
                embed(url.data);
            } catch (error) {
                console.log(error)
                setView(true);
                setLoading(false);
            }
    }, [tabs]);

    useEffect(() => {
        loadProviders()
    }, []);

    const changeTab = (key) => {
        setTabValue(key);
        handleChangeProviders(providerID);
    }

    return (
        <>
            <HeaderProvider
                title="Insights"
                user={user}
                view='reports'
                icon={icon_list_products}
                listBreadcrumbs={[]}
                listProviders={providers}
                handleChangeProviders={handleChangeProviders}
            />
            <Container maxWidth="xl" style={{ paddingLeft: '100px' }}>
                <Tabs
                    variant="standard"
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    className="bgtabs">
                    {tabs.map((tab) =>
                        <Tab value={tab.key} label={tab.name} onClick={() => changeTab(tab.key)} />
                    )}
                </Tabs>
                {loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        {view ? (
                            <>
                                <div ref={dashboardRef} className="iframe-reports" />
                            </>
                        ) : (
                            <>
                                <div className="error-reports">
                                    <span className="text-error">
                                        Usuario no posee reporte
                                    </span>
                                </div>
                            </>)}
                    </>
                )}
            </Container>
            <Footer />
        </>
    );
}

export default Quicksight;