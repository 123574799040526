import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

// imports
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";
import FormSuggestOrder from '../../../../../components/form-suggest-order';

export default function EditSuggestOrders(props) {

    const [listProductsApi, setListProductsApi] = useState([]);
    const [listProductSelect, setListProductSelect] = useState([]);
    const [order, setOrder] = useState([]);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        setValue
    } = useForm();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`products/provider/iam?filter=&order=description ASC&page=0&size=5000`,);
            let responseOrder = await axiosInstance.get(`purchase_orders/${props.match.params.id}`,);
            setOrder(responseOrder.data);
            setListProductsApi(response.data.products);
        }

        fetch();
    }, []);

    useEffect(() => {
        if (order.id && listProductsApi.length) {
            let listPro = [];
            for (let index = 0; index < order.products.length; index++) {
                const element = order.products[index];
                const product = listProductsApi.find((item) => item.id === element.product_id);
                listPro.push({ ...product, total: (element.unit_price * element.cant) });

                setValue(`cant[${index}]`, element.cant);
                setValue(`product[${index}]`, {value: element.product_id, label: product.description});
            }
            setListProductSelect(listPro);
            setLoading(false);
        }
    }, [order.id, listProductsApi.length])

    //listas para breadcrumbs
    const listBreadcrumbs = [
        {
            title: 'Órdenes de compra',
            url: '/provider/orders/list'
        },
        {
            title: `Editar ${order.suggest_order_id ? order.suggest_order_id : ''}`
        }
    ];

    const onSubmit = async (values) => {
        const { product, cant } = values;

        let data = [];
        for (let index = 0; index < product.length; index++) {
            if (product[index] !== '') {
                data.push({
                    product_id: product[index].value,
                    cant: cant[index],
                    unit_price: listProductsApi.find((item) => item.id === product[index].value).net_unit_cost
                })
            }
        }

        try {
            setLoading(true);
            await axiosInstance.put('purchase_orders/'+order.id, { products: data });
            toast.info('¡Orden de compra modificada con éxito!');
            setTimeout(() => {
                history.push("/provider/orders/list");
                setLoading(false);
            }, 2000);
        } catch (error) {
            console.log(error);
            console.log(error.response);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            }
        }
    }

    return (
        <>
            <HeaderProvider
                title={`Editar ${order.suggest_order_id ? order.suggest_order_id : ''}`}
                user={user}
                view='new-incidence'
                type='detail'
                listBreadcrumbs={listBreadcrumbs} />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <FormSuggestOrder
                        listProductsApi={listProductsApi} 
                        listProductSelected={listProductSelect}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        titleButton="Guardar cambios"
                        />
                    <Footer />
                </>
            )}
        </>
    );

}