import * as React from "react"

export default function RegistroImg1(props) {
    return (
        <svg
          width={84}
          height={80}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.565 10.293c4.392-.28 8.2.996 12.038 2.288 4.742 1.6 9.061 4.033 13.326 6.544.677.397 1.227.515 1.871-.028A145.318 145.318 0 0158.13 7.121C62.611 4.324 67.21 1.68 72.35.487c2.543-.56 5.353-.873 7.957.403 2.132 1.046 2.965 3.037 3.332 5.096 1.11 5.834-.411 11.08-3.587 16.048-4.442 6.953-10.55 12.133-17.002 16.994-.25.19-.583.268-.844.441-.66.448-.928.996-.433 1.757a75.598 75.598 0 015.802 10.197c1.76 3.916 3.487 7.882 4.004 12.278.27 1.952.246 3.934-.073 5.88-.694 3.876-4.442 7.07-8.273 6.544a15.603 15.603 0 01-7.451-3.356 62.821 62.821 0 01-12.366-13.151c-.683-.951-1.3-.974-1.883.072a68.647 68.647 0 01-7.773 11.02c-3.165 3.714-6.774 6.847-11.377 8.687-4.492 1.801-10.667-.587-12.25-5.113-.766-2.204-.61-4.66-.332-7.03.621-5.348 2.737-10.159 4.997-14.958a110.672 110.672 0 017.957-13.917c.777-1.175.36-1.567-.556-1.941-1.277-.515-2.637-.772-3.948-1.164a60.367 60.367 0 01-7.401-2.842 28.317 28.317 0 01-6.785-4.122 12.206 12.206 0 01-3.887-5.896c-.555-1.8.294-3.49 1.11-5.034a13.686 13.686 0 016.475-5.963 11.817 11.817 0 016.802-1.124z"
            fill="#FFC102"
          />
        </svg>
      )
}




