import React, { useState, useEffect } from 'react';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import HeaderOC from "../../header";
import axiosInstance from "../../../../../utils/axios";
import ListProductsDeliveries from "./listProducts";
import { toast } from "react-toastify";
import { generateString } from "../../../../../utils";

export default function DetailScheduledDeliveries(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [scheduledDelivery, setScheduledDelivery] = useState({});
    const [rescheduleModal, setRescheduleModal] = useState(false);
    const [confirmUnitsModal, setConfirmUnitsModal] = useState(false);
    const [randomStr, setRandomStr] = useState();

    const [listBreadcrumbs, setListBreadcrumbs] = useState([
        {
            title: 'Entregas programadas',
            url: '/finance/deliveries/list'
        },
        {
            title: ''
        }
    ]);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/${props.match.params.id}`);
            setScheduledDelivery(response.data);
            let temp = [...listBreadcrumbs];
            temp[1].title = scheduledDelivery?.scheduled_delivery_id;
            setListBreadcrumbs(temp);
            setLoading(false);
        }

        fetch();
    }, [randomStr]);

    useEffect(() => {
        let temp = [...listBreadcrumbs];
        temp[1].title = scheduledDelivery?.scheduled_delivery_id;
    }, [scheduledDelivery]);

    const handleConfirmUnits = async (products) => {
        setLoading(true);
        try {
            let response = await axiosInstance.put(`scheduled_deliveries/confirm/${scheduledDelivery?.id}`, { products });
            toast.info("Entrega programada actualizada con éxito");
            setRandomStr(generateString());
            setConfirmUnitsModal(true);
        } catch (e) {
            console.log(e);
            toast.error("No se pudo actualizar la entrega programada");
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            <HeaderOC
                title={scheduledDelivery?.scheduled_delivery_id}
                user={user}
                view='detail-packlist'
                type='detail'
                listBreadcrumbs={listBreadcrumbs}
                scheduledDelivery={scheduledDelivery}
            />
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <ListProductsDeliveries
                        handleConfirm={handleConfirmUnits}
                        confirmUnitsModal={setConfirmUnitsModal}
                        setReschedule={setRescheduleModal}
                        status={scheduledDelivery?.status}
                        scheduled_delivery_id={scheduledDelivery.id}
                        listProductsView={scheduledDelivery?.products} />
                    <Footer />
                </>
            )}
        </>
    )
}