import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// importaciones de modulos
import Loading from '../../../../../components/loading/Loading';
import Footer from "../../../../../components/footer/Footer";
import axiosInstance from "../../../../../utils/axios";
import FormProvider from "./FormProvider";
import HeaderAdmin from '../../header/Header';

import { initAxiosInterceptor } from '../../../../../utils/auth-herpers';

// importar estilos
import '../../../../register/register-provider/register-provider.css';
import './edit-provider.css';

// images
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import img_icon_arrow_logout from '../../../../../assets/images/icon-arrow-logout.png';

initAxiosInterceptor();

class EditProviderCatalogue extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: this.props.match.params,
            user: { ...JSON.parse(localStorage.getItem('user')), role: null },
            loading: false,
            disabledSubmitButton: true,
            listStates: [],
            listDelegations: [],
            listSuburbs: [],
            listBreadcrumbs: [
                {
                    title: 'Proveedores',
                    url: '/catalogue/providers/list'
                },
                {
                    title: ''
                }
            ],
            formProvider: {
                id_temp: Math.floor(1 + Math.random() * (99999 - 1)),
                provider_id: '',
                business_name: '',
                rfc: '',
                account_clabe: '',
                errorAccountClabe: false,
                departments: [],
                warehouses: [],
                schedule: '',
                textErrorAccountClabe: 'El número de cuenta clabe debe tener mínimo 11 dígitos',
                account_bank: '',
                account_number: '',
                errorAccountNumber: false,
                textErrorAccountNumber: 'El número de cuenta debe tener mínimo 6 dígitos',
                username: '',
                email: '',
                errorEmail: false,
                textErrorEmail: 'El correo no es válido',
                password: '',
                passwordErrorMessage: '',
                passwordValid: false,
                showPassword: false,
                confirm_password: '',
                confirmPasswordErrorMessage: '',
                showConfirmPassword: false,
                confirmPasswordValid: false,
                contact_business_email: '',
                errorContactBusinessEmail: false,
                textErrorContactBusinessEmail: 'El correo no es válido',
                contact_business_phone: '',
                contact_business_name: '',
                contact_business_workstation: '',
                contact_logistic_email: '',
                contact_logistic_phone: '',
                contact_logistic_name: '',
                contact_logistic_workstation: '',
                contact_financial_email: '',
                contact_financial_phone: '',
                contact_financial_name: '',
                contact_financial_workstation: '',
                address_street: '',
                address_number_exterior: '',
                address_number_interior: '',
                address_suburb: '',
                address_delegation: '',
                address_postcode: '',
                address_city: '',
                address_state: '',
                number_employees: '',
                company_classification: '',
                credit_days: 0,
                name_legal_representative: '',
                curp_legal_representative: '',
                status: ''
            },
            form: {
                bank_face: '', //file
                bank_face_percent: '',
                opinion_fulfillment_sat: '', //file
                opinion_fulfillment_sat_percent: '',
                power_attorney: '', //file
                power_attorney_percent: '', //file
                opinion_fulfillment_tax_obligations: '', //file
                opinion_fulfillment_tax_obligations_percent: '', //file
                identification_legal_representative_front: '', //file
                identification_legal_representative_front_percent: '', //file
                identification_legal_representative_reverse: '', //file
                identification_legal_representative_reverse_percent: '', //file
                proof_address: '', //file
                proof_address_percent: '', //file
                proof_tax_situation: '', //file
                proof_tax_situation_percent: '', //file
                constitutive_act: '', //file
                constitutive_act_percent: '', //file
            }
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const get = await axiosInstance.get(`providers/${this.state.params.id}`,);
                const states = await axiosInstance.get(`utils/estados`);

                let listDelegations = [];
                let listSuburbs = [];

                if (get.data.address_state) {
                    const delegations = await axiosInstance.get(`utils/municipios/${get.data.address_state}`);
                    const suburbs = await axiosInstance.get(`utils/colonias/${get.data.address_delegation}`);

                    listDelegations = delegations.data.map((delegation) => { return { value: delegation.id, label: delegation.nombre } });
                    listSuburbs = suburbs.data.map((suburb) => { return { value: suburb.id, label: suburb.nombre } });

                }

                const listStates = states.data.map((state) => { return { value: state.id, label: state.nombre } });
                let temp = this.state.listBreadcrumbs;
                temp[1].title = get.data.business_name;

                this.setState({
                    ...this.state,
                    listStates,
                    listDelegations,
                    listSuburbs,
                    formProvider: {
                        ...this.state.formProvider,
                        ...get.data,
                        password: ''
                    },
                    form: {
                        ...this.state.form,
                        ...get.data,
                    },
                    listBreadcrumbs: temp,
                    loading: false
                });

            } catch (error) {
                console.log(error);
            }
        });
    }

    render() {
        return (
            <>

                <HeaderAdmin
                    title={this.state.formProvider.business_name}
                    icon={icon_list_products}
                    user={this.state.user}
                    view='edit-provider-catalogue'
                    type='detail'
                    listBreadcrumbs={this.state.listBreadcrumbs}
                    disabledSubmitButton={this.state.disabledSubmitButton}
                    handleSaveInfo={this.saveInfoHeader} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Container maxWidth="md">
                            <div className="section-register">
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            ID
                                        </div>
                                        <span>{this.state.formProvider.provider_id}</span>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            Estado
                                        </div>
                                        <div>
                                            <div className={`status ${this.state.formProvider.status === 'pending' ? 'pending' : this.state.formProvider.status === 'inactive' ? 'inactive' : 'active'}`}>
                                                {this.state.formProvider.status === 'aproved_adviser' ? (
                                                    <>
                                                        <Button className="menu-status" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClick(e)}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} className="estado">
                                                                    <span className="desk item-status-provider">
                                                                        {this.state.formProvider.status === 'pending' ? 'Pendiente' : this.state.formProvider.status === 'inactive' ? 'Inactivo' : this.state.formProvider.status === 'aproved_adviser' ? 'Aprobado' : 'Activo'}
                                                                        <img src={img_icon_arrow_logout} alt="" />
                                                                    </span>
                                                                    <span className="mobile item-status-provider">
                                                                        {this.state.formProvider.status === 'pending' ? 'P' : this.state.formProvider.status === 'inactive' ? 'I' : this.state.formProvider.status === 'aproved_adviser' ? 'AP' : 'AC'}
                                                                        <img src={img_icon_arrow_logout} alt="" />
                                                                    </span>
                                                                </Grid>
                                                            </Grid>

                                                        </Button>
                                                        <Menu
                                                            id="long-menu"
                                                            anchorEl={this.state.anchorEl}
                                                            keepMounted
                                                            open={Boolean(this.state.anchorEl)}
                                                            onClose={(e) => this.handleClose(e)}>
                                                            <MenuItem onClick={(event) => this.handleSelect(event, 'active')}> Activo </MenuItem>
                                                            <MenuItem onClick={(event) => this.handleSelect(event, 'inactive')}> Inactivo </MenuItem>
                                                        </Menu>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} className="estado">
                                                                <span className="desk item-status-provider" style={{ paddingTop: '5px'}}>
                                                                    {this.state.formProvider.status === 'pending' ? 'Pendiente' : this.state.formProvider.status === 'inactive' ? 'Inactivo' : this.state.formProvider.status === 'aproved_adviser' ? 'Aprobado' : 'Activo'}
                                                                </span>
                                                                <span className="mobile item-status-provider" style={{ paddingTop: '5px'}}>
                                                                    {this.state.formProvider.status === 'pending' ? 'P' : this.state.formProvider.status === 'inactive' ? 'I' : this.state.formProvider.status === 'aproved_adviser' ? 'AP' : 'AC'}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            Departamentos
                                        </div>
                                        <span>{this.state.formProvider?.departments.map((v) => v.department.name+" , " )}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            Bodega(s)
                                        </div>
                                        <span>{this.state.formProvider.warehouses.map((v)=> v.warehouse.initials + ", ")}</span>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            Días de crédito
                                        </div>
                                        <span>{this.state.formProvider.credit_days}</span>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register">
                                            Horario para entregas
                                        </div>
                                        <span>{this.state.formProvider.schedule}</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </Container>
                        <FormProvider handleSaveInfo={this.saveInfoProviderByAdviser} state={this.state} disabledSubmitButton={this.state.disabledSubmitButton} handleDisabledSubmitButton={this.handleDisabledSaveButton} hiddenPassword='true' titleButton='Guardar cambios' />
                        <Footer />
                    </>
                )}

                <ToastContainer />
            </>

        );
    }

    componentDidMount() {
        this.loadInfoData();
    }

}

export default EditProviderCatalogue;