import React, { useState, useEffect } from "react";
import Pagination from '@material-ui/lab/Pagination';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { Container, Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';

//importaciones
import Footer from "../../../../../components/footer/Footer";
import axiosInstance from "../../../../../utils/axios";
import HeaderAdmin from '../../header/Header';
import { currencyFormat } from '../../../../../utils/pipes';
import DataCRUD from '../../../../../components/DataCRUD';

// imagenes
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import img_close_modal from '../../../../../assets/images/close-modal.png';
import StatusTable from "../../../../../components/status-table";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ImportFileProducts from "../../../../../components/import-products";

const optionsStatusSelect = [
    { value: 'aproved_adviser', label: 'Preaprobado' },
    { value: 'inactive', label: 'Inactivo' },
    { value: 'pending', label: 'Pendiente' },
];

export default function ListProductsAdmin2(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [hideColumns, setHideColumns] = useState({
        'public_sale_price': false,
        'description': true,
        'upc': true,
        'sku': true,
        'department': false,
        'net_unit_cost': false,
        'packing': false,
        'variety': false,
        'status_id': false
    });
    const [openModaleImport, setOpenModaleImport] = useState(false);
    const [listProducts, setListProducts] = useState([]);
    const [sort, setSort] = useState('description ASC');
    const [search, setSearch] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [providerId, setProviderId] = useState(null);
    const [idDelete, setIdDelete] = useState(null);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [listProductsExport, setListProductsExport] = useState([]);
    const [paginator, setPaginator] = useState({
        totalPages: 0,
        limit: 10,
        page: 1
    });

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    };

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`products/provider/iam?filter=${search}&order=${sort}&page=${(paginator.page - 1)}&size=${paginator.limit}`,)
            setListProducts(response.data.products);
            setPaginator({
                ...paginator,
                page: response.data.currentPage + 1,
                totalItems: response.data.totalItems,
                totalPages: response.data.totalPages
            });

            setLoading(false);
        }
        fetch();

    }, [paginator.page, sort, search]);

    const sortFunction = (column, sortDirection) => {
        setSort(`${column.sortField} ${sortDirection}`);
        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const handleSearch = (data) => {
        setTextSearch(data);
    }

    const handleSearchButton = (text) => {
        console.log(text);
        setPaginator({
            ...paginator,
            page: 1
        })
        setSearch(text);
    }

    const clickExportFile = () => {
        document.getElementById('file-export').click();
    }

    const handleChangeProviders = (providerId) => {
        setProviderId(providerId);
        setPaginator({
            ...paginator,
            page: 1
        });
        setSort('description ASC');
        setSearch('');
    }

    const handleGetInfoToExportExcel = () => {
        setLoading(true);
        axiosInstance.get(`products/provider/iam?filter=${search}&order=${sort}&page=0&size=5000`,)
            .then((get) => {
                if (get.data) {
                    var temp = [];
                    get.data.products.map((element, i) => {
                        temp.push({
                            numberProduct: i + 1,
                            sku: element.sku,
                            upc: element.upc,
                            barcode_piece: element.barcode_piece,
                            product_classification: element.product_classification,
                            sub_category: element.product_subcategory.product_category.department.name,
                            product_brand: element.product_brand.name,
                            description: element.description,
                            variety: element.variety,
                            packing: element.packing,
                            grammage: element.grammage,
                            unit: element.unit,
                            product_explanation: element.product_explanation,
                            image_link: element.image_download_link,
                            barcode_box: element.barcode_box,
                            sat_key: element.sat_key,
                            refill_type: element.refill_type,
                            number_pieces_box: element.number_pieces_box,
                            gross_cost_box: element.gross_cost_box ? currencyFormat(element.gross_cost_box) : null,
                            gross_cost_piece: element.gross_cost_piece ? currencyFormat(element.gross_cost_piece) : null,
                            ieps: element.ieps,
                            iva: element.tax ? Number(element.tax) * 100 + "%" : null,
                            net_unit_cost: element.net_unit_cost ? currencyFormat(element.net_unit_cost) : null,
                            public_sale_price: element.public_sale_price ? currencyFormat(element.public_sale_price) : null,
                            margin: element.margin ? Number(element.margin) * 100 + "%" : null,
                            sales_price_competition: element.sales_price_competition ? currencyFormat(element.sales_price_competition) : null,
                            difference_competence: element.difference_competence ? Number(element.difference_competence) * 100 + "%" : null,
                            monthly_sale_estimate: element.monthly_sale_estimate,
                            id_provider: element.provider.provider_id,
                            business_name: element.provider.business_name,
                            rfc: element.provider.rfc,
                            receiving_temperature: element.receiving_temperature,
                            long_box: element.long_box,
                            width_box: element.width_box,
                            tall_box: element.tall_box,
                            weight_box: element.weight_box,
                            long_piece: element.long_piece,
                            width_piece: element.width_piece,
                            tall_piece: element.tall_piece,
                            weight_piece: element.weight_piece,
                            boxes_per_laying: element.boxes_per_laying,
                            laying_per_platform: element.laying_per_platform,
                            life_of_anaquel: element.life_of_anaquel,
                            status: element.status === 'active' ? 'Activo' : element.status === 'inactive' ? 'Inactivo' : 'Pendiente'
                        });
                    });
                    setListProductsExport(temp);

                    clickExportFile();

                }
            })
            .catch(function (error) {
                toast.error("Error al exportar el catálogo")
            })
            .finally(() => setLoading(false))

    }

    const handleEditProduct = (id) => {
        props.history.push(`/provider/products/edit/${id}`)
    }

    const handleOpenDeleteModal = (id) => {
        setIdDelete(id);
        setOpenModalDelete(true);
    }

    const handleCloseModal = () => {
        setIdDelete('');
        setOpenModalDelete(false);
    }

    const handleDelete = async () => {
        setOpenModalDelete(false);
        setLoading(true);
        try {
            await axiosInstance.delete(`products/${idDelete}`);
            toast.success('¡Producto modificado con éxito!');
            setIdDelete('');
            setLoading(false);
            handleChangeProviders(providerId);
        } catch (error) {
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
            setLoading(false);

        }
        console.log('delete');
    }

    const handleCloseModalImport = () => {
        document.getElementById('selectedFile').value = null;
        setOpenModaleImport(false);
    }

    const handleOpenModalImport = () => {
        setOpenModaleImport(true);
    }

    const handleChangeFormFileImport = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.files[0]
        });
    }

    const handleCleanForm = () => {
        this.setState({
            ...this.state,
            file: ''
        });
        document.getElementById('selectedFile').value = null;
    }

    const handleValidFormImport = () => {
        if (this.state.file !== '' && this.state.file !== null) {
            return true;
        }
        return false;
    }

    const handleSaveProducts = async (file) => {
        setOpenModaleImport(false);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("provider_id", "provider");
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        try {
            await axiosInstance.post(`products/upload?view=provider`, formData, config);
            toast.success('¡Archivo cargado con éxito!');
            setSearch('');
        } catch (error) {
            console.log(error.response.data.error.data);
            if (error.response) {
                if (error.response.data.error) {
                    toast.error(error.response.data.msg);
                } else {
                    toast.info(error.response.data.msg);
                }
            }
            console.log('Error carga de archivo')
        }
        finally {
            setLoading(false);
        }
    }

    const columnsModel = [
        {
            name: 'Nombre del producto',
            sortField: 'description',
            selector: row => row?.description,
            sortable: true,
            omit: hideColumns.description,
        },
        {
            name: 'UPC',
            sortField: 'upc',
            selector: row => `#${row?.upc}`,
            sortable: true,
            omit: hideColumns.upc,
        },
        {
            name: 'SKU',
            sortField: 'sku',
            selector: row => `#${row?.sku}`,
            sortable: true,
            omit: hideColumns.sku,
        },
        {
            name: 'Departamento',
            sortField: 'department',
            selector: row => row?.product_subcategory?.product_category?.department?.name,
            sortable: true,
            omit: hideColumns.department,
        },
        {
            name: 'Precio',
            sortField: 'net_unit_cost',
            selector: row => currencyFormat(row?.net_unit_cost),
            sortable: true,
            omit: hideColumns.net_unit_cost,
        },
        {
            name: 'Precio sugerido',
            sortField: 'public_sale_price',
            selector: row => currencyFormat(row?.public_sale_price),
            sortable: true,
            omit: hideColumns.public_sale_price,
        },
        {
            name: 'Variedad',
            sortField: 'variety',
            selector: row => row?.variety,
            sortable: true,
            omit: hideColumns.variety,
        },
        {
            name: 'Empaque',
            sortField: 'packing',
            selector: row => row?.packing,
            sortable: true,
            omit: hideColumns.packing,
        },
        {
            name: 'Impuestos',
            selector: () => 'IVA - IEPS',
        },
        {
            name: 'Estado',
            sortField: 'status_id',
            selector: row => row?.status_id,
            omit: hideColumns.status_id,
            cell: row => row?.status.key === 'pending' ? (
                <div className="status-provider pending" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Pendiente
                        </span>
                        <span className="mobile">
                            PE
                        </span>
                    </div>
                </div>
            ) : row?.status.key === 'aproved_adviser' ? (
                <div className="status-provider active" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Preaprobado
                        </span>
                        <span className="mobile">
                            PA
                        </span>
                    </div>
                </div>
            ) : row?.status.key === 'inactive' ? (
                <div className="status-provider inactive" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Inactivo
                        </span>
                        <span className="mobile">
                            I
                        </span>
                    </div>
                </div>
            ) : (
                <div className="status-provider active" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Activo
                        </span>
                        <span className="mobile">
                            AC
                        </span>
                    </div>
                </div>
            ),
            sortable: true,
        },
    ];

    const optionsFilter = [
        { value: 'description', label: 'Nombre de producto' },
        { value: 'upc', label: 'UPC' },
        { value: 'sku', label: 'SKU' },
        { value: 'department', label: 'Departamento' },
        { value: 'net_unit_cost', label: 'Precio' },
        { value: 'public_sale_price', label: 'Precio sugerido' },
        { value: 'packing', label: 'Empaque' },
        { value: 'variety', label: 'Variedad' },
        { value: 'status_id', label: 'Estado' }
    ];

    const handleHideColumns = (select) => {
        let temp = { ...hideColumns };
        for (const item of optionsFilter) {
            if (Boolean(select.find((element) => element.value === item.value)))
                temp[`${item.value}`] = false;
            else
                temp[`${item.value}`] = true;
        }
        setHideColumns(temp);
    }

    return (
        <>
            <HeaderAdmin
                title="Catálogo"
                icon={icon_list_products}
                user={user}
                search={handleSearch}
                textSearch='Buscar producto'
                view='products-list'
                filterOption={optionsFilter}
                hideColumns={handleHideColumns}
                listHideColumns={hideColumns}
                onSearchButton={handleSearchButton}
                handleGetInfoToExportExcel={handleGetInfoToExportExcel}
                handleOpenModalImport={handleOpenModalImport}
            />
            <ExcelFile element={<button id="file-export"></button>} filename="Catálogo de productos">
                <ExcelSheet data={listProductsExport} name="Hoja 1">
                    <ExcelColumn label="" value="numberProduct" />
                    <ExcelColumn label="SKU" value="sku" />
                    <ExcelColumn label="UPC" value="upc" />
                    <ExcelColumn label="Codigo de Barra  Pieza (EAN 13)" value="barcode_piece" />
                    <ExcelColumn label="Kilo-pieza  /  Gramaje Sustancia Activa" value="product_classification" />
                    <ExcelColumn label="Subcategoría" value="sub_category" />
                    <ExcelColumn label="Marca" value="product_brand" />
                    <ExcelColumn label="Descripción de producto (qué producto es)" value="description" />
                    <ExcelColumn label="Variedad del Producto (Sabor, ejemplo Adobadas,Natural )" value="variety" />
                    <ExcelColumn label="Empaque( Botella, Envase, Pouch, cartón, lata)" value="packing" />
                    <ExcelColumn label="GRAMAJE" value="grammage" />
                    <ExcelColumn label="UNIDAD" value="unit" />
                    <ExcelColumn
                        label="Explicación de Producto. Entre 50 y 100 palabras. Incluir descripción del producto, beneficios, modo de uso y preparación."
                        value="product_explanation" />
                    <ExcelColumn label="Link de descarga de imagén, UPC como nombre de imagen y fomato JPG"
                        value="image_link" />
                    <ExcelColumn label="Codigo de Barra  Caja (DUN 14)" value="barcode_box" />
                    <ExcelColumn label="Clave SAT" value="sat_key" />
                    <ExcelColumn label="Tipo de Resurtido (I&O/Línea)" value="refill_type" />
                    <ExcelColumn label="Piezas por Caja" value="number_pieces_box" />
                    <ExcelColumn label="Costo Bruto Caja" value="gross_cost_box" />
                    <ExcelColumn label="Costo Bruto Pieza" value="gross_cost_piece" />
                    <ExcelColumn label="IEPS" value="ieps" />
                    <ExcelColumn label="IVA" value="iva" />
                    <ExcelColumn label="COSTO NETO UNITARIO" value="net_unit_cost" />
                    <ExcelColumn label="Precio de Venta Público" value="public_sale_price" />
                    <ExcelColumn label="Margen" value="margin" />
                    <ExcelColumn label="Precio de Venta Competencia" value="sales_price_competition" />
                    <ExcelColumn label="Diferencia vs. Competencia" value="difference_competence" />
                    <ExcelColumn label="Estimado de Venta Mensual (Cajas)" value="monthly_sale_estimate" />
                    <ExcelColumn label="No. Proveedor" value="id_provider" />
                    <ExcelColumn label="Proveedor" value="business_name" />
                    <ExcelColumn label="RFC" value="rfc" />
                    <ExcelColumn label="LARGO Caja [cm]" value="long_box" />
                    <ExcelColumn label="ANCHO Caja [cm]" value="width_box" />
                    <ExcelColumn label="ALTO Caja[cm]" value="tall_box" />
                    <ExcelColumn label="Peso Caja [kg]" value="weight_box" />
                    <ExcelColumn label="LARGO Pieza [cm]" value="long_piece" />
                    <ExcelColumn label="ANCHO Pieza [cm]" value="width_piece" />
                    <ExcelColumn label="ALTO Pieza [cm]" value="tall_piece" />
                    <ExcelColumn label="Peso Pieza [kg]" value="weight_piece" />
                    <ExcelColumn label="Cajas por tendido" value="boxes_per_laying" />
                    <ExcelColumn label="Tendidos por tarima" value="laying_per_platform" />
                    <ExcelColumn label="Vida de anaquel [días]" value="life_of_anaquel" />
                    <ExcelColumn label="Temp Recepción [°C]" value="receiving_temperature" />
                </ExcelSheet>
            </ExcelFile>
            <div className="body-provider body-list">
                <Container>
                    <DataCRUD
                        url="/products"
                        data={listProducts}
                        columns={columnsModel}
                        handleSort={sortFunction}
                        handleClickEdit={handleEditProduct}
                        handleClickRemove={handleOpenDeleteModal}
                        loading={loading} />
                    <div className="providers">
                        <div className="paginator">
                            <Pagination
                                hidePrevButton
                                hideNextButton
                                count={paginator.totalPages}
                                page={paginator.page}
                                onChange={handleChangePage} />
                        </div>
                    </div>
                </Container>
            </div>
            <ToastContainer />
            <Footer />

            {openModaleImport && (
                <ImportFileProducts
                    open={openModaleImport}
                    onClose={handleCloseModalImport}
                    onSave={handleSaveProducts}
                    init="" />
            )}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal-content"
                open={openModalDelete}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            ><Fade in={openModalDelete}>
                    <div className="modal-delete">
                        <div className="icon-close" onClick={handleCloseModal}>
                            <div className="title-modal-provider">
                                Eliminar producto
                                <img src={img_close_modal} alt="Cerrar" />
                            </div>
                        </div>
                        <div className="documents-modal-provider">
                            <div className="container-load-products container-modal-detele">
                                <div className="label text-center">
                                    ¿Seguro desea eliminar el producto?
                                </div>

                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <div className="container-btn-save" onClick={handleCloseModal}>
                                            <button className="button-cancel">
                                                Cancelar
                                            </button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="container-btn-save">
                                            <button className="button-delete" onClick={handleDelete}>
                                                Eliminar
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

