import { useState, useRef, useEffect } from 'react';
import { Container } from '@material-ui/core';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// estilos
import './quicksight.css';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";

// imagenes
import icon_list_products from '../../../../../assets/images/icon-list-products.png';

function Quicksight() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [tabValue, setTabValue] = useState('hermes');
    const [loading, setLoading] = useState(false);
    const [view, setView] = useState(true);
    const [tabs, setTabs] = useState([]);

    const dashboardRef = useRef(null);

    const embed = (url) => {
        const options = {
            url: url.data,
            container: dashboardRef.current,
            scrolling: "no",
            height: "700px",
            width: "100%",
            locale: "en-US",
            footerPaddingEnabled: true
        };
        QuickSightEmbedding.embedDashboard(options)
    };

    useEffect(() => {
        async function fetch() {
            const data = await axiosInstance.get('providers/info/insights');
            const tabs = data.data.map((item) => item.insight_plan);
            setTabs(tabs);
        }
        fetch();
    }, []);

    useEffect(async () => {
        try {
            setLoading(true);
            setView(false);
            let url = await axiosInstance.get(`utils/quicksight/${tabValue}`,);
            setView(true);
            setLoading(false);
            embed(url);
        } catch (error) {
            console.log(error)
            setView(true);
            setLoading(false);
        }
    }, [tabValue]);

    return (
        <>
            <HeaderProvider
                title="Insights"
                user={user}
                view='reports'
                type='detail'
                icon={icon_list_products}
                listBreadcrumbs={[]} />

            <Container maxWidth="xl" style={{ paddingLeft: '100px' }}>
                <Tabs
                    variant="standard"
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    className="bgtabs">
                    {tabs.map((tab) =>
                        <Tab value={tab.key} label={tab.name} onClick={() => setTabValue(tab.key)} />
                    )}
                </Tabs>
                {loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        {view ? (
                            <>
                                <div ref={dashboardRef} className="iframe-reports" />
                            </>
                        ) : (
                            <>
                                <div className="error-reports">
                                    <span className="text-error">
                                        Usuario no posee reporte
                                    </span>
                                </div>
                            </>)}
                    </>)}
            </Container>

            <Footer />
        </>
    );
}

export default Quicksight;