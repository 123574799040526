import React from 'react';
import {Link} from "react-router-dom";
import './not-found.css';

const NotFound = () => {
    return (
        <div className="NotFound text-center">
            <h1 className="title-404">404</h1>
            <h3 className="title-page">¡Esta página no está disponible!</h3>

            <Link to="/">Ir a inicio</Link>
        </div>
    );
}

export default NotFound;