import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';

// importar estilos
import '../../../../register/register-provider/register-provider.css';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";
import FormProduct from "../form-product/FormProduct";

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Catálogo',
        url: '/provider/products/list'
    },
    {
        title: 'Nuevo producto'
    }
];

class NewProduct extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            listSubcategories: [],
            listBrands: [],
            formProduct: {
                description: '',
                product_explanation: '',
                product_subcategory_id: '0',
                product_brand: '',
                variety: '',
                packing: '',
                barcode_piece: '',
                image_download_link: '',
                grammage: '',
                unit: '',
                barcode_box: '',
                sat_key: '',
                refill_type: '',
                gross_cost_piece: '',
                ieps: ''
            }
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const product_subcategories = await axiosInstance.get(`product_subcategories`,);

                const listSubcategories = product_subcategories.data.map((subcategory) => { return {value: subcategory.id, label: subcategory.name}});

                this.setState({
                    ...this.state,
                    listSubcategories,
                    loading: false
                })
            } catch (error) {
                console.log(error);
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            }
        });
    }

    handleSaveInfo = (state) => {
        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.post('products', state.formProduct);
                    toast.success("Producto cargado con éxito")
                    this.props.history.push("/provider/products/list");
                } catch (error) {
                    // Error 😨
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
                finally {
                    this.setState({ loading: false })
                }
            });
        } catch (e) { alert(e.message); }
        finally {
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <>
                <HeaderProvider
                    title="Nuevo producto"
                    user={this.state.user}
                    view='new-product'
                    type='detail'
                    listBreadcrumbs={listBreadcrumbs} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <FormProduct state={this.state} handleSaveInfo={this.handleSaveInfo} textButton='Subir producto'/>
                        <Footer />
                    </>
                )}

            </>

        );
    }

    componentDidMount() {
        this.loadInfoData();
    }

}

export default NewProduct;