import { useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

//import
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from '../../header/Header';
import Footer from "../../../../../components/footer/Footer";
import FormUserProvider from "../../../../../components/form-user-provider";

// images
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import Loading from "../../../../../components/loading/Loading";

export default function EditUserProviderSuperAdmin(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [listModules, setListModules] = useState([]);
    const [listRoles, setListRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userApi, setUserApi] = useState();
    const [providerId, setProviderId] = useState(props.match.params.providerId);
    const [provider, setProvider] = useState();

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        setValue,
        reset
    } = useForm();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const listMod = await axiosInstance.get('/modules?filter=&page=0&size=1000&order=name ASC');
                const listRol = await axiosInstance.get(`/roles/provider`,);
                const userData = await axiosInstance.get(`/providers/info/users/${props.match.params.id}`,);
                const prov = await axiosInstance.get(`/providers/${providerId}`,);

                reset(userData.data.user);
                setValue('role', {value: userData.data.role.id, label: userData.data.role.description});
                setValue('modules', userData.data?.modules?.map((m) => ({value: m.key, label: m.name})));

                setListModules(listMod.data.modules);
                setListRoles(listRol.data);
                setUserApi(userData.data.user);
                setProvider(prov.data);
                
            } catch (error) {
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                } else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            } finally {
                setLoading(false);
            }

        }
        fetch();
    }, []);

    // lista para breadcrounb
    const listBreadcrumbs = [
        {
            title: 'Proveedores',
            url: '/super_admin/providers/list'
        },
        {
            title: provider ? provider.business_name : '',
            url: '/super_admin/providers/edit/'+providerId
        },
        {
            title: userApi !== null ? userApi?.name : ''
        }
    ];


    const onSubmit = async (values) => {
        try {
            setLoading(true);
            await axiosInstance.put(`providers/info/users/${props.match.params.id}`, values);
            toast.success('Usuario modificado con éxito!');
        } catch (error) {
            console.log(error.response);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <HeaderProvider
                title={userApi !== null ? userApi?.name : ''}
                icon={icon_list_products}
                listBreadcrumbs={listBreadcrumbs}
                user={user}
                view='empresa'
                type='detail' />

            {loading ? (
                <Loading />
            ) : (
                <>
                    <FormUserProvider
                        register={register}
                        errors={errors}
                        control={control}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit} 
                        listModules={listModules}
                        listRoles={listRoles}
                        textButton='Guardar Cambios'/>
                    < Footer />
                </>
            )}
        </>
    )
}