import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { toast, ToastContainer } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';

//importar estilos
import '../../../provider/products/list-products/list-products.css';
import './list-advisers.css';

//images
import icon_list_users from '../../../../../assets/images/icon-user-list.png';
import img_icon_edit from '../../../../../assets/images/edit.png';
import icon_off from '../../../../../assets/images/icon_person_off.png';
import icon_on from '../../../../../assets/images/icon_person.png';

//importaciones
import Footer from '../../../../../components/footer/Footer';
import axiosInstance from '../../../../../utils/axios';
import HeaderAdmin from '../../header/Header';
import Loading from '../../../../../components/loading/Loading';
import ModalDeletUser from './Delete';

class ListAdvisersManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModalDelete: false,
      submitData: {},
      listAdmins: [],
      loading: false,
      user: JSON.parse(localStorage.getItem('user')),
      search: '',
      paginator: {
        page: 1,
        size: 5,
        totalPages: 0,
        totalItems: 0,
      },
    };
  }

  loadInfoData = () => {
    this.setState({ loading: true }, () => {
      axiosInstance
        .get(`users/info/getAdmins?filter=&page=${this.state.paginator.page - 1}&size=${this.state.paginator.size}`)
        .then(get => {
          if (get.data) {
            get.data.admins.map(element => {
              element.openDepartments = false;
            });

            console.log(get.data);

            this.setState({
              ...this.state,
              listAdmins: get.data.admins,
              loading: false,
              paginatorAdmins: {
                ...this.state.paginator,
                page: get.data.currentPage + 1,
                totalItems: get.data.totalItems,
                totalPages: get.data.totalPages,
              },
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  handleSearch = term => {
    this.setState({
      ...this.state,
      search: term,
    });
  };

  handleSearchButton = () => {
    this.setState({
      paginator: {
        ...this.state.paginator,
        page: 1,
      },
    });
    this.loadInfoData();
  };

  handleChangePage = (event, value) => {
    this.setState({
      paginator: {
        ...this.state.paginator,
        page: value,
      },
    });
    this.loadInfoData();
  };

  handleOpenDepartments = provider => {
    const data = [];
    this.state.listAdmins.forEach(element => {
      if (provider.id === element.id) {
        element.openDepartments = !element.openDepartments;
      } else {
        element.openDepartments = false;
      }
      data.push(element);
    });

    this.setState({
      listAdmins: data,
    });
  };

  handleGo = id => {
    this.props.history.push('/manager/advisers/edit/' + id);
  };

  updateStatus = async () => {
    const { id, status } = this.state.submitData || {};

    this.setState({ ...this.state, openModalDelete: false, loading: true, submitData: {} });

    try {
      await axiosInstance.put('users/updateStatus/' + id, { status_id: status });
      toast.info('Usuario actualizado con éxito!');
      this.loadInfoData(1, null, this.state.search);
    } catch (error) {
      console.log(error.response.data.error);
      if (error.response) {
        if (error.response.data.error) {
          toast.error(error.response.data.msg);
        } else {
          toast.info(error.response.data.msg);
        }
      }
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  handleUpdateStatus = (status, id) =>
    this.setState({ ...this.state, openModalDelete: true, submitData: { status, id } });

  render() {
    const rowsAdmins = this.state.listAdmins.map(admin => {
      const isActive = admin.user.status.key === 'active';
      const id = admin.user.id;

      const newState = isActive ? '0021a567-66a4-4147-8f4c-4a54287d9304' : '0021a567-66a4-4147-8f4c-4a54287d9305';

      return (
        <div className='row-table row-body' key={admin.id}>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <div className='item-provider'>{admin.user.name}</div>
            </Grid>
            <Grid item xs={3}>
              <div className='item-provider'>{admin.user.email}</div>
            </Grid>
            <Grid item xs={3}>
              <div className='item-provider'>
                {admin.departments.length > 2 ? (
                  <>
                    {admin.openDepartments ? (
                      <>
                        {admin.departments.map((department, index) => {
                          return (
                            <>
                              <div className='text-item-table-privider product department' key={index}>
                                {department.department.name}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {admin.departments.map((department, index) => {
                          if (index < 2) {
                            return (
                              <>
                                <div className='text-item-table-privider product department' key={index}>
                                  {department.department.name}
                                </div>
                              </>
                            );
                          }
                        })}
                      </>
                    )}
                    <div className='icon-more' onClick={e => this.handleOpenDepartments(admin)}>
                      {admin.openDepartments ? <Visibility /> : <VisibilityOff />}
                    </div>
                  </>
                ) : (
                  <>
                    {admin.departments.map((department, index) => {
                      return (
                        <>
                          <div className='text-item-table-privider product department' key={index}>
                            {department.department.name}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={1}>
              <div className='item-provider'>{admin.user.status.name}</div>
            </Grid>
            {admin.role.name !== 'super_admin' && (
              <Grid item xs={1}>
                <div className='icon-action-provider'>
                  <Tooltip title='Editar' className='tooltip' onClick={e => this.handleGo(admin.user.id)}>
                    <img src={img_icon_edit} alt='' />
                  </Tooltip>
                  <Tooltip
                    title={isActive ? 'Desactivar' : 'Activar'}
                    className='tooltip'
                    onClick={() => this.handleUpdateStatus(newState, id)}
                  >
                    <img src={isActive ? icon_on : icon_off} alt='' width={24} height={24} />
                  </Tooltip>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      );
    });

    return (
      <>
        <HeaderAdmin
          title='Asesores'
          icon={icon_list_users}
          user={this.state.user}
          search={this.handleSearch}
          textSearch='Buscar asesor'
          view='advisers'
          onSearchButton={e => this.handleSearchButton()}
        />
        {this.state.loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <div className='body-provider'>
              <Container>
                <div className='row-table row-title'>
                  <Grid container spacing={5}>
                    <Grid item xs={4}>
                      <div className='title-table-orders mobilNone'>Nombre</div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className='title-table-orders mobilNone'>Correo</div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className='title-table-orders mobilNone'>Departamentos</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className='title-table-orders mobilNone'>Estado</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className='title-table-orders mobilNone'>Acciones</div>
                    </Grid>
                  </Grid>
                </div>
                <div className='providers'>
                  {rowsAdmins}
                  <div className='paginator'>
                    <Pagination
                      hidePrevButton
                      hideNextButton
                      count={this.state.paginator.totalPages}
                      page={this.state.paginator.page}
                      onChange={this.handleChangePage}
                    />
                  </div>
                </div>
              </Container>
            </div>
            <Footer />
          </>
        )}
        <ToastContainer />
        <ModalDeletUser
          open={this.state.openModalDelete}
          onClose={() => this.setState({ ...this.state, openModalDelete: false })}
          onSubmit={this.updateStatus}
        />
      </>
    );
  }

  componentDidMount() {
    this.loadInfoData();
  }
}

export default ListAdvisersManager;
