import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

// imports
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";
import FormSuggestOrder from '../../../../../components/form-suggest-order';

export default function SuggestOrders() {

    const [listProductsApi, setListProductsApi] = useState([]);
    const [listProductSelect, setListProductSelect] = useState([]);

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        setValue
    } = useForm();

    const history = useHistory();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try{
                let response = await axiosInstance.get(`products/ocs/iam?filter=&order=description ASC&page=0&size=5000`,);
                setListProductsApi(response.data.products);
            }catch (e) {
                console.log(e);
            }finally {
                setLoading(false);
            }
        }

        fetch();
    }, []);

    //listas para breadcrumbs
    const listBreadcrumbs = [
        {
            title: 'Órdenes de compra',
            url: '/master/orders/list'
        },
        {
            title: 'Sugerir orden de compra',

        }
    ];

    const onSubmit = async (values) => {
        const { product, cant } = values;

        let data = [];
        for (let index = 0; index < product.length; index++) {
            if (product[index] !== '') {
                data.push({
                    product_id: product[index].value,
                    cant: cant[index],
                    unit_price: listProductsApi.find((item) => item.id === product[index].value).net_unit_cost
                })
            }

        }

        try {
            setLoading(true);
            await axiosInstance.post('orders', { products: data });
            toast.info('¡Orden de compra creada con éxito!');
            setTimeout(() => {
                history.push("/provider/orders/list");
                setLoading(false);
            }, 2000);
        } catch (error) {
            console.log(error);
            console.log(error.response);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            }
        }
    }

    return (
        <>
            <HeaderProvider
                title="Sugerir orden de compra"
                user={user}
                view='new-incidence'
                type='detail'
                listBreadcrumbs={listBreadcrumbs} />
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <FormSuggestOrder 
                        listProductsApi={listProductsApi} 
                        listProductSelected={listProductSelect}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        titleButton="Sugerir orden de compra"
                        />
                    <Footer />
                </>
            )}
            <ToastContainer />
        </>
    );

}
