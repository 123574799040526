import React, { Component, createRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Container, Grid } from '@material-ui/core';
import moment from 'moment';

// estilos
import './detail-incidence.css';

// images
import img_file_load from '../../../../../assets/images/file_load.png';
import img_icon_user from '../../../../../assets/images/user.png';
import image_icon_remove from '../../../../../assets/images/icon-remove-file.png';
import image_icon_user_justo from '../../../../../assets/images/icono-incidences.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";

class DetailIncidence extends Component {

    constructor(props) {
        super(props);

        this.state = {
            params: this.props.match.params,
            oc_required: false,
            listBreadcrumbs: [
                {
                    title: 'Incidencias',
                    url: '/provider/incidences/list'
                },
                {
                    title: 'Titulo de la incidencia'
                }
            ],
            detailIncidence: {
                title: '',
                date: '',
                subtopic: {
                    topic: {
                        name: ''
                    }
                },
                purchase_order_file: '',
                purchase_order_id: '0',
                evidence_file: '',
                description: '',
            },
            histoyIncidence: [],
            formResponse: {
                description: '',
                evidence_file: '',
            },
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            myRef: createRef()
        }
    }

    executeScroll = () => {
        this.state.myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    loadInfoData = () => {

        this.setState({ loading: true }, async () => {
            try {
                const incidence = await axiosInstance.get(`incidences/${this.state.params.id}`,);

                let temp = this.state.listBreadcrumbs;

                temp[1].title = incidence.data.incidence.title;

                this.setState({
                    ...this.state,
                    detailIncidence: incidence.data.incidence,
                    histoyIncidence: incidence.data.history,
                    loading: false,
                    listBreadcrumbs: temp
                });

                this.executeScroll();
            } catch (error) {
                console.log(error);
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            }
        });
    }

    fileIsImage = (url) => {
        if(url){
            let url_spread = url.split('.');
            let lastIndex = url.split('.').length - 1;
            let file_extension = url_spread[lastIndex];
    
            let images_extensions = ['jpg', 'png', 'jpeg', 'svg']
            let match = false;
    
            if (file_extension !== '') {
                let ext = images_extensions.find((ex) => ex === file_extension);
                ext ? match = true : match = false;
            }
            return match;
        }
        return false;        
    }

    handleDelete = () => {
        this.handleCloseModal();
        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.put(`incidences/close/${this.state.detailIncidence.id}`);
                    toast.success('Incidencia modificada con éxito!');
                    this.setState({ ...this.state, loading: false });
                    this.loadInfoData();
                } catch (error) {
                    // Error 😨
                    if (error.response) {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }
    }

    selectFileEvidence = (e) => {
        document.getElementById('selectFileEvidence').click();
    }

    handleChangeFormImage = (event) => {
        this.setState({
            formIncidence: {
                ...this.state.formIncidence,
                [event.target.name]: event.target.files[0]
            }
        });
    }

    handleChangeForm = (event) => {
        this.setState({
            formResponse: {
                ...this.state.formResponse,
                [event.target.name]: event.target.value
            }
        });
    }

    handleClearFile = (attr, id) => {
        this.setState({
            formResponse: {
                ...this.state.formResponse,
                [attr]: ''
            }
        });

        document.getElementById(id).value = null;
    }

    handleValidForm = () => {
        if (this.state.formResponse.description !== '' && this.state.formResponse.description !== null) {
            return true;
        }
        return false;
    }

    handleSave = (event) => {
        if (this.handleValidForm())
            try {
                this.setState({ loading: true }, async () => {
                    try {
                        event.preventDefault();
                        const formData = new FormData();
                        // agregar imagenes
                        formData.append("incidence_id", this.state.detailIncidence.id);
                        formData.append("file_path", this.state.formResponse.evidence_file);
                        formData.append("description", this.state.formResponse.description);

                        axiosInstance.post("/incidences/addHistory", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                        toast.info('¡Incidencia guardada con éxito!');

                        this.setState({
                            formResponse: {
                                description: '',
                                evidence_file: ''
                            }
                        });

                        setTimeout(() => {
                            this.setState({ loading: false });
                            this.loadInfoData();
                        }, 1000);
                    } catch (error) {
                        // Error 😨
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                });
            } catch (e) { alert(e.message); }
    }

    render() {
        return (
            <>
                <div style={{ width: '100%', background: 'white', top: '0', position: 'fixed' }}>
                    <HeaderProvider
                        title={this.state.detailIncidence.title}
                        user={this.state.user}
                        view='edit-incidences'
                        type='detail'
                        listBreadcrumbs={this.state.listBreadcrumbs}
                        theme={this.state.detailIncidence.subtopic?.name}
                        status={this.state.detailIncidence.status}
                        themeDepIncidences={this.state.detailIncidence.subtopic?.topic.name}
                        DateIncidence={this.state.detailIncidence.date} />
                </div>
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <div className="body-provider" style={{ paddingTop: '310px' }}>
                            <Container maxWidth="sm">
                                {this.state.histoyIncidence.map((history, index) => (
                                    <>
                                        <div className="container-message" style={{ backgroundColor: history.user_role !== 'provider' ? 'rgba(242, 245, 245, 1)' : 'white' }} key={index}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={10}>
                                                    <div className="content-user">
                                                        {history.user_role !== 'provider' ? (
                                                            <>
                                                                <img src={image_icon_user_justo} alt="" className="icon-user" /> {history?.user?.email}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img src={img_icon_user} alt="" className="icon-user" /> {history?.user?.email}
                                                            </>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="date-send">
                                                        {moment(history.date).format("DD/MM/YYYY")}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <div className='text-details-incidence'>
                                                        {history.description}
                                                        <br />
                                                        {this.fileIsImage(history.file_path) ? <img width="340" src={history.file_path} alt="" /> :
                                                            <a style={{ color: 'rgba(0,0,250,.6)', fontWeight: '500' }} target="_blank" href={history.file_path}>{history.file_path}</a>}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ))}
                                {this.state.detailIncidence.status !== 'close' ? (
                                    <>
                                        <Grid container className="container-btn-response">
                                            <Grid item xs={12}>
                                                <div className="label-register label-form">
                                                    Respuesta *
                                                </div>
                                                <textarea required className="input-register text-area-form" name="description" value={this.state.formResponse.description} onChange={(e) => this.handleChangeForm(e)}></textarea>
                                            </Grid>
                                        </Grid>
                                        <Grid container className="container-btn-response">
                                            <Grid item xs={12}>
                                                <div className="label-register label-form">
                                                    Adjunta evidencia
                                                </div>
                                                <input
                                                    id="selectFileEvidence"
                                                    type="file"
                                                    name="evidence_file"
                                                    onChange={(e) => this.handleChangeFormImage(e)}
                                                />
                                                {this.state.formResponse.evidence_file !== '' ? (
                                                    <>
                                                        <div className="button-load-register">
                                                            <Grid container >
                                                                <Grid item xs={11}>
                                                                    <div className="text-load">
                                                                        {this.state.formResponse.evidence_file.name}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <div className="text-load">
                                                                        <img src={image_icon_remove} alt="" srcset="" onClick={(e) => this.handleClearFile('evidence_file', 'selectFileEvidence')} />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button className="button-load-file-form button-load-products-file button-load-incidence" onClick={(e) => this.selectFileOC()}>
                                                            <Grid container >
                                                                <Grid item xs={1}>
                                                                    <div className="img-button-not-loaded">
                                                                        <img src={img_file_load} alt="Cargar" />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <div className="text-button text-button-not-loaded">
                                                                        Arrastra el archivo aquí o <span className="text-underline-load-products">selecciónalo desde tu dispositivo</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </button>
                                                    </>)}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5} ref={this.state.myRef}>
                                            <Grid item xs={6}>
                                                <div className="container-btn-save">
                                                    <button  disabled={!this.handleValidForm}className="button-delete" onClick={(e) => this.handleSave(e)}>
                                                        Responder
                                                    </button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <div style={{ textAlign: 'center' }} ref={this.state.myRef}>
                                            <span className="close-incidence">Incidencia cerrarda por el agente {this.state.detailIncidence.closing_user[0].name} </span>
                                        </div>
                                    </>
                                )}
                            </Container>
                        </div>
                        <Footer />
                    </>)
                }
            </>

        );
    }

    componentDidMount() {
        this.loadInfoData();
    }
}

export default DetailIncidence;