import React, { Component } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, Grid, TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';




// imagenes
import img_file_donwload from '../../../../../assets/images/icon-file-donwload.png';

export default class FormProviderFinance extends Component {
    selectRefDelegation = null;
    selectRefSuburb = null;

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state,
        }
    }

    handleDownloadFile = (id) =>{
        document.getElementById(id).click();
    }

    render() {
        return (
            <>
                <Container maxWidth="md">
                    <div className="section-register">
                        <div className="title-section">
                            Información cuenta
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Nombre de contacto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.business_name}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Correo electrónico
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.email}
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Información comercial
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Razón social
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.business_name}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    RFC
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.rfc}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Opinión de cumplimiento de SAT
                                </div>
                                {(this.state.form.opinion_fulfillment_sat !== '' && this.state.form.opinion_fulfillment_sat !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.opinion_fulfillment_sat?.name ? this.state.form.opinion_fulfillment_sat.name : (
                                                            <Link id="opinion_fulfillment_sat" className="value-provider" href={this.state.form.opinion_fulfillment_sat}>opinióndecumplimientodeSAT.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("opinion_fulfillment_sat")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Carátula bancaria
                                </div>
                                {(this.state.form.bank_face !== '' && this.state.form.bank_face !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.bank_face?.name ? this.state.form.bank_face.name : (
                                                            <Link id="bank_face" className="value-provider" href={this.state.form.bank_face}>caratulabancaria.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("bank_face")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Banco
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.account_bank ? this.state.formProvider.account_bank : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Número de cuenta
                                </div>
                                <span className="value-provider ">
                                    {this.state.formProvider.account_number ? this.state.formProvider.account_number : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Cuenta CLABE
                                </div>
                                <span className="value-provider ">
                                    {this.state.formProvider.account_clabe ? this.state.formProvider.account_clabe : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="section-register">
                        <div className="title-section">
                            Datos de contacto comercial
                        </div>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Nombre de contacto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_name ? this.state.formProvider.contact_business_name : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Puesto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_workstation ? this.state.formProvider.contact_business_workstation : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Correo electrónico
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_email ? this.state.formProvider.contact_business_email : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Teléfono
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_phone ? this.state.formProvider.contact_business_phone : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Datos de contacto logístico
                        </div>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register  title-provider-info">
                                    Nombre de contacto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_logistic_name ? this.state.formProvider.contact_logistic_name : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Puesto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_logistic_workstation ? this.state.formProvider.contact_logistic_workstation : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Correo electrónico
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_logistic_email ? this.state.formProvider.contact_logistic_email : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Teléfono
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_logistic_phone ? this.state.formProvider.contact_logistic_phone : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Datos de contacto financiero
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Nombre de contacto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_financial_name ? this.state.formProvider.contact_financial_name : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Puesto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_financial_workstation ? this.state.formProvider.contact_financial_workstation : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Correo electrónico
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_financial_email ? this.state.formProvider.contact_financial_email : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Teléfono
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_financial_phone ? this.state.formProvider.contact_financial_phone : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Domicilio fiscal
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Calle
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_street ? this.state.formProvider.address_street : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Número exterior
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_number_exterior ? this.state.formProvider.address_number_exterior : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Número interior
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_number_interior ? this.state.formProvider.address_number_interior : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    CP
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_postcode ? this.state.formProvider.address_postcode : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Estado
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_state ? this.state.formProvider.address_state : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Municipio / Alcaldía
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_delegation ? this.state.formProvider.address_delegation : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Colonia
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_suburb ? this.state.formProvider.address_suburb : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Ciudad
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.address_city ? this.state.formProvider.address_city : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Información empresarial
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Número de empleados
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.number_employees ? this.state.formProvider.number_employees : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Empresa
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.company_classification ? this.state.formProvider.company_classification === 'national' ? 'Nacional' : 'Internacional' : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Comprobante de domicilio no mayor a 3 meses de antigüedad
                                </div>
                                {(this.state.form.proof_address !== '' && this.state.form.proof_address !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.proof_address?.name ? this.state.form.proof_address.name : (
                                                            <Link className="value-provider" id="proof_address" href={this.state.form.proof_address}>comprobantedomicilio.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("proof_address")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Constancia de situación fiscal
                                </div>
                                {(this.state.form.proof_tax_situation !== '' && this.state.form.proof_tax_situation !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.proof_tax_situation?.name ? this.state.form.proof_tax_situation.name : (
                                                            <Link className="value-provider" id="proof_tax_situation" href={this.state.form.proof_tax_situation}>constancia_Situacion_fiscal.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("proof_tax_situation")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Acta constitutiva completa con sus anexos
                                </div>
                                {(this.state.form.constitutive_act != null && this.state.form.constitutive_act !== '') ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.constitutive_act?.name ? this.state.form.constitutive_act.name : (
                                                            <Link className="value-provider" id="constitutive_act" href={this.state.form.constitutive_act}>acta_constitutiva_con_anexos.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("constitutive_act")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Opinión del cumplimiento de obligaciones fiscales
                                </div>
                                {(this.state.form.opinion_fulfillment_tax_obligations !== '' && this.state.form.opinion_fulfillment_tax_obligations !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.opinion_fulfillment_tax_obligations?.name ? this.state.form.opinion_fulfillment_tax_obligations.name : (
                                                            <Link className="value-provider" id="opinion_fulfillment_tax_obligations" href={this.state.form.opinion_fulfillment_tax_obligations}>opinion_cumplimiento_de_obligaciones.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("opinion_fulfillment_tax_obligations")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Poder notarial del representante legal
                                </div>
                                {(this.state.form.power_attorney !== '' && this.state.form.power_attorney !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.power_attorney?.name ? this.state.form.power_attorney.name : (
                                                            <Link id="power_attorney" className="value-provider" href={this.state.form.power_attorney}>poder_notarial_representante_legal.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img  onClick={()=>this.handleDownloadFile("power_attorney")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register borF">
                        <div className="title-section">
                            Información de representante legal
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Nombre completo del representante legal
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.name_legal_representative ? this.state.formProvider.name_legal_representative : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    CURP del representante legal
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.curp_legal_representative ? this.state.formProvider.curp_legal_representative : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Identificación oficial INE - Frente
                                </div>
                                {(this.state.form.identification_legal_representative_front !== '' && this.state.form.identification_legal_representative_front !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.identification_legal_representative_front?.name ? this.state.form.identification_legal_representative_front.name : (
                                                            <Link className="value-provider" id="identification_legal_representative_front" href={this.state.form.identification_legal_representative_front}>identificacion_oficial_INE_frente.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("identification_legal_representative_front")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Identificación oficial INE - Reverso
                                </div>
                                {(this.state.form.identification_legal_representative_reverse !== '' && this.state.form.identification_legal_representative_reverse !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.identification_legal_representative_reverse?.name ? this.state.form.identification_legal_representative_reverse.name : (
                                                            <Link className="value-provider" id="identification_legal_representative_reverse" href={this.state.form.identification_legal_representative_reverse}>identificacion_oficial_INE_reverso.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img onClick={()=>this.handleDownloadFile("identification_legal_representative_reverse")} src={img_file_donwload} alt="Descargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </>
        );
    }

}
