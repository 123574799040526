import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

// importar estilos
import '../../../register/register-provider/register-provider.css';

// images
import img_icon_information from '../../../../assets/images/icon-information.png';

//imports
import Header from '../../../../components/header/Header';
import Loading from '../../../../components/loading/Loading';
import Footer from '../../../../components/footer/Footer';
import axiosInstance from '../../../../utils/axios';
import FormRegister from '../../../../components/form-register';

// lista para breadcrounb
const listBreadcrumbs = [
  {
    title: 'Inicio',
    url: '/',
  },
  {
    title: '1. Carga de información general',
  },
];

export default function LoadMissingInformation2() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [idTemp, setIdTemp] = useState(Math.floor(1 + Math.random() * (99999 - 1)));

  const [estadosState, setEstadosState] = useState([]);
  const [municipiosState, setMunicipiosState] = useState([]);
  const [coloniasState, setColoniasState] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        const response = await axiosInstance.get(`providers/info/iam`);
        const estados = await axiosInstance.get(`utils/estados`);

        reset(response.data);
        setEstadosState(estados.data);

        if (response.data.opinion_fulfillment_sat === null) setValue('opinion_fulfillment_sat', '');
        if (response.data.bank_face === null) setValue('bank_face', '');
        if (response.data.proof_address === null) setValue('proof_address', '');
        if (response.data.proof_tax_situation === null) setValue('proof_tax_situation', '');
        if (response.data.constitutive_act === null) setValue('constitutive_act', '');
        if (response.data.opinion_fulfillment_tax_obligations === null)
          setValue('opinion_fulfillment_tax_obligations', '');
        if (response.data.power_attorney === null) setValue('power_attorney', '');
        if (response.data.identification_legal_representative_front === null)
          setValue('identification_legal_representative_front', '');
        if (response.data.identification_legal_representative_reverse === null)
          setValue('identification_legal_representative_reverse', '');

        if (response.data.address_state) {
          const delegations = await axiosInstance.get(`utils/municipios/${response.data.address_state}`);
          const suburbs = await axiosInstance.get(`utils/colonias/${response.data.address_delegation}`);

          setMunicipiosState(delegations.data);
          setColoniasState(suburbs.data);
          let dele = delegations.data.filter(e => e.id.toString() === response.data.address_delegation)[0];
          if (dele) setValue('address_delegation', { value: dele.id, label: dele.nombre });

          let sub = suburbs.data.filter(e => e.id.toString() === response.data.address_suburb)[0];
          if (sub) setValue('address_suburb', { value: sub.id, label: sub.nombre });
        }
        let esta = estados.data.filter(e => e.id.toString() === response.data.address_state)[0];
        if (esta) setValue('address_state', { value: esta.id, label: esta.nombre });
        if (response.number_employees)
          setValue('number_employees', { value: response.data.number_employees, label: response.number_employees });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        // Error 😨
        if (error && error.response && error.response.data) {
          if (error.response.data.error) {
            toast.error(error.response.data.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
          } else {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
          }
        } else {
          toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
        }
      }
    }
    fetch();
  }, []);

  const onSubmit = async values => {
    const data = {
      ...values,
      address_state: getValues('address_state').value,
      address_delegation: getValues('address_delegation').value,
      address_suburb: getValues('address_suburb').value,
      number_employees: getValues('number_employees') !== null ? getValues('number_employees').value : '',
      id_temp: idTemp,
    };
    try {
      setLoading(true);
      await axiosInstance.post('providers/info/iam', data);
      var user = JSON.parse(localStorage.getItem('user'));
      user.notLoad = false;
      localStorage.setItem('user', JSON.stringify(user));
      setLoading(false);
      history.push('/provider/profile');
    } catch (error) {
      console.log(error);
      console.log(error.response);
      // Error 😨
      if (error.response) {
        if (error.response.data.error) {
          toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
        } else {
          toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
        }
      } else {
        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header
        listBreadcrumbs={listBreadcrumbs}
        title='1. Información general'
        icon={img_icon_information}
        clear={true}
      />
      {loading ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <FormRegister
            estados={estadosState}
            municipios={municipiosState}
            colonias={coloniasState}
            register={register}
            errors={errors}
            control={control}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            getValues={getValues}
            setValue={setValue}
            setError={setError}
            idTemp={idTemp}
          />
          <Footer />
        </>
      )}
    </>
  );
}
