import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from "react-router-dom";

// importar estilos
import '../../../../register/register-provider/register-provider.css';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header";
import FormProduct from "../form-product/FormProduct";

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Catálogo',
        url: '/super_admin/products/list'
    },
    {
        title: 'Editar producto'
    }
];

class EditProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: this.props.match.params,
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            listSubcategories: [],
            formProduct: {
                description: '',
                product_explanation: '',
                product_subcategory_id: '',
                product_brand: '',
                variety: '',
                packing: '',
                barcode_piece: '',
                image_download_link: '',
                grammage: '',
                unit: '',
                barcode_box: '',
                sat_key: '',
                refill_type: '',
                gross_cost_piece: '',
                ieps: '',
                status: ''
            }
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const product_subcategories = await axiosInstance.get(`product_subcategories`,);
                const product = await axiosInstance.get(`products/${this.state.params.id}`,);

                const listSubcategories = product_subcategories.data.map((subcategory) => { return {value: subcategory.id, label: subcategory.name}});
                
                this.setState({
                    ...this.state,
                    listSubcategories,
                    formProduct: {
                        ...product.data,
                        status: 'pending',
                        product_brand: product.data.product_brand.name
                    },
                    loading: false
                })
            } catch (error) {
                console.log(error);
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            }
        });
    }

    handleSaveInfo = (state) => {
        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.put(`products/${state.formProduct.id}`, state.formProduct);
                    toast.info("Producto modificado con éxito")
                    this.setState({ loading: false })
                } catch (error) {
                    console.log(error);
                    console.log(error.response);
                    // Error 😨
                    if (error.response) {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }
    }

    render() {
        return (
            <>
                <HeaderProvider
                    title="Editar producto"
                    user={this.state.user}
                    view='edit-product'
                    type='detail'
                    listBreadcrumbs={listBreadcrumbs} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <FormProduct state={this.state} handleSaveInfo={this.handleSaveInfo} textButton='Guardar cambios' editCost='false'/>
                        <Footer />
                    </>
                )}
                <ToastContainer />

            </>
        );
    }

    componentDidMount() {
        this.loadInfoData();
    }

}

export default EditProduct;