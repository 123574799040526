import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';

// importaciones
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';

// images
import img_icon_information from '../../../assets/images/icon-information.png';
import img_icon_products from '../../../assets/images/icon-products.png';
import img_check_success from '../../../assets/images/check-success.png';

// importar estilos
import './home.css';


// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Inicio',
        url: '/'
    },
    {
        title: 'Registro',
    }
];


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRegisterProvider: localStorage.getItem('isRegisterProvider') ? localStorage.getItem('isRegisterProvider') : false,
            isLoadProducts: localStorage.getItem('isLoadProducts') ? localStorage.getItem('isLoadProducts') : false,
        }
    }

    handleRegisterProvider = (data) => {
        if (data)
            this.props.history.push("/register/provider");
    }

    handleLoadProducts = (data) => {
        if (data)
            this.props.history.push("/register/load-products");
    }

    handleFinish = () => {
        this.props.history.push("/register/finish");
    }

    render() {
        return (
            <>
                <Header listBreadcrumbs={listBreadcrumbs} type='home' />
                <Container maxWidth="md">
                    <div className="container-home">
                        <div className="center-card">
                            <div className="card-home" onClick={(e) => this.handleRegisterProvider(!this.state.isRegisterProvider)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} >
                                        <img src={img_icon_information} alt="1- Información general" className="imgRegister" />
                                    </Grid>
                                    <Grid item xs={10} >
                                        <div className="title-card">
                                            1- Información general
                                        </div>
                                        {this.state.isRegisterProvider ? (
                                            <>
                                                <div className="button-card-success">
                                                    Completado <img className="img" src={img_check_success} alt="Completado" />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="button-card">
                                                    Completar →
                                                </div>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div className="center-card">
                            <div className={`card-home ${this.state.isRegisterProvider ? "" : "card-home-disable"}`} onClick={(e) => this.handleLoadProducts(this.state.isRegisterProvider && !this.state.isLoadProducts)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} >
                                        <img src={img_icon_products} alt="2 - Alta de productos" />
                                    </Grid>
                                    <Grid item xs={10} >
                                        <div className="title-card">
                                            2 - Alta de productos
                                        </div>
                                        {this.state.isLoadProducts ? (
                                            <>
                                                <div className="button-card-success">
                                                    Completado <img className="img" src={img_check_success} alt="Completado" />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="button-card">
                                                    Completar →
                                                </div>
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>


                        <button className={`button-finish ${this.state.isRegisterProvider && this.state.isLoadProducts ? 'button-finish-active' : ''}`} disabled={!(this.state.isRegisterProvider && this.state.isLoadProducts)} onClick={(e) => this.handleFinish()}>
                            <div className="text"> Finalizar Registro</div>
                        </button>
                    </div>
                </Container>
                <Footer />
            </>
        );
    }
}

export default HomePage;