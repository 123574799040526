import Tooltip from '@material-ui/core/Tooltip';

export default function ActionTable({ title, icon, handleClick, id, status = '', role = '' }) {
  return (
    <div className='icon-action-provider'>
      <Tooltip title={title} className='tooltip' onClick={_ => handleClick(id, status, role)}>
        <img src={icon} alt='' srcset='' width={24} height={24} />
      </Tooltip>
    </div>
  );
}
