import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { useState } from 'react';

export default function InputSelectMultiple({ errors, register, name, placeholder, required = true, options, control }) {

    const [openStyle, setOpenStyle] = useState('');

    const handleOnChange = (values) => {
        const open = values.length > 3 && values.length <= 5
            ? 'input-select-open1' : values.length > 5 && values.length <= 7
                ? 'input-select-open2' : values.length > 7 && values.length <= 9
                    ? 'input-select-open3' : values.length > 9 && values.length <= 12
                        ? 'input-select-open4' : values.length > 12 && values.length <= 14
                            ? 'input-select-open5' : values.length > 14
                                ? 'input-select-open6' : '';
        setOpenStyle(open);
    }

    return (
        <>
            <Controller
                name={name}
                control={control}
                {...register(name, {
                    required: {
                        value: required,
                        message: 'Este campo es requerido',
                    },
                })}
                render={({ field }) => (
                    <Select
                        {...field}
                        placeholder={placeholder}
                        options={options}
                        isClearable
                        isSearchable
                        isMulti
                        onChange={(e) => {
                            field.onChange(e);
                            handleOnChange(e);
                        }}
                        className={openStyle}
                    />
                )}
            />
            {errors[`${name}`] && (
                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                    {errors[`${name}`].message}
                </p>
            )}
        </>
    )
}