import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

// images
import img_close_modal from '../../../../../assets/images/close-modal.png';
import img_file_load from '../../../../../assets/images/file_load.png';
import img_remove_file from '../../../../../assets/images/icon-remove-file.png';

export default function ModalApprovedOC(props) {

    const [file, setFile] = useState(props.init);
    const [error, setError] = useState(props.init);
    const [idOC, setIdOC] = useState("");

    const handleCleanForm = () => {
        setFile('');
        document.getElementById('selectedFile').value = null;
    }

    const handleChangeFormFileImport = (event) => {
        setFile(event.target.files[0]);
        setError('');
    }

    const onSave = (event) => {
        if (file !== '') {
            props.onSave(file, idOC);
            handleCleanForm();
        } else {
            setError('Este campo es obligatorio');
        }
    }

    return (
        <>
            <Modal
                {...props}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modal-content"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            ><Fade in={props.open}>
                    <div className="modal-delete">
                        <div className="icon-close" onClick={props.onClose}>
                            <div className="title-modal-provider">
                                Aprobar orden de compra
                                <img src={img_close_modal} alt="Cerrar" />
                            </div>
                        </div>
                        <div className="documents-modal-provider">
                            <div className="container-load-products container-modal-detele">
                                <Grid container spacing={5}>
                                    {/* <Grid item xs={12}>
                                        <div className="label-register">
                                            ID de orden de compra *
                                        </div>
                                        <input className="input-register" name="username" type="text" />
                                    </Grid> */}

                                    <Grid item xs={12}>
                                        <Grid item xs={12} style={{marginBottom:15}}>
                                            <div className="label">
                                                ID de orden de compra*
                                            </div>
                                            <input
                                                className="input-oc"
                                                value={idOC}
                                                onChange={(e) => setIdOC(e.target.value)}
                                                name="idOC"
                                                type="text"
                                                placeholder="ID de orden de compra"
                                            />
                                        </Grid>
                                        <div className="label">
                                            Sube OC en formato PDF diligenciado
                                        </div>

                                        <input
                                            id="selectedFile"
                                            type="file"
                                            name="file"
                                            accept="application/pdf"
                                            onChange={(e) => handleChangeFormFileImport(e)}
                                        />

                                        {file.name ? (
                                            <>
                                                <div className="button-load-file antes-nota" style={{ width: '460px' }}>
                                                    <Grid container >
                                                        <Grid item xs={11}>
                                                            <div className="text-load-modal">
                                                                {file.name}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <button className="img-button-modal img-button-load" onClick={handleCleanForm}>
                                                                <img src={img_remove_file} alt="Eliminar" />
                                                            </button>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <button className="button-load-products-file antes-nota" style={{ width: '460px' }} onClick={(e) => document.getElementById('selectedFile').click()}>
                                                    <Grid container >
                                                        <Grid item xs={1}>
                                                            <div className="img-button-not-loaded">
                                                                <img src={img_file_load} alt="Cargar" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <div className="text-button text-button-not-loaded">
                                                                <span className="text-underline-load-products">Selecciónalo desde tu dispositivo</span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </button>
                                            </>
                                        )}
                                        {error && (
                                            <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                {error}
                                            </p>
                                        )}
                                        <br />
                                        <span className="nota"><strong>Nota:</strong>La OC debe ser formato PFD</span>
                                    </Grid>
                                </Grid>


                                <Grid container spacing={5}>
                                    <Grid item xs={6}>
                                        <div className="container-btn-save" onClick={props.onClose}>
                                            <button className="button-cancel">
                                                Cancelar
                                            </button>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <div className="container-btn-save">
                                            <button className="button-delete" onClick={onSave}>
                                                Aceptar
                                            </button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}