import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import NativeSelect from '@material-ui/core/NativeSelect';
import { Container, Grid } from '@material-ui/core';
import Select from 'react-select';

// estilos
import './new-incidence.css';

// images
import img_file_load from '../../../../../assets/images/file_load.png';
import image_icon_remove from '../../../../../assets/images/icon-remove-file.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header";

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Incidencias',
        url: '/receipt/incidences/list'
    },
    {
        title: 'Nueva incidencia'
    }
];


class NewIncidence extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listIncidences: [],
            listTopics: [],
            listSubTopics: [],
            listSubTopicsView: [],
            listOrders: [],
            oc_required: false,
            formIncidence: {
                title: '',
                date: '',
                topic_id: '',
                subtopic_id: '',
                purchase_order_file: '',
                purchase_order_id: '',
                evidence_file: '',
                description: ''
            },
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            isRequieredOc: false,
            maxDate: new Date().toISOString().split("T")[0],
            selectRef: null
        }
    }

    handleChangeOrders = (event) => {

    }

    handleChangeSubTopic = (value) => {
        const subtopic = this.state.listSubTopics.find((element) => {
            return element.id === value;
        });

        if (subtopic.oc_required === 1) {
            this.setState({
                ...this.state,
                formIncidence: {
                    ...this.state.formIncidence,
                    subtopic_id: value
                },
                isRequieredOc: true
            });
        } else {
            this.setState({
                ...this.state,
                formIncidence: {
                    ...this.state.formIncidence,
                    subtopic_id: value
                },
                isRequieredOc: false
            });
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const topics = await axiosInstance.get(`topics`,);
                const ocs = await axiosInstance.get('purchase_orders?filter=&order=suggest_order_id%20DESC&page=0&size=500')
                const listTopics = topics.data.map((topic) => { return { value: topic.id, label: topic.name } });
                const listOrders = ocs.data?.orders?.map((oc)=>({ value: oc?.id, label: oc?.purchase_order_id || oc?.suggest_order_id}))

                this.setState({
                    ...this.state,
                    listTopics,
                    listOrders,
                    loading: false
                })

            } catch (error) {
                console.log(error);
            }
        });
    }

    selectFileOC = (e) => {
        document.getElementById('selectFileOC').click();
    }

    selectFileEvidence = (e) => {
        document.getElementById('selectFileEvidence').click();
    }

    handleChangeFormImage = (event) => {
        this.setState({
            formIncidence: {
                ...this.state.formIncidence,
                [event.target.name]: event.target.files[0]
            }
        });
    }

    handleChangeForm = (event) => {
        this.setState({
            formIncidence: {
                ...this.state.formIncidence,
                [event.target.name]: event.target.value
            }
        });
    }

    handleClearFile = (attr, id) => {
        this.setState({
            formIncidence: {
                ...this.state.formIncidence,
                [attr]: ''
            }
        });

        document.getElementById(id).value = null;
    }

    handleChangeFormSelect = (value, attr) => {
        if (attr.action !== 'clear') {
            if (attr.name === 'topic_id') {
                this.setState({
                    ...this.state,
                    formIncidence: {
                        ...this.state.formIncidence,
                        [attr.name]: value.value,
                    }
                });
                this.loadSubtopics(value.value);
            } else if (attr.name === 'subtopic_id') {
                this.handleChangeSubTopic(value.value)
            }
            if(attr.name === 'purchase_order_id'){
                this.setState({
                    ...this.state,
                    formIncidence: {
                        ...this.state.formIncidence,
                        [attr.name]: value.value,
                    }
                });
            }
        }

    }

    loadSubtopics = async (topic) => {
        this.clearValue();
        try {
            const subtopics = await axiosInstance.get(`subtopics/bytopic/${topic}`);
            const listSubTopicsView = subtopics.data.map((subtopic) => { return { value: subtopic.id, label: subtopic.name } });

            this.setState({
                ...this.state,
                formIncidence: {
                    ...this.state.formIncidence,
                    subtopic_id: '',
                },
                listSubTopicsView,
                listSubTopics: subtopics.data,
                loading: false
            });

        } catch (error) {
            console.log(error);
        }
    }

    clearValue = () => {
        this.state.selectRef.clearValue();
    };

    handleValidForm = () => {
        if ((this.state.formIncidence.title !== '' && this.state.formIncidence.title !== null) &&
            (this.state.formIncidence.date !== '' && this.state.formIncidence.date !== null) &&
            (this.state.formIncidence.topic_id !== '' && this.state.formIncidence.topic_id !== null) &&
            (this.state.formIncidence.subtopic_id !== '' && this.state.formIncidence.subtopic_id !== null) &&
            (this.state.formIncidence.description !== '' && this.state.formIncidence.description !== null) 
            // && (this.state.formIncidence.evidence_file !== '' && this.state.formIncidence.evidence_file !== null)
            ) {
            if (this.state.isRequieredOc) {
                if (this.state.formIncidence.purchase_order_file.name != '' && this.state.formIncidence.purchase_order_id != '0') {
                    return true;
                }
                return false;
            }
            return true
        }
        return false;
    }

    handleSave = (event) => {
        try {
            this.setState({ loading: true }, async () => {
                try {
                    event.preventDefault();
                    const formData = new FormData();
                    // agregar imagenes
                    formData.append("purchase_order_file", this.state.formIncidence.purchase_order_file);
                    formData.append("evidence_file", this.state.formIncidence.evidence_file);
                    formData.append("title", this.state.formIncidence.title);
                    formData.append("date", this.state.formIncidence.date);
                    formData.append("description", this.state.formIncidence.description);
                    formData.append("subtopic_id", this.state.formIncidence.subtopic_id);
                    formData.append("topic_id", this.state.formIncidence.topic_id);
                    formData.append("purchase_order_id", this.state.formIncidence.purchase_order_id);

                    await axiosInstance.post("/incidences", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });
                    toast.info('¡Incidencia guardada con éxito!');
                   setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.replace('/receipt/incidences/list');
                    }, 2000);
                } catch (error) {
                    // Error 😨
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            });
        } catch (e) { alert(e.message); }
    }

    render() {
        return (
            <>
                <HeaderProvider
                    title="Nueva incidencia"
                    user={this.state.user}
                    view='new-incidence'
                    type='detail'
                    listBreadcrumbs={listBreadcrumbs} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <div className="provider">
                            <Container maxWidth="sm">
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <div className='text-new-incidence'>
                                            ¡Hola! Buscaremos resolver tus incidencias de la manera más rápida y efectiva. Para ello, por favor completa la información presentada a continuación
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register label-form">
                                            Titulo de la incidencia *
                                        </div>
                                        <input className="input-register" value={this.state.formIncidence.title} onChange={(e) => this.handleChangeForm(e)} name="title" type="text" />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register label-form">
                                            Fecha de la incidencia *
                                        </div>
                                        <input className="input-register"
                                            value={this.state.formIncidence.date}
                                            onChange={(e) => this.handleChangeForm(e)}
                                            name="date"
                                            type="date"
                                            max={this.state.maxDate} />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register label-form">
                                            Departamento de la incidencia *
                                        </div>
                                        <Select
                                            id="topic_id"
                                            name="topic_id"
                                            placeholder={'Seleccione una opción'}
                                            defaultValue={
                                                this.state.listTopics.filter(option =>
                                                    option.value.toString() === this.state.formIncidence.topic_id)
                                            }
                                            options={this.state.listTopics}
                                            onChange={this.handleChangeFormSelect} />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <div className="label-register label-form">
                                            Tema relacionado *
                                        </div>
                                        <Select
                                            ref={ref => {
                                                this.state.selectRef = ref;
                                            }}
                                            id="subtopic_id"
                                            name="subtopic_id"
                                            placeholder={'Seleccione una opción'}
                                            defaultValue={
                                                this.state.listSubTopicsView.filter(option =>
                                                    option.value.toString() === this.state.formIncidence.subtopic_id)
                                            }
                                            options={this.state.listSubTopicsView}
                                            onChange={this.handleChangeFormSelect} />
                                    </Grid>
                                </Grid>
                                {this.state.isRequieredOc ? (
                                    <>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <div className="label-register label-form">
                                                    Orden de compra relacionado *
                                                </div>
                                                <Select
                                                    id="purchase_order_id"
                                                    name="purchase_order_id"
                                                    placeholder={'Seleccione una opción'}
                                                    options={this.state.listOrders}
                                                    onChange={this.handleChangeFormSelect} />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <div className="label-register label-form">
                                                    Adjunta orden de compra en PDF *
                                                </div>
                                                <input
                                                    id="selectFileOC"
                                                    type="file"
                                                    accept="application/pdf"
                                                    name="purchase_order_file"
                                                    onChange={(e) => this.handleChangeFormImage(e)}
                                                />
                                                {this.state.formIncidence.purchase_order_file !== '' ? (
                                                    <>
                                                        <div className="button-load-register">
                                                            <Grid container >
                                                                <Grid item xs={11}>
                                                                    <div className="text-load">
                                                                        {this.state.formIncidence.purchase_order_file.name}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    <div className="text-load">
                                                                        <img src={image_icon_remove} alt="" srcset="" onClick={(e) => this.handleClearFile('purchase_order_file', 'selectFileOC')} />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <button className="button-load-file-form button-load-products-file button-load-incidence" onClick={(e) => this.selectFileOC()}>
                                                            <Grid container >
                                                                <Grid item xs={1}>
                                                                    <div className="img-button-not-loaded">
                                                                        <img src={img_file_load} alt="Cargar" />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <div className="text-button text-button-not-loaded">
                                                                        <span className="text-underline-load-products">Selecciónalo desde tu dispositivo</span>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </button>
                                                    </>)}
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (<></>)}
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <div className="label-register label-form">
                                            Describe la incidencia *
                                        </div>
                                        <textarea required className="input-register text-area-form" name="description" value={this.state.formIncidence.description} onChange={(e) => this.handleChangeForm(e)}></textarea>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className='text-new-incidence text-new-incidence-not-first'>
                                        Por último, te solicitamos adjuntar evidencia de tu incidencia, esta puede ser un documento, captura de pantalla, o una imagen.
                                    </div>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <div className="label-register label-form">
                                            Adjunta evidencia
                                        </div>
                                        <input
                                            id="selectFileEvidence"
                                            type="file"
                                            name="evidence_file"
                                            onChange={(e) => this.handleChangeFormImage(e)}
                                        />
                                        {this.state.formIncidence.evidence_file !== '' ? (
                                            <>
                                                <div className="button-load-file">
                                                    <Grid container >
                                                        <Grid item xs={11}>
                                                            <div className="text-load-modal">
                                                                {this.state.formIncidence.evidence_file.name}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <div className="img-button-modal">
                                                                <img src={image_icon_remove} alt="" srcset="" onClick={(e) => this.handleClearFile('evidence_file', 'selectFileEvidence')} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <button className="button-load-products-file button-load-incidence" onClick={(e) => this.selectFileEvidence()}>
                                                    <Grid container >
                                                        <Grid item xs={1}>
                                                            <div className="img-button-not-loaded">
                                                                <img src={img_file_load} alt="Cargar" />
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                            <div className="text-button text-button-not-loaded">
                                                                Arrastra el archivo aquí o <span className="text-underline-load-products">selecciónalo desde tu dispositivo</span>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </button>
                                            </>)}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className='text-new-incidence text-end text-new-incidence-not-first'>
                                        ¡No te preocupes! Pronto nos contactaremos contigo. Por favor revisa la información proporcionada, y al confirmarla, presiona el botón de envíar.
                                    </div>
                                </Grid>
                                <div className="container-btn-save">
                                    <button className={`button-save-incidence ${this.handleValidForm() ? 'button-save-active' : ''}`} onClick={(e) => this.handleSave(e)} disabled={!this.handleValidForm()}>
                                        <div className="text">
                                            Enviar incidencia
                                        </div>
                                    </button>
                                </div>
                            </Container>
                        </div>
                        <Footer />
                    </>)
                }
                <ToastContainer />
            </>

        );
    }

    componentDidMount() {
        this.loadInfoData();
    }
}

export default NewIncidence;