import React, { useState } from 'react';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { OutlinedInput } from '@material-ui/core';

export default function InputPassword({errors, register, name, placeholder, required = true}) {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <OutlinedInput
                placeholder={placeholder}
                fullWidth
                name={name}
                type={showPassword ? 'text' : 'password'}
                {...register(name, {
                    required: {
                        value: required,
                        message: 'Este campo es requerido',
                    },
                    pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/g,
                        message: 'Ingrese contraseña nueva'
                    }
                })}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {errors[`${name}`] && (
                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                    {errors[`${name}`].message}
                </p>
            )}
        </>
    )
}