import { useState } from "react";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid } from '@material-ui/core';

//images
import img_icon_arrow_logout from '../../assets/images/icon-arrow-logout.png';

export default function StatusTable({ status, id, options, handleSelect }) {
        console.log(status)
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectItem, setSelectItem] = useState();

    const handleClick = (anchorEl, id) => {
        setAnchorEl(anchorEl.currentTarget);
        setSelectItem(id);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectOption = (event, action) => {
        setAnchorEl(null);
        handleSelect(selectItem, action);
    }

    return (
        <div>
            <div className={`status-provider status-provider-product-admin ${status === 'pending' ? 'pending' : status === 'inactive' ? 'inactive' : 'active'}`}>
                <Button className="menu-status" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => handleClick(e, id)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="estado">
                            <span className="desk item-status-provider" style={{ width: '95px', marginTop: '.1px' }}>
                                {status === 'pending' ? 'Pendiente' : status === 'inactive' ? 'Inactivo' : status === 'active' ? 'Activo' : status === 'aproved_adviser' ? 'Preaprobado' : status === 'aproved_boss' ? 'Aprobado' :'Aprobado'}
                                <img src={img_icon_arrow_logout} alt="" />
                            </span>
                            <span className="mobile item-status-provider" style={{ width: '95px' }}>
                                {status === 'pending' ? 'PE' : status === 'inactive' ? 'IN' : status === 'aproved_adviser' ? 'PA' : status === 'aproved_boss' ? 'AP' : status === 'active' ? 'AC' : 'AP'}
                                <img src={img_icon_arrow_logout} alt="" />
                            </span>
                        </Grid>
                    </Grid>
                </Button>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    {options.map((option) =>
                        <MenuItem onClick={(event) => handleSelectOption(event, option.value)}> {option.label} </MenuItem>
                    )}
                </Menu>
            </div>
        </div>
    );
}