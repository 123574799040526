import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Grid, Tab, Tabs } from '@material-ui/core';
import Select, { components } from 'react-select';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// importaciones de modulos
import Loading from '../../../../../components/loading/Loading';
import Footer from '../../../../../components/footer/Footer';
import axiosInstance from '../../../../../utils/axios';
import FormProvider from '../../../../../components/form-provider/FormProvider';
import HeaderAdmin from '../../header/Header';

import { initAxiosInterceptor } from '../../../../../utils/auth-herpers';
// importar estilos
import '../../../../register/register-provider/register-provider.css';
import './edit-provider.css';

// images
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import img_icon_arrow_logout from '../../../../../assets/images/icon-arrow-logout.png';
import ListUserProvider from '../../../../../components/list-users-providers';

const listSchedules = [
  { value: 'Horarios (7am - 5pm)', label: 'Horarios (7am - 5pm)' },
  { value: 'Matutino 7am - 10am', label: 'Matutino 7am - 10am' },
];

const MultiValueRemove = props => {
  if (props.data.isFixed) {
    return null;
  }
  return <components.MultiValueRemove {...props} />;
};

initAxiosInterceptor();

class EditProviderManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      params: this.props.match.params,
      user: { ...JSON.parse(localStorage.getItem('user')), role: null },
      loading: false,
      disabledSubmitButton: true,
      listStates: [],
      listDelegations: [],
      listSuburbs: [],
      listDepartments: [],
      listInsightaPlans: [],
      listWarehouses: [],
      listBreadcrumbs: [
        {
          title: 'Proveedores',
          url: '/super_admin/providers/list',
        },
        {
          title: '',
        },
      ],
      tabValue: 0,
      cantUsers: 0,
      formProvider: {
        id_temp: Math.floor(1 + Math.random() * (99999 - 1)),
        provider_id: '',
        business_name: '',
        rfc: '',
        // account_clabe: '',
        // errorAccountClabe: false,
        departments: [],
        warehouses: [],
        provider_insight_plans: [],
        schedule: '',
        // textErrorAccountClabe: 'El número de cuenta clabe debe tener mínimo 11 dígitos',
        // account_bank: '',
        // account_number: '',
        // errorAccountNumber: false,
        // textErrorAccountNumber: 'El número de cuenta debe tener mínimo 6 dígitos',
        username: '',
        email: '',
        errorEmail: false,
        textErrorEmail: 'El correo no es válido',
        password: '',
        passwordErrorMessage: '',
        passwordValid: false,
        showPassword: false,
        confirm_password: '',
        confirmPasswordErrorMessage: '',
        showConfirmPassword: false,
        confirmPasswordValid: false,
        contact_business_email: '',
        errorContactBusinessEmail: false,
        textErrorContactBusinessEmail: 'El correo no es válido',
        contact_business_phone: '',
        contact_business_name: '',
        contact_business_workstation: '',
        contact_logistic_email: '',
        contact_logistic_phone: '',
        contact_logistic_name: '',
        contact_logistic_workstation: '',
        contact_financial_email: '',
        contact_financial_phone: '',
        contact_financial_name: '',
        contact_financial_workstation: '',
        address_street: '',
        address_number_exterior: '',
        address_number_interior: '',
        address_suburb: '',
        address_delegation: '',
        address_postcode: '',
        address_city: '',
        address_state: '',
        number_employees: '',
        company_classification: '',
        credit_days: 0,
        name_legal_representative: '',
        curp_legal_representative: '',
        status: '',
      },
      form: {
        // bank_face: '', //file
        // bank_face_percent: '',
        // opinion_fulfillment_sat: '', //file
        // opinion_fulfillment_sat_percent: '',
        power_attorney: '', //file
        power_attorney_percent: '', //file
        opinion_fulfillment_tax_obligations: '', //file
        opinion_fulfillment_tax_obligations_percent: '', //file
        identification_legal_representative_front: '', //file
        identification_legal_representative_front_percent: '', //file
        identification_legal_representative_reverse: '', //file
        identification_legal_representative_reverse_percent: '', //file
        proof_address: '', //file
        proof_address_percent: '', //file
        proof_tax_situation: '', //file
        proof_tax_situation_percent: '', //file
        constitutive_act: '', //file
        constitutive_act_percent: '', //file
      },
    };
  }

  handleDisabledSaveButton = value => {
    this.setState({ disabledSubmitButton: value });
  };

  handleClick = anchorEl => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleSelect = (event, status) => {
    this.setState({
      ...this.state,
      disabledSubmitButton: false,
      anchorEl: null,
      formProvider: {
        ...this.state.formProvider,
        status,
      },
    });
  };

  loadInfoData = () => {
    this.setState({ loading: true }, async () => {
      try {
        const get = await axiosInstance.get(`providers/${this.state.params.id}`);
        const states = await axiosInstance.get(`utils/estados`);

        let listDelegations = [];
        let listSuburbs = [];

        if (get.data.address_state) {
          const delegations = await axiosInstance.get(`utils/municipios/${get.data.address_state}`);
          const suburbs = await axiosInstance.get(`utils/colonias/${get.data.address_delegation}`);

          listDelegations = delegations.data.map(delegation => {
            return { value: delegation.id, label: delegation.nombre };
          });
          listSuburbs = suburbs.data.map(suburb => {
            return { value: suburb.id, label: suburb.nombre };
          });
        }
        const departments = await axiosInstance.get(`departments`);
        const warehouses = await axiosInstance.get(`warehouses`);
        const insight_plans = await axiosInstance.get(`insight_plans`);

        const listStates = states.data.map(state => {
          return { value: state.id, label: state.nombre };
        });
        const listDepartments = departments.data.map(department => {
          return { value: department.id, label: department.name };
        });
        const listWarehouses = warehouses.data.map(warehosuse => {
          return { value: warehosuse.id, label: warehosuse.initials };
        });
        const listInsightaPlans = insight_plans.data.map(insight_plan => {
          return {
            value: insight_plan.id,
            label: insight_plan.name,
            isFixed: insight_plan.name === 'Hermes' ? true : false,
          };
        });

        let temp = this.state.listBreadcrumbs;
        temp[1].title = get.data.business_name;

        this.setState({
          ...this.state,
          listStates,
          listDelegations,
          listSuburbs,
          listDepartments,
          listWarehouses,
          listInsightaPlans,
          formProvider: {
            ...this.state.formProvider,
            ...get.data,
            password: '',
          },
          form: {
            ...this.state.form,
            ...get.data,
          },
          listBreadcrumbs: temp,
          loading: false,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ loading: false });
      }
    });
  };

  saveInfoProviderByAdviser = data => {
    let stateLocal = this.state;
    let combinedData = {
      ...data,
      formProvider: {
        ...data.formProvider,
        departments: stateLocal.formProvider.departments,
        warehouses: stateLocal.formProvider.warehouses,
        provider_insight_plans: stateLocal.formProvider.provider_insight_plans,
        credit_days: stateLocal.formProvider.credit_days,
        schedule: stateLocal.formProvider.schedule,
        status: stateLocal.formProvider.status,
      },
    };

    this.saveInfo(combinedData);
  };

  saveInfoHeader = data => {
    this.saveInfo(this.state);
  };

  handleCantUsers = cant => {
    this.setState({
      ...this.state,
      cantUsers: cant,
    });
  };

  handleChangeTab = (event, value) => {
    this.setState({
      ...this.state,
      tabValue: value,
    });
  };

  saveInfo = data => {
    try {
      this.setState({ loading: true }, async () => {
        try {
          await axiosInstance.put(`providers/updateForAdmin/${data.formProvider.id}`, data.formProvider);

          this.setState({ loading: false });

          toast.success('¡Proveedor modificado con éxito!');
          this.loadInfoData();
        } catch (error) {
          console.log(error.response);
          // Error 😨
          if (error.response) {
            if (error.response.data?.msg) toast.error(error.response.data?.msg);
            else toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');

            this.setState({ ...this.state, loading: false });
          } else {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          }
        }
      });
    } catch (e) {
      alert(e.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <>
        <HeaderAdmin
          title={this.state.formProvider.business_name}
          icon={icon_list_products}
          user={this.state.user}
          view={this.state.tabValue === 0 ? 'edit-provider' : 'empresa'}
          type='detail'
          listBreadcrumbs={this.state.listBreadcrumbs}
          disabledSubmitButton={false}
          handleSaveInfo={this.saveInfoHeader}
          isUsers={this.state.tabValue === 0 ? false : this.state.cantUsers > 3 ? false : true}
          providerId={this.state.params.id}
        />
        {this.state.loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Container>
              <Tabs
                variant='standard'
                value={this.state.tabValue}
                onChange={this.handleChangeTab}
                indicatorColor='primary'
                textColor='primary'
                className='bgtabs'
              >
                <Tab label='Información' />
                <Tab label='Usuarios' />
              </Tabs>
              {this.state.tabValue === 0 ? (
                <>
                  <Container maxWidth='md'>
                    <div className='section-register'>
                      <Grid container spacing={5}>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>ID</div>
                          <input
                            className='input-register'
                            name='provider_id'
                            type='text'
                            value={this.state.formProvider.provider_id}
                            onChange={e => {
                              this.handleDisabledSaveButton(false);
                              this.setState({
                                formProvider: { ...this.state.formProvider, provider_id: e.target.value },
                              });
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>Estado</div>
                          <div>
                            <div
                              className={`status ${
                                this.state.formProvider.status === 'pending'
                                  ? 'pending'
                                  : this.state.formProvider.status === 'inactive'
                                  ? 'inactive'
                                  : 'active'
                              }`}
                            >
                              <>
                                <Button
                                  className='menu-status'
                                  aria-controls='simple-menu'
                                  aria-haspopup='true'
                                  onClick={e => this.handleClick(e)}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} className='estado'>
                                      <span className='desk'>
                                        {this.state.formProvider.status === 'pending'
                                          ? 'Pendiente'
                                          : this.state.formProvider.status === 'inactive'
                                          ? 'Inactivo'
                                          : this.state.formProvider.status === 'aproved_adviser'
                                          ? 'Aprobado'
                                          : 'Activo'}
                                        <img src={img_icon_arrow_logout} alt='' />
                                      </span>
                                      <span className='mobile'>
                                        {this.state.formProvider.status === 'pending'
                                          ? 'P'
                                          : this.state.formProvider.status === 'inactive'
                                          ? 'I'
                                          : this.state.formProvider.status === 'aproved_adviser'
                                          ? 'AP'
                                          : 'AC'}
                                        <img src={img_icon_arrow_logout} alt='' />
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Button>
                                <Menu
                                  id='long-menu'
                                  anchorEl={this.state.anchorEl}
                                  keepMounted
                                  open={Boolean(this.state.anchorEl)}
                                  onClose={e => this.handleClose(e)}
                                >
                                  <MenuItem onClick={event => this.handleSelect(event, 'active')}> Activo </MenuItem>
                                  <MenuItem onClick={event => this.handleSelect(event, 'inactive')}>
                                    {' '}
                                    Inactivo{' '}
                                  </MenuItem>
                                  <MenuItem onClick={event => this.handleSelect(event, 'aproved_adviser')}>
                                    {' '}
                                    Aprobado{' '}
                                  </MenuItem>
                                  <MenuItem onClick={event => this.handleSelect(event, 'pending')}>
                                    {' '}
                                    Pendiente{' '}
                                  </MenuItem>
                                </Menu>
                              </>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>Departamentos</div>
                          <Select
                            id='address_state'
                            name='address_state'
                            placeholder={'Seleccione una opción'}
                            defaultValue={() =>
                              this.state.formProvider?.departments.map(v => ({
                                value: v.department.id,
                                label: v.department.name,
                              }))
                            }
                            options={this.state.listDepartments}
                            onChange={values => {
                              this.handleDisabledSaveButton(false);
                              const open =
                                this.state.formProvider.departments.length > 3 &&
                                this.state.formProvider.departments.length <= 5
                                  ? 'input-select-open1'
                                  : this.state.formProvider.departments.length > 5 &&
                                    this.state.formProvider.departments.length <= 7
                                  ? 'input-select-open2'
                                  : this.state.formProvider.departments.length > 7 &&
                                    this.state.formProvider.departments.length <= 9
                                  ? 'input-select-open3'
                                  : this.state.formProvider.departments.length > 9 &&
                                    this.state.formProvider.departments.length <= 12
                                  ? 'input-select-open4'
                                  : this.state.formProvider.departments.length > 12 &&
                                    this.state.formProvider.departments.length <= 14
                                  ? 'input-select-open5'
                                  : this.state.formProvider.departments.length > 14
                                  ? 'input-select-open6'
                                  : '';
                              this.setState({
                                formProvider: {
                                  ...this.state.formProvider,
                                  departments: values.map(dp => ({ department: { id: dp.value, name: dp.label } })),
                                  openDepartments: open,
                                },
                              });
                            }}
                            className={this.state.formProvider.openDepartments}
                            isMulti
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>Bodega(s)</div>
                          <Select
                            id='address_state'
                            name='address_state'
                            placeholder={'Seleccione una opción'}
                            defaultValue={this.state.formProvider?.warehouses?.map(v => ({
                              value: v.warehouse.id,
                              label: v.warehouse.initials,
                            }))}
                            options={this.state.listWarehouses}
                            onChange={values => {
                              this.handleDisabledSaveButton(false);
                              this.setState({
                                formProvider: {
                                  ...this.state.formProvider,
                                  warehouses: values.map(wh => ({ warehouse: { id: wh.value, name: wh.label } })),
                                },
                              });
                            }}
                            isMulti
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>Días de crédito</div>
                          <input
                            className='input-register'
                            name='credit_days'
                            type='number'
                            value={this.state.formProvider.credit_days}
                            onChange={e => {
                              this.handleDisabledSaveButton(false);
                              this.setState({
                                formProvider: { ...this.state.formProvider, credit_days: e.target.value },
                              });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>Horario para entregas</div>
                          <Select
                            id='schedule_id'
                            name='schedule_id'
                            placeholder={'Seleccione una opción'}
                            defaultValue={
                              this.state.formProvider.schedule && {
                                value: this.state.formProvider.schedule,
                                label: this.state.formProvider.schedule,
                              }
                            }
                            options={listSchedules}
                            onChange={data => {
                              this.handleDisabledSaveButton(false);
                              this.setState({
                                formProvider: {
                                  ...this.state.formProvider,
                                  schedule: data.value,
                                },
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={5}>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className='label-register'>Tableros insights</div>
                          <Select
                            id='insight_plans'
                            name='insight_plans'
                            placeholder={'Seleccione una opción'}
                            defaultValue={() =>
                              this.state.formProvider?.provider_insight_plans.map(i => ({
                                value: i.insight_plan.id,
                                label: i.insight_plan.name,
                                isFixed: i.insight_plan.name === 'Hermes' ? true : false,
                              }))
                            }
                            options={this.state.listInsightaPlans}
                            onChange={values => {
                              this.handleDisabledSaveButton(false);
                              const open =
                                this.state.formProvider.provider_insight_plans.length > 3 &&
                                this.state.formProvider.provider_insight_plans.length <= 5
                                  ? 'input-select-open1'
                                  : this.state.formProvider.provider_insight_plans.length > 5 &&
                                    this.state.formProvider.provider_insight_plans.length <= 7
                                  ? 'input-select-open2'
                                  : this.state.formProvider.provider_insight_plans.length > 7 &&
                                    this.state.formProvider.provider_insight_plans.length <= 9
                                  ? 'input-select-open3'
                                  : this.state.formProvider.provider_insight_plans.length > 9 &&
                                    this.state.formProvider.provider_insight_plans.length <= 12
                                  ? 'input-select-open4'
                                  : this.state.formProvider.provider_insight_plans.length > 12 &&
                                    this.state.formProvider.provider_insight_plans.length <= 14
                                  ? 'input-select-open5'
                                  : this.state.formProvider.provider_insight_plans.length > 14
                                  ? 'input-select-open6'
                                  : '';
                              this.setState({
                                formProvider: {
                                  ...this.state.formProvider,
                                  provider_insight_plans: values.map(ip => ({
                                    insight_plan: { id: ip.value, name: ip.label },
                                  })),
                                  openInsightPlans: open,
                                },
                              });
                            }}
                            isClearable={false}
                            components={{ MultiValueRemove }}
                            className={this.state.formProvider.openInsightPlans}
                            isMulti
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Container>
                  <FormProvider
                    handleSaveInfo={this.saveInfoProviderByAdviser}
                    state={this.state}
                    disabledSubmitButton={false}
                    handleDisabledSubmitButton={this.handleDisabledSaveButton}
                    hiddenPassword='true'
                    titleButton='Guardar cambios'
                  />
                </>
              ) : (
                <ListUserProvider onCantUsers={this.handleCantUsers} providerId={this.state.params.id} />
              )}
            </Container>
            <Footer />
          </>
        )}
      </>
    );
  }

  componentDidMount() {
    this.loadInfoData();
  }
}

export default EditProviderManager;
