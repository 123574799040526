import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Container, Grid } from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';

// imports
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header";
import { currencyFormat } from '../../../../../utils/pipes';
import ModalSheduleDelivery from './SheduleDelivery';

export default function PackList(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

    const [listProductsApi, setListProductsApi] = useState([]);
    const [listProductSelect, setListProductSelect] = useState([]);
    const [order, setOrder] = useState([]);

    const[listDataToModal, setListDataToModal] = useState([]);

    const [loading, setLoading] = useState(false);
    const [openModaleSheduleDelivery, setOpenModaleSheduleDelivery] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues
    } = useForm();

    const history = useHistory();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`products/ocs/iam?filter=&order=description ASC&page=0&size=5000`,);
            let responseOrder = await axiosInstance.get(`purchase_orders/${props.match.params.id}`,);
            setOrder(responseOrder.data);
            setListProductsApi(response.data.products);
        }

        fetch();
    }, []);

    useEffect(() => {
        if (order.id) {
            console.log(order);

            let listPro = [];
            for (let index = 0; index < order.products.length; index++) {
                const element = order.products[index];
                const product = listProductsApi.find((item) => item.id === element.product_id);
                listPro.push({ ...product, uOrder: element.cant, cant_delivered: element.cant_delivered });
            }
            setListProductSelect(listPro);
            setLoading(false);
        }
    }, [order.i, listProductsApi.length]);

    //listas para breadcrumbs
    const listBreadcrumbs = [
        {
            title: 'Órdenes de compra',
            url: '/ocs/orders/list'
        },
        {
            title: `Orden ${order.purchase_order_id ? order.purchase_order_id : ''}`
        }
    ];

    const onSubmit = (values) => {
        const { cant } = values;
        let data = [];
        for (let index = 0; index < cant.length; index++) {
            data.push({
                product_id: listProductSelect[index].id,
                department: listProductSelect[index].product_subcategory?.product_category?.department?.id,
                cant: cant[index]
            })
        }

        setListDataToModal(data);
        setOpenModaleSheduleDelivery(true);
    }

    const handleSaveData = async (info) => {

        setOpenModaleSheduleDelivery(false);
        const { cant } = getValues();


        let data = [];
        for (let index = 0; index < cant.length; index++) {
            data.push({
                product_id: listProductSelect[index].id,
                department: listProductSelect[index].product_subcategory?.product_category?.department?.id,
                cant: cant[index]
            })
        }

        const dataInfo = {
            ...info,
            purchase_order_id: order.id,
            products: data
        }

        try {
            setLoading(true);
            await axiosInstance.post('scheduled_deliveries', dataInfo);
            toast.info('Entrega programada creada con éxito!');
            setTimeout(() => {
                history.push("/provider/deliveries/list");
                setLoading(false);
            }, 2000);
        } catch (error) {
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                setLoading(false);
            }
        }
    }

    const handleCloseModal = () => {
        setOpenModaleSheduleDelivery(false);
    }

    const isEven = (score) => !isNaN(score);

    const rowsProductsList = listProductSelect.map((product, index) => {
        return (
            <div className="row-table row-body" key={product.id}>
                <Grid container spacing={1}>
                    <Grid item xs={8} md={3}>
                        <div className="item-provider" style={{ paddingTop: '20px' }} >
                            {product.description}
                        </div>
                    </Grid>
                    <Grid item xs={2} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            #{product.upc}
                        </div>
                    </Grid>
                    <Grid item xs={2} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.product_subcategory?.product_category?.department?.name}
                        </div>
                    </Grid>
                    <Grid item xs={1} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.net_unit_cost ? currencyFormat(product?.net_unit_cost) : ''}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.net_unit_cost ? 'IVA-IEPS' : ''}
                        </div>
                    </Grid>
                    <Grid item xs={1} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.uOrder}
                        </div>
                    </Grid>
                    <Grid item xs={1} className="mobilNone">
                        <div className="item-provider" style={{ paddingTop: '20px' }}>
                            {product.cant_delivered}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider">
                            <div className="item-provider">
                                <Controller
                                    name={`cant[${index}]`}
                                    control={control}
                                    {...register(`cant[${index}]`,
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido',
                                            },
                                            min: {
                                                value: 0,
                                                message: 'Valor mínimo permitido 0',
                                            },
                                            max: {
                                                value: (product.uOrder - product.cant_delivered),
                                                message: `Valor máximo permitido ${(product.uOrder - product.cant_delivered)}`,
                                            },
                                            validate: isEven,
                                        })
                                    }
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            className="input-register"
                                        />
                                    )}
                                />
                                {errors.cant && (
                                    errors.cant[index]?.type === "validate" && (
                                        <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                            El campo solo admite números
                                        </p>
                                    )
                                )}

                                {errors.cant && (
                                    errors.cant[index] && (
                                        <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                            {errors.cant[index].message}
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    });

    return (
        <>
            <HeaderProvider
                title={`Orden ${order.purchase_order_id ? order.purchase_order_id : ''}`}
                user={user}
                view='edit-order'
                type='detail'
                listBreadcrumbs={listBreadcrumbs} />

            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <div className="body-provider">
                        <Container>
                            <div className="row-table row-title">
                                <Grid container spacing={1}>
                                    <Grid item xs={5} md={3}>
                                        <div className="title-table-orders">
                                            Nombre del producto
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className="title-table-orders mobilNone">
                                            UPC
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className="title-table-orders mobilNone">
                                            Departamento
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            precio
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            IMPUESTOS*
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            u. pedidas
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            u. entregadas
                                        </div>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <div className="title-table-orders">
                                            u. A entregar
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="providers">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {rowsProductsList}
                                    <button className="btnDetails rigth">
                                        <div className="text">
                                            Continuar con fecha de entrega
                                        </div>
                                    </button>
                                </form>
                            </div>

                        </Container>
                    </div>
                    <Footer />
                </>
            )}
            <ModalSheduleDelivery
                open={openModaleSheduleDelivery}
                onClose={handleCloseModal}
                handleSaveData={handleSaveData}
                purchaseOrderId={`${order.purchase_order_id ? order.purchase_order_id : ''}`} 
                listDataToModal={listDataToModal}/>
            <ToastContainer />
        </>

    );
}