import React from 'react'
import { useHistory } from 'react-router';

import './topnav.css'

import logo_image from '../../assets/images/logo192.png'

import Logo from '../icons/logo'


const curr_user = {
    display_name: 'Justo',
    image: logo_image
}

const Topnav = () => {

    const history = useHistory();

    return (
        <div className='topnav'>
            <div className="topnav__logo" onClick={(e) => history.push('/')}>
                <Logo />
            </div>
        </div>
    )
}

export default Topnav