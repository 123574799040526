import React, { Component } from "react";
import { Container, Grid } from '@material-ui/core';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import { ToastContainer, toast } from 'react-toastify';
import ExportReact from 'react-export-excel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';

// importar estilos
import './list-orders.css';

// imagenes
import icon_list_orders from '../../../../../assets/images/icon-list-orders.png';
import img_icon_export from '../../../../../assets/images/icon-export.png';
import img_icon_upload from '../../../../../assets/images/icon-upload-file.png';
import img_icon_cancel from '../../../../../assets/images/icon-cancel.png';
import img_icon_remove from '../../../../../assets/images/delete.png';
import img_icon_edit from '../../../../../assets/images/edit.png';
import img_icon_transport from '../../../../../assets/images/icon-transport.png';
import img_close_modal from '../../../../../assets/images/close-modal.png';
import icon_order from '../../../../../assets/images/icon-order.png';
import icon_order_desc from '../../../../../assets/images/icon-order-desc.png';
import icon_order_asc from '../../../../../assets/images/icon-order-asc.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderProvider from "../../header/Header";
import { currencyFormat } from '../../../../../utils/pipes';
import ModalUploadFiles from "./UploadFiles";
import ModalCancelPurchaseOrder from "./Cancel";

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;
class ListOrders extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listOrders: [],
            listNumberEmployees: [],
            listOrdersExport: [],
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            search: '',
            order: 'suggest_order_id DESC',
            idDelete: '',
            idCancel: '',
            paginator: {
                page: 1,
                size: 5,
                totalPages: 0,
                totalItems: 0
            },
            openModaleDelete: false,
            openModaleImport: false,
            file: ''
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, () => {
            try {
                axiosInstance.get(`purchase_orders?filter=${this.state.search}&order=${this.state.order}&page=${(this.state.paginator.page - 1)}&size=${this.state.paginator.size}`,)
                    .then((get) => {
                        if (get.data) {
                            get.data.orders.map((element) => {
                                element.openDepartments = false;
                            });

                            this.setState({
                                listOrders: get.data.orders,
                                loading: false,
                                paginator: {
                                    ...this.state.paginator,
                                    page: get.data.currentPage + 1,
                                    totalItems: get.data.totalItems,
                                    totalPages: get.data.totalPages
                                }
                            })
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        if (error.response) {
                            if (error.response.data.error) {
                                toast.error(error.response.data.msg);
                            } else {
                                toast.info(error.response.data.msg);
                            }
                        }
                    });
            } catch (error) {
                this.setState({ loading: false });
                if (error.response) {
                    if (error.response.data.error) {
                        toast.error(error.response.data.msg);
                    } else {
                        toast.info(error.response.data.msg);
                    }
                }
            }
        });
    }

    handleGetInfoToExportExcel = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`purchase_orders?filter=${this.state.search}&order=${this.state.order}&page=0&size=${this.state.paginator.totalItems}`,)
                .then((get) => {
                    if (get.data) {
                        var temp = [];

                        get.data.orders.map((element, i) => {
                            var departments = '';
                            element.departments.map((ele, index) => {
                                if (index !== (element.departments.length - 1)) {
                                    departments += ele + ' | ';
                                } else {
                                    departments += ele;
                                }
                            });

                            temp.push({
                                suggest_order_id: element.suggest_order_id,
                                purchase_order_id: element.purchase_order_id,
                                created_at: moment(element.created_at).format("DD/MM/YYYY"),
                                departments,
                                cant: element.cant,
                                total: currencyFormat(element.total),
                                status_pay: element.status_pays[0].key === 'pay' ? 'Pagado' : element.status_pays[0].key === 'pending' ? 'Pendiente' : 'NA',
                                status_oc: element.status_ocs[0].key === 'pay' ? 'Pagado' : element.status_ocs[0].key === 'pending' ? 'Pendiente' : 'NA'
                            });
                        });
                        this.setState({
                            ...this.state,
                            listOrdersExport: temp,
                            loading: false
                        });

                        this.clickExportFile();


                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ loading: false });
                    if (error.response) {
                        if (error.response.data.error) {
                            toast.error(error.response.data.msg);
                        } else {
                            toast.info(error.response.data.msg);
                        }
                    }
                });
        });
    }

    handleSort = async (field, direction) => {
        let order = '';
        if (this.state.order.split(' ')[0] === field) {
            order = `${field} ${this.state.order.split(' ')[1] === 'ASC' ? 'DESC' : 'ASC'}`
        } else {
            order = `${field} ASC`;
        }
        this.setState({
            ...this.state,
            order,
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        });

        this.loadInfoData();
    }

    clickExportFile = () => {
        document.getElementById('file-export').click();
    }

    handleSearch = (term) => {
        this.setState({
            ...this.state,
            search: term
        });
    }

    handleSearchButton = () => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        });
        this.loadInfoData();
    }

    handleOpenDeleteModal = (id) => {
        this.setState({
            ...this.state,
            idDelete: id,
            openModaleDelete: true
        });
    }

    handleOpenScheduleModal = (id) => {
        this.setState({
            idSchedule: id,
            openScheduleModal: true
        });
    }

    handleChangeFormFileImport = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.files[0]
        });
    }

    handleOpenModalImport = (idSelect, typeSelect) => {
        this.setState({
            openModaleImport: true,
            idSelect,
            typeSelect
        })
    }

    handleCloseModalImport = () => {
        this.setState({
            openModaleImport: false,
            idSelect: '',
            typeSelect: ''
        })
    }

    handleCloseModalImport = () => {
        document.getElementById('selectedFile').value = null;
        this.setState({
            ...this.state,
            openModaleImport: false
        });
    }

    handleValidFormImport = () => {
        if (this.state.file !== '' && this.state.file !== null) {
            return true;
        }
        return false;
    }


    selectFile = (e) => {
        document.getElementById('selectedFile').click();
    }


    handleCloseModal = () => {
        this.setState({
            ...this.state,
            idDelete: '',
            idSchedule: '',
            openModaleDelete: false,
            openScheduleModal: false,
        });
    }

    handleCleanForm = () => {
        this.setState({
            ...this.state,
            file: ''
        });
        document.getElementById('selectedFile').value = null;
    }

    handleChangePage = (event, value) => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: value
            }
        });
        this.loadInfoData();
    };

    handleDelete = () => {
        this.setState({
            openModaleDelete: false
        });
        this.setState({ loading: true }, async () => {
            try {
                await axiosInstance.delete('purchase_orders/' + this.state.idDelete,);
                toast.info('¡Orden de compra eliminada con éxito!');
                this.loadInfoData();
            } catch (error) {
                console.log(error.response.data.error);
                this.setState({ loading: false });
                if (error.response) {
                    if (error.response.data.error) {
                        toast.error(error.response.data.msg);
                    } else {
                        toast.info(error.response.data.msg);
                    }
                }
            }
        });
    };

    handleOpenDepartments = (provider) => {
        const data = [];
        this.state.listOrders.forEach((element) => {
            if (provider.id === element.id) {
                element.openDepartments = !element.openDepartments;
            } else {
                element.openDepartments = false;
            }
            data.push(element);
        });

        this.setState({
            listOrders: data
        })
    }

    handleGo = (id) => {
        this.props.history.push('/provider/orders/suggest/edit/' + id);
    }

    handleGoToScheduleDelivery = (id) => {
        this.props.history.push('/provider/orders/packlist/' + id);
    }

    hanldeSaveImport = (file) => {
        this.handleCloseModalImport();
        const formData = new FormData();

        formData.append("file", file);
        formData.append("type", this.state.typeSelect);

        this.setState({ loading: true }, async () => {
            try {
                await axiosInstance.put("/purchase_orders/uploadBilling/" + this.state.idSelect, formData);
                toast.info('¡Factura cargada con éxito!');
                setTimeout(() => {
                    this.setState({
                        loading: false
                    });

                    this.loadInfoData();
                }, 2000);

            } catch (error) {
                this.setState({
                    loading: false
                });
                console.log(error);
                console.log(error.response);
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                } else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            }
        });
    }

    onCancel = async () => {
        this.setState({ loading: true, openModalCancel: false }, async () => {
            try {
                await axiosInstance.put("/purchase_orders/cancel/" + this.state.idCancel);
                toast.info('Orden de compra modificada con éxito!');
                this.setState({
                    idCancel: '',
                    loading: false
                });
                this.loadInfoData();
            } catch (error) {
                this.setState({
                    loading: false
                });
                // Error 😨
                if (error.response) {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                } else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                    this.setState({ ...this.state, loading: false });
                }
            }
        });
    }

    render() {
        const rowsOrders = this.state.listOrders.map((order) => {
            return (
                <div className="row-table row-body" key={order.id}>
                    <Grid container>
                        <Grid item md={1}>
                            <div className="item-provider">
                                {order.suggest_order_id}
                            </div>
                        </Grid>
                        <Grid item md={1}>
                            <div className="item-provider">
                                {order.purchase_order_id}
                            </div>
                        </Grid>
                        <Grid item md={1}>
                            <div className="item-provider mobilNone">
                                {moment(order.created_at).format("DD/MM/YYYY")}
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <div className="item-provider created_at mobilNone">
                                {order.departments.length > 2 ? (
                                    <>
                                        {order.openDepartments ? (
                                            <>
                                                {order.departments.map((department, index) => {
                                                    return (
                                                        <>
                                                            <div className="text-item-table-privider product department" key={index}>
                                                                {department}
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </>
                                        ) : (
                                            <>
                                                {order.departments.map((department, index) => {
                                                    if (index < 2) {
                                                        return (
                                                            <>
                                                                <div className="text-item-table-privider product department" key={index}>
                                                                    {department}
                                                                </div>
                                                            </>
                                                        );
                                                    }

                                                })}
                                            </>
                                        )}
                                        <div className="icon-more" onClick={(e) => this.handleOpenDepartments(order)}>
                                            {order.openDepartments ? <Visibility /> : <VisibilityOff />}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {order.departments.map((department, index) => {
                                            return (
                                                <>
                                                    <div className="text-item-table-privider product department" key={index}>
                                                        {department}
                                                    </div>
                                                </>
                                            );
                                        })}
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider mobilNone">
                                {order.cant}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider mobilNone">
                                {currencyFormat(order.total)}
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            {order.status_ocs[0].key === 'aproved_oc' ? (
                                <>
                                    <div className="item-provider mobilNone">
                                        {order.file_billing_pdf ? (
                                            <>
                                                <Tooltip title="Descargar factura en PDF" className="tooltip">
                                                    <div className="icon-export-provider">
                                                        <Link className="text-load" target="_blank" href={order.file_billing_pdf}>
                                                            <img src={img_icon_export} alt="" />
                                                            <br />
                                                            PDF
                                                        </Link>
                                                    </div>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                <Tooltip title="Cargar factura en PDF" className="tooltip" onClick={(e) => this.handleOpenModalImport(order.id, "PDF")}>
                                                    <div className="icon-export-provider empty">
                                                        <img src={img_icon_upload} alt="" />
                                                        <br />
                                                        PDF
                                                    </div>
                                                </Tooltip>
                                            </>
                                        )}
                                        {order.file_billing_xml ? (
                                            <>
                                                <Tooltip title="Descargar factura en XML" className="tooltip">
                                                    <div className="icon-export-provider ">
                                                        <Link className="text-load" target="_blank" href={order.file_billing_xml}>
                                                            <img src={img_icon_export} alt="" />
                                                            <br />
                                                            XML
                                                        </Link>
                                                    </div>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                <Tooltip title="Cargar factura en XML" className="tooltip" onClick={(e) => this.handleOpenModalImport(order.id, "XML")}>
                                                    <div className="icon-export-provider empty">
                                                        <img src={img_icon_upload} alt="" />
                                                        <br />
                                                        XML
                                                    </div>
                                                </Tooltip>
                                            </>
                                        )}

                                    </div>
                                </>
                            ) : (<></>)}
                        </Grid>
                        <Grid item xs={2} className="mobilNone">
                            <div style={{ width: '120px' }} className={`status-provider ${order.status_ocs[0].key === 'aproved_oc' || order.status_ocs[0].key === 'delivered' ? 'active' : order.status_ocs[0].key === 'pending' ? 'pending' : 'inactive'}`}>
                                <div className="item-provider item-status ">
                                    <span className="desk">
                                        {order.status_ocs[0].key === 'aproved_oc' ? 'Por entregar' : order.status_ocs[0].key === 'pending' ? 'Pendiente' : order.status_ocs[0].key === 'cancel' ? 'Cancelada' : order.status_ocs[0].key === 'delivered' ? 'Entregada' : 'NA'}
                                    </span>
                                    <span className="mobile">
                                        {order.status_ocs[0].key === 'aproved_oc' ? 'PE' : order.status_ocs[0].key === 'pending' ? 'PE' : order.status_ocs[0].key === 'cancel' ? 'CA' : order.status_ocs[0].key === 'delivered' ? 'EN' : 'NA'}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div style={{ width: '120px' }} className={`status-provider ${order.status_pays[0].key === 'pay' ? 'active' : order.status_pays[0].key === 'pending' ? 'pending' : 'inactive'}`}>
                                <div className="item-provider item-status ">
                                    <span className="desk">
                                        {order.status_pays[0].key === 'pay' ? 'Pagado' : order.status_pays[0].key === 'pending' ? 'Pendiente' : 'NA'}
                                    </span>
                                    <span className="mobile">
                                        {order.status_pays[0].key === 'pay' ? 'PA' : order.status_pays[0].key === 'pending' ? 'PE' : 'NA'}
                                    </span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider item-actions">
                                {order.status_ocs[0].key === 'pending' ? (
                                    <>
                                        <div className="icon-action-provider">
                                            <Tooltip title="Editar" className="tooltip" onClick={(e) => this.handleGo(order.id)}>
                                                <img src={img_icon_edit} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div className="icon-action-provider">
                                            <Tooltip title="Eliminar" className="tooltip" onClick={(e) => this.handleOpenDeleteModal(order.id)}>
                                                <img src={img_icon_remove} alt="" />
                                            </Tooltip>
                                        </div>
                                    </>
                                ) : order.status_ocs[0].key === 'aproved_oc' ? (
                                    <>
                                        <>
                                            <div className="icon-action-provider">
                                                <Tooltip title="Cancelar" className="tooltip" onClick={(e) => this.setState({ idCancel: order.id, openModalCancel: true })}>
                                                    <img src={img_icon_cancel} alt="" />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-action-provider">
                                                <Tooltip title="Agendar entrega" className="tooltip" onClick={(e) => this.handleGoToScheduleDelivery(order.id)}>
                                                    <img src={img_icon_transport} alt="" />
                                                </Tooltip>
                                            </div>
                                        </>
                                    </>
                                ) : order.status_ocs[0].key === 'delivered' ? (
                                    <>
                                        <Tooltip title="Descargar OC en PDF" className="tooltip">
                                            <div className="icon-export-provider">
                                                <Link className="text-load" target="_blank" href={order.file_oc_pdf}>
                                                    <img src={img_icon_export} alt="" />
                                                    <br />
                                                    PDF
                                                </Link>
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : (<></>)}
                            </div>
                        </Grid>
                    </Grid>
                </div >
            )
        });
        return (
            <>
                <HeaderProvider
                    title="Órdenes de compra"
                    icon={icon_list_orders}
                    user={this.state.user}
                    search={this.handleSearch}
                    textSearch='Buscar orden de compra'
                    view='orders'
                    onSearchButton={(e) => this.handleSearchButton()}
                    handleGetInfoToExportExcel={this.handleGetInfoToExportExcel} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <ExcelFile element={<button id="file-export"></button>} filename="Lista de órdenes de compra">
                            <ExcelSheet data={this.state.listOrdersExport} name="Hoja 1">
                                <ExcelColumn label="# Orden de compra sugerida" value="suggest_order_id" />
                                <ExcelColumn label="# Orden de compra" value="purchase_order_id" />
                                <ExcelColumn label="Fecha de creación" value="created_at" />
                                <ExcelColumn label="Departamentos" value="departments" />
                                <ExcelColumn label="Unidades" value="cant" />
                                <ExcelColumn label="Monto total" value="total" />
                                <ExcelColumn label="Estado de pago" value="status_pay" />
                                <ExcelColumn label="Estado de OC" value="status_oc" />
                            </ExcelSheet>
                        </ExcelFile>
                        <div className="body-provider">
                            <Container>
                                <div className="row-table row-title">
                                    <Grid container>
                                        <Grid item xs={9} md={1}>
                                            <div className="title-table-orders sort" onClick={(e) => this.handleSort('suggest_order_id')}>
                                                # Orden de compra sugerida<img src={this.state.order.split(' ')[0] === 'suggest_order_id' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={9} md={1}>
                                            <div className="title-table-orders sort" onClick={(e) => this.handleSort('purchase_order_id')}>
                                                # Orden de compra<img src={this.state.order.split(' ')[0] === 'purchase_order_id' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('created_at')}>
                                                Fecha de creación<img src={this.state.order.split(' ')[0] === 'created_at' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={2}>
                                            <div className="title-table-orders created_at mobilNone">
                                                Departamento
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('cant')}>
                                                Unidades<img src={this.state.order.split(' ')[0] === 'cant' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('total')}>
                                                Monto total<img src={this.state.order.split(' ')[0] === 'total' ? this.state.order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                Factura
                                            </div>
                                        </Grid>
                                        <Grid item md={2}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('suggest_order_id')}>
                                                Estado de órden
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone sort" onClick={(e) => this.handleSort('suggest_order_id')}>
                                                Estado de pago
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} md={1}>
                                            <div className="title-table-orders text-center">
                                                Acciones
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="providers">
                                    {rowsOrders}
                                    <div className="paginator">
                                        <Pagination
                                            hidePrevButton
                                            hideNextButton
                                            count={this.state.paginator.totalPages}
                                            page={this.state.paginator.page}
                                            onChange={this.handleChangePage} />
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <Footer />
                    </>)}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal-content"
                    open={this.state.openModaleDelete}
                    onClose={(e) => this.handleCloseModal()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                ><Fade in={this.state.openModaleDelete}>
                        <div className="modal-delete">
                            <div className="icon-close" onClick={(e) => this.handleCloseModal()}>
                                <div className="title-modal-provider">
                                    Eliminar Orden
                                    <img src={img_close_modal} alt="Cerrar" />
                                </div>
                            </div>
                            <div className="documents-modal-provider">
                                <div className="container-load-products container-modal-detele">
                                    <div className="label text-center">
                                        ¿Seguro desea eliminar esta order de compra?
                                    </div>

                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <div className="container-btn-save" onClick={(e) => this.handleCloseModal()}>
                                                <button className="button-cancel">
                                                    Cancelar
                                                </button>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="container-btn-save">
                                                <button className="button-delete" onClick={(e) => this.handleDelete()}>
                                                    Eliminar
                                                </button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                <ModalUploadFiles
                    open={this.state.openModaleImport}
                    onClose={this.handleCloseModalImport}
                    onSave={this.hanldeSaveImport}
                    type={this.state.typeSelect}
                    init="" />

                <ModalCancelPurchaseOrder
                    open={this.state.openModalCancel}
                    onClose={() => this.setState({ idCancel: '', openModalCancel: false })}
                    onCancel={this.onCancel} />
            </>
        )
    }



    componentDidMount() {
        this.loadInfoData();
    }
}

export default ListOrders;