import React, { useState, useRef } from 'react';
import { Grid, Paper, TextField, } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm } from 'react-hook-form';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';

// imports
import Topnav from '../../components/topnav/Topnav';
import Footer from '../../components/footer/Footer';
import loginService from '../../services/login';
import Loading from '../../components/loading/Loading';

const CssTextField = withStyles({
    root: {
        margin: 0,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#2D4838',
            },
        },

    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '151px 141px',
        [theme.breakpoints.down(425 + theme.spacing(2))]: {
            padding: '20px 20px 30px',
        },
        textTransform: 'capitalize'
    },
    grid: {
        border: '1px solid rgb(45 72 56 / 10%)',
        boxSizing: 'border-box',
        borderRadius: '10px',
        marginTop: 0,
        [theme.breakpoints.down(400 + theme.spacing(2))]: {
            width: '100%',
        },
    },
    paper: {
        height: '500px;',
        color: '#333333',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        padding: theme.spacing(9),
        [theme.breakpoints.down(768 + theme.spacing(2))]: {
            padding: '30px 26px',
            width: '100%',
            height: '100%'
        },

    },
    paper2: {
        textAlign: 'center',
        height: '500px;',
        color: '#333333',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F2F5F5',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        padding: theme.spacing(9),
        [theme.breakpoints.down(768 + theme.spacing(2))]: {
            padding: '30px 21px',
            width: '100%',
            height: '100%'
        },
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper3: {
        width: 584,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
        outline: 0,

    },
}));

export default function PasswordReset(props) {

    const classes = useStyles();
    const history = useHistory();

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [userId, setUserId] = useState(props.match.params.userId);
    const [token, setToken] = useState(props.match.params.token);

    const [loading, setLoading] = useState(Boolean);

    const {
        register,
        formState: { errors },
        handleSubmit,
        watch
    } = useForm();

    const password = useRef({});
    password.current = watch("password", "");

    const handleClickShowNewPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    }

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    }

    const hanldePasswordReset = async (values) => {
        try {
            setLoading(true);
            const data = {
                password: values.password,
                userId,
                token
            }
            await loginService.updatePasswordReset(data);
            toast.info('¡Se ha actualizado la contraseña con éxito!');
            setLoading(false);
            setTimeout(() => {
                history.push('/');
            }, 2000);
        } catch (error) {
            console.log(error);
            setLoading(false);
            if (error.response) {
                if (error.response.data.error) {
                    toast.error(error.response.data.msg);
                } else {
                    toast.info(error.response.data.msg);
                }
            }
            console.log('Error de identificacion')
        }
    }

    return (
        <>
            {
                loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Topnav />
                        <div className={classes.root}>
                            <Grid container className={classes.grid}>
                                <Grid item xs={3} />
                                <Grid item xs={12} sm={6}>
                                    <Paper
                                        disableElevation
                                        className={classes.paper}>
                                        <div className="label-register">
                                            RECUPERAR CONTRASEÑA
                                        </div>
                                        <form onSubmit={handleSubmit(hanldePasswordReset)}>
                                            <div className="label-login">
                                                Nueva contraseña *
                                                <p>Al menos 8 caracteres, 1 letra mayúscula,  1 letra minúscula, 1 número</p>
                                            </div>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <CssTextField InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={(e) => handleClickShowNewPassword()}
                                                                    onMouseDown={(e) => handleMouseDownNewPassword(e)}
                                                                    edge="end" >
                                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                        className="input-register"
                                                        name="password"
                                                        {...register('password', {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido',
                                                            },
                                                            pattern: {
                                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
                                                                message: 'La contraseña no es segura',
                                                            },
                                                        })}
                                                        type={showPassword ? 'text' : 'password'}
                                                        variant='outlined' />

                                                    {errors.password && (
                                                        <p className="text-red-700  text-xs error-text">
                                                            {errors.password.message}
                                                        </p>
                                                    )}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="label-login">
                                                        Confirmar Contraseña *
                                                    </div>
                                                    <CssTextField InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={(e) => handleClickShowConfirmPassword()}
                                                                    onMouseDown={(e) => handleMouseDownConfirmPassword(e)}
                                                                    edge="end" >
                                                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                        className="input-register"
                                                        name="confirm_password"
                                                        {...register('confirm_password', {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido',
                                                            },
                                                            validate: value =>
                                                                value === password.current || "No coinciden las contraseñas."
                                                        }
                                                        )}
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        variant='outlined' />
                                                    {errors.confirm_password && (
                                                        <p className="text-red-700  text-xs error-text">
                                                            {errors.confirm_password.message}
                                                        </p>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            <button type="submit" className='buttonLogin-active'>
                                                <div className="text">
                                                    Guardar
                                                </div>
                                            </button>
                                        </form>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </div>
                        <Footer />
                    </>
                )
            }
        </>
    )
}