import React, { Component } from 'react';
import { toast } from 'react-toastify';

// importaciones de modulos
import Header from '../../../../components/header/Header';
import Loading from '../../../../components/loading/Loading';
import Footer from "../../../../components/footer/Footer";
import axiosInstance from "../../../../utils/axios";
import FormProvider from "../../../../components/form-provider/FormProvider";

// importar estilos
import '../../../register/register-provider/register-provider.css';

// images
import img_icon_information from '../../../../assets/images/icon-information.png';

// lista para breadcrounb
const listBreadcrumbs = [
    {
        title: 'Inicio',
        url: '/'
    },
    {
        title: '1. Carga de información general'
    }
];

class LoadMissingInformation extends Component {
    constructor() {
        super();

        this.state = {
            loading: false,
            listStates: [],
            listDelegations: [],
            listSuburbs: [],
            formProvider: {
                id_temp: Math.floor(1 + Math.random() * (99999 - 1)),
                business_name: '',
                rfc: '',
                account_clabe: '',
                errorAccountClabe: false,
                textErrorAccountClabe: 'El número de cuenta clabe debe tener mínimo 11 dígitos',
                account_bank: '',
                account_number: '',
                errorAccountNumber: false,
                textErrorAccountNumber: 'El número de cuenta debe tener mínimo 6 dígitos',
                username: '',
                email: '',
                errorEmail: false,
                textErrorEmail: 'El correo no es válido',
                password: '',
                passwordErrorMessage: 'La contraseña no puede ser la misma',
                passwordValid: false,
                showPassword: false,
                confirm_password: '',
                confirmPasswordErrorMessage: '',
                showConfirmPassword: false,
                confirmPasswordValid: false,
                contact_business_email: '',
                errorContactBusinessEmail: false,
                textErrorContactBusinessEmail: 'El correo no es válido',
                contact_business_phone: '',
                contact_business_name: '',
                contact_business_workstation: '',
                contact_logistic_email: '',
                contact_logistic_phone: '',
                contact_logistic_name: '',
                contact_logistic_workstation: '',
                contact_financial_email: '',
                contact_financial_phone: '',
                contact_financial_name: '',
                contact_financial_workstation: '',
                address_street: '',
                address_number_exterior: '',
                address_number_interior: '',
                address_suburb: '',
                address_delegation: '',
                address_postcode: '',
                address_city: '',
                address_state: '',
                number_employees: '',
                company_classification: '',
                credit_days: '',
                name_legal_representative: '',
                curp_legal_representative: ''
            },
            form: {
                bank_face: '', //file
                bank_face_percent: '',
                opinion_fulfillment_sat: '', //file
                opinion_fulfillment_sat_percent: '',
                power_attorney: '', //file
                power_attorney_percent: '', //file
                opinion_fulfillment_tax_obligations: '', //file
                opinion_fulfillment_tax_obligations_percent: '', //file
                identification_legal_representative_front: '', //file
                identification_legal_representative_front_percent: '', //file
                identification_legal_representative_reverse: '', //file
                identification_legal_representative_reverse_percent: '', //file
                proof_address: '', //file
                proof_address_percent: '', //file
                proof_tax_situation: '', //file
                proof_tax_situation_percent: '', //file
                constitutive_act: '', //file
                constitutive_act_percent: '', //file
            }
        }
    }

    loadInfoData = () => {
        this.setState({ loading: true }, async () => {
            try {
                const get = await axiosInstance.get(`providers/info/iam`,);
                const states = await axiosInstance.get(`utils/estados`);

                let listDelegations = [];
                let listSuburbs = [];
                
                if(get.data.address_state){
                    const delegations = await axiosInstance.get(`utils/municipios/${get.data.address_state}`);
                    const suburbs = await axiosInstance.get(`utils/colonias/${get.data.address_delegation}`);

                    listDelegations = delegations.data.map((delegation) => { return { value: delegation.id, label: delegation.nombre } });
                    listSuburbs = suburbs.data.map((suburb) => { return { value: suburb.id, label: suburb.nombre } });
                
                }
                const listStates = states.data.map((state) => { return { value: state.id, label: state.nombre } });

                this.setState({
                    ...this.state,
                    listStates,
                    listDelegations,
                    listSuburbs,
                    formProvider: {
                        ...this.state.formProvider,
                        ...get.data,
                        password: ''
                    },
                    form: {
                       ...this.state.form,
                       ...get.data, 
                    },
                    loading: false
                });

            } catch (error) {
                console.log(error);
            }
        });
    }


    handleSaveInfo = (state) => {
        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.post('providers/info/iam', state.formProvider);
                    this.setState({ loading: false });
                    var user = JSON.parse(localStorage.getItem('user'));
                    user.notLoad = false;
                    localStorage.setItem('user', JSON.stringify(user));
                    this.props.history.push("/provider/products/list");
                } catch (error) {
                    console.log(error);
                    console.log(error.response);
                    // Error 😨
                    if (error.response) {
                        if (error.response.data.error) {
                            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        }
                        else {
                            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        }
                        this.setState({ ...state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }

    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Header listBreadcrumbs={listBreadcrumbs} title='1. Información general' icon={img_icon_information} clear={true} />
                        <FormProvider state={this.state} handleSaveInfo={this.handleSaveInfo} />
                        <Footer />
                    </>
                )}
            </>
        );
    }
    componentDidMount() {
        var notLoad = JSON.parse(localStorage.getItem('user'));
        if(!notLoad.notLoad){
            this.props.history.push('/provider/products/list');
        }
        this.loadInfoData();
    }
}

export default LoadMissingInformation;
