import React, { Component } from "react";
import LinearProgress from '@material-ui/core/LinearProgress';
import { Container, Grid, TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';



// importaciones de modulos
import axiosInstance from "../../../../../utils/axios";

// imagenes
import img_file_donwload from '../../../../../assets/images/icon-file-donwload.png';

export default class FormProviderOCS extends Component {
    selectRefDelegation = null;
    selectRefSuburb = null;

    constructor(props) {
        super(props);

        this.state = {
            ...this.props.state,
        }
    }

    render() {
        return (
            <>
                <Container maxWidth="md">
                    <div className="section-register">
                        <div className="title-section">
                            Información cuenta
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Nombre de contacto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.business_name}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Correo electrónico
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.email}
                                </span>
                            </Grid>
                        </Grid>
                    </div>

                    <div className="section-register">
                        <div className="title-section">
                            Información comercial
                        </div>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Razón social
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.business_name}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    RFC
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.rfc}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Opinión de cumplimiento de SAT
                                </div>
                                {(this.state.form.opinion_fulfillment_sat !== '' && this.state.form.opinion_fulfillment_sat !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.opinion_fulfillment_sat?.name ? this.state.form.opinion_fulfillment_sat.name : (
                                                            <Link className="value-provider" href={this.state.form.opinion_fulfillment_sat}>opinióndecumplimientodeSAT.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img src={img_file_donwload} alt="Cargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Carátula bancaria
                                </div>
                                {(this.state.form.bank_face !== '' && this.state.form.bank_face !== null) ? (
                                    <>
                                        <div className="button-load-register">
                                            <Grid container >
                                                <Grid item xs={11}>
                                                    <div className="text-load">
                                                        {this.state.form.bank_face?.name ? this.state.form.bank_face.name : (
                                                            <Link className="value-provider" href={this.state.form.bank_face}>caratulabancaria.pdf</Link>
                                                        )}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="img-button-not-loaded icon-load-provider-info">
                                                        <img src={img_file_donwload} alt="Cargar" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <span className="value-provider">
                                            ---
                                        </span>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Banco
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.account_bank ? this.state.formProvider.account_bank : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Número de cuenta
                                </div>
                                <span className="value-provider ">
                                    {this.state.formProvider.account_number ? this.state.formProvider.account_number : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Cuenta CLABE
                                </div>
                                <span className="value-provider ">
                                    {this.state.formProvider.account_clabe ? this.state.formProvider.account_clabe : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="section-register">
                        <div className="title-section">
                            Datos de contacto comercial
                        </div>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Nombre de contacto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_name ? this.state.formProvider.contact_business_name : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Puesto
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_workstation ? this.state.formProvider.contact_business_workstation : '---'}
                                </span>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Correo electrónico
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_email ? this.state.formProvider.contact_business_email : '---'}
                                </span>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className="label-register title-provider-info">
                                    Teléfono
                                </div>
                                <span className="value-provider">
                                    {this.state.formProvider.contact_business_phone ? this.state.formProvider.contact_business_phone : '---'}
                                </span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="button-register" style={{marginTop: 20}}>
                        <button className={'button-save-active'} onClick={(e) => this.props.handleSaveInfo(this.state)} >
                            <div className="text">
                                {this.props.titleButton ? this.props.titleButton : 'Finalizar Registro'}
                            </div>
                        </button>
                    </div>
                </Container>
            </>
        );
    }

}
