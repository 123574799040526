import React from 'react';
import { OutlinedInput } from '@material-ui/core';

export default function InputEmail({errors, register, name, placeholder, required = true}) {
    return (
        <>
            <OutlinedInput
                fullWidth
                placeholder={placeholder}
                name={name}
                {...register(name, {
                    required: {
                        value: required,
                        message: 'Este campo es requerido',
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'La dirección de correo electrónico es inválida',
                    },
                })}
            />
            {errors[`${name}`] && (
                <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                    {errors[`${name}`].message}
                </p>
            )}
        </>
    )
}