import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

// importaciones de modulos
import Header from '../../../components/header/Header';
import Loading from '../../../components/loading/Loading';
import Footer from '../../../components/footer/Footer';
import axiosInstance from '../../../utils/axios';

// importar estilos
import './register-provider.css';

// images
import img_icon_information from '../../../assets/images/icon-information.png';
import FormProvider from '../../../components/form-provider/FormProvider';

// lista para breadcrounb
const listBreadcrumbs = [
  {
    title: 'Inicio',
    url: '/',
  },
  {
    title: 'Registro',
    url: '/register',
  },
  {
    title: '1. Información general',
  },
];

class RegisterProvider extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      listStates: [],
      listDelegations: [],
      listSuburbs: [],
      formProvider: {
        id_temp: Math.floor(1 + Math.random() * (99999 - 1)),
        business_name: '',
        rfc: '',
        account_clabe: '',
        errorAccountClabe: false,
        textErrorAccountClabe: 'El número de cuenta clabe debe tener mínimo 11 dígitos',
        account_bank: '',
        account_number: '',
        errorAccountNumber: false,
        textErrorAccountNumber: 'El número de cuenta debe tener mínimo 6 dígitos',
        username: '',
        email: '',
        errorEmail: false,
        textErrorEmail: 'El correo no es válido',
        password: '',
        passwordErrorMessage: '',
        passwordValid: false,
        showPassword: false,
        confirm_password: '',
        confirmPasswordErrorMessage: '',
        showConfirmPassword: false,
        confirmPasswordValid: false,
        contact_business_email: '',
        errorContactBusinessEmail: false,
        textErrorContactBusinessEmail: 'El correo no es válido',
        contact_business_phone: '',
        errorContactbusinessPhone: false,
        textErrorContactbusinessPhone: 'El número de telefono debe tener mínimo 7 dígitos',
        contact_business_name: '',
        contact_business_workstation: '',
        contact_logistic_email: '',
        contact_logistic_phone: '',
        errorContactlogisticPhone: false,
        textErrorContactlogisticPhone: 'El número de telefono debe tener mínimo 7 dígitos',
        contact_logistic_name: '',
        contact_logistic_workstation: '',
        contact_financial_email: '',
        contact_financial_phone: '',
        errorContactfinancialPhone: false,
        textErrorContactfinancialPhone: 'El número de telefono debe tener mínimo 7 dígitos',
        contact_financial_name: '',
        contact_financial_workstation: '',
        address_street: '',
        address_number_exterior: '',
        address_number_interior: '',
        address_suburb: '',
        address_delegation: '',
        address_postcode: '',
        address_city: '',
        address_state: '',
        number_employees: '',
        company_classification: '',
        credit_days: '',
        name_legal_representative: '',
        curp_legal_representative: '',
      },
      form: {
        bank_face: '', //file
        bank_face_percent: '',
        // opinion_fulfillment_sat: '', //file
        // opinion_fulfillment_sat_percent: '',
        power_attorney: '', //file
        power_attorney_percent: '', //file
        opinion_fulfillment_tax_obligations: '', //file
        opinion_fulfillment_tax_obligations_percent: '', //file
        identification_legal_representative_front: '', //file
        identification_legal_representative_front_percent: '', //file
        identification_legal_representative_reverse: '', //file
        identification_legal_representative_reverse_percent: '', //file
        proof_address: '', //file
        proof_address_percent: '', //file
        proof_tax_situation: '', //file
        proof_tax_situation_percent: '', //file
        constitutive_act: '', //file
        constitutive_act_percent: '', //file
      },
    };
  }

  handleSaveInfo = state => {
    try {
      this.setState({ loading: true }, async () => {
        try {
          const data = await axiosInstance.post('providers', state.formProvider);

          this.setState({ loading: false });
          // this.handleCleanForm();
          localStorage.setItem('provider', JSON.stringify(data.data.data));
          localStorage.setItem('isRegisterProvider', JSON.stringify(true));
          this.props.history.push('/register');
        } catch (error) {
          // Error 😨
          if (error && error.response && error.response.data) {
            if (error.response.data.error) {
              toast.error(error.response.data.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
              toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
          } else {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
          }

          this.setState({ ...state, loading: false });
        }
      });
    } catch (e) {
      alert(e.message);
    }
  };

  loadInfoData = () => {
    this.setState({ loading: true }, async () => {
      try {
        const states = await axiosInstance.get(`utils/estados`);

        const listStates = states.data.map(state => {
          return { value: state.id, label: state.nombre };
        });

        this.setState({
          ...this.state,
          listStates,
          loading: false,
        });
      } catch (error) {
        console.log(error);
      }
    });
  };

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <Header listBreadcrumbs={listBreadcrumbs} title='1. Información general' icon={img_icon_information} />
            <FormProvider state={this.state} handleSaveInfo={this.handleSaveInfo} />
            <Footer />
          </>
        )}
      </>
    );
  }

  componentDidMount() {
    this.loadInfoData();
  }
}

export default RegisterProvider;
