import { useState, useEffect } from "react";
import { Container } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';

//importaciones
import Footer from "../../../../../components/footer/Footer";
import axiosInstance from "../../../../../utils/axios";
import HeaderAdmin from '../../header/Header';
import DataCRUD from '../../../../../components/DataCRUD';

// imagenes
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import moment from "moment";
import StatusTable from "../../../../../components/status-table";

const optionsStatusSelect = [
    { value: 'aproved_adviser', label: 'Aprobado' },
    { value: 'inactive', label: 'Inactivo' },
    { value: 'active', label: 'Activo' },
]

export default function ListProvidersAdmin2(props) {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [listProviders, setListProviders] = useState([]);
    const [sort, setSort] = useState('business_name ASC');
    const [search, setSearch] = useState('');
    const [textSearch, setTextSearch] = useState('');
    const [change, setChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hideColumns, setHideColumns] = useState({
        'provider_id': true,
        'business_name': true,
        'email': false,
        'phone': false,
        'rfc': true,
        'status_id': true,
        'departments': true,
        'warehouses': true,
        'schedule': true,
        'created_at': true,
    });

    const [paginator, setPaginator] = useState({
        totalPages: 0,
        limit: 10,
        page: 1
    });

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    };

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`providers?role=admin&filter=${search}&order=${sort}&page=${(paginator.page - 1)}&size=${paginator.limit}`,)
            setListProviders(response.data.providers);
            setPaginator({
                ...paginator,
                page: response.data.currentPage + 1,
                totalItems: response.data.totalItems,
                totalPages: response.data.totalPages
            });
            setLoading(false);
        }
        fetch();
    }, [paginator.page, sort, search, change]);

    const sortFunction = (column, sortDirection) => {
        console.log(sortDirection);
        setSort(`${column.sortField} ${sortDirection}`);
        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const handleSearch = (data) => {
        setTextSearch(data);
    }

    const handleSearchButton = (text) => {
        setPaginator({
            ...paginator,
            page: 1
        })
        setSearch(text);
    }

    const handleGetInfoToExportExcel = (data) => {
        console.log(data);
    }

    const handleOpenDepartments = (provider) => {
        const data = [];
        listProviders.forEach((element) => {
            if (provider.id === element.id) {
                element.openDepartments = !element.openDepartments;
            } else {
                element.openDepartments = false;
            }
            data.push(element);
        });

        setListProviders(data);
    }

    const handleSelect = async (id, action) => {
        const data = {
            status: action
        }
        try {
            await axiosInstance.put(`users/${id}`, data);
            toast.success("Información del proveedor actializada con éxito!");
            setChange(!change);
        } catch (error) {
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
        finally {
            setLoading(false);
        }
    }

    const columnsModel = [
        {
            name: 'ID',
            sortField: 'provider_id',
            selector: row => row?.provider_id,
            sortable: true,
            omit: hideColumns.provider_id
        },
        {
            name: 'Nombre de proveedor',
            sortField: 'business_name',
            selector: row => row?.business_name,
            sortable: true,
            omit: hideColumns.business_name
        },
        {
            name: 'RFC',
            sortField: 'rfc',
            selector: row => row?.rfc,
            sortable: true,
            omit: hideColumns.rfc
        },
        {
            name: 'Correo',
            sortField: 'email',
            selector: row => row?.email,
            sortable: true,
            omit: hideColumns.email
        },
        {
            name: 'Teléfono',
            sortField: 'phone',
            selector: row => row?.contact_business_phone,
            sortable: true,
            omit: hideColumns.phone
        },
        {
            name: 'Estado',
            sortField: 'status_id',
            selector: row => row?.status,
            omit: hideColumns.status_id,
            cell: row => <StatusTable
                status={row?.status}
                id={row?.user_id}
                options={optionsStatusSelect}
                handleSelect={handleSelect} />,
            sortable: true,
        },
        {
            name: 'Departamentos',
            sortField: 'departments',
            sortable: true,
            omit: hideColumns.departments,
            cell: row =>
                <div className="item-provider">
                    {row?.departments.length > 1 ? (
                        <>
                            {row.openDepartments ? (
                                <>
                                    {row.departments.map((department, index) => {
                                        return (
                                            <div className="text-item-table-privider product department" key={index}>
                                                {department.department.name}
                                                <br />
                                            </div>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {row.departments.map((department, index) => {
                                        if (index < 1) {
                                            return (
                                                <div className="text-item-table-privider product department" key={index}>
                                                    {department.department.name}
                                                    <br />
                                                </div>
                                            );
                                        }

                                    })}
                                </>
                            )}
                            <div className="icon-more" onClick={(e) => handleOpenDepartments(row)}>
                                {row.openDepartments ? <Visibility /> : <VisibilityOff />}
                            </div>
                        </>
                    ) : (
                        <>
                            {row.departments.map((department, index) => {
                                return (
                                    <div className="text-item-table-privider product department" key={index}>
                                        {department.department.name}
                                        <br />
                                    </div>
                                );
                            })}
                        </>
                    )}
                </div>

        },
        {
            name: 'Bodegas',
            sortField: 'warehouses',
            sortable: true,
            omit: hideColumns.warehouses,
            cell: row =>
                <div className="item-provider">
                    {row?.warehouses.map((warehouse, index) => {
                        return (
                            <div className="text-item-table-privider product department" key={index}>
                                {warehouse.warehouse.initials}
                                <br />
                            </div>
                        );
                    })}
                </div>
        },
        {
            name: 'Horario',
            sortField: 'schedule',
            selector: row => row?.schedule,
            sortable: true,
            omit: hideColumns.schedule
        },
        {
            name: 'Fecha de registro',
            sortField: 'created_at',
            selector: row => moment(row?.created_at).format("DD/MM/YYYY"),
            sortable: true,
            omit: hideColumns.created_at
        }
    ];

    const optionsFilter = [
        { value: 'provider_id', label: 'ID' },
        { value: 'business_name', label: 'Nombre de proveedor' },
        { value: 'rfc', label: 'RFC' },
        { value: 'email', label: 'Correo' },
        { value: 'phone', label: 'Teléfono' },
        { value: 'status_id', label: 'Estado' },
        { value: 'departments', label: 'Departamentos' },
        { value: 'warehouses', label: 'Bodegas' },
        { value: 'schedule', label: 'Horario' },
        { value: 'created_at', label: 'Fecha de registro' }
    ];

    const handleHideColumns = (select) => {
        let temp = { ...hideColumns };
        for (const item of optionsFilter) {
            if (Boolean(select.find((element) => element.value === item.value)))
                temp[`${item.value}`] = false;
            else
                temp[`${item.value}`] = true;
        }
        setHideColumns(temp);
    }

    const handleEditProvider = (id) => {
        props.history.push(`/admin/providers/edit/${id}`)
    }

    return (
        <>
            <HeaderAdmin
                title="Proveedores"
                icon={icon_list_products}
                user={user}
                search={handleSearch}
                textSearch='Buscar proveedor'
                view='providers-list'
                filterOption={optionsFilter}
                hideColumns={handleHideColumns}
                listHideColumns={hideColumns}
                onSearchButton={handleSearchButton}
                handleGetInfoToExportExcel={handleGetInfoToExportExcel} />
            <div className="body-provider body-list">
                <Container>
                    <DataCRUD
                        url="/providers"
                        data={listProviders}
                        columns={columnsModel}
                        handleClickView={handleEditProvider}
                        handleSort={sortFunction}
                        loading={loading} />
                    <div className="providers">
                        <div className="paginator">
                            <Pagination
                                hidePrevButton
                                hideNextButton
                                count={paginator.totalPages}
                                page={paginator.page}
                                onChange={handleChangePage} />
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
            <ToastContainer />
        </>
    );
}

