import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExportReact from 'react-export-excel';

//importar estilos
import '../../../provider/products/list-products/list-products.css';
import './list-providers.css';

// imagenes
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import icon_detail from '../../../../../assets/images/icon-detail.png';
import img_icon_arrow_logout from '../../../../../assets/images/icon-arrow-logout.png';

//importaciones
import Footer from '../../../../../components/footer/Footer';
import axiosInstance from '../../../../../utils/axios';
import HeaderAdmin from '../../header/Header';
import Loading from '../../../../../components/loading/Loading';
import icon_order_asc from '../../../../../assets/images/icon-order-asc.png';
import icon_order_desc from '../../../../../assets/images/icon-order-desc.png';
import icon_order from '../../../../../assets/images/icon-order.png';

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;

class ListProvidersManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listProviders: [],
      listProvidersExport: [],
      loading: false,
      anchorEl: null,
      user: JSON.parse(localStorage.getItem('user')),
      search: '',
      order: 'provider_id DESC',
      paginator: {
        page: 1,
        size: 5,
        totalPages: 0,
        totalItems: 0,
      },
      user_id: '',
    };
  }

  loadInfoData = (page, order, searchTerm) => {
    this.setState({ loading: true });
    axiosInstance
      .get(
        `providers?filter=${searchTerm === undefined ? this.state.search : searchTerm}&order=${
          !order && order != '' ? this.state.order : order
        }&page=${!page && page != 0 ? this.state.paginator.page - 1 : page ? page - 1 : page}&size=${
          this.state.paginator.size
        }`
      )
      .then(get => {
        if (get.data) {
          get.data.providers.map(element => {
            element.openDepartments = false;
            var departments = '';

            element.departments.map((ele, index) => {
              if (index !== element.departments.length - 1) {
                departments += ele.department.name + ' | ';
              } else {
                departments += ele.department.name;
              }
            });
          });
          this.setState({
            listProviders: get.data.providers,
            loading: false,
            paginator: {
              ...this.state.paginator,
              page: get.data.currentPage + 1,
              totalItems: get.data.totalItems,
              totalPages: get.data.totalPages,
            },
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleSearch = term => {
    this.setState({
      ...this.state,
      search: term,
    });
  };

  handleSearchButton = text => {
    this.setState({
      paginator: {
        ...this.state.paginator,
        page: 1,
      },
      search: text,
    });
    this.loadInfoData(1, null, text);
  };

  handleChangePage = (event, value) => {
    this.setState({
      paginator: {
        ...this.state.paginator,
        page: value,
      },
    });
    this.loadInfoData(value);
  };

  handleEditProduct = id => {
    this.props.history.push(`/super_admin/providers/edit/${id}`);
  };

  handleOpenDepartments = provider => {
    const data = [];
    this.state.listProviders.forEach(element => {
      if (provider.id === element.id) {
        element.openDepartments = !element.openDepartments;
      } else {
        element.openDepartments = false;
      }
      data.push(element);
    });

    this.setState({
      listProviders: data,
    });
  };

  handleClick = (anchorEl, user_id) => {
    this.setState({
      ...this.state,
      anchorEl: anchorEl.currentTarget,
      user_id,
    });
  };

  handleClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });
  };

  handleSelect = (event, action) => {
    this.setState({
      ...this.state,
      anchorEl: null,
    });

    const data = {
      status: action,
    };

    try {
      this.setState({ loading: true }, async () => {
        try {
          await axiosInstance.put(`users/${this.state.user_id}`, data);
          this.setState({
            ...this.state,
            loading: false,
          });
          toast.success('Información del proveedor actializada con éxito!');
          this.loadInfoData();
        } catch (error) {
          // Error 😨
          if (error.response) {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          } else {
            toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            this.setState({ ...this.state, loading: false });
          }
        } finally {
          this.setState({ loading: false });
        }
      });
    } catch (e) {
      alert(e.message);
    } finally {
      this.setState({ ...this.state, loading: false });
    }
  };

  handleGetInfoToExportExcel = () => {
    this.setState({ loading: true }, () => {
      axiosInstance
        .get(`providers?filter=${this.state.search}&page=0&size=${this.state.paginator.totalItems}`)
        .then(get => {
          if (get.data) {
            var temp = [];
            get.data.providers.map(element => {
              element.openDepartments = false;
              var departments = '';

              element.departments.map((ele, index) => {
                if (index !== element.departments.length - 1) {
                  departments += ele.department.name + ' | ';
                } else {
                  departments += ele.department.name;
                }
              });

              var warehouses = '';

              element.warehouses.map((ele, index) => {
                if (index !== element.warehouses.length - 1) {
                  warehouses += ele.warehouse.initials + ' | ';
                } else {
                  warehouses += ele.warehouse.initials;
                }
              });
              temp.push({
                id: element.provider_id,
                business_name: element.business_name,
                rfc: element.rfc,
                status:
                  element.status === 'active' ? 'Activo' : element.status === 'inactive' ? 'Inactivo' : 'Pendiente',
                ubicacion: element.address,
                departments,
                warehouses,
                schedules: element.schedule,
                created_at: moment(element.created_at).format('DD/MM/YYYY'),
              });
            });
            this.setState({
              ...this.state,
              listProvidersExport: temp,
              loading: false,
            });

            this.clickExportFile();
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => this.setState({ loading: false }));
    });
  };

  clickExportFile = () => {
    document.getElementById('file-export').click();
  };

  handleSort = async (field, direction) => {
    let order = '';
    if (this.state.order.split(' ')[0] === field) {
      order = `${field} ${this.state.order.split(' ')[1] === 'ASC' ? 'DESC' : 'ASC'}`;
    } else {
      order = `${field} ASC`;
    }
    this.setState({
      ...this.state,
      order,
      paginator: {
        ...this.state.paginator,
        page: 1,
      },
    });

    this.loadInfoData(null, order);
  };

  render() {
    const rowsProviders = this.state.listProviders.map(provider => {
      return (
        <div className='row-table row-body' key={provider.id}>
          <Grid container spacing={1}>
            <Grid item md={1}>
              <div className='item-provider'>{provider.provider_id}</div>
            </Grid>
            <Grid item xs={2} className='mobilNone'>
              <div className='item-provider'>{provider.business_name}</div>
            </Grid>
            <Grid item xs={2} className='mobilNone'>
              <div className='item-provider'>{provider.rfc}</div>
            </Grid>
            <Grid item xs={2} className='mobilNone'>
              <div>
                <div
                  className={`status-provider status-provider-product-admin ${
                    provider.status === 'pending' ? 'pending' : provider.status === 'inactive' ? 'inactive' : 'active'
                  }`}
                >
                  {provider.status === 'aproved_adviser' ? (
                    <>
                      <Button
                        className='menu-status'
                        aria-controls='simple-menu'
                        aria-haspopup='true'
                        onClick={e => this.handleClick(e, provider.user_id)}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} className='estado'>
                            <span className='desk item-status-provider'>
                              {provider.status === 'pending'
                                ? 'Pendiente'
                                : provider.status === 'inactive'
                                ? 'Inactivo'
                                : provider.status === 'aproved_adviser'
                                ? 'Aprobado'
                                : 'Activo'}
                              <img src={img_icon_arrow_logout} alt='' />
                            </span>
                            <span className='mobile item-status-provider'>
                              {provider.status === 'pending'
                                ? 'P'
                                : provider.status === 'inactive'
                                ? 'I'
                                : provider.status === 'aproved_adviser'
                                ? 'AP'
                                : 'AC'}
                              <img src={img_icon_arrow_logout} alt='' />
                            </span>
                          </Grid>
                        </Grid>
                      </Button>
                      <Menu
                        id='long-menu'
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={e => this.handleClose(e)}
                      >
                        <MenuItem onClick={event => this.handleSelect(event, 'active')}> Activo </MenuItem>
                        <MenuItem onClick={event => this.handleSelect(event, 'inactive')}> Inactivo </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12} className='estado'>
                          <span className='desk item-status-provider' style={{ paddingTop: '2px' }}>
                            {provider.status === 'pending'
                              ? 'Pendiente'
                              : provider.status === 'inactive'
                              ? 'Inactivo'
                              : provider.status === 'aproved_adviser'
                              ? 'Aprobado'
                              : 'Activo'}
                          </span>
                          <span className='mobile item-status-provider' style={{ paddingTop: '2px' }}>
                            {provider.status === 'pending'
                              ? 'P'
                              : provider.status === 'inactive'
                              ? 'I'
                              : provider.status === 'aproved_adviser'
                              ? 'AP'
                              : 'AC'}
                          </span>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {provider.contact_business_phone}
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {provider.contact_business_email}
                            </div>
                        </Grid> */}
            {/* <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {provider.address}
                            </div>
                        </Grid> */}

            <Grid item xs={1} className='mobilNone'>
              <div className='item-provider'>
                {provider.departments.length > 1 ? (
                  <>
                    {provider.openDepartments ? (
                      <>
                        {provider.departments.map((department, index) => {
                          return (
                            <div className='text-item-table-privider product department' key={index}>
                              {department.department.name}
                              <br />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {provider.departments.map((department, index) => {
                          if (index < 1) {
                            return (
                              <div className='text-item-table-privider product department' key={index}>
                                {department.department.name}
                                <br />
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                    <div className='icon-more' onClick={e => this.handleOpenDepartments(provider)}>
                      {provider.openDepartments ? <Visibility /> : <VisibilityOff />}
                    </div>
                  </>
                ) : (
                  <>
                    {provider.departments.map((department, index) => {
                      return (
                        <div className='text-item-table-privider product department' key={index}>
                          {department.department.name}
                          <br />
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </Grid>
            <Grid item xs={1} className='mobilNone'>
              <div className='item-provider'>
                <>
                  {provider.warehouses.map((warehouse, index) => {
                    return (
                      <div className='text-item-table-privider product department' key={index}>
                        {warehouse.warehouse.initials}
                        <br />
                      </div>
                    );
                  })}
                </>
              </div>
            </Grid>
            <Grid item xs={1} className='mobilNone'>
              <div className='item-provider'>{provider.schedule}</div>
            </Grid>
            <Grid item xs={1} className='mobilNone'>
              <div className='item-provider'>{moment(provider.created_at).format('DD/MM/YYYY')}</div>
            </Grid>
            <Grid item xs={1}>
              <div className='item-provider'>
                <div className='icon-action-provider'>
                  <Tooltip title='Ver detalle' className='tooltip' onClick={e => this.handleEditProduct(provider.id)}>
                    <img src={icon_detail} alt='' srcset='' />
                  </Tooltip>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    });

    return (
      <>
        <HeaderAdmin
          title='Proveedores'
          icon={icon_list_products}
          user={this.state.user}
          search={this.handleSearch}
          textSearch='Buscar proveedor'
          view='providers'
          onSearchButton={this.handleSearchButton}
          handleGetInfoToExportExcel={this.handleGetInfoToExportExcel}
        />
        {this.state.loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <ExcelFile element={<button id='file-export'></button>} filename='Lista proveedores'>
              <ExcelSheet data={this.state.listProvidersExport} name='Hoja 1'>
                <ExcelColumn label='ID' value='id' />
                <ExcelColumn label='Nombre' value='business_name' />
                <ExcelColumn label='RFC' value='rfc' />
                <ExcelColumn label='Estado' value='status' />
                <ExcelColumn label='Ubicación' value='ubicacion' />
                <ExcelColumn label='Departamentos' value='departments' />
                <ExcelColumn label='Bodegas' value='warehouses' />
                <ExcelColumn label='Horarios' value='schedules' />
                <ExcelColumn label='Fecha de registro' value='created_at' />
              </ExcelSheet>
            </ExcelFile>
            <div className='body-provider'>
              <Container>
                <div className='row-table row-title'>
                  <Grid container spacing={1}>
                    <Grid item xs={5} md={1}>
                      <div className='title-table-orders' onClick={e => this.handleSort('provider_id')}>
                        ID
                        <img
                          src={
                            this.state.order.split(' ')[0] === 'provider_id'
                              ? this.state.order.split(' ')[1] === 'ASC'
                                ? icon_order_asc
                                : icon_order_desc
                              : icon_order
                          }
                          alt='Ordenar'
                          style={{ paddingLeft: '5px' }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={2}>
                      <div className='title-table-orders mobilNone' onClick={e => this.handleSort('business_name')}>
                        Nombre de proveedor
                        <img
                          src={
                            this.state.order.split(' ')[0] === 'business_name'
                              ? this.state.order.split(' ')[1] === 'ASC'
                                ? icon_order_asc
                                : icon_order_desc
                              : icon_order
                          }
                          alt='Ordenar'
                          style={{ paddingLeft: '5px' }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={2} onClick={e => this.handleSort('rfc')}>
                      <div className='title-table-orders mobilNone'>
                        RFC
                        <img
                          src={
                            this.state.order.split(' ')[0] === 'rfc'
                              ? this.state.order.split(' ')[1] === 'ASC'
                                ? icon_order_asc
                                : icon_order_desc
                              : icon_order
                          }
                          alt='Ordenar'
                          style={{ paddingLeft: '5px' }}
                        />
                      </div>
                    </Grid>
                    <Grid item md={2} onClick={e => this.handleSort('status')}>
                      <div className='title-table-orders mobilNone'>
                        Estado
                        <img
                          src={
                            this.state.order.split(' ')[0] === 'status'
                              ? this.state.order.split(' ')[1] === 'ASC'
                                ? icon_order_asc
                                : icon_order_desc
                              : icon_order
                          }
                          alt='Ordenar'
                          style={{ paddingLeft: '5px' }}
                        />
                      </div>
                    </Grid>
                    {/* <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                Contanco de proveedor
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                Correo comercial
                                            </div>
                                        </Grid> */}
                    {/* <Grid item md={1}>
                                                <div className="title-table-orders mobilNone">
                                                    Ubicación
                                                </div>
                                            </Grid> */}
                    <Grid item xs={1}>
                      <div className='title-table-orders'>Departamentos</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className='title-table-orders'>Bodegas</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className='title-table-orders'>Horarios</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className='title-table-orders' onClick={e => this.handleSort('created_at')}>
                        Fecha de registro
                        <img
                          src={
                            this.state.order.split(' ')[0] === 'created_at'
                              ? this.state.order.split(' ')[1] === 'ASC'
                                ? icon_order_asc
                                : icon_order_desc
                              : icon_order
                          }
                          alt='Ordenar'
                          style={{ paddingLeft: '5px' }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <div className='title-table-orders'>Acciones</div>
                    </Grid>
                  </Grid>
                </div>
                <div className='providers'>
                  {rowsProviders}
                  <div className='paginator'>
                    <Pagination
                      hidePrevButton
                      hideNextButton
                      count={this.state.paginator.totalPages}
                      page={this.state.paginator.page}
                      onChange={this.handleChangePage}
                    />
                  </div>
                </div>
              </Container>
            </div>
            <Footer />
          </>
        )}
        <ToastContainer />
      </>
    );
  }

  componentDidMount() {
    this.loadInfoData();
  }
}

export default ListProvidersManager;
