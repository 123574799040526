import React from 'react';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

// images
import img_close_modal from '../../../../../assets/images/close-modal.png';
import moment from 'moment';

export default function ModalChangeShedule({ open, scheduleSuggest, onClose, onAcept }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        ><Fade in={open}>
                <div className="modal-delete">
                    <div className="icon-close" onClick={onClose}>
                        <div className="title-modal-provider">
                            Cambio de horarios
                            <img src={img_close_modal} alt="Cerrar" />
                        </div>
                    </div>
                    <div className="documents-modal-provider">
                        <div className="container-load-products container-modal-detele">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="label">
                                        Orden de compra
                                    </div>
                                    <div className="item-privider">
                                        {scheduleSuggest?.purchase_order_id}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="label">
                                        Bodega de entrega
                                    </div>
                                    <div className="item-privider">
                                        {scheduleSuggest?.warehouse}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="label">
                                        Fecha de entrega
                                    </div>
                                    <div className="item-privider">
                                        {moment(scheduleSuggest?.date).format('DD/MM/YYYY')}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="label">
                                        Horario
                                    </div>
                                    <div className="item-privider">
                                        {scheduleSuggest?.hour}
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="container-btn-save" onClick={onClose}>
                                        <button className="button-cancel">
                                            Cancelar
                                        </button>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="container-btn-save">
                                        <button className="button-delete" onClick={onAcept}>
                                            Aceptar
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}