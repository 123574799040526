import React, { useState, useEffect } from 'react';
import ExportReact from 'react-export-excel';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ErrorIcon from '@material-ui/icons/Error';
import { red } from '@material-ui/core/colors';
import Link from '@material-ui/core/Link';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// images
import icon_transport from '../../../../../assets/images/icon-transport-title.png';
import icon_order from '../../../../../assets/images/icon-order.png';
import icon_order_desc from '../../../../../assets/images/icon-order-desc.png';
import icon_order_asc from '../../../../../assets/images/icon-order-asc.png';
import img_icon_cancel from '../../../../../assets/images/icon-cancel.png';
import img_icon_edit from '../../../../../assets/images/edit.png';
import icon_detail from '../../../../../assets/images/icon-detail.png';
import img_icon_export from '../../../../../assets/images/icon-export.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import HeaderProvider from "../../header/Header";
import axiosInstance from "../../../../../utils/axios";
import ModalCancelSheduleDelivery from './Cancel';
import ModalChangeShedule from './ChangeSchedule';

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;

export default function ListScheduledDeliveries() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('scheduled_delivery_id DESC');
    const [search, setSearch] = useState('');
    const [onSearch, setOnSearch] = useState(false);
    const [paginator, setPaginator] = useState({
        page: 1,
        totalPages: 0,
        size: 5
    });
    const [tabValue, setTabValue] = useState(0);

    const [openModaleCancel, setOpenModaleCancel] = useState(false);
    const [openModaleChangeSchedule, setOpenModaleChangeSchedule] = useState(false);
    const [dataModalChange, setDataModalChange] = useState(null);

    const [idCancel, setIdCancel] = useState();
    const [idChange, setIdChange] = useState();
    const [cancel, setCancel] = useState(false);

    const [listScheduledDeliveries, setListScheduledDeliveries] = useState([]);
    const [listScheduledDeliveriesExport, setListScheduledDeliveriesExport] = useState([]);

    const history = useHistory();

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries?type=${tabValue}&filter=${search}&order=${order}&page=${paginator.page - 1}&size=${paginator.size}`,);
            response.data.scheduled_deliveries.map((element) => {
                element.openDepartments = false;
            });
            setListScheduledDeliveries(response.data.scheduled_deliveries);
            setPaginator({
                ...paginator,
                totalPages: response.data.totalPages
            })
            setLoading(false);
        }

        fetch();
    }, [paginator.page, cancel, order, onSearch, tabValue]);

    const generatePackingList = async (id) => {
        try {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/pdf/${id}`);

            // Abre el PDF
            if (response.data.response) {
                window.open(response.data.response.url, '_blank');
            }
            else {
                toast.error('Ha ocurrido un error al generar el pdf');
            }

            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            console.log(error);

            // Error 😨
            if (error && error.response && error.response.data) {
                if (error.response.data.error) {
                    toast.error(error.response.data.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
                else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
    }

    const handleSort = async (field, direction) => {
        console.log(field);
        console.log(order.split(' ')[0]);
        let orderTemp = '';
        if (order.split(' ')[0] === field) {
            orderTemp = `${field} ${order.split(' ')[1] === 'ASC' ? 'DESC' : 'ASC'}`
        } else {
            orderTemp = `${field} ASC`;
        }

        setOrder(orderTemp);
        setPaginator({
            ...paginator,
            page: 1
        })
    }

    const handleSearch = (term) => {
        setSearch(term);
    }

    const handleSearchButton = (text) => {
        setPaginator({
            ...paginator,
            page: 1
        })
        setOnSearch(text);
    }

    const handleGetInfoToExportExcel = () => {
        console.log('exportar');
    }

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    }

    const handleChangeTab = (index) => {
        setTabValue(index);
        setPaginator({
            ...paginator,
            page: 1
        });
    }

    const handleCloseModalCancel = () => {
        setOpenModaleCancel(false);
    }

    const handleCloseModalChangeSchedule = () => {
        setOpenModaleChangeSchedule(false);
    }

    const handleOpenChangeSchedule = (id, data, purchase_order_id) => {
        setIdChange(id);
        setDataModalChange({ ...data, purchase_order_id });
        setOpenModaleChangeSchedule(true);
    }

    const onAcept = async () => {
        setOpenModaleChangeSchedule(false);
        setLoading(true);
        try {
            const data = {
                schedule_id: dataModalChange.id,
            }
            await axiosInstance.put("/scheduled_deliveries/aceptChange/" + idChange, data);
            toast.info('Entrega programda modificada con éxito!');
            setTimeout(() => {
                setLoading(false);
                setCancel(!cancel);
            }, 1000);

        } catch (error) {
            setLoading(false);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
        finally {
            setLoading(false);
        }
    }

    const onCancel = async () => {
        setOpenModaleCancel(false);
        setLoading(true);
        try {
            await axiosInstance.put("/scheduled_deliveries/cancel/" + idCancel);
            toast.info('Entrega programda modificada con éxito!');
            setTimeout(() => {
                setLoading(false);
                setCancel(!cancel);
            }, 1000);

        } catch (error) {
            setLoading(false);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                this.setState({ ...this.state, loading: false });
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                this.setState({ ...this.state, loading: false });
            }
        }
    }

    const rowsScheduledDeliveries = listScheduledDeliveries.map((scheduleDelivery) => {
        return (
            <div className="row-table row-body" key={scheduleDelivery.id}>
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.scheduled_delivery_id}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.purchase_order.purchase_order_id}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {moment(scheduleDelivery.date).format("DD/MM/YYYY")}
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.departments.length > 2 ? (
                                <>
                                    {scheduleDelivery.openDepartments ? (
                                        <>
                                            {scheduleDelivery.departments.map((department, index) => {
                                                return (
                                                    <>
                                                        <div className="text-item-table-privider product department" key={index}>
                                                            {department}
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {scheduleDelivery.departments.map((department, index) => {
                                                if (index < 2) {
                                                    return (
                                                        <>
                                                            <div className="text-item-table-privider product department" key={index}>
                                                                {department}
                                                            </div>
                                                        </>
                                                    );
                                                }

                                            })}
                                        </>
                                    )}
                                    <div className="icon-more" onClick={(e) => this.handleOpenDepartments(scheduleDelivery)}>
                                        {scheduleDelivery.openDepartments ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {scheduleDelivery.departments.map((department, index) => {
                                        return (
                                            <>
                                                <div className="text-item-table-privider product department" key={index}>
                                                    {department}
                                                </div>
                                            </>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.cant}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.warehouse.name}
                        </div>
                    </Grid>
                    <Grid item xs={tabValue === 0 ? 1 : 2}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.hour}
                            {scheduleDelivery.scheduleSuggest && (
                                <Tooltip title="Existe una sugerencia de cambio de horario" className="tooltip" onClick={() => handleOpenChangeSchedule(scheduleDelivery.id, scheduleDelivery.scheduleSuggest, scheduleDelivery.purchase_order.purchase_order_id)}>
                                    <ErrorIcon style={{ color: red[400], marginTop: '-10px' }} />
                                </Tooltip>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{ width: '120px' }} className={`status-provider ${scheduleDelivery.status.key === 'pending' ? 'pending' : scheduleDelivery.status.key === 'delivered' || scheduleDelivery.status.key === 'partial_delivery' ? 'active' : 'inactive'}`}>
                            <div className="item-provider item-status ">
                                <span className="desk">
                                    {scheduleDelivery.status.key === 'pending' ? 'Por entregar' : scheduleDelivery.status.key === 'delivered' ? 'Entregado' : scheduleDelivery.status.key === 'partial_delivery' ? 'Entrega parcial' : 'Cancelado'}
                                </span>
                                <span className="mobile">
                                    {scheduleDelivery.status.key === 'pending' ? 'PE' : scheduleDelivery.status.key === 'delivered' ? 'EN' : 'CA'}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={tabValue === 0 ? 2 : 1}>
                        {scheduleDelivery.status.key === 'pending' ? (
                            <div style={{paddingLeft:'40px'}}>
                                <div className="icon-action-provider" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Tooltip title="Editar" className="tooltip" onClick={(e) => history.push(`/provider/deliveries/edit/${scheduleDelivery.id}`)}>
                                        <img src={img_icon_edit} alt="" />
                                    </Tooltip>
                                </div>
                                <div className="icon-action-provider" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Tooltip title="Cancelar" className="tooltip" onClick={(e) => { setOpenModaleCancel(true); setIdCancel(scheduleDelivery.id) }}>
                                        <img src={img_icon_cancel} alt="" />
                                    </Tooltip>
                                </div>
                                <div className="icon-action-provider" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                                    <Tooltip title="Ver detalle" className="tooltip" onClick={(e) => history.push(`/provider/deliveries/detail/${scheduleDelivery.id}`)}>
                                        <img src={icon_detail} alt="" />
                                    </Tooltip>
                                </div>
                                <Tooltip title="Descargar oc en PDF" className="tooltip">
                                    <div className="icon-export-provider">
                                        <Link className="text-load" target="_blank" onClick={() => generatePackingList(scheduleDelivery.id)}>
                                            <img src={img_icon_export} alt="" />
                                            <br />
                                            PDF
                                        </Link>
                                    </div>
                                </Tooltip>
                            </div>
                        ) : scheduleDelivery.status.key === 'delivered' || scheduleDelivery.status.key === 'partial_delivery' ? (
                            <>
                                <div className="icon-action-provider">
                                    <Tooltip title="Descargar oc en PDF" className="tooltip">
                                        <div className="icon-export-provider">
                                            <Link className="text-load" target="_blank" onClick={() => generatePackingList(scheduleDelivery.id)}>
                                                <img src={img_icon_export} alt="" />
                                                <br />
                                                PDF
                                            </Link>
                                        </div>
                                    </Tooltip>
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    });

    return (
        <>
            <HeaderProvider
                title="Entregas programadas"
                icon={icon_transport}
                user={user}
                search={handleSearch}
                textSearch='Buscar entregas programadas'
                view='scheduled_deliveries'
                onSearchButton={(e) => handleSearchButton()}
                handleGetInfoToExportExcel={handleGetInfoToExportExcel} />
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <ExcelFile element={<button id="file-export"></button>} filename="Lista de entregas programadas">
                        <ExcelSheet data={listScheduledDeliveriesExport} name="Hoja 1">
                            <ExcelColumn label="# Orden de compra sugerida" value="suggest_order_id" />
                            <ExcelColumn label="# Orden de compra" value="purchase_order_id" />
                            <ExcelColumn label="Fecha de creación" value="created_at" />
                            <ExcelColumn label="Departamentos" value="departments" />
                            <ExcelColumn label="Unidades" value="cant" />
                            <ExcelColumn label="Monto total" value="total" />
                            <ExcelColumn label="Estado de pago" value="status_pay" />
                            <ExcelColumn label="Estado de OC" value="status_oc" />
                        </ExcelSheet>
                    </ExcelFile>
                    <div className="body-provider">
                        <Tabs
                            variant="standard"
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            className="bgtabs">
                            <Tab label="Programadas" onClick={() => handleChangeTab(0)} />
                            <Tab label="Finalizadas" onClick={() => handleChangeTab(1)} />
                        </Tabs>
                        <Container>
                            <div className="row-table row-title">
                                <Grid container>
                                    <Grid item xs={9} md={1}>
                                        <div className="title-table-orders sort" onClick={(e) => handleSort('scheduled_delivery_id')}>
                                            # id Packlist<img src={order.split(' ')[0] === 'scheduled_delivery_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={1}>
                                        <div className="title-table-orders sort" onClick={(e) => handleSort('purchase_order_id')}>
                                            # Orden de compra<img src={order.split(' ')[0] === 'purchase_order_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('date')}>
                                            Fecha de entrega<img src={order.split(' ')[0] === 'date' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className="title-table-orders mobilNone">
                                            Departamento
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('cant')}>
                                            Unidades<img src={order.split(' ')[0] === 'cant' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone sort">
                                            Bodega
                                        </div>
                                    </Grid>
                                    <Grid item md={tabValue === 0 ? 1 : 2}>
                                        <div className="title-table-orders mobilNone">
                                            Horario
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('status_id')}>
                                            Estado de entrega <img src={order.split(' ')[0] === 'status_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={tabValue === 0 ? 2 : 1}>
                                        <div className="title-table-orders text-center">
                                            Acciones
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="providers">
                                {rowsScheduledDeliveries}
                                <div className="paginator">
                                    <Pagination
                                        hidePrevButton
                                        hideNextButton
                                        count={paginator.totalPages}
                                        page={paginator.page}
                                        onChange={handleChangePage} />
                                </div>
                            </div>
                        </Container>
                    </div>
                    <Footer />
                </>
            )}
            <ModalCancelSheduleDelivery
                open={openModaleCancel}
                onClose={handleCloseModalCancel}
                onCancel={onCancel} />

            <ModalChangeShedule
                open={openModaleChangeSchedule}
                onClose={handleCloseModalChangeSchedule}
                onAcept={onAcept}
                scheduleSuggest={dataModalChange} />
        </>
    )
}