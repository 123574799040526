import React, { useEffect, useState } from 'react';
import { Container, Grid } from '@material-ui/core';

// importaciones
import { currencyFormat } from '../../utils/pipes';


export default function FormDetailOrder({role, order, listProductSelected, onSubmit, handleSubmit, openModalCancel, openModalApprovedOC }) {

    const [listProductSelect, setListProductSelect] = useState([]);
    function handleSumTotal() {
        let sum = 0;
        for (const item of listProductSelect) {
            if (!isNaN(item.total))
                sum += item.total;
        }

        let ret = !isNaN(sum) ? parseFloat(sum) : 0;

        return ret;
    }

    useEffect(() => {
        setListProductSelect(listProductSelected)
    }, [listProductSelected])

    const isEven = (score) => !isNaN(score);
    return (
        <>
            <div className="body-provider">
                <Container>
                    <div className="row-table row-title">
                        <Grid container spacing={1}>
                            <Grid item md={4}>
                                <div className="title-table-orders">
                                    Nombre del producto
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    #SKU
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    Departamento
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    precio
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">

                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    CANTIDAD
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    TOTAL
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="providers">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {listProductSelect.map((product, index) => (
                                <>
                                    <div className="row-table row-body" key={index}>
                                        <Grid container spacing={1}>
                                            <Grid item md={4}>
                                                <div className="item-provider">
                                                    <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                        {product.product.description}
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    #{product.product.sku}
                                                </div>
                                            </Grid>
                                            <Grid item xs={2} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.product.product_subcategory?.product_category?.department?.name || 'Deparment'}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.unit_price ? currencyFormat(product?.unit_price) : ''}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.unit_price ? 'IVA-IEPS' : ''}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px', textAlign: "center" }}>
                                                    {product.cant}
                                                </div>
                                            </Grid>
                                            <Grid item xs={1} className="mobilNone">
                                                <div className="item-provider" style={{ paddingTop: '20px' }}>
                                                    {product.total ? currencyFormat(product?.total) : ''}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>)
                            )}

                            {listProductSelect?.length > 0 ? (
                                <>
                                    <div className="total-orders">
                                        Total orden de compra: <span>{currencyFormat(handleSumTotal())}</span>
                                    </div>
                                </>
                            ) : (<></>)}
                            {
                                role !== 'super_admin' &&
                                <>
                                    {role !== "receipt" && <>
                                        {order?.status_ocs && order?.status_ocs[0]?.key !== 'aproved_oc' && order?.status_ocs[0]?.key !== 'cancel' &&
                                            <button onClick={() => openModalApprovedOC()} type="submit"
                                                    className="btnDetails rigth">
                                                <div className="text">
                                                    Aprobar orden de compra
                                                </div>
                                            </button>
                                        }
                                        {order?.status_ocs && order?.status_ocs[0]?.key !== 'cancel'  &&
                                            <button onClick={() => openModalCancel()} className="btnAddProduct rigth" style={{ minWidth: '300px' }}>
                                                <div className="text">
                                                    Rechazar orden de compra
                                                </div>
                                            </button>
                                        }

                                    </>}

                                </>
                            }
                        </form>
                    </div>
                </Container>
            </div>
        </>
    );
}