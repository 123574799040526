import React from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ActionTable from '../action-table';
import Loading from '../loading/Loading';
import { customStyles } from './config';

//imagenes
import icon_detail from '../../assets/images/icon-detail.png';
import icon_off from '../../assets/images/icon_person_off.png';
import icon_on from '../../assets/images/icon_person.png';
import icon_edit from '../../assets/images/edit.png';
import jwtDecode from 'jwt-decode';

const renderActions = ({
  row,
  handleClickEdit = null,
  handleClickView = null,
  handleClickRemove = null,
  handleClickCancel = null,
}) => {
  const decoded = jwtDecode(localStorage.getItem('token') || '');

  const disabled = row.status_id === '0021a567-66a4-4147-8f4c-4a54287d9304';
  const newStatus = disabled ? '0021a567-66a4-4147-8f4c-4a54287d9305' : '0021a567-66a4-4147-8f4c-4a54287d9304';
  const role = row.role_users[0].role.name;

  return (
    <>
      {decoded.role.key === 'provider' ? (
        <>
          {disabled ? (
            <span
              style={{
                width: '44px',
                textAlign: 'center',
              }}
            >
              {'-'}
            </span>
          ) : (
            <>
              {handleClickEdit && (
                <ActionTable title='Editar' icon={icon_edit} handleClick={handleClickEdit} id={row.id} />
              )}
              {handleClickView && (
                <ActionTable title='Ver detalle' icon={icon_detail} handleClick={handleClickView} id={row.id} />
              )}
            </>
          )}

          {handleClickRemove && (
            <ActionTable
              title={disabled ? 'Activar' : 'Desactivar'}
              icon={disabled ? icon_off : icon_on}
              handleClick={handleClickRemove}
              id={row.id}
              status={newStatus}
              role={role}
            />
          )}
        </>
      ) : (
        <p>-</p>
      )}
    </>
  );
};

export default function DataCRUD({
  url,
  data,
  columns,
  handleSort,
  loading,
  handleClickEdit = null,
  hideActions = null,
  handleClickView = null,
  handleClickRemove = null,
  handleClickCancel = null,
}) {
  let columnsWithActions;
  if (!hideActions) {
    const actions = {
      name: 'Acciones',
      cell: row =>
        renderActions({
          row,
          url,
          handleClickEdit,
          handleClickView,
          handleClickRemove,
          handleClickCancel,
        }),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    };

    columnsWithActions = [...columns, actions];
  } else {
    columnsWithActions = [...columns];
  }

  return (
    <DataTable
      customStyles={customStyles}
      columns={columnsWithActions}
      data={data}
      noDataComponent={``}
      onSort={handleSort}
      persistTableHead
      sortServer
      striped
      progressPending={loading}
      progressComponent={<Loading />}
    />
  );
}
