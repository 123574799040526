import React, { useState, useEffect } from 'react';
import ExportReact from 'react-export-excel';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router';
import tableIcon from './../../../../../assets/images/tableIcon.png';
import calendarIcon from './../../../../../assets/images/calendarIcon.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// images
import icon_transport from '../../../../../assets/images/icon-transport-title.png';
import icon_order from '../../../../../assets/images/icon-order.png';
import icon_order_desc from '../../../../../assets/images/icon-order-desc.png';
import icon_order_asc from '../../../../../assets/images/icon-order-asc.png';
import img_icon_cancel from '../../../../../assets/images/icon-cancel.png';
import img_icon_edit from '../../../../../assets/images/edit.png';
import icon_detail from '../../../../../assets/images/icon-detail.png';
import img_icon_export from '../../../../../assets/images/icon-export.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import HeaderProvider from "../../header";
import axiosInstance from "../../../../../utils/axios";
import ModalCancelSheduleDelivery from '../cancel';
import { currencyFormat } from "../../../../../utils/pipes";
import DeliveriesCalendar from "../../../../../components/deliveriesCalendar";

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;

export default function ListScheduledDeliveries() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [providers, setProviders] = useState([]);
    const [providerID, setProviderID] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('scheduled_delivery_id DESC');
    const [paginator, setPaginator] = useState({
        page: 1,
        totalPages: 0,
        size: 5
    });
    const [typeView, setTypeView] = useState('table');
    const [tabValue, setTabValue] = useState(0);

    const [openModaleCancel, setOpenModaleCancel] = useState(false);
    const [idCancel, setIdCancel] = useState();
    const [cancel, setCancel] = useState(false);
    const [search, setSearch] = useState("");

    const [listScheduledDeliveries, setListScheduledDeliveries] = useState([]);
    const [schedulesCalendar, setSchedulesCalendar] = useState([]);
    const [listScheduledDeliveriesExport, setListScheduledDeliveriesExport] = useState([]);

    const history = useHistory();

    const loadScheduledDeliveries = () => {
        setLoading(true);
        axiosInstance.get(`scheduled_deliveries/getAll/list?type=${tabValue}&filter=${search}&order=${order}&page=${(paginator.page - 1)}&size=${paginator.size}`,)
            .then((get) => {
                if (get.data) {
                    setListScheduledDeliveries(get.data.scheduled_deliveries);
                    setPaginator({
                        ...paginator,
                        totalPages: get.data.totalPages
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => setLoading(false))
    }

    const generatePackingList = async (id) => {
        try {
            setLoading(true);
            let response = await axiosInstance.get(`scheduled_deliveries/pdf/${id}`);

            // Abre el PDF
            if (response.data.response) {
                window.open(response.data.response.url, '_blank');
            }
            else {
                toast.error('Ha ocurrido un error al generar el pdf');
            }

            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            console.log(error);

            // Error 😨
            if (error && error.response && error.response.data) {
                if (error.response.data.error) {
                    toast.error(error.response.data.msg || 'Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
                else {
                    toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                }
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
    }
    
    useEffect(() => {
        loadScheduledDeliveries()
    }, [paginator.page, order, search, tabValue])

    useEffect(() => {
        if (typeView === 'calendar') {
            handleInfoCalendar();
        }
    }, [typeView])

    const handleInfoCalendar = () => {
        setLoading(true);
        axiosInstance.get(`scheduled_deliveries/getAll/calendar?filter=${search}`,)
            .then((get) => {
                if (get.data) {
                    setSchedulesCalendar(get.data);
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            .finally(() => setLoading(false))
    }

    const handleSearch = (term) => {
        setSearch(term);
    }

    const handleSearchButton = (text) => {
        setPaginator({
            ...paginator,
            page: 1
        })
        console.log(text);
    }

    const clickExportFile = () => {
        document.getElementById('file-export').click();
    }

    const handleGetInfoToExportExcel = () => {
        axiosInstance.get(`scheduled_deliveries?filter=${search}&order=${order}&page=0&size=${paginator.size}`,)
            .then((get) => {
                if (get.data) {
                    let temp = [];

                    get.data?.scheduled_deliveries.map((element, i) => {
                        var departments = '';
                        element.departments.map((ele, index) => {
                            if (index !== (element.departments.length - 1)) {
                                departments += ele + ' | ';
                            } else {
                                departments += ele;
                            }
                        });

                        temp.push({
                            scheduled_delivery_id: element.scheduled_delivery_id,
                            purchase_order_id: element.purchase_order?.purchase_order_id,
                            date: moment(element.date).format("DD/MM/YYYY"),
                            departments,
                            cant: element.cant,
                            hour: element.hour,
                            status: element.status.name,
                            warehouse: element.warehouse.name
                        });
                    });

                    setListScheduledDeliveriesExport(temp);
                    clickExportFile();
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                if (error.response) {
                    if (error.response.data.error) {
                        toast.error(error.response.data.msg);
                    } else {
                        toast.info(error.response.data.msg);
                    }
                }
            })

    }

    const handleChangePage = (event, value) => {
        setPaginator({
            ...paginator,
            page: value
        });
    }

    const handleChangeTab = (index) => {
        setTabValue(index);
        setPaginator({
            ...paginator,
            page: 1
        });
    }

    const handleSort = async (field, direction) => {
        let orderL = '';
        if (order.split(' ')[0] === field) {
            orderL = `${field} ${order.split(' ')[1] === 'ASC' ? 'DESC' : 'ASC'}`
        } else {
            orderL = `${field} ASC`;
        }
        console.log(orderL);
        setOrder(orderL);
        setPaginator((prev) => ({ ...prev, page: 1 }));
    }

    const handleOpenDepartments = (scheduleDelivery) => {
        const data = [];
        listScheduledDeliveries.forEach((element) => {
            if (scheduleDelivery.id === element.id) {
                element.openDepartments = !element.openDepartments;
            } else {
                element.openDepartments = false;
            }
            data.push(element);
        });

        setListScheduledDeliveries(data);
    }

    const handleCloseModalCancel = () => {
        setOpenModaleCancel(false);
    }

    const onCancel = async () => {
        setOpenModaleCancel(false);
        setLoading(true);
        try {
            await axiosInstance.put("/scheduled_deliveries/cancel/" + idCancel);
            toast.info('Entrega programda modificada con éxito!');
            setTimeout(() => {
                setLoading(false);
                setCancel(!cancel);
            }, 1000);

        } catch (error) {
            setLoading(false);
            // Error 😨
            if (error.response) {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            } else {
                toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
            }
        }
        finally {
            setLoading(false);
        }
    }

    const rowsScheduledDeliveries = listScheduledDeliveries.map((scheduleDelivery) => {
        return (
            <div className="row-table row-body" key={scheduleDelivery.id}>
                <Grid container spacing={1}>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.scheduled_delivery_id}
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.purchase_order.purchase_order_id}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {moment(scheduleDelivery.date).format("DD/MM/YYYY")}
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.departments.length > 2 ? (
                                <>
                                    {scheduleDelivery.openDepartments ? (
                                        <>
                                            {scheduleDelivery.departments.map((department, index) => {
                                                return (
                                                    <>
                                                        <div className="text-item-table-privider product department" key={index}>
                                                            {department}
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {scheduleDelivery.departments.map((department, index) => {
                                                if (index < 2) {
                                                    return (
                                                        <>
                                                            <div className="text-item-table-privider product department" key={index}>
                                                                {department}
                                                            </div>
                                                        </>
                                                    );
                                                }

                                            })}
                                        </>
                                    )}
                                    <div className="icon-more" onClick={(e) => this.handleOpenDepartments(scheduleDelivery)}>
                                        {scheduleDelivery.openDepartments ? <Visibility /> : <VisibilityOff />}
                                    </div>
                                </>
                            ) : (
                                <>
                                    {scheduleDelivery.departments.map((department, index) => {
                                        return (
                                            <>
                                                <div className="text-item-table-privider product department" key={index}>
                                                    {department}
                                                </div>
                                            </>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.cant}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.warehouse.name}
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <div className="item-provider mobilNone">
                            {scheduleDelivery.hour}
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div style={{ width: '120px' }} className={`status-provider ${scheduleDelivery.status.key === 'pending' ? 'pending' : scheduleDelivery.status.key === 'delivered' || scheduleDelivery.status.key === 'partial_delivery' ? 'active' : 'inactive'}`}>
                            <div className="item-provider item-status ">
                                <span className="desk">
                                    {scheduleDelivery.status.key === 'pending' ? 'Por entregar' : scheduleDelivery.status.key === 'delivered' ? 'Entregado' : scheduleDelivery.status.key === 'partial_delivery' ? 'Entrega parcial' : 'Cancelado'}
                                </span>
                                <span className="mobile">
                                    {scheduleDelivery.status.key === 'pending' ? 'PE' : scheduleDelivery.status.key === 'delivered' ? 'EN' : scheduleDelivery.status.key === 'partial_delivery' ? 'EP' : 'CA'}
                                </span>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        {scheduleDelivery.status.key === 'pending' ? (
                            <>
                                <div className="icon-action-provider">
                                    <Tooltip title="Cancelar" className="tooltip" onClick={(e) => { setOpenModaleCancel(true); setIdCancel(scheduleDelivery.id) }}>
                                        <img src={img_icon_cancel} alt="" />
                                    </Tooltip>
                                </div>
                                <div className="icon-action-provider">
                                    <Tooltip title="Ver detalle" className="tooltip" onClick={(e) => history.push(`/receipt/deliveries/detail/${scheduleDelivery.id}`)}>
                                        <img src={icon_detail} alt="" />
                                    </Tooltip>
                                </div>
                            </>
                        ) : scheduleDelivery.status.key === 'delivered' || scheduleDelivery.status.key === 'partial_delivery' ? (
                            <>
                                <div className="icon-action-provider">
                                    <Tooltip title="Descargar oc en PDF" className="tooltip">
                                        <div className="icon-export-provider">
                                        <Link className="text-load" target="_blank" onClick={() => generatePackingList(scheduleDelivery.id)}>
                                                <img src={img_icon_export} alt="" />
                                                <br />
                                                PDF
                                            </Link>
                                        </div>
                                    </Tooltip>
                                </div>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    });

    return (
        <>
            <HeaderProvider
                title="Entregas programadas"
                icon={icon_transport}
                user={user}
                search={handleSearch}
                textSearch='Buscar entregas programadas'
                view='scheduled_deliveries_receipt'
                listProviders={providers}
                onSearchButton={(e) => handleSearchButton()}
                handleGetInfoToExportExcel={handleGetInfoToExportExcel} />
            {loading ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    <ExcelFile element={<button id="file-export"></button>} filename="Lista de entregas programadas">
                        <ExcelSheet data={listScheduledDeliveriesExport} name="Hoja 1">
                            <ExcelColumn label="ID PACKLIST" value="scheduled_delivery_id" />
                            <ExcelColumn label="Orden de compra" value="purchase_order_id" />
                            <ExcelColumn label="Fecha de entrega" value="date" />
                            <ExcelColumn label="Departamentos" value="departments" />
                            <ExcelColumn label="Unidades" value="cant" />
                            <ExcelColumn label="Bodega" value="warehouse" />
                            <ExcelColumn label="Horario" value="hour" />
                            <ExcelColumn label="Estado de entrega" value="status" />
                        </ExcelSheet>
                    </ExcelFile>
                    <div className="body-provider">
                        <Container>
                            <div style={{ display: "flex", padding: 10 }}>
                                <div><span style={{ color: "rgba(45, 72, 56, .5)", fontSize: 12 }}>VISTA</span></div>
                                <div style={{ marginLeft: 15, marginRight: 15, cursor: 'pointer' }} onClick={() => setTypeView('table')}>
                                    <span style={{
                                        color: "rgba(45, 72, 56, 1)",
                                        fontSize: 12,
                                        padding: 5,
                                        backgroundColor: typeView === 'table' && "rgba(242, 245, 245, 1)",
                                        borderRadius: 5
                                    }}>
                                        <img src={tableIcon} alt="icon table" style={{ marginRight: 4, height: 8.5 }} />
                                        TABLAS
                                    </span>
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => setTypeView('calendar')}>
                                    <div style={{
                                        color: "rgba(45, 72, 56, 1)",
                                        fontSize: 12,
                                        padding: 5,
                                        backgroundColor: typeView === 'calendar' && "rgba(242, 245, 245, 1)",
                                        borderRadius: 5
                                    }}>
                                        <img src={calendarIcon} alt="icon calendar" style={{ marginRight: 4 }} />
                                        CALENDARIO
                                    </div>
                                </div>
                            </div>
                            {typeView === 'table' ? <> <div className="row-table row-title">
                                <Tabs
                                    variant="standard"
                                    value={tabValue}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className="bgtabs">
                                    <Tab label="Programadas" onClick={() => handleChangeTab(0)} />
                                    <Tab label="Finalizadas" onClick={() => handleChangeTab(1)} />
                                </Tabs>
                                <br />
                                <br />
                                <Grid container>
                                    <Grid item xs={9} md={1}>
                                        <div className="title-table-orders sort" onClick={(e) => handleSort('scheduled_delivery_id')}>
                                            # id Packlist<img src={order.split(' ')[0] === 'scheduled_delivery_id_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={9} md={2}>
                                        <div className="title-table-orders sort" onClick={(e) => handleSort('purchase_order_id')}>
                                            # Orden de compra<img src={order.split(' ')[0] === 'purchase_order_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('date')}>
                                            Fecha de entrega<img src={order.split(' ')[0] === 'date' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className="title-table-orders mobilNone">
                                            Departamento
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('cant')}>
                                            Unidades<img src={order.split(' ')[0] === 'cant' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('warehouse_id')}>
                                            Bodega<img src={order.split(' ')[0] === 'warehouse_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item md={1}>
                                        <div className="title-table-orders mobilNone">
                                            Horario
                                        </div>
                                    </Grid>
                                    <Grid item md={2}>
                                        <div className="title-table-orders mobilNone sort" onClick={(e) => handleSort('status_id')}>
                                            Estado de entrega <img src={order.split(' ')[0] === 'status_id' ? order.split(' ')[1] === 'ASC' ? icon_order_asc : icon_order_desc : icon_order} alt="Ordenar" style={{ paddingLeft: '5px' }} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={2} md={1}>
                                        <div className="title-table-orders text-center">
                                            Acciones
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                                <div className="providers">
                                    {rowsScheduledDeliveries}
                                    <div className="paginator">
                                        <Pagination
                                            hidePrevButton
                                            hideNextButton
                                            count={paginator.totalPages}
                                            page={paginator.page}
                                            onChange={handleChangePage} />
                                    </div>
                                </div> </> : <DeliveriesCalendar deliveries={schedulesCalendar} />}
                        </Container>
                    </div>
                    <Footer />
                </>
            )}
            <ModalCancelSheduleDelivery
                open={openModaleCancel}
                onClose={handleCloseModalCancel}
                onCancel={onCancel} />
            <ToastContainer />
        </>
    )
}