import React, { Component } from "react";

// importar estilos
import './footer.css';

class Footer extends Component {
    render() {
        return (
            <>
                <div className="footer">
                    <ul>
                        <li>© 2021 Jüsto</li>
                        <li>Términos y condiciones</li>
                        <li>Avisos de privacidad</li>
                    </ul>
                </div>
            </>
        );
    }
}

export default Footer;