import React, { Component } from "react";
import { Container, Grid } from '@material-ui/core';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// imagenes
import icon_list_incidences from '../../../../../assets/images/icon-list-incidences.png';
import icon_detail from '../../../../../assets/images/icon-detail.png';

// importaciones
import Footer from "../../../../../components/footer/Footer";
import Loading from '../../../../../components/loading/Loading';
import axiosInstance from "../../../../../utils/axios";
import HeaderAdmin from "../../header";

class ListIncidencesManager extends Component {

    constructor(props) {
        super(props);

        this.state = {
            listIncidences: [],
            listTopics: [],
            listSubTopics: [],
            formIncidence: {
                topic_id: '0'
            },
            openModalIncidence: false,
            loading: false,
            user: JSON.parse(localStorage.getItem('user')),
            search: '',
            order: 'invoice_id DESC',
            paginator: {
                page: 1,
                size: 5,
                totalPages: 0,
                totalItems: 0
            },
            tabValue: 0
        }
    }

    loadInfoData = () => {

        this.setState({ loading: true }, () => {
            axiosInstance.get(`incidences?role=receipt&filter=${this.state.search}&order=${this.state.order}&page=${(this.state.paginator.page - 1)}&size=${this.state.paginator.size}`,)
                .then((get) => {
                    if (get.data) {
                        this.setState({
                            listIncidences: get.data.incidences,
                            loading: false,
                            paginator: {
                                ...this.state.paginator,
                                page: get.data.currentPage + 1,
                                totalItems: get.data.totalItems,
                                totalPages: get.data.totalPages
                            }
                        })
                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    handleChangePage = (event, value) => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: value
            }
        });
        this.loadInfoData();
    };

    handleSearch = (term) => {
        this.setState({
            ...this.state,
            search: term
        });
    }

    handleSearchButton = () => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        });
        this.loadInfoData();
    }

    changeOpenModal = (value) => {
        this.setState({
            ...this.state,
            openModalIncidence: value
        });
    }

    handleCloseModalIncidence = () => {
        this.changeOpenModal(false);
    }

    handleOpenModalIncidence = () => {
        this.changeOpenModal(true);
    }

    handleViewDetail = (id) => {
        this.props.history.push(`/receipt/incidences/detail/${id}`)
    }

    handleChangeTab = (event, value) => {
        this.setState({
            ...this.state,
            tabValue: value
        });
    }

    render() {
        const rowsIncidences = this.state.listIncidences.map((incidence) => {
            if (incidence.status !== 'close')
                return (
                    <div className="row-table row-body" key={incidence.id}>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    {incidence.invoice_id}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="item-provider">
                                    {incidence.title}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="item-provider">
                                    {incidence.subtopic.topic.name}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={`status-incidences status-provider ${incidence.status === 'answer_justo' ? 'active' : incidence.status === 'close' ? 'inactive' : 'pending'}`}>
                                    <div className="item-provider item-status">
                                        <span className="desk">
                                            {incidence.status === 'answer_justo' ? 'Respuesta Jüsto' : incidence.status === 'close' ? 'Cerrada' : 'Esperando respuesta'}
                                        </span>
                                        <span className="mobile">
                                            {incidence.status === 'answer_justo' ? 'RJ' : incidence.status === 'close' ? 'CE' : 'ER'}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="item-provider">
                                    {incidence.creator_user[0].name}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    {incidence.discharge_date ? moment(incidence.discharge_date).format("DD/MM/YYYY") : '-'}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    {incidence.last_reply_date ? moment(incidence.last_reply_date).format("DD/MM/YYYY") : '-'}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    <div className="icon-action-provider">
                                        <Tooltip title="Ver detalle" className="tooltip" onClick={(e) => this.handleViewDetail(incidence.id)}>
                                            <img src={icon_detail} alt="" srcset="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div >
                )
        });

        const rowsIncidencesClose = this.state.listIncidences.map((incidence) => {
            if (incidence.status === 'close')
                return (
                    <div className="row-table row-body" key={incidence.id}>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    {incidence.invoice_id}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="item-provider">
                                    {incidence.title}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="item-provider">
                                    {incidence.subtopic.topic.name}
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className={`status-incidences status-provider ${incidence.status === 'answer_justo' ? 'active' : incidence.status === 'close' ? 'inactive' : 'pending'}`}>
                                    <div className="item-provider item-status">
                                        <span className="desk">
                                            {incidence.status === 'answer_justo' ? 'Respuesta Jüsto' : incidence.status === 'close' ? 'Cerrada' : 'Esperando respuesta'}
                                        </span>
                                        <span className="mobile">
                                            {incidence.status === 'answer_justo' ? 'RJ' : incidence.status === 'close' ? 'CE' : 'ER'}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="item-provider">
                                    {incidence.creator_user[0].name}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    {incidence.discharge_date ? moment(incidence.discharge_date).format("DD/MM/YYYY") : '-'}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    {incidence.last_reply_date ? moment(incidence.last_reply_date).format("DD/MM/YYYY") : '-'}
                                </div>
                            </Grid>
                            <Grid item xs={1}>
                                <div className="item-provider">
                                    <div className="icon-action-provider">
                                        <Tooltip title="Ver detalle" className="tooltip" onClick={(e) => this.handleViewDetail(incidence.id)}>
                                            <img src={icon_detail} alt="" srcset="" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div >
                )
        });
        return (
            <>
                <HeaderAdmin
                    title="Incidencias"
                    icon={icon_list_incidences}
                    user={this.state.user}
                    search={this.handleSearch}
                    textSearch='Buscar incidencias'
                    view='incidences'
                    onSearchButton={(e) => this.handleSearchButton()} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <div className="body-provider">
                            <Tabs
                                variant="standard"
                                value={this.state.tabValue}
                                onChange={this.handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                className="bgtabs"
                            >
                                <Tab label="Abiertas" />
                                <Tab label="Cerradas" />
                            </Tabs>
                            {this.state.tabValue === 0 ? (
                                <>
                                    <Container>
                                        <div className="row-table row-title">
                                            <Grid container spacing={1}>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders" onClick={(e) => this.handleOpenModalIncidence()}>
                                                        Folio
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Incidencia
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Tipo
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Estado
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Usuario
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders">
                                                        Fecha de alta
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders">
                                                        última respuesta
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders">
                                                        Acciones
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="providers">
                                            {rowsIncidences}
                                            <div className="paginator">
                                                <Pagination
                                                    hidePrevButton
                                                    hideNextButton
                                                    count={this.state.paginator.totalPages}
                                                    page={this.state.paginator.page}
                                                    onChange={this.handleChangePage} />
                                            </div>
                                        </div>
                                    </Container>
                                </>
                                ) : (
                                <>
                                    <Container>
                                        <div className="row-table row-title">
                                            <Grid container spacing={1}>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders" onClick={(e) => this.handleOpenModalIncidence()}>
                                                        Folio
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Incidencia
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Tipo
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Estado
                                                    </div>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <div className="title-table-orders">
                                                        Usuario
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders">
                                                        Fecha de alta
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders">
                                                        última respuesta
                                                    </div>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <div className="title-table-orders">
                                                        Acciones
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="providers">
                                            {rowsIncidencesClose}
                                            <div className="paginator">
                                                <Pagination
                                                    hidePrevButton
                                                    hideNextButton
                                                    count={this.state.paginator.totalPages}
                                                    page={this.state.paginator.page}
                                                    onChange={this.handleChangePage} />
                                            </div>
                                        </div>
                                    </Container>
                                </>
                            )}
                        </div>
                        <Footer />
                    </>
                )}
            </>
        )
    }

    componentDidMount() {
        this.loadInfoData();
    }
}

export default ListIncidencesManager;