import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import DataCRUD from '../DataCRUD';
import ModalDeletUser from './Delete';
import { toast } from 'react-toastify';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function ListUserProvider(props) {
  const [listUsers, setListUser] = useState([]);
  const [provider, setProvider] = useState();
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState('name ASC');
  const [{ idDelete, data }, setIdDelete] = useState({ idDelete: '', data: {} });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const history = useHistory();

  const handleOpenModules = provider => {
    const data = [];
    listUsers.forEach(element => {
      if (provider.id === element.id) {
        element.openModules = !element.openModules;
      } else {
        element.openModules = false;
      }
      data.push(element);
    });

    setListUser(data);
  };

  const columnsModel = [
    {
      name: 'Nombre',
      sortField: 'name',
      selector: row => row?.name,
    },
    {
      name: 'Correo',
      sortField: 'email',
      selector: row => row?.email,
    },
    {
      name: 'Puesto',
      sortField: 'sku',
      selector: row =>
        row?.role_users[0].role.name === 'provider'
          ? ''
          : row?.role_users[0].role.name === 'provider_financial'
          ? provider?.contact_financial_workstation
          : row?.role_users[0].role.name === 'provider_logistic'
          ? provider?.contact_logistic_workstation
          : row?.role_users[0].role.name === 'provider_business'
          ? provider?.contact_business_workstation
          : provider?.contact_other_workstation,
    },
    {
      name: 'Rol',
      sortField: 'role',
      selector: row =>
        row?.role_users[0].role.name === 'provider' ? 'Proveedor Administrador' : row?.role_users[0].role.description,
    },
    {
      name: 'Módulos',
      sortField: 'modules',
      selector: row =>
        row?.role_users[0].role.name === 'provider' ? (
          'Todos'
        ) : (
          <div className='item-provider'>
            {row?.role_groups.length > 1 ? (
              <>
                {row.openModules ? (
                  <>
                    {row.role_groups.map((role_group, index) => {
                      return (
                        <div className='text-item-table-privider product department' key={index}>
                          {role_group.group.group_permissions[0].permission.module.name}
                          <br />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {row.role_groups.map((role_group, index) => {
                      if (index < 1) {
                        return (
                          <div className='text-item-table-privider product department' key={index}>
                            {role_group.group.group_permissions[0].permission.module.name}
                            <br />
                          </div>
                        );
                      }
                    })}
                  </>
                )}
                <div className='icon-more' onClick={e => handleOpenModules(row)}>
                  {row.openModules ? <Visibility /> : <VisibilityOff />}
                </div>
              </>
            ) : (
              <>
                {row.role_groups.map((role_group, index) => {
                  return (
                    <div className='text-item-table-privider product department' key={index}>
                      {role_group.group.group_permissions[0].permission.module.name}
                      <br />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        ),
    },
  ];

  useEffect(() => {
    async function fetch() {
      const resp = await axiosInstance.get(
        `/providers/info/users${props.providerId != null ? '?providerId=' + props.providerId : ''}`
      );

      const activeUsers = (resp?.data?.users || []).filter(u => u.status_id === '0021a567-66a4-4147-8f4c-4a54287d9305'); // Only Filter Active Users

      setListUser(resp.data.users);
      props.onCantUsers(activeUsers.length);
      setProvider(resp.data.provider);
    }
    fetch();
  }, [sort, isDelete]);

  const sortFunction = (column, sortDirection) => {
    setSort(`${column.sortField} ${sortDirection}`);
  };

  const handleEditUser = id => {
    history.push(
      `${props.providerId != null ? '/super_admin/providers/' + props.providerId : '/provider/profile'}/user/edit/${id}`
    );
  };

  const handleRemoveUser = async (idDelete, status, uRole) => {
    const listRol = await axiosInstance.get(`roles/provider`);
    const providerUsers = (await axiosInstance.get('/providers/info/users'))?.data?.users || [];

    const usedRoles = providerUsers
      .filter(u => u.status_id === '0021a567-66a4-4147-8f4c-4a54287d9305') // Only Filter Active Users
      .map(u => u.role_users[0].role.name); // Get Role Names

    const filteredRoles = listRol.data.filter(rol => !usedRoles.includes(rol.name));
    const isDisabled = status !== '0021a567-66a4-4147-8f4c-4a54287d9304';

    // Usuario Desactivado y Rol no Disponible
    if (isDisabled)
      if (!filteredRoles.some(role => role.name === uRole))
        return toast.error('Ya hay un usuario activo con este rol.');

    let newStatus = {
      idDelete,
      data: {
        status_id: status,
        role: { name: uRole },
      },
    };

    const user = (await axiosInstance.get(`providers/info/users/${idDelete}`))?.data?.user || {};

    newStatus.data = { ...user, ...newStatus.data };

    setIdDelete(newStatus);
    setOpenModalDelete(true);
  };

  const handleDelete = async () => {
    setOpenModalDelete(false);

    try {
      await axiosInstance.put('providers/info/usersStatus/' + idDelete, data);
      toast.info('Usuario actualizado con éxito!');
      setIsDelete(!isDelete);
    } catch (error) {
      console.log(error.response.data.error);
      if (error.response) {
        if (error.response.data.error) {
          toast.error(error.response.data.msg);
        } else {
          toast.info(error.response.data.msg);
        }
      }
    } finally {
      props.updateProfile();
      setLoading(false);
    }
  };

  return (
    <div style={{ minHeight: '90vh' }}>
      <DataCRUD
        url='/users'
        data={listUsers}
        columns={columnsModel}
        handleSort={sortFunction}
        handleClickEdit={handleEditUser}
        handleClickRemove={handleRemoveUser}
        loading={loading}
      />
      <ModalDeletUser open={openModalDelete} onClose={() => setOpenModalDelete(false)} onSubmit={handleDelete} />
    </div>
  );
}
