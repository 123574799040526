import React, {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';

// images
import img_close_modal from '../../../../assets/images/close-modal.png';
import axiosInstance from '../../../../utils/axios';

const listNumberEmployees = [
    { value: '00:00 AM - 12:00 PM', label: '00:00 AM - 12:00 PM' },
    { value: '12:00 AM - 6:00 PM', label: '12:00 AM - 6:00 PM' },
    { value: '6:00 AM - 12:00 PM', label: '6:00 AM - 12:00 PM' },
    { value: '6:00 PM - 12:00 PM', label: '6:00 PM - 12:00 PM' },
    { value: '12:00 AM - 6:00 AM', label: '12:00 AM - 6:00 AM' },
];

export default function RescheduleDeliveryModal({ open, handleSaveData, listDataToModal, onClose, scheduled_delivery_id }) {

    const [listShedules, setListShedules] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        getValues,
        setValue
    } = useForm();

    const onSubmit = async (values) => {
        const warehouse = values.warehouse.value;
        const date = values.date;
        const hour = values.hour.value;
        handleSaveData(warehouse, date, hour);
    }
    useEffect(()=> {
        async function loadWarehouses(){
            const response = await axiosInstance.get(`warehouses`);
            setWarehouses(response.data.map((warehosuse) => { return { value: warehosuse.id, label: warehosuse.name } }));
        }
        loadWarehouses();
    },[])

    const loadSchedules = async () => {
        let data = [];
        for (let index = 0; index < listDataToModal.length; index++) {
            data.push({
                product_id: listDataToModal[index].product_id,
                department: listDataToModal[index].product?.product_subcategory?.product_category?.department?.id,
                cant: listDataToModal[index].cant
            })
        }

        const body = {
            date: getValues('date'),
            warehouse_id: getValues('warehouse')?.value,
            products: data
        }

        const list = await axiosInstance.post('/scheduled_deliveries/calculateScheduled', body);
        setListShedules(list.data);
    }

    return (
        <Modal
            open={open}
            onClose={()=>onClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="modal-content"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        ><Fade in={open}>
            <div className="modal-delete">
                <div className="icon-close" onClick={onClose}>
                    <div className="title-modal-provider">
                        Reagendar Entrega
                        <img src={img_close_modal} alt="Cerrar" />
                    </div>
                </div>
                <div className="documents-modal-provider">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="container-load-products container-modal-detele">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div className="label-order">
                                        Entrega programada
                                    </div>
                                    <div className="item-privider">
                                        {scheduled_delivery_id}
                                    </div>
                                    <div className="container-select-day"  style={{paddingTop:6}}>
                                        <div className="label-order">
                                            Seleccionar día de entrega
                                        </div>
                                        <div className="item-privider">
                                        <Controller
                                            name='date'
                                            control={control}
                                            {...register('date',
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido',
                                                    }
                                                })
                                            }
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    onChange={e => {
                                                        field.onChange(e);
                                                        loadSchedules();
                                                    }}
                                                    min={new Date().toISOString().split("T")[0]}
                                                    type="date"
                                                    className="input-register"
                                                />
                                            )}
                                        />
                                        {errors.date && (
                                            <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                {errors.date.message}
                                            </p>
                                        )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="label-order">
                                        Bodega de entrega
                                    </div>
                                    <div className="item-provider">
                                        <Controller
                                            control={control}
                                            render= {
                                                ({ field }) => (
                                                    <Select
                                                        {...field}
                                                        id="warehouses"
                                                        placeholder={'Seleccione una opción'}
                                                        options={warehouses}
                                                    />
                                                )
                                            }
                                            name="warehouse"
                                        />
                                    </div>
                                    <div className="container-select-day">

                                        <div className="label-order">
                                            Seleccionar horario de entrega
                                        </div>
                                        <div className="item-privider">
                                        <Controller
                                            name='hour'
                                            control={control}
                                            {...register('hour', {
                                                required: {
                                                    value: false,
                                                    message: 'Este campo es requerido',
                                                },
                                            })}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    placeholder={'Seleccione un horario'}
                                                    options={listShedules.map((item) => ({
                                                        value: `${item.start_date} - ${item.end_date}`,
                                                        label: `${item.start_date} - ${item.end_date}`
                                                    }))}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                        {errors.hour && (
                                            <p className="text-red-700  text-xs error-text" style={{ marginTop: '0px' }}>
                                                {errors.hour.message}
                                            </p>
                                        )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6}>
                                    <div className="container-btn-save">
                                        <button className="button-delete" type="submit">
                                            Agendar entrega
                                        </button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
        </Fade>
        </Modal>
    );
}