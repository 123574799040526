import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import ExportReact from 'react-export-excel';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

//importaciones
import Footer from "../../../../../components/footer/Footer";
import axiosInstance from "../../../../../utils/axios";
import HeaderAdmin from '../../header/Header';
import Loading from '../../../../../components/loading/Loading';
import { currencyFormat } from '../../../../../utils/pipes';
import DataCRUD from '../../../../../components/DataCRUD';

// imagenes
import icon_list_products from '../../../../../assets/images/icon-list-products.png';
import icon_edit from '../../../../../assets/images/edit.png';
import icon_delete from '../../../../../assets/images/delete.png';
import img_close_modal from '../../../../../assets/images/close-modal.png';
import img_icon_arrow_logout from '../../../../../assets/images/icon-arrow-logout.png';

// estilos
import './list-products-admin.css';

const ExcelFile = ExportReact.ExcelFile;
const ExcelSheet = ExportReact.ExcelFile.ExcelSheet;
const ExcelColumn = ExportReact.ExcelFile.ExcelColumn;


const columnsModel = [
    {
        name: 'Nombre del producto',
        sortField: 'description',
        selector: row => row?.description,
        sortable: true,
    },
    {
        name: 'UPC',
        sortField: 'upc',
        selector: row => `#${row?.upc}`,
        sortable: true,
    },
    {
        name: 'Departamento',
        sortField: 'department',
        selector: row => row?.product_subcategory?.product_category?.department?.name,
        sortable: true,
    },
    {
        name: 'Precio',
        sortField: 'net_unit_cost',
        selector: row => currencyFormat(row?.net_unit_cost),
        sortable: true,
    },
    {
        name: 'Precio sugerido',
        sortField: 'public_sale_price',
        selector: row => currencyFormat(row?.public_sale_price),
        sortable: true,
    },
    {
        name: 'Impuestos',
        selector: () => 'IVA - IEPS',
    },
    {
        name: 'Estado',
        sortField: 'status_id',
        selector: row => row?.status_id,
        cell: row =>
            row?.status_id.key === 'pending' ? (
                <div className="status-provider pending" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Pendiente
                        </span>
                        <span className="mobile">
                            PE
                        </span>
                    </div>
                </div>
            ) : row?.status_id.key === 'aproved_adviser' ? (
                <div className="status-provider active" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Preaprobado
                        </span>
                        <span className="mobile">
                            PA
                        </span>
                    </div>
                </div>
            ) : (
                <div className="status-provider active" style={{ width: '95px' }}>
                    <div style={{ paddingTop: '5px' }}>
                        <span className="desk">
                            Activo
                        </span>
                        <span className="mobile">
                            AC
                        </span>
                    </div>
                </div>
            ),
        sortable: true,
    },
];

class ListProductsAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listProviders: [],
            listProducts: [],
            listProductsExport: [],
            loading: false,
            anchorEl: null,
            user: JSON.parse(localStorage.getItem('user')),
            search: '',
            sort: 'description ASC',
            paginator: {
                page: 1,
                size: 5,
                totalPages: 0,
                totalItems: 0
            },
            product_id: '',
            provider_id: '',
            providerId: '',
            idDelete: '',
            openModaleDelete: false,
        }
    }

    handleSearch = (term) => {
        this.setState({
            ...this.state,
            search: term
        })
    }

    handleSearchButton = () => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: 1,
            }
        });
        this.handleChangeProviders(this.state.provider_id);
    }

    handleOpenDeleteModal = (id) => {
        this.setState({
            ...this.state,
            idDelete: id,
            openModaleDelete: true
        });
    }

    handleCloseModal = () => {
        this.setState({
            ...this.state,
            idDelete: '',
            openModaleDelete: false
        });
    }

    handleClick = (anchorEl, product_id) => {
        this.setState({
            ...this.state,
            anchorEl: anchorEl.currentTarget,
            product_id
        });
    };

    handleClose = () => {
        this.setState({
            ...this.state,
            anchorEl: null
        });
    };

    handleCloseModalSave = () => {
        this.setState({
            ...this.state,
            openModaleDelete: false
        });
    };

    handleSelect = (event, action) => {
        this.setState({
            ...this.state,
            anchorEl: null
        });

        const data = {
            status: action
        }

        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.put(`products/${this.state.product_id}`, data);
                    this.setState({
                        ...this.state,
                        loading: false
                    });
                    toast.success("Información del producto actializada con éxito!");
                    this.handleChangeProviders(this.state.provider_id);
                } catch (error) {
                    // Error 😨
                    if (error.response) {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }

    }

    handleGetInfoToExportExcel = () => {
        if (this.state.provider_id !== '') {
            this.setState({ loading: true }, () => {
                axiosInstance.get(`products/byProvider/${this.state.provider_id}?filter=${this.state.search}&order=${this.state.sort}&page=0&size=${this.state.paginator.totalItems}`,)
                    .then((get) => {
                        if (get.data) {
                            var temp = [];
                            get.data.products.map((element, i) => {
                                temp.push({
                                    numberProduct: i + 1,
                                    sku: element.sku,
                                    upc: element.upc,
                                    barcode_piece: element.barcode_piece,
                                    product_classification: element.product_classification,
                                    sub_category: element.product_subcategory.product_category.department.name,
                                    product_brand: element.product_brand.name,
                                    description: element.description,
                                    variety: element.variety,
                                    packing: element.packing,
                                    grammage: element.grammage,
                                    unit: element.unit,
                                    product_explanation: element.product_explanation,
                                    image_link: element.image_download_link,
                                    barcode_box: element.barcode_box,
                                    sat_key: element.sat_key,
                                    refill_type: element.refill_type,
                                    number_pieces_box: element.number_pieces_box,
                                    gross_cost_box: element.gross_cost_box ? currencyFormat(element.gross_cost_box) : null,
                                    gross_cost_piece: element.gross_cost_piece ? currencyFormat(element.gross_cost_piece) : null,
                                    ieps: element.ieps,
                                    iva: element.tax ? Number(element.tax) * 100 + "%" : null,
                                    net_unit_cost: element.net_unit_cost ? currencyFormat(element.net_unit_cost) : null,
                                    public_sale_price: element.public_sale_price ? currencyFormat(element.public_sale_price) : null,
                                    margin: element.margin ? Number(element.margin) * 100 + "%" : null,
                                    sales_price_competition: element.sales_price_competition ? currencyFormat(element.sales_price_competition) : null,
                                    difference_competence: element.difference_competence ? Number(element.difference_competence) * 100 + "%" : null,
                                    monthly_sale_estimate: element.monthly_sale_estimate,
                                    id_provider: element.provider.provider_id,
                                    business_name: element.provider.business_name,
                                    rfc: element.provider.rfc,
                                    receiving_temperature: element.receiving_temperature,
                                    long_box: element.long_box,
                                    width_box: element.width_box,
                                    tall_box: element.tall_box,
                                    weight_box: element.weight_box,
                                    long_piece: element.long_piece,
                                    width_piece: element.width_piece,
                                    tall_piece: element.tall_piece,
                                    weight_piece: element.weight_piece,
                                    boxes_per_laying: element.boxes_per_laying,
                                    laying_per_platform: element.laying_per_platform,
                                    life_of_anaquel: element.life_of_anaquel,
                                    status: element.status === 'active' ? 'Activo' : element.status === 'inactive' ? 'Inactivo' : 'Pendiente'
                                });
                            });
                            this.setState({
                                ...this.state,
                                listProductsExport: temp,
                                loading: false
                            });

                            this.clickExportFile();


                        } else {
                            this.setState({ loading: false })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
        }
    }

    clickExportFile = () => {
        document.getElementById('file-export').click();
    }

    handleDelete = () => {
        this.handleCloseModalSave();
        try {
            this.setState({ loading: true }, async () => {
                try {
                    await axiosInstance.delete(`products/${this.state.idDelete}`);
                    toast.success('¡Producto modificado con éxito!');
                    this.setState({ ...this.state, loading: false });
                    this.handleChangeProviders(this.state.provider_id);
                } catch (error) {
                    // Error 😨
                    if (error.response) {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    } else {
                        toast.error('Ha ocurrido un error en nuestro sistema, intenta nuevamente');
                        this.setState({ ...this.state, loading: false });
                    }
                }
            });
        } catch (e) { alert(e.message); }
    }

    handleChangePage = (event, value) => {
        this.setState({
            paginator: {
                ...this.state.paginator,
                page: value
            }
        });
        this.handleChangeProviders(this.state.provider_id);
    };


    loadInfoData = () => {
        this.setState({ loading: true }, () => {
            axiosInstance.get(`providers/list`,)
                .then((get) => {
                    if (get.data) {

                        let listProviders = get.data.map((provider) => { return { value: provider.id, label: provider.business_name } });

                        listProviders.unshift({ value: 'all', label: 'TODOS' });

                        this.setState({
                            ...this.state,
                            listProviders,
                            loading: false,
                        });
                    } else {
                        this.setState({ loading: false })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    }

    handleChangeProviders = (provider_id) => {
        this.setState({
            providerId: provider_id
        });
        if (provider_id === 'all') {
            this.setState({ loading: true }, () => {
                axiosInstance.get(`products?filter=${this.state.search}&order=${this.state.sort}&page=${(this.state.paginator.page - 1)}&size=${this.state.paginator.size}`,)
                    .then((get) => {
                        if (get.data) {
                            this.setState({
                                listProducts: get.data.products,
                                loading: false,
                                paginator: {
                                    ...this.state.paginator,
                                    page: get.data.currentPage + 1,
                                    totalItems: get.data.totalItems,
                                    totalPages: get.data.totalPages
                                },
                                provider_id
                            })
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
        } else {
            this.setState({ loading: true }, () => {
                axiosInstance.get(`products/byProvider/${provider_id}?filter=${this.state.search}&order=${this.state.sort}&page=${(this.state.paginator.page - 1)}&size=${this.state.paginator.size}`,)
                    .then((get) => {
                        if (get.data) {
                            this.setState({
                                listProducts: get.data.products,
                                loading: false,
                                paginator: {
                                    ...this.state.paginator,
                                    page: get.data.currentPage + 1,
                                    totalItems: get.data.totalItems,
                                    totalPages: get.data.totalPages
                                },
                                provider_id
                            })
                        } else {
                            this.setState({ loading: false })
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            });
        }
    }

    handleEditProduct = (id) => {
        this.props.history.push(`/admin/products/edit/${id}`)
    }

    sortFunction = (column, sortDirection) => {
        console.log(column, sortDirection);
        this.setState({
            sort: `${column.sortField} ${sortDirection}`,
            paginator: {
                ...this.state.paginator,
                page: 1
            }
        })
        this.handleChangeProviders(this.state.providerId);
    }

    render() {
        const rowsProducts = this.state.listProducts.map((product) => {
            return (
                <div className="row-table row-body" key={product.id}>
                    <Grid container spacing={1}>
                        <Grid item xs={8} md={2}>
                            <div className="item-provider">
                                {product.description}
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {product.sku}
                            </div>
                        </Grid>
                        <Grid item xs={2} className="mobilNone">
                            <div className="item-provider">
                                #{product.upc}
                            </div>
                        </Grid>
                        <Grid item xs={2} className="mobilNone">
                            <div className="item-provider">
                                {product.product_subcategory.product_category.department.name}
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {currencyFormat(product.net_unit_cost)}
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {currencyFormat(product.public_sale_price)}
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <div className="item-provider">
                                {product.tax !== '0' ? 'IVA' : ''} - {product.ieps !== '0' ? 'IEPS' : ''}
                            </div>
                        </Grid>
                        <Grid item xs={1} className="mobilNone">
                            <Grid container spacing={1}>
                                <div>
                                    <div className={`status-provider status-provider-product-admin ${product.status.key === 'pending' ? 'pending' : product.status.key === 'inactive' ? 'inactive' : 'active'}`} style={{ width: '115px' }}>
                                        <Button className="menu-status" aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => this.handleClick(e, product.id)}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="estado">
                                                    <span className="desk item-status-provider">
                                                        {product.status.key === 'pending' ? 'Pendiente' : product.status.key === 'inactive' ? 'Inactivo' : product.status.key === 'aproved_adviser' ? 'Preaprobado' : product.status.key === 'aproved_boss' ? 'Aprobado' : 'Activo'}
                                                        <img src={img_icon_arrow_logout} alt="" />
                                                    </span>
                                                    <span className="mobile item-status-provider">
                                                        {product.status.key === 'pending' ? 'P' : product.status.key === 'inactive' ? 'I' : product.status.key === 'aproved_adviser' ? 'PA' : product.status.key === 'aproved_boss' ? 'AP' : 'AC'}
                                                        <img src={img_icon_arrow_logout} alt="" />
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                        <Menu
                                            id="long-menu"
                                            anchorEl={this.state.anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={(e) => this.handleClose(e)}>
                                            <MenuItem onClick={(event) => this.handleSelect(event, 'aproved_adviser')}> Preaprobado </MenuItem>
                                            <MenuItem onClick={(event) => this.handleSelect(event, 'inactive')}> Inactivo </MenuItem>
                                            <MenuItem onClick={(event) => this.handleSelect(event, 'pending')}> Pendiente </MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <div className="item-provider">
                                <div className="icon-action-provider">
                                    <Tooltip title="Editar" className="tooltip" onClick={(e) => this.handleEditProduct(product.id)}>
                                        <img src={icon_edit} alt="" srcset="" />
                                    </Tooltip>
                                </div>
                                <div className="icon-action-provider">
                                    <Tooltip title="Eliminar" className="tooltip" onClick={(e) => this.handleOpenDeleteModal(product.id)}>
                                        <img src={icon_delete} alt="" srcset="" className="icon-remove" />
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            )
        });

        return (
            <>
                <HeaderAdmin
                    title="Catálogo"
                    icon={icon_list_products}
                    user={this.state.user}
                    search={this.handleSearch}
                    textSearch='Buscar producto'
                    view='products'
                    onSearchButton={(e) => this.handleSearchButton()}
                    listProviders={this.state.listProviders}
                    handleChangeProviders={this.handleChangeProviders}
                    handleGetInfoToExportExcel={this.handleGetInfoToExportExcel} />
                {this.state.loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <ExcelFile element={<button id="file-export"></button>} filename="Catálogo de productos">
                            <ExcelSheet data={this.state.listProductsExport} name="Hoja 1">
                                <ExcelColumn label="" value="numberProduct" />
                                <ExcelColumn label="SKU" value="sku" />
                                <ExcelColumn label="UPC" value="upc" />
                                <ExcelColumn label="Codigo de Barra  Pieza (EAN 13)" value="barcode_piece" />
                                <ExcelColumn label="Kilo-pieza  /  Gramaje Sustancia Activa" value="product_classification" />
                                <ExcelColumn label="Subcategoría" value="sub_category" />
                                <ExcelColumn label="Marca" value="product_brand" />
                                <ExcelColumn label="Descripción de producto (qué producto es)" value="description" />
                                <ExcelColumn label="Variedad del Producto (Sabor, ejemplo Adobadas,Natural )" value="variety" />
                                <ExcelColumn label="Empaque( Botella, Envase, Pouch, cartón, lata)" value="packing" />
                                <ExcelColumn label="GRAMAJE" value="grammage" />
                                <ExcelColumn label="UNIDAD" value="unit" />
                                <ExcelColumn label="Explicación de Producto. Entre 50 y 100 palabras. Incluir descripción del producto, beneficios, modo de uso y preparación." value="product_explanation" />
                                <ExcelColumn label="Link de descarga de imagén, UPC como nombre de imagen y fomato JPG" value="image_link" />
                                <ExcelColumn label="Codigo de Barra  Caja (DUN 14)" value="barcode_box" />
                                <ExcelColumn label="Clave SAT" value="sat_key" />
                                <ExcelColumn label="Tipo de Resurtido (I&O/Línea)" value="refill_type" />
                                <ExcelColumn label="Piezas por Caja" value="number_pieces_box" />
                                <ExcelColumn label="Costo Bruto Caja" value="gross_cost_box" />
                                <ExcelColumn label="Costo Bruto Pieza" value="gross_cost_piece" />
                                <ExcelColumn label="IEPS" value="ieps" />
                                <ExcelColumn label="IVA" value="iva" />
                                <ExcelColumn label="COSTO NETO UNITARIO" value="net_unit_cost" />
                                <ExcelColumn label="Precio de Venta Público" value="public_sale_price" />
                                <ExcelColumn label="Margen" value="margin" />
                                <ExcelColumn label="Precio de Venta Competencia" value="sales_price_competition" />
                                <ExcelColumn label="Diferencia vs. Competencia" value="difference_competence" />
                                <ExcelColumn label="Estimado de Venta Mensual (Cajas)" value="monthly_sale_estimate" />
                                <ExcelColumn label="No. Proveedor" value="id_provider" />
                                <ExcelColumn label="Proveedor" value="business_name" />
                                <ExcelColumn label="RFC" value="rfc" />
                                <ExcelColumn label="LARGO Caja [cm]" value="long_box" />
                                <ExcelColumn label="ANCHO Caja [cm]" value="width_box" />
                                <ExcelColumn label="ALTO Caja[cm]" value="tall_box" />
                                <ExcelColumn label="Peso Caja [kg]" value="weight_box" />
                                <ExcelColumn label="LARGO Pieza [cm]" value="long_piece" />
                                <ExcelColumn label="ANCHO Pieza [cm]" value="width_piece" />
                                <ExcelColumn label="ALTO Pieza [cm]" value="tall_piece" />
                                <ExcelColumn label="Peso Pieza [kg]" value="weight_piece" />
                                <ExcelColumn label="Cajas por tendido" value="boxes_per_laying" />
                                <ExcelColumn label="Tendidos por tarima" value="laying_per_platform" />
                                <ExcelColumn label="Vida de anaquel [días]" value="life_of_anaquel" />
                                <ExcelColumn label="Temp Recepción [°C]" value="receiving_temperature" />
                            </ExcelSheet>
                        </ExcelFile>
                        <div className="body-provider body-list">
                            <Container>
                                {/* <div className="row-table row-title">
                                    <Grid container spacing={1}>
                                        <Grid item xs={5} md={2}>
                                            <div className="title-table-orders">
                                                Nombre del producto
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                SKU
                                            </div>
                                        </Grid>
                                        <Grid item md={2}>
                                            <div className="title-table-orders mobilNone">
                                                UPC
                                            </div>
                                        </Grid>

                                        <Grid item md={2}>
                                            <div className="title-table-orders mobilNone">
                                                Departamento
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                costo
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                precio sugerido*
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                IMPUESTOS*
                                            </div>
                                        </Grid>
                                        <Grid item md={1}>
                                            <div className="title-table-orders mobilNone">
                                                Estado
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <div className="title-table-orders">
                                                ACCIONES
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div> */}
                                <DataCRUD
                                    url="/products"
                                    data={this.state.listProducts}
                                    columns={columnsModel}
                                    handleSort={this.sortFunction} />
                                <div className="providers">
                                    {/* {rowsProducts} */}
                                    <div className="paginator">
                                        <Pagination
                                            hidePrevButton
                                            hideNextButton
                                            count={this.state.paginator.totalPages}
                                            page={this.state.paginator.page}
                                            onChange={this.handleChangePage} />
                                    </div>
                                </div>

                            </Container>

                        </div>
                        <Footer />
                    </>
                )}

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal-content"
                    open={this.state.openModaleDelete}
                    onClose={(e) => this.handleCloseModal()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                ><Fade in={this.state.openModaleDelete}>
                        <div className="modal-delete">
                            <div className="icon-close" onClick={(e) => this.handleCloseModal()}>
                                <div className="title-modal-provider">
                                    Eliminar producto
                                    <img src={img_close_modal} alt="Cerrar" />
                                </div>
                            </div>
                            <div className="documents-modal-provider">
                                <div className="container-load-products container-modal-detele">
                                    <div className="label text-center">
                                        ¿Seguro desea eliminar el producto?
                                    </div>

                                    <Grid container spacing={5}>
                                        <Grid item xs={6}>
                                            <div className="container-btn-save" onClick={(e) => this.handleCloseModal()}>
                                                <button className="button-cancel">
                                                    Cancelar
                                                </button>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div className="container-btn-save">
                                                <button className="button-delete" onClick={(e) => this.handleDelete()}>
                                                    Eliminar
                                                </button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }

    componentDidMount() {
        this.loadInfoData();
    }
}

export default ListProductsAdmin;