import React, { useState } from "react";
import { Container, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Close from '@material-ui/icons/Close';
import Search from '@material-ui/icons/Search';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import moment from 'moment';

// Importaciones
import { useAppContext } from "../../../../libs/contextLib";
import SideBarProvider from "../../../../components/sidebar/SideBar";

// importar estilos
import './header.css';

// images
import img_logo from '../../../../assets/images/logo.png';
import img_icon_arrow_logout from '../../../../assets/images/icon-arrow-logout.png';
import img_icon_export_button from '../../../../assets/images/icon-export-button.png';
import img_icon_import_button from '../../../../assets/images/icon-import-button.png';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        width: 584,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        borderRadius: 10,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
        outline: 0,

    },
}));

function HeaderManager(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');

    const history = useHistory();
    const location = useLocation();
    const { userHasAuthenticated } = useAppContext();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        localStorage.clear();
        history.replace('/');
        setAnchorEl(null);
        userHasAuthenticated(false);
    }

    const handleChangeForm = (event) => {
        setSearch(event.target.value);
        props.search(event.target.value);
    }

    const handleClearSearch = () => {
        setSearch('');
        props.search('');
        props.onSearchButton('');
    }

    const handleSubmitSearch = (event) => {
        event.preventDefault();
        props.onSearchButton(search);
    }

    const openModalIncidence = () => {
        history.push('/provider/incidences/new');
    }

    const handleLink = (url) => {
        history.push(url);
    }

    const handleOpenModalImport = () => {
        props.handleOpenModalImport();
    }

    const handleChangeFormSelect = (value, attr) => {
        props.handleChangeProviders(value.value);
    }

    const handleGetInfoToExportExcel = () => {
        props.handleGetInfoToExportExcel();
    }

    const handleSaveInfo = () => {
        props.handleSaveInfo();
    }
    const rowsBreadcrumbs = props.listBreadcrumbs ? props.listBreadcrumbs.map((item, index) => {
        return (index !== props.listBreadcrumbs.length - 1) ? (
            <span key={index} onClick={(e) => handleLink(item.url)} className="item-breadcrumb">
                <span className="link-inactive">{item.title}</span>
                <span className="link-inactive"> &gt; </span>
            </span>
        ) : (
            <span key={index}>
                <span className="link-active">{item.title}</span>
            </span>
        )
    }) : [];

    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={4}>
                    <Grid item xs={6} md={6}>
                        <div className="logo-admin">
                            <img src={img_logo} alt="logo" />
                        </div>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button className="menu" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            {props.user.email} <span className="arrow-logout"><img src={img_icon_arrow_logout} alt="" /></span>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Container>
            <SideBarProvider location={location} />
            {props.type === 'detail' ? (
                <>
                    <div className={`header-provider-detail ${props.view === 'edit-incidences' ? 'edit-incidences' : ''}`}>
                        <div className="box-title-breadcrumbs-provider">
                            <div className="breadcrumbs">
                                {rowsBreadcrumbs}
                            </div>
                            <Container>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <div className="title-header-admin">
                                            {props.title}
                                        </div>
                                    </Grid>
                                    {props.view === 'edit-provider' ? (
                                        <>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <button className={`button-header-new rigth ${props.disabledSubmitButton ? 'disable' : ''}`} onClick={handleSaveInfo}>
                                                            <div className="text">
                                                                Guardar cambios
                                                            </div>
                                                        </button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>) : props.view === 'edit-incidences' ? (
                                            <>
                                                <Grid item xs={4}>
                                                    <div className=" title-header-admin-status ">
                                                        <div className={`status-incidences status-provider ${props.status === 'answer_justo' ? 'active' : props.status === 'close' ? 'inactive' : 'pending'}`}>
                                                            <div className="item-provider item-status">
                                                                <span className="desk">
                                                                    {/* Respuesta Jüsto */}
                                                                    {props.status === 'answer_justo' ? 'Respuesta Jüsto' : props.status === 'close' ? 'Cerrada' : 'Esperando respuesta'}
                                                                </span>
                                                                <span className="mobile">
                                                                    {props.status === 'answer_justo' ? 'RJ' : props.status === 'close' ? 'CE' : 'ER'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Container maxWidth="lg">
                                                    <Grid container spacing={33}>
                                                        <Grid item xs={4}>
                                                            <div className="text-Title">
                                                                Tema departamento de la incidencia
                                                            </div>
                                                            <div className="text">
                                                                {props.themeDepIncidences}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <div className="text-Title">
                                                                Tema
                                                            </div>
                                                            <div className="text">
                                                                {props.theme}
                                                            </div>
                                                        </Grid><Grid item xs={4}>
                                                            <div className="text-Title">
                                                                Fecha de Alta
                                                            </div>
                                                            <div className="text">
                                                                {moment(props.DateIncidence).format('DD/MM/YYYY')}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Container>
                                            </>
                                        ) : (<></>)}
                                </Grid>
                            </Container>

                        </div>
                    </div>


                </>
            ) : (
                <>
                    <div className="header-provider">
                        <Container>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <div className="title-view-header-provider">
                                                <img src={props.icon} alt={props.title} /> {props.title}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {props.view === 'products' ? (
                                                <>
                                                    <Select
                                                        className="select-providers-header"
                                                        id="provider_id"
                                                        name="provider_id"
                                                        placeholder={'Seleccione un proveedor'}
                                                        options={props.listProviders}
                                                        onChange={handleChangeFormSelect} />
                                                </>) : (<></>)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8}>
                                            <div className="con-provider">
                                                <form onSubmit={handleSubmitSearch}>
                                                    <input type="text" className="input-header-search-provider" placeholder={props.textSearch ? props.textSearch : 'Buscar'} name="search" value={search} onChange={(e) => handleChangeForm(e)} />
                                                    {search.length > 0 ? (
                                                        <>
                                                            <Close onClick={handleClearSearch} />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Search />
                                                        </>
                                                    )}
                                                </form>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={6}>
                                    <div className="button-header-provider">
                                        <span> Filtrar</span> <img src={img_icon_arrow_logout} alt="" />
                                    </div>
                                </Grid> */}
                                    </Grid>
                                </Grid>
                                {props.view === 'providers' ? (
                                    <>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <div className="button-header-provider rigth" onClick={handleGetInfoToExportExcel}>
                                                        <span> Exportar</span> <img src={img_icon_export_button} alt="" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : props.view === 'products' ? (
                                    <>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <div className="button-header-provider rigth" onClick={handleGetInfoToExportExcel}>
                                                        <span> Exportar</span> <img src={img_icon_export_button} alt="" />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (<></>)}

                            </Grid>
                        </Container>
                    </div>
                </>)}

        </>
    )
}

export default HeaderManager;