import React, { Component } from 'react';

// importaciones
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

// importar estilos
import './finish.css';

// imagenes
import img_image_one_finish from '../../../assets/images/image_one_finish.png';
import img_image_two_finish from '../../../assets/images/image_two_finish.png';
import img_ready from '../../../assets/images/ready.png';
import img_separator from '../../../assets/images/separator-header-home.png';

class Finish extends Component {
  handleFinish = () => {
    localStorage.clear();
    this.props.history.push('/');
    window.location.reload();
  };
  render() {
    return (
      <>
        <Header type='finish' listBreadcrumbs={[]} />
        <div className='container-finish'>
          <div className='image'>
            <img className='one' src={img_image_one_finish} alt='Imagen' />
          </div>
          <div className='center-card'>
            <div className='card'>
              <img src={img_ready} alt='Listo' />
              <br />
              <br />
              <img src={img_separator} alt='Separator' />
              <br />
              <br />
              <div className='text'>
                Estamos validando tu información para generar tu alta como proveedor.
                <br />
                <br />
                Recibiras una notificación vía correo electrónico sobre el estado de tu solicitud
              </div>
              <br />
              <br />
              <button className='button-finish-finish' onClick={e => this.handleFinish()}>
                <div className='text'> Aceptar</div>
              </button>
            </div>
          </div>
          <div className='image'>
            <img className='two' src={img_image_two_finish} alt='Imagen' />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Finish;
