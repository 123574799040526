import React, { useState } from 'react';
import { Container, Grid } from '@material-ui/core';
import {currencyFormat} from "../../../../../utils/pipes";
import {Controller, useForm} from "react-hook-form";

// importaciones

export default function ListProductsDeliveries({ listProductsView, setReschedule, status, handleConfirm }) {

    const [listProducts, setListProducts] = useState(listProductsView);
    const { register, formState: { errors }, control, handleSubmit } = useForm();

    const isEven = (score) => !isNaN(score);

    const sendSubmit = (values) => {
        if(status?.key !== "delivered" && status?.key !== "partial_delivery"){
            const products = listProducts.map((product, index)=> ({
                product_id:product.product_id, cant: Number(values.cant[index])
            }))
            handleConfirm(products);
        }
    }

    return (
        <>
            <div className="body-provider">
                <form onSubmit={handleSubmit(sendSubmit)}>
                <Container>
                    <div className="row-table row-title">
                        <Grid container spacing={1}>
                            <Grid item xs={5} md={4}>
                                <div className="title-table-orders">
                                    Nombre del producto
                                </div>
                            </Grid>
                            <Grid item md={2}>
                                <div className="title-table-orders mobilNone">
                                    SKU
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    Departamento
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    precio
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    IMPUESTOS*
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    CANTIDAD
                                </div>
                            </Grid>
                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    U. ENTREGADAS
                                </div>
                            </Grid>

                            <Grid item md={1}>
                                <div className="title-table-orders mobilNone">
                                    TOTAL
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="providers">
                        {listProducts?.map((product, index) => (
                            <>
                                <div className="row-table row-body" key={index}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8} md={4} style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider mobilNone">
                                                {product?.product?.description}
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} className="mobilNone" style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider">
                                                #{product?.product?.upc}
                                            </div>
                                        </Grid>
                                        <Grid item xs={1} className="mobilNone" style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider">
                                                {product?.product?.product_subcategory?.product_category?.department?.name}
                                            </div>
                                        </Grid>
                                        <Grid item xs={1} className="mobilNone" style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider">
                                                {product?.product?.net_unit_cost ? currencyFormat(product?.product.net_unit_cost) : ''}
                                            </div>
                                        </Grid>
                                        <Grid item xs={1} style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider">
                                                {product?.product?.net_unit_cost ? 'IVA-IEPS' : ''}
                                            </div>
                                        </Grid>
                                        <Grid item xs={1} className="mobilNone" style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider">
                                                <div className="item-provider mobilNone">
                                                    {product?.cant}
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={1} className="mobilNone">
                                            <div className="item-provider">
                                                {product?.cant_delivered}
                                            </div>
                                        </Grid>
                                        <Grid item xs={1} className="mobilNone" style={{display:"flex",alignItems:"center"}}>
                                            <div className="item-provider">
                                                {currencyFormat(product?.product?.net_unit_cost * product?.cant)}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        ))}
                    </div>

                </Container>
                </form>
            </div>
        </>
    );
}