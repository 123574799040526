import axiosInstance from "./axios";

export function setToken(token){
    localStorage.setItem('token', token);
}

export function getToken(){
    return localStorage.getItem('token');
}

export function isUserAuthenticated() {
    return localStorage.getItem('token') !== null
}

export function deleteToken(){
    localStorage.removeItem('token');
}

export function initAxiosInterceptor(){
    axiosInstance.interceptors.request.use((config) => {
        const token = getToken();

        if(token){
            config.headers.Authorization = token;
        }

        return config;
    });
}
